import React, { Component } from 'react';
import TorGrid from '../torgrid/torgrid';
import { dealAttributeChangesBALService } from '../../bal/Deal-Dashboard/dealAttributeChanges.bal'
import { dealCreditMetricsChangesBALService } from '../../bal/Deal-Dashboard/dealCreditMetricsChanges.bal'
import { dealMacroRiskChangesBALService } from '../../bal/Deal-Dashboard/dealMacroRiskChanges.bal'
import { dealReturnsChangesBALService } from '../../bal/Deal-Dashboard/dealReturnsChanges.bal'
import { dealTrancheAttributeChangesBALService } from '../../bal/Deal-Dashboard/dealTrancheAttributeChanges.bal'
import { dealBALService } from '../../bal/deal.bal';
import { Redirect } from "react-router-dom";
import queryString from 'query-string';

class DealChanges extends Component {
    constructor(props) {
        super(props);

        
        this.approveDeals = this.approveDeals.bind(this);

        let params = queryString.parse(this.props.location.search);
        var deal = ''
        if(params && params.deal){
          deal = params.deal;
        } else {
          deal = this.props?.location?.state?.deal;
        }

        this.state = {
          deal                                  : deal,
          dealId                                : this.props?.location?.state?.dealId,
          dealAttributeChangesList              : [
           /* { 
              Deal : 'APLN',
              column : 'BICS Industry',
              'New Value' : 'Real Estate Investment Trusts (REIT)',
              'Existing Value' :  'Real Estate Owners & Developers'
            }*/
          ],
          dealTrancheAttributeChangesList       : [],
          dealCreditMetricsChangesList          : [],
          dealMacroRiskChangesList              : [],
          dealReturnsChangesList                : [],
        }

        if(deal) { 
          dealAttributeChangesBALService.getRowData({ Deal : deal }).then(data => {
            this.setState({ dealAttributeChangesList: data });
          });
           dealCreditMetricsChangesBALService.getRowData({ Deal : deal }).then(data => {
            this.setState({ dealCreditMetricsChangesList: data });
          });
          dealReturnsChangesBALService.getRowData({ Deal : deal }).then(data => {
            this.setState({ dealReturnsChangesList: data });
          });
          dealMacroRiskChangesBALService.getRowData({ Deal : deal }).then(data => {
            this.setState({ dealMacroRiskChangesList: data });
          });
          dealTrancheAttributeChangesBALService.getRowData({ Deal : deal }).then(data => {
            this.setState({ dealTrancheAttributeChangesList: data });
          }); 
        }         
    }

    render() {
        if(this.state.redirectTo)
          return <Redirect to={ this.state.redirectTo } />;
        return this.getTemplate();
    }

    approveDeals = async () => { 
      await dealBALService.approve([{ dealName: this.state.deal }]);
      this.setState({ redirectTo:{pathname : '/dss' } });
    }
 
    getTemplate() {
      const { deal, dealAttributeChangesList, dealTrancheAttributeChangesList, dealCreditMetricsChangesList, dealMacroRiskChangesList, dealReturnsChangesList } = this.state;
      return (
          <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
            <div style={{ display: "inline-block"}} >
              <div style={{paddingLeft: '25px', paddingTop: '15px', float: "left"}} ><h3>Changes for Deal - {deal}</h3></div>
              <div style={{ paddingTop: '10px', float: "right"}}>
                <button key='approveButton' className="blueB-no-margin" onClick={this.approveDeals}>Approve</button>
              </div>
            </div>
            <div style={{height: '100%', width: '100%', paddingBottom: '25px', paddingLeft: '25px', paddingRight: '25px', overflowX: 'hidden', overflowY:'auto'  }}>
              {
                dealAttributeChangesList && dealAttributeChangesList.length > 0 ? 
                <div style={{paddingTop : '20px'}}>
                    <TorGrid
                        guikey="dealAttributeChanges"
                        isHideDateInputs={true}
                        isHideSaveGridLayout={true}
                        rowData={dealAttributeChangesList}
                        
                        className="ag-theme-alpine"
                        rowGroupPanelShow="none"
                        enableFilter={false}
                        domLayout = "autoHeight"

                        defaultColDef={this.props.defaultColDef ??
                          {
                              sortable: false
                              , filter: false
                              , floatingFilter: false
                              , enableRowGroup: true
                              , resizable: true
                              , suppressSizeToFit: false
                              , flex: 1
                          }
                        }                      
                        sideBar='none'
                        gridTitle='Executive Summary / Deal Attributes'
                    />
                </div>
                :null
              }
              {
                dealCreditMetricsChangesList && dealCreditMetricsChangesList.length > 0 ? 
                <div style={{paddingTop : '20px'}}>
                    <TorGrid
                        guikey="dealCreditMetricsChanges"
                        isHideDateInputs={true}
                        isHideSaveGridLayout={true}
                        rowData={dealCreditMetricsChangesList}
                        
                        className="ag-theme-alpine"
                        rowGroupPanelShow="none"
                        enableFilter={false}
                      
                        domLayout = "autoHeight"

                        defaultColDef={this.props.defaultColDef ??
                          {
                              sortable: false
                              , filter: false
                              , floatingFilter: false
                              , enableRowGroup: true
                              , resizable: true
                              , suppressSizeToFit: false
                              , flex: 1
                          }
                        }                      
                        sideBar='none'
                        gridTitle='Credit Metrics'
                    />
                </div>
                :null
              }
              {
                dealReturnsChangesList && dealReturnsChangesList.length > 0 ? 
                <div style={{paddingTop : '20px'}}>
                    <TorGrid
                        guikey="dealReturnsChanges"
                        isHideDateInputs={true}
                        isHideSaveGridLayout={true}
                        
                        rowData={dealReturnsChangesList}
                      
                        className="ag-theme-alpine"
                        rowGroupPanelShow="none"
                        enableFilter={false}
                        domLayout = "autoHeight"

                        defaultColDef={this.props.defaultColDef ??
                          {
                              sortable: false
                              , filter: false
                              , floatingFilter: false
                              , enableRowGroup: true
                              , resizable: true
                              , suppressSizeToFit: false
                              , flex: 1
                          }
                        }                      
                        sideBar='none'
                        gridTitle='Deal Returns'
                    />
                </div>
                :null
              }
              {
                dealMacroRiskChangesList && dealMacroRiskChangesList.length > 0 ? 
                <div style={{paddingTop : '20px'}}>
                  <TorGrid
                      guikey="dealMacroRiskChanges"
                      isHideDateInputs={true}
                      isHideSaveGridLayout={true}
                      rowData={dealMacroRiskChangesList}
                    
                      className="ag-theme-alpine"
                      rowGroupPanelShow="none"
                      enableFilter={false}
                      domLayout = "autoHeight"

                      defaultColDef={this.props.defaultColDef ??
                        {
                            sortable: false
                            , filter: false
                            , floatingFilter: false
                            , enableRowGroup: true
                            , resizable: true
                            , suppressSizeToFit: false
                            , flex: 1
                        }
                      }                      
                      sideBar='none'
                      gridTitle='Macro Risks'
                  />
              </div>
                :null
              }
              {
                dealTrancheAttributeChangesList && dealTrancheAttributeChangesList.length > 0 ? 
                <div style={{paddingTop : '20px'}}>
                  <TorGrid
                      guikey="dealTrancheAttributeChanges"
                      isHideDateInputs={true}
                      isHideSaveGridLayout={true}
                      rowData={dealTrancheAttributeChangesList}
                      
                      className="ag-theme-alpine"
                      rowGroupPanelShow="none"
                      enableFilter={false}
                      domLayout = "autoHeight"

                      defaultColDef={this.props.defaultColDef ??
                        {
                            sortable: false
                            , filter: false
                            , floatingFilter: false
                            , enableRowGroup: true
                            , resizable: true
                            , suppressSizeToFit: false
                            , flex: 1
                        }
                      }                      
                      sideBar='none'
                      gridTitle='Tranche Attribute'
                  />
              </div>
                :null
              }
      </div>
          </div>
      );
         
    }
}

export default DealChanges;