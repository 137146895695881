import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'ID', 'col': 'deal_risk_commentary_id', 'ag-grid': true, width: 130, hide: true }, 
            {'display': 'Date', 'col': 'report_date', 'agg': 'uniq', 'ag-grid': true, width: 130, 'format': 'dateFormat', sort: 'desc' }, 
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, minWidth: 190  },
            {'display': 'Added By', 'col': 'addedBy', 'agg': 'uniq', 'ag-grid': true, autoWidth:true }, 
            {'display': 'Comment', 'col': 'comment', cellEditor: 'numericEditor', 'ag-grid': true, autoHeight:true, wrapText: true, flex: 1,
                cellRenderer: function(params) {                   
                    return params.value;
                }
            },
            {'display': 'Edit', 'ag-grid': true, width : 100}
    ]};
}

export const assetCommentaryBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    addComment,
    getComment,
    generateReport
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'deal',
        minWidth: 100,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/get/riskCommentary`, requestOptions).then(Utils.handleResponse);
}

function generateReport(body) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/generate/riskCommentary`, requestOptions).then(Utils.handleResponse);
}

function addComment(deal, body) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/riskCommentary/add/deal=${deal}`, requestOptions).then(Utils.handleResponse);
}

function getComment(body) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/get/riskCommentary`, requestOptions).then(Utils.handleResponse);
}