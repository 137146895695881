import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Underlying', 'col': 'Underlying', 'agg': 'uniq', 'ag-grid': true, width : 200},
            {'display': 'P&L Topsheet for Equity % Shock.-50', 'col': '-50PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-25', 'col': '-25PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-20', 'col': '-20PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-15', 'col': '-15PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-10', 'col': '-10PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-5', 'col': '-5PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.-1', 'col': '-1PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.1', 'col': '1PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.5', 'col': '5PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for Equity % Shock.10', 'col': '10PnL', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-50', 'col': '-50Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-25', 'col': '-25Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-20', 'col': '-20Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-15', 'col': '-15Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-10', 'col': '-10Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-5', 'col': '-5Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.-1', 'col': '-1Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.1', 'col': '1Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.5', 'col': '5Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Delta Topsheet for Equity % Shock.10', 'col': '10Delta', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Underlying', 'col': 'Issuer', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Asset Class', 'col': 'AssetClass', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Tag1', 'col': 'Tag1', 'agg': 'uniq', 'ag-grid': true, hide: true},
        ]};
}

export const equityTopsheetBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 250
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}