import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');
let restrictionTypes = []
let exchanges = []
const getColumnList = async (params) => {
    const fetchData1 = restrictedListBALService.getAllRestrictionType().then(
        data => {
            restrictionTypes = data.filter(function (item) {
               return (item ? true : false);
           }).map(item => { return item.displayName });
        } ); 
        const fetchData2 = restrictedListBALService.getAllExchangeMco().then(
        data => {
            exchanges = data.filter(function (item) {
                return (item ? true : false);
            }).sort((a, b) => a.code > b.code ? 1 : -1).map(item => { return item.code });
        } );
    
        await fetchData1;
        await fetchData2;
    
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Restriction Type', 'col': 'restrictionType', cellEditor: 'agRichSelectCellEditor', cellEditorParams: { values: restrictionTypes}, editable: true, 'ag-grid': true, width : 160},
            {displayIndex: 2, 'display': 'Company Name', 'col': 'companyName', editable: true, cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 128 }, 'ag-grid': true, width : 150},
            {displayIndex: 3, 'display': 'IsRestrictedAtIssuerLevel', 'col': 'isRestrictedAtIssuerLevel', cellRenderer: "checkboxRenderer", 'ag-grid': true, width : 220},
            {displayIndex: 4, 'display': 'Ticker', 'col': 'ticker', cellEditor: 'agTextCellEditor', cellEditorParams: { maxLength: 32 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 5, 'display': 'ExchangeSymbol', 'col': 'exchangeSymbol', cellEditor: 'agRichSelectCellEditor', cellEditorParams: {  values: exchanges, maxLength: 128 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 6, 'display': 'ISIN', 'col': 'isin', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 12 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 7, 'display': 'Cusip', 'col': 'cusip', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 9 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 8, 'display': 'Sedol', 'col': 'sedol', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 7 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 9, 'display': 'Groups', 'col': 'groups', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 256 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 10, 'display': 'Users', 'col': 'users', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 256 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 11, 'display': 'Description', 'col': 'description', cellEditor: 'agTextCellEditor', cellEditorParams: {  maxLength: 300 }, editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 13, 'display': 'Date put on the list', 'col': 'addedDate', cellEditor: 'datePicker', editable: true, 'agg': 'uniq', 'ag-grid': true, width : 180},
            {displayIndex: 14, 'display': 'Proposed cleansing date', 'col': 'proposedRemovalDate', cellEditor: 'datePicker', editable: true, 'agg': 'uniq', 'ag-grid': true, width : 180},
            {displayIndex: 15, 'display': 'Last Private Info Communicated Date', cellEditor: 'datePicker', editable: true, 'col': 'lastPrivateInfoCommunicatedDate', 'agg': 'uniq', 'ag-grid': true, width : 180},
            {displayIndex: 16, 'display': 'Restricted List Name', 'col': 'restrictedListName', editable: true, 'ag-grid': true, width : 250},
            {displayIndex: 17, 'display': 'Last Modified By User Id', 'col': 'lastModifiedByUserId', 'ag-grid': true, width : 250},
            {displayIndex: 18, 'display': 'Last Modified By User Name', 'col': 'lastModifiedByName', 'ag-grid': true, width : 250},
            {displayIndex: 19, 'display': 'Last Modified By IPAddress', 'col': 'lastModifiedByIPAddress', 'ag-grid': true, width : 250},
            {displayIndex: 20, 'display': 'RestrictionTypeId', 'col': 'restrictionTypeId', 'ag-grid': true, width : 250},
            {displayIndex: 20, 'display': 'ExchangeMcoId', 'col': 'ExchangeMcoId', 'ag-grid': true, width : 250},
            {displayIndex: 21, 'display': 'Id', 'col': 'restrictedListId', 'ag-grid': true, width : 120},
            {displayIndex: 22, 'display': 'Remove', valueGetter :  params => {
                return "Delete";
            }, colId: 'delete', 'ag-grid': true, cellClass: 'linkButton', width: 100, editable: false } 
        ]};
};

export const restrictedListBALService = {
     getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
    ,getAllRestrictionType
    ,getAllExchangeMco
    ,save
    ,remove
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'RestrictedList'
        ,field: 'description'
        ,width: 120
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };
}

function getRowData(query) { 
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }  };
    return fetch(`${config.apiDDSUrl}/getAllRestrictedList`, requestOptions).then(Utils.handleResponse);
}

function save(restrictedList) {
    const body = JSON.stringify(restrictedList);
    return fetch(`${config.apiDDSUrl}/add/restrictedList/`, { body: body, method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function remove(idsToDelete) { 
    return fetch(`${config.apiDDSUrl}/remove/restrictedList/`, { body: JSON.stringify({'idsToDelete' : idsToDelete}), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function getAllRestrictionType(query) { 
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }  };
    return fetch(`${config.apiDDSUrl}/getAllRestrictionType`, requestOptions).then(Utils.handleResponse);
}

function getAllExchangeMco(query) { 
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }  };
    return fetch(`${config.apiDDSUrl}/getAllExchangeMco`, requestOptions).then(Utils.handleResponse);
}