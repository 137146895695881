import { userBALService } from '../user.bal';

const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Trade Date', 'col': 'TradeDate', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width : 100, 'format': 'fundFormat', hide: true},
            {'display': 'Transaction Type', 'col': 'TranType', 'agg': 'uniq', 'ag-grid': true, width : 160},
            {'display': 'Description', 'col': 'Investment', 'agg': 'uniq', 'ag-grid': true, width : 200},
            // {'display': 'Custodian Account', 'col': 'CustodianAccount', 'agg': 'uniq', 'ag-grid': true, width : 170},
            {'display': 'Currency', 'col': 'LocalCurrency', 'agg': 'uniq', 'ag-grid': true, width : 130},
            {'display': 'Quantity', 'col': 'Quantity', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 130},
            {'display': 'Price', 'cols': ['Price', 'Quantity'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': true, width : 90},
            {'display': 'Local Amount', 'col': 'LocalAmount', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'USD Amount', 'col': 'BookAmount', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150}
        ]};
}

export const dTradesBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 200
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true }
        , flex: 1
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }
        return JSON.parse(str);
    });
}