import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

export const reportGeneratorBALService = {
    emailReport
};

function emailReport(params) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json'}, body: JSON.stringify(params) };
    return fetch(`${config.apiDDSUrl}/generateReport`, requestOptions).then( data => { return Utils.handleResponse(data) });
}
