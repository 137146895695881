import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './../private_route/private_route';

// Our Components
import PortfolioDashboard from '../portfolioDashboard/portfolioDashboard';
import Login from './../login/login'; 
import DealDashboard from './../dealDashboard/dealDashboard';
import TorGrid from './../torgrid/torgrid';
import Deals from './../deals/deals';
import AssetCommentaryInput from '../assetCommentaryInput/assetCommentaryInput';
import AssetCommentaryReview from '../assetCommentaryReview/assetCommentaryReview';
import DealChanges from './../deals/dealChanges';
import DealSetup from './../deals/deal_setup';
import ChangePassword from './../changePassword/changePassword';
import ForgotPassword from '../forgotPassword/forgotPassword';
import IcApproval from './../icApproval/icApproval';
import CoInvestment from './../coInvestment/coInvestment';
import MarkingTool from './../markingTool/markingTool';
import ApproveReject from './../approval/approveReject';
import Pnl from './../pnl/pnl';
import PublicPnl from './../pnl/publicPnL';
import CovenantDashboard from '../covenant/covenantDashboard'; 
import CovenantNew from './../covenant/covenantNew'; 
import CovenantInstance from './../covenant/covenantInstance';
import Housing from './../housing/housing';
import HousingSummary from './../housingSummary/housingSummary';
import UserProfile from '../userProfile/profile';
import UserLog from './../userLog/userLog';
import DealLegalDetails from '../dealLegalDetails/dealLegalDetails';
import DealLegalDetailsSummary from '../dealLegalDetails/dealLegalDetailsSummary';
import RestrictedList from '../restrictedList/restrictedList';
import LiquidityDashboard from '../liquidity/liquidityDashboard'
import DealPipelineSummary from '../dealPipeline/dealPipelineSummary';
import DealPipelineDashboard from '../dealPipeline/dealPipelineDashboard';
import TaskDetailDashboard from '../task/taskDetailDashboard';
import TaskDetailNew from '../task/taskDetailNew';
import TaskInstanceDashboard from '../task/taskInstanceDashboard';
import TaskInstanceNew from '../task/taskInstanceNew';

class NavRoutes extends Component {
  render() {
    return (
      <main style={{height: "100%", width: "100%"}}> 
        <Router history={this.props.history}>
          
        <Switch>
            (// Publicly visible components)
            <Route exact path='/' component={Login}/>
            <Route path="/login" component={Login}/>
            <Route path="/forgotpassword" component={ForgotPassword}/>

            (// Privately visible components, i.e. one need to login to see these components)
            <PrivateRoute exact path="/liquidity" component={LiquidityDashboard}  roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester']} />
            <PrivateRoute exact path="/portfolio" component={PortfolioDashboard}  roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin']} />
            <PrivateRoute exact path="/deals" component={DealDashboard}           roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin']} />
            <PrivateRoute exact path="/dss" component={Deals}                     roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'Ops', 'Finance', 'Compliance', 'Legal']}/>
            <PrivateRoute exact path="/coInvestment" component={CoInvestment}     roles = {['Risk', 'Tester', 'IR', 'Analyst', 'Approver', 'Trader', 'Partner', 'PM', 'Legal' ]}/>
            <PrivateRoute exact path="/dealQuestionaire" component={DealLegalDetails}  roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'Ops', 'Finance', 'Compliance', 'Legal']}/>
            <PrivateRoute exact path="/dealQuestionaireSummary" component={DealLegalDetailsSummary}  roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'Ops', 'Finance', 'Compliance', 'Legal']}/>
            <PrivateRoute exact path="/assetCommentaryInput" component={AssetCommentaryInput}       roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester']} />
            <PrivateRoute exact path="/assetCommentaryReview" component={AssetCommentaryReview}       roles = {['Tester', 'Risk']} />
            <PrivateRoute exact path="/positions" component={TorGrid}             roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin']} guikey="positions1" gridIdentityColumn="position_id" gridTitle="Positions" />
            <PrivateRoute exact path='/changepassword' component={ChangePassword} />
            <PrivateRoute exact path='/dealchanges' component={DealChanges}       roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester']} />
            <PrivateRoute exact path='/approvereject' component={ApproveReject}   roles = {['Approver', 'Risk', 'Partner', 'Legal', 'Analyst' ]} />
            <PrivateRoute exact path='/dealsetup' component={DealSetup}           roles = {['Approver']} />
            <PrivateRoute exact path="/icApproval" component={IcApproval}         roles = {['Risk', 'Partner', 'Legal', 'Analyst', 'Compliance' ]} />
            <PrivateRoute exact path="/markingTool" component={MarkingTool}       roles = {['Risk', 'Trader', 'Partner', 'Finance']} />
            <PrivateRoute exact path="/pnl" component={Pnl}                       roles = {['Risk', 'Trader', 'Partner', 'Finance']} />
            <PrivateRoute exact path="/publicpnl" component={PublicPnl}           roles = {['Risk', 'Trader', 'Partner', 'Finance']} />
            <PrivateRoute exact path="/covenantDashboard" component={CovenantDashboard} roles = {['Risk', 'Partner', 'Legal', 'Ops' ]} />
            <PrivateRoute exact path="/covenantNew" component={CovenantNew} roles = {['Risk', 'Partner', 'Legal', 'Ops' ]} />
            <PrivateRoute exact path="/covenants" component={CovenantInstance} roles = {['Risk', 'Partner', 'Legal', 'Ops' ]} />
            <PrivateRoute exact path="/housing" component={Housing}               roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/taskMasterDashboard" component={TaskDetailDashboard}               roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/taskMaster" component={TaskDetailNew}               roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/taskInstanceDashboard" component={TaskInstanceDashboard}               roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/taskInstance" component={TaskInstanceNew}               roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/userlog" component={UserLog}               roles = {['Risk', 'Tester']} />
            <PrivateRoute exact path="/restrictedList" component={RestrictedList} roles = {['Tester', 'Compliance']} />
            <PrivateRoute exact path="/housingSummary" component={HousingSummary}  roles = {['FinanceAdmin', 'Tester']} />
            <PrivateRoute exact path="/userprofile" component={UserProfile}  roles = {['Employee', 'Tester']} />
            <PrivateRoute exact path="/dealPipeline" component={DealPipelineSummary} roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin']} />
            <PrivateRoute exact path="/dealPipelineDashboard" component={DealPipelineDashboard} roles = {['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin']} />
            <Route component={Login} />
          </Switch>
          </Router> 
        </main>
    );
  }
}
export default NavRoutes;
