import React, { Component } from 'react';
import TorGrid from './../torgrid/torgrid';

class CreditMetrics extends Component {
    render() {
        return this.getTemplate();
    }

    getTemplate() {
        return (
            <div style={{ height: '100%', width: '100%'}}>
                <div style={{ height: '100%', width: '100%'}}>
                    <TorGrid
                        guikey="creditMetrics"
                        query={ `exec [sp_get_credit_metrics] '${this.props.username}'`}
                        isHideDateInputs={true}
                        gridIdentityColumn="dealID"
                        groupBy = "Deal"
                        className="ag-theme-alpine"
                        groupDefaultExpanded = {-1}
                    />
                </div>
            </div>
        );
    }
}
export default CreditMetrics;