import { userBALService } from '../user.bal';

const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width : 100, 'format': 'fundFormat', hide: true},
            {'display': 'Report Date', 'col': 'Report Date', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 150},
            {'display': 'Description', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width : 200, hide: true},
            {'display': 'Product Type', 'col': 'Product Type', 'agg': 'uniq', 'ag-grid': true, width : 200},
            {'display': 'Currency', 'col': 'Curr', 'agg': 'uniq', 'ag-grid': true, width : 130},
            {'display': 'Quantity', 'col': 'Quantity', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 130},
            {'display': 'Mark', 'col': 'Ending Local Price', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 150},
            {'display': 'Local.Clean MV', 'col': 'Local MV Clean', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Local.AI', 'col': 'Local AI', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Local.MV', 'col': 'Local MV', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Local.Cost', 'col': 'Local Cost', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Book.Clean MV', 'col': 'Book MV Clean', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Book.AI', 'col': 'Book AI', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Book.MV', 'col': 'Book MV', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Book.Cost', 'col': 'Book Cost', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Exchange Rate', 'col': 'Exchange Rate', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 150},
            {'display': 'Month End Accrual.PIK', 'col': 'EndingPIKLocalAccrualBalance', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Month End Accrual.Cash', 'col': 'EndingCashLocalAccrualBalance', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'Month End Accrual.Total', 'col': 'EndingTotalLocalAccrualBalance', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'PNL.Price', 'col': 'Pnl Price', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'PNL.Interest', 'col': 'Pnl Interest', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'PNL.Other', 'col': 'pnl Other', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'PNL.Total ex FX', 'col': 'Pnl Total', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
        ]};
}

export const dealMarksBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 350
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true }
        , flex: 1
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }
        return JSON.parse(str);
    });
}