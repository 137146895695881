/**<import>****************************************************************************************************/
import dateFormat from "dateformat";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { markingToolBALService } from '../../bal/markingTool.bal';
import { torGridBALService } from '../../bal/torGridBALService.bal';
import { approvalBALService } from '../../bal/approval.bal';
import { fundBALService } from '../../bal/fund.bal';
import { Prompt } from "react-router-dom";


import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class PriceMark extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === "markingtool" });

        this.state = { 
            rowData: [],
            loadDate: true,
            isShowLoad: false, 
            isShowGrid: false, 
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            open:false,
            fundList : [],
            fund : null,
            forDate : moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.approve = this.approve.bind(this);
        this.save = this.save.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        //getFormatted = getFormatted(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.loadFunds = this.loadFunds.bind(this);
        this.cellValueChanged = this.cellValueChanged.bind(this);
        this.loadFunds();

        var date = new Date();
        date.setDate(date.getDate() - 7);
        var dateString = moment(date).format('YYYYMMDD');

        torGridBALService.getRowData('hedgeReport', { query: `Select MAX([Report Date]) as repDate From vw_position Where [For Frequency] = 'd' And Fund = 'TOR'  And [For Report Date] > '${dateString}'` }).then(data => {
            const repDate = moment(data[0]['repDate'], "YYYYMMDD").format("YYYY-MM-DD");
            this.setState({ maxDate: repDate, forDate: repDate, loadDate: false });
        });
    }

    loadFunds() {
        fundBALService.getRowData().then(data => {
            if(data) {
                this.setState({
                    fundList : data.map(item => { return { key: item.display_name, value: item.display_name, text: item.code } })
                });
            }
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the fund list:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Fund list could not be retrieved, please contact support!'
            }); 
            console.log(error); 
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    refresh(e, modalVisible = false) {
        const { forDate, fund } = this.state;
        this.setState({ 
            isShowLoad : true, 
            isShowGrid : false,
            showMessage: false, 
            errorList: [], 
            messageHeader : null,
            modalVisible: false,
         });
        markingToolBALService.getRowData({ reportDate : forDate, fund : fund }).then(data => {
            this.setState({
                gridData: data,
                isShowLoad:false,
                isShowGrid: data?.length > 0,
                ...(data?.length <= 0) && {
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'No bonds exists for given date/fund.'
                },
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null
                }
            });
        }, error => {
            this.setState({ 
                isShowGrid: false,
                isShowLoad : false,
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the grid data :",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); 
        });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({ modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false});
        } else {
            this.setState({ modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    approveReject(action) {
        const { newDeal, num } = this.state;
        const { id, approvalRejectionComment, instrumentMark } = newDeal;
        approvalBALService.approve({id : id, type : 'instrumentMark', action: action, comment: approvalRejectionComment })
        .then(
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: `${action} ${instrumentMark} successfully!`,
                        open: false,
                        num : num+1
                    });
                    this.refresh(null, true);
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while ${action} ${instrumentMark}:`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: `${action} ${instrumentMark} failed!`
                    }); 
                    console.log(error); 
                this.refresh(null, true);
            }
        );
    }

    save = async (sendForApproval) => {        
        const { forDate, fund, gridData } = this.state;
        const pricecol = `col001_${dateFormat(forDate, "dd-mmm-yyyy")}`;
        let previouspricecol = `col002_${dateFormat(forDate, "dd-mmm-yyyy")}`;

        if(gridData && gridData.length > 0){
            const cols = Object.keys(gridData[0]).filter(p=> p.startsWith("col002_"));
            if(cols && cols.length > 0)
                previouspricecol = cols[0];
        }

        let allRows = this.gridApi?.rowModel?.rowsToDisplay?.map(row => {
            if(row && row.data) {
                return {
                    ...row.data,
                    price : row?.data[pricecol],               
                    previousPrice : row?.data[previouspricecol]
                };
            } else {
                return undefined;
            }
        });
        allRows = allRows.filter(p => p !== undefined);

        var noPriceList = allRows.filter(p=> p && p.price === undefined);

        if(sendForApproval && noPriceList.length > 0) {
            var errorList = noPriceList.map(p => {
                return  `${p['Investment Code']} - ${p['Description']}`                
            });
            this.setState({ 
                showMessage: true, 
                messageHeader : `Marks for below are not specified:`,
                errorList: errorList, 
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Saving and sending for approval failed'
            });
        } else {
            this.setState({
                showMessage: false, 
                errorList: null, 
                messageHeader : "",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: sendForApproval ? 'Saving and sending for approval...' : 'Saving marks...',
                open: false 
            });
            markingToolBALService.save(allRows, sendForApproval, forDate, fund).then(
                data => {
                        this.setState({
                            showMessage: false, 
                            errorList: null, 
                            messageHeader : "",
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'close',
                            modalHeading: sendForApproval ? 'Saved and sent for approval successfully!' : 'Saved marks successfully!',
                            open: false ,
                            gridData: data
                        });
                    }
                    , error => {
                        this.setState({ 
                            showMessage: true, 
                            errorList: [error], 
                            messageHeader : `Error while saving marks:`,
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'close',
                            modalHeading: sendForApproval ? 'Saving and sending for approval failed!' : 'Saving marks failed!'
                        }); 
                        console.log(error);
                });
        }
    }

    approve = async () => {
        const selectedRows = this.gridApi?.getSelectedNodes();
        if(selectedRows.length > 0) {
            const proposals = selectedRows.map(deal => {return {id: deal.data['id'], type: 'CreditProposal', action: 'approved'} });

            var promises = approvalBALService.approveGrid(proposals);
            Promise.all(promises).then(data => {
                    var errorList = data.filter(element => element.error).map(element => { return element.error });
                    if(errorList && errorList.length > 0)
                        this.setState({ showMessage: true, errorList : errorList, messageHeader : "Error while approving the request :" });
                    else {
                        this.setState({ showMessage: true, errorList : [], messageHeader : "Approved Successfully"});
                    }
                    this.refresh(null, true);
                }
            );
        } else {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: 'First select proposal(s) to approve'
            });
        }
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ [name]: value });
    }
    
    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    cellValueChanged(e) {
        if(e && e.data) {
            let col1 = Object.keys(e.data).filter(p => p.startsWith("col001_"));
            let data = this.state.gridData.filter(p=>p['Investment Code'] === e.data['Investment Code'] && p.instrument_id !== e.data.instrument_id);

            data = data.map(row => {
                return {
                    ...row,
                    [col1] : e.data[col1],
                    isBloomberg : e.data.isBloomberg
                }
            })

            this.setState({
                GridRowsToUpdate: data
            });
        }
    }

    getPage() {
        const { newDeal, GridRowsToUpdate, existingDeal, modalHeading, isShowLoad, loadDate, isShowGrid, gridData, isApprover, fundList, fund, forDate, showMessage, messageHeader, errorList } = this.state;
        return (
            
                loadDate === false
                ?
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                    {
                        showMessage ?
                            <ToastMessage
                                header={messageHeader}
                                errorList={errorList}
                                closeMessage={this.closeMessage}
                            />:<div></div>
                    }  
                    <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                        <div>
                            <div style={{ float: "Left", padding: "5px 15px 0px 15px" }} ><h4><u>Marking Tool</u></h4></div>
                            <div style={{ float: "right", padding: "15px 15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                            <div style={{ float: "right", padding: "15px 15px 0px 15px"}}>
                                <b>For Date: </b>
                                <DatePicker
                                    name="forDate"                                        
                                    selected={forDate ? new Date(forDate) : null}
                                    onChange={(date) => { 
                                        this.handleChange('forDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                    }}
                                    filterDate={(date) => {
                                        const day = date.getDay();
                                        return day !== 0 && day !== 6;
                                    }}
                                    maxDate = {this.state.maxDate}
                                    autoComplete = "off"
                                    showYearDropdown
                                    showMonthDropdown
                                />
                            </div>                                
                            <div style={{ float: "right", height: "50px", padding: "10px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                                <Dropdown
                                    placeholder='Select Fund'
                                    name="fund"
                                    fluid
                                    search
                                    selection
                                    onChange={this.handleDropDownChange}
                                    options={fundList}
                                    value={fund}
                                    className={'form-control'}
                                />
                            </div>
                        </div>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TorGrid
                                setAgGridAPI = {this.setAgGridAPI}
                                isHideDateInputs={true}
                                domLayout="normal"

                                isHideSaveGridLayout={true}

                                rowData={gridData}
                                GridRowsToUpdate={GridRowsToUpdate}

                                className="ag-theme-alpine"
                                rowSelection="multiple"
                                gridIdentityColumn="instrument_id"

                                groupDefaultExpanded = {-1}
                                groupIncludeTotalFooter = {false}

                                guikey = "markingTool"
                                sortColumn = {true}

                                singleClickEdit = {true}
                                isApprover = {isApprover}
                                approve = {this.approve}
                                save = {() => { this.save(false)}}
                                showSave = {true}
                                saveAndSend = {() => { this.save(true)}}
                                showSaveAndSend = {!isApprover}

                                isShowLoad = {isShowLoad}
                                isShowGrid = {isShowGrid}
                                cellValueChanged = {this.cellValueChanged}
                            />
                        </div>
                    </div> 
                    <Prompt
                        when={this.state.isShowLoad === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                        message={location => `Do you want to discard changes?`}
                    />
                    <div>
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                                    :
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                            }
                        </section>
                    </div>
                </div>
                :
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            
            
        );
    }
}

export default PriceMark;