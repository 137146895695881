import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Date', 'col': 'Cashflow Date', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width : 100, hide: true, 'format': 'fundFormat', 'rowGroup': true},
            {'display': 'Currency', 'col': 'Denominated', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {'display': 'Local Amount', 'col': 'Cashflow Amount', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150},
            {'display': 'USD Amount', 'col': 'Cashflow Book Amount', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 150}
        ]};
}

export const pCashflowBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'Fund'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true }
        , flex: 1
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}