import { Utils } from '../helper/utils';
import { Session } from '../helper/session';
const {config} = require('../config');

export const userBALService = {
    login,
    logout,
    getAll,
    getById,
    update,
    getAuthHeader,
    getUserInfo,
    delete: _delete,
    changePassword,
    generateOtp,
    validateOtp,
    generateOtpForgotPassword,
    validateOtpForgotPassword,
    changePasswordForgot,
    getAuthHeaderJson,
    getUserDetails,
    validateLoginOtp,
    addUserLog
};

function getUserDetails(type = null) {
    const user = JSON.parse(Session.getItem(config.token));
    const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === type?.toLowerCase() });
    const isRiskRole = user?.userRoles?.filter(item => item && item.toLowerCase() === 'risk');
    return {
        isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
        isRiskRole: isRiskRole?.length>0,
        user: user
    };
}

function addUserLog(user_id, path) {
    const requestOptions = {
        method: 'POST',
        headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id, path })
    };

    return fetch(`${config.apiDDSUrl}/addUserLog`, requestOptions)
        .then(Utils.handleResponse);
}

function login(user_name, password, ipAddress) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name, password, ipAddress })
    };

    return fetch(`${config.apiDDSUrl}/login`, requestOptions)
        .then(Utils.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(config.token, JSON.stringify(user));
            return user;
        });
}

function validateLoginOtp(user_name, otpnum) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name, otpnum })
    };

    return fetch(`${config.apiDDSUrl}/validateLoginOtp`, requestOptions)
        .then(Utils.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(config.token, JSON.stringify(user));
            return user;
        });;
}

function changePassword(password, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: userBALService.getAuthHeaderJson(),
        body: JSON.stringify({ 'password' : password, 'newPassword' : newPassword })
    };

    return fetch(`${config.apiDDSUrl}/update/password`, requestOptions)
        .then(Utils.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(config.token, JSON.stringify(user));
            return user;
        });
}

function changePasswordForgot(username, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'username' : username, 'newPassword' : newPassword })
    };

    return fetch(`${config.apiDDSUrl}/update/passwordForgot`, requestOptions)
        .then(Utils.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(config.token, JSON.stringify(user));
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(config.token);
}

function getAll() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/users`, requestOptions).then(Utils.handleResponse);
}

function getById(id) {

}

function update(user) {

}

function generateOtp() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/generateOtp`, requestOptions).then(Utils.handleResponse).then(otp => { return otp; });
}

function validateOtp(otp) {
    const requestOptions = { 
        method: 'PUT', 
        headers:{...userBALService.getAuthHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({ otpnum : otp })
    };
    return fetch(`${config.apiDDSUrl}/validateOtp`, requestOptions).then(Utils.handleResponse).then(otp => { return otp; });
}

function generateOtpForgotPassword(username) {
    const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
    return fetch(`${config.apiDDSUrl}/generateOtpForgotPassword?username=${username}`, requestOptions).then(Utils.handleResponse).then(otp => { return otp; });
}

function validateOtpForgotPassword(username, otp) {
    const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
    return fetch(`${config.apiDDSUrl}/validateOtpForgotPassword?otpnum=${otp}&username=${username}`, requestOptions).then(Utils.handleResponse).then(otp => { return otp; });
}

function getUserInfo() {
    return JSON.parse(localStorage.getItem(config.token));
}

function getAuthHeader() {
    return { Authorization: 'Bearer ' + this.getUserInfo().token };
}

function getAuthHeaderJson() {
    return { Authorization: 'Bearer ' + this.getUserInfo().token, 'Content-Type': 'application/json' };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {

}