
// const cellClassRules = {
//     "cell-red": params => params.data && params.data['cellcss'] === 'cell-red',
//     "cell-green": params => params.data && params.data['cellcss'] === 'cell-green',
//     "cell-blue": params => params.data && params.data['cellcss'] === 'cell-blue',
//     "cell-normal": params => params.data === undefined || params.data['cellcss'] === 'cell-normal'
//  };

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 0,  'display': 'position_id', 'col': 'position_id', 'agg': 'uniq', 'ag-grid': true, hide: true },
            {displayIndex: 1,  'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, width : 150, sort: 'asc' },
            {displayIndex: 2,  'display': 'Description', 'col': 'description', 'agg': 'uniq', 'ag-grid': true, width : 250 },
            {displayIndex: 3,  'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'ag-grid': true, width : 120, hide: true },
            {displayIndex: 3,  'display': 'Root', 'col': 'root', 'agg': 'uniq', 'ag-grid': true, width : 120, hide: true },

            {displayIndex: 10,  'display': 'Terms.Coupon', 'col': 'coupon', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 100 },
            {displayIndex: 11, 'display': 'Terms.Maturity Date', 'col': 'maturity_date', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 140 },

            {displayIndex: 20, 'display': 'Position.Quantity', 'col': 'quantity', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width : 120 },
            {displayIndex: 21, 'display': 'Position.$MV', 'col': '$mv', 'agg': 'sum', 'format': 'millionsFormat2M', 'ag-grid': true, width : 110 },
            {displayIndex: 22, 'display': 'Position.Currency', 'col': 'currency', 'agg': 'uniq', 'ag-grid': true, width : 110 },
            {displayIndex: 23, 'display': 'Position.Avg Cost', 'col': 'avg_cost', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 120 },

            // {displayIndex: 30, 'display': 'Price.Curr', 'cols': ['price_current', 'quantity'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            // {displayIndex: 31, 'display': 'Price.Prev', 'cols': ['price_previous', 'quantity'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            // {displayIndex: 32, 'display': 'Price.Chg', 'cols': ['price change', 'quantity'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },

            {displayIndex: 30, 'display': 'Price.Curr', 'col': 'price_current',  'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            {displayIndex: 31, 'display': 'Price.Prev', 'col': 'price_previous', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            {displayIndex: 32, 'display': 'Price.Chg',  'col': 'price change',   'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },

            {displayIndex: 40, 'display': 'Daily PnL.Position', 'col': 'pnl position', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120 },
            {displayIndex: 41, 'display': 'Daily PnL.Accrual', 'col': 'pnl accrual', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120 },
            {displayIndex: 42, 'display': 'Daily PnL.Total', 'col': 'pnl total', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120 },

            {displayIndex: 45, 'display': 'MTD PnL.Position', 'col': 'mtd pnl position', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120, hide: true },
            {displayIndex: 46, 'display': 'MTD PnL.Accrual', 'col': 'mtd pnl accrual', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120, hide: true },
            {displayIndex: 47, 'display': 'MTD PnL.Total', 'col': 'mtd pnl total', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120, hide: true },

            {displayIndex: 50, 'display': 'Yield.Current', 'col': 'yield_current', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 100 },
            {displayIndex: 51, 'display': 'Yield.YTM', 'col': 'yield_ytm', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 100 },
            {displayIndex: 52, 'display': 'Yield.YTC', 'col': 'yield_ytc', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 100 },
            {displayIndex: 53, 'display': 'Yield.YTW', 'col': 'yield_ytw', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 100 },

            {displayIndex: 60, 'display': 'Spread.Z Sprd', 'col': 'z_spread', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 110 },		 								
            {displayIndex: 61, 'display': 'Spread.OAS', 'col': 'oas', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 110 },
            {displayIndex: 62, 'display': 'Spread.I Sprd', 'col': 'i_spread', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 110 },

            {displayIndex: 70, 'display': 'Accrued.Curr', 'col': 'accrued_current', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            {displayIndex: 71, 'display': 'Accrued.Prev', 'col': 'accrued_previous', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            {displayIndex: 72, 'display': 'Accrued.Chg', 'col': 'accrued change', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            
            {displayIndex: 80,  'display': 'Classification.Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 81,  'display': 'Classification.Sector', 'col': 'sector', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 82,  'display': 'Classification.Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': true, width : 150 },

            {displayIndex: 90, 'display': 'Terms.Accruing?', 'col': 'accruing', 'agg': 'uniq', 'ag-grid': true, width : 110 },            
            {displayIndex: 91, 'display': 'Terms.Callable?', 'col': 'is_callable', 'agg': 'uniq', 'ag-grid': true, width : 110 },
            {displayIndex: 92, 'display': 'Terms.Next Cpn Date', 'col': 'coupon_date', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 93, 'display': 'Terms.Next Call Date', 'col': 'call_date', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 94, 'display': 'Terms.Next Call Price', 'col': 'call_price', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 150 },
            {displayIndex: 95, 'display': 'Terms.Puttable?', 'col': 'is_putable', 'agg': 'uniq', 'ag-grid': true, width : 110 },
            {displayIndex: 96, 'display': 'Terms.Next Put Date', 'col': 'put_date', 'format': 'dateFormat', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 97, 'display': 'Terms.Next Put Price', 'col': 'put_price', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 150 }, 

            {displayIndex: 100, 'display': 'Price Change.WTD', 'col': 'price change wtd', 'format': 'decimalFormat2', 'agg': 'uniq', 'ag-grid': true, width : 90 },
            {displayIndex: 101, 'display': 'Price Change.MTD', 'col': 'price change mtd', 'format': 'decimalFormat2', 'agg': 'uniq', 'ag-grid': true, width : 90 },

            {displayIndex: 110, 'display': 'YTW Change.WTD', 'col': 'ytw change wtd', 'format': 'decimalFormat2', 'agg': 'uniq', 'ag-grid': true, width : 90 },
            {displayIndex: 111, 'display': 'YTW Change.MTD', 'col': 'ytw change mtd', 'format': 'decimalFormat2', 'agg': 'uniq', 'ag-grid': true, width : 90 },

            {displayIndex: 120, 'display': 'Convertible Bonds.Parity', 'col': 'parity', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },
            {displayIndex: 121, 'display': 'Convertible Bonds.Delta', 'col': 'delta', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },

            {displayIndex: 130, 'display': 'Price.FX', 'col': 'fx price', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width : 90 },

            {displayIndex: 140,  'display': 'ISIN', 'col': 'isin', 'agg': 'uniq', 'ag-grid': true, width : 140 },
            {displayIndex: 141,  'display': 'CUSIP', 'col': 'cusip', 'agg': 'uniq', 'ag-grid': true, width : 120 },
            // {displayIndex: 31, 'display': 'Trade P&L', 'col': 'Trade P&L', 'agg': 'sum', 'ag-grid': true, width : 150, 'format': 'decimalFormat2' }, 
    ]};
}

export const publicPnLBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: '',
        resizable: true,
        width: 180,
        pinned: 'left'
  };
}

function getRowData(params) { 

}

function save(instrumentMarks, isSendForApproval, date, fund) { 
    
}