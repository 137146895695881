import { userBALService } from './user.bal';
import Moment from 'moment';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Curr', 'col': 'Curr', 'agg': 'uniq', 'ag-grid': true, rowGroup: true, 'hide': true},
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, rowGroup: true, 'hide': true},
            {'display': 'Sort', 'col': 'Sort', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Custodian Account', 'col': 'Custodian Account', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Quantity', 'col': 'Quantity', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'Tax Lot Date', 'col': 'Tax Lot Date', 'ag-grid': true, hide: true},
            {'display': 'Spot Date', 'col': 'Spot Date', 'ag-grid': true, hide: true},
            {'display': 'Settle Date', 'col': 'Settle Date', 'ag-grid': true, hide: true},
            {'display': 'Maturity Date', 'col': 'Maturity Date', 'ag-grid': true, hide: true},
            {'display': 'Principal', 'col': 'Principal', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'LMV (USD)', 'col': 'LMV (USD)', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'SMV (USD)', 'col': 'SMV (USD)', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'Book Present Market Value', 'col': 'Book Present Market Value', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'Expected Future PnL USD', 'col': 'Expected Future PnL USD', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'Expected Future MV USD', 'col': 'Expected Future MV USD', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'LMV (Local)', 'col': 'LMV (Local)', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'SMV (Local)', 'col': 'SMV (Local)', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'Local Present Market Value', 'col': 'Local Present Market Value', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true},
            {'display': 'Expected Future PnL Local', 'col': 'Expected Future PnL Local', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'Expected Future MV Local', 'col': 'Expected Future MV Local', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, hide: true},
            {'display': 'Toggle', 'col': 'Toggle', 'agg': 'uniq', 'ag-grid': true, hide: true}
        ]};
}

export const fxReportBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
    ,getPositionsDB
};

function getIndentClass(params) {
    var indent = 0;
    var node = params.node;
    while (node && node.parent) {
      indent++;
      node = node.parent;
    }
    return ['indent-' + indent];
}

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Group'
        ,field: 'description'
        ,width: 250
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
        ,cellClass: getIndentClass
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositionsDB(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(handleResponse);
}

function getPositions(params) {
    var {AsOfDate, Frequency, Fund} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : 'd';
    Fund = Fund ? Fund : '';
    return fetch(`${config.apiDDSUrl}/fxcache/frequency=${Frequency}/asofdate=${asdate}/fund=${Fund}`, requestOptions).then(handleResponseArray).then(positions => { return positions; });
}

function handleResponseArray(response) {
    return response.text().then(text => {
        text = text.replace(/\bNaN\b/g, "null")
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }
            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }
        return JSON.parse(str);
    });
}
