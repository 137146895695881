import { userBALService } from './user.bal';
import { Session } from '../helper/session';
import { Utils } from '../helper/utils';
const {config} = require('../config');

const getColumnList = async (params) => {
    const user = JSON.parse(Session.getItem(config.token));        
    const approverRole = user.userRoles.filter(function (item) { return item === "Approver" });
    const isApprover = approverRole.length > 0;

    return  {
        'columnSpecs': [
        {'display': 'Id', 'col': 'id', 'ag-grid': true, hide:true },
        {'display': 'Name', 'col': 'creditProposalName',  'ag-grid': true, headerCheckboxSelection: isApprover, headerCheckboxSelectionFilteredOnly: isApprover, checkboxSelection: isApprover, minWidth : 230 },
        {'display': 'Status', 'col': 'status',  'ag-grid': true, minWidth : 120, rowGroup: true},
        {'display': 'Lead Analyst', 'col': 'analyst1Name',  'ag-grid': true, minWidth : 190}, 
        {'display': 'Proposal Date', 'col': 'proposalDate',  'ag-grid': true, 'format': 'dateFormat', minWidth : 120}, 
        {'display': 'Transaction Size Max', 'col': 'transactionSizeMax', 'agg': 'sum', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 120, hide: true },
        {'display': 'Size Approved', 'col': 'sizeApproved', 'agg': 'sum', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 60 },
        {'display': 'TACF %', 'col': 'tacfPercentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 60},
        {'display': 'TACF Amount', 'col': 'tacfAmount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 140},
        {'display': 'TIFO %', 'col': 'tifoPercentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 100},
        {'display': 'TIFO Amount', 'col': 'tifoAmount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 140},
        {'display': 'TACO II %', 'col': 'tacoiiPercentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 120},
        {'display': 'TACO II Amount', 'col': 'tacoiiAmount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 160},
        {'display': 'TACO III %', 'col': 'tacoiiiPercentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 120},
        {'display': 'TACO III Amount', 'col': 'tacoiiiAmount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 160},
        {'display': 'CoInvestor 1 Name', 'col': 'coInvestor1Name', 'ag-grid': true, minWidth : 230},
        {'display': 'CoInvestor 1 %', 'col': 'coinvest1Percentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 90},
        {'display': 'CoInvestor 1 Amount', 'col': 'coinvest1Amount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 120},
        {'display': 'CoInvestor 2 Name', 'col': 'coInvestor2Name', 'ag-grid': true, minWidth : 230},
        {'display': 'CoInvestor 2 %', 'col': 'coinvest2Percentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 150},
        {'display': 'CoInvestor 2 Amount', 'col': 'coinvest2Amount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 190},
        {'display': 'CoInvestor 3 Name', 'col': 'coInvestor3Name', 'ag-grid': true, minWidth : 230},
        {'display': 'CoInvestor 3 %', 'col': 'coinvest3Percentage', 'format': 'percentFormat2P', 'ag-grid': true, minWidth : 150},
        {'display': 'CoInvestor 3 Amount', 'col': 'coinvest3Amount', 'format': 'decimalFormat2M', 'ag-grid': true, minWidth : 190},
        {'display': 'Requester', 'col': 'analyst2Name',  'ag-grid': true, minWidth : 190},
    ]};
}

export const icApprovalBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'creditproposal_id',
        minWidth: 100,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/allCreditProposal`, requestOptions).then(Utils.handleResponse).then(data => {
        return data;
    });
}

function save(params) {
    params = Utils.removeBlanks(params);
    delete params['file'];
    return fetch(`${config.apiDDSUrl}/addCreditProposal`, { body: JSON.stringify(params), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

