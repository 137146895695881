/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { dealBALService } from '../../../bal/deal.bal';
//https://react.semantic-ui.com/modules/dropdown/
import FormDisplay from './formDisplay.js';
// Our components
import '../deals.css';

class Deal_Metrics extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            ratesList: []
        }
        dealBALService.getRates().then(data => { this.setState({ ratesList: data }); }, error => { console.log(error); });

        this.handleChangeMetrics = this.handleChangeMetrics.bind(this);
        this.handleDropDownChangeMetrics = this.handleDropDownChangeMetrics.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
    };

    handleChangeMetrics(e) {
        this.props.handleChangeMetrics(e);
    }

    handleDropDownChangeMetrics = async (e, { name, value }) => {
        if(name === "currency") {
            let rateVal = this.state.ratesList.find(
                data => data.Currency === value
            );
            await this.props.handleChangeCurrency(name, value, rateVal?.Rate);
        } else {
            this.props.handleDropDownChangeMetrics(e, { name, value });
        }
    }

    handleChangeDate(name, value) {
        this.props.handleChangeDate(name, value);
    }

    getSettingByName(name) {
        const { settingList } = this.props;
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    render() {
        const { dealMetrics, dealPriorMetrics } = this.props;
        const grossDebt = parseFloat(dealMetrics?.total_debt_entry ?? '0') + parseFloat(dealMetrics?.cash_balance_entry ?? '0');
        const grossPriorDebt = parseFloat(dealPriorMetrics?.total_debt_entry ?? '0') + parseFloat(dealPriorMetrics?.cash_balance_entry ?? '0');

        const impOwn = dealMetrics?.cash_balance_entry && dealMetrics?.enterprise_value && this.props?.torPositionVal ? parseFloat((this.props?.torPositionVal / (dealMetrics?.enterprise_value + dealMetrics?.cash_balance_entry) * 100.0).toFixed(2))  : 0.0;
        
        console.log(dealMetrics?.cash_balance_entry, dealMetrics?.enterprise_value, this.props?.torPositionVal, impOwn);

        const debtEquityVal = (dealMetrics?.total_debt_entry || dealMetrics?.cash_balance_entry) && dealMetrics?.equity_book_value ? parseFloat((grossDebt / parseInt(dealMetrics?.equity_book_value)).toFixed(2)) : null;
        const debtEquityPriorVal = (dealPriorMetrics?.total_debt_entry || dealPriorMetrics?.cash_balance_entry) && dealPriorMetrics?.equity_book_value ? parseFloat((grossPriorDebt / parseInt(dealPriorMetrics?.equity_book_value)).toFixed(2)) : null;
        
        const totalLeverage = dealMetrics?.total_debt_entry  && dealMetrics?.adjusted_ltm_ebitda ? parseFloat((parseInt(dealMetrics?.total_debt_entry) / parseInt(dealMetrics?.adjusted_ltm_ebitda)).toFixed(2)) : null;
        const totalLtv = dealMetrics?.total_debt_entry && dealMetrics?.enterprise_value ? (parseFloat((parseInt(dealMetrics?.total_debt_entry) / parseInt(dealMetrics?.enterprise_value)).toFixed(2))) * 100 : null;
        
        const statusList = this.getSettingByName("Real Estate Status").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const ratesList = this.state.ratesList.map(item => { return { key: item.Currency, value: item.Currency, text: item.Currency } });
        const esgRatingList = [{ key: 1, value: 1, text: 1 }, { key: 2, value: 2, text: 2 }, { key: 3, value: 3, text: 3 }, { key: 4, value: 4, text: 4 }, { key: 5, value: 5, text: 5 }]
        let quarterDate = '';
        if(dealMetrics?.quarter !== "Underwriting") {
            quarterDate = new Date(dealMetrics?.quarter.substring(2), (parseInt(dealMetrics?.quarter.substring(1, 2)) * 3).toString(), '01');
            quarterDate = new Date(quarterDate - 1);

            if(! dealMetrics?.as_of_date) {
                this.handleChangeDate('as_of_date', quarterDate);
            }
        }

        return (
            <div className="spacer">
                {
                    dealMetrics?.quarter !== "Underwriting"
                        ?
                        <div className="row">
                            <div className="column2 left2">
                                <h3>Deal Level: Credit Metrics</h3>
                            </div>
                            <div className="column2 right2">
                                <div className="column2 three">
                                    <h3>Response</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>Prior Quarter Response</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>Comments</h3>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="column2 left2">
                                <h3>Deal Level: Credit Metrics</h3>
                            </div>
                            <div className="column2 right2">
                                <div className="column2 left">
                                    <h3>Response</h3>
                                </div>
                                <div className="column2 right">
                                    <h3>Comments</h3>
                                </div>
                            </div>
                        </div>
                }

                <div>
                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Has the borrower missed any interest, principal or other contractual payments in general?"
                        fieldName="has_borrower_missed_payment"
                        priorDataField={dealPriorMetrics?.has_borrower_missed_payment}
                        dataField={dealMetrics?.has_borrower_missed_payment}
                        dataCommentField={dealMetrics?.has_borrower_missed_payment_comment}
                        handleChange={this.handleChangeMetrics}
                        handleDropDownChange={this.handleDropDownChangeMetrics}
                        handleChangeDate={this.handleChangeDate}
                        type="checkbox"
                    />

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Is the borrower in breach of any covenants?"
                        fieldName="has_borrower_breach_covenant"
                        priorDataField={dealPriorMetrics?.has_borrower_breach_covenant}
                        dataField={dealMetrics?.has_borrower_breach_covenant}
                        dataCommentField={dealMetrics?.has_borrower_breach_covenant_comment}
                        handleChange={this.handleChangeMetrics}
                        handleDropDownChange={this.handleDropDownChangeMetrics}
                        handleChangeDate={this.handleChangeDate}
                        type="checkbox"
                    />

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="As Of Date"
                        fieldName="as_of_date"
                        priorDataField={dealPriorMetrics?.as_of_date ?? quarterDate}
                        dataField={dealMetrics?.as_of_date ?? quarterDate}
                        dataCommentField={dealMetrics?.as_of_date_comment}
                        maxDate = {quarterDate}
                        handleChange={this.handleChangeMetrics}
                        handleChangeDate={this.handleChangeDate}
                        type="datepick"
                    />

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Currency"
                        fieldName="currency"
                        priorDataField={dealPriorMetrics?.currency}
                        dataField={dealMetrics?.currency}
                        handleDropDownChange={this.handleDropDownChangeMetrics}
                        type="dropdown"
                        dataList={ratesList}
                    />

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Rate"
                        fieldName="rate"
                        priorDataField={dealPriorMetrics?.rate}
                        dataField={dealMetrics?.dispRate}
                        handleChange={this.handleChangeMetrics}
                        type="textbox"
                    />  

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Total Drawn Debt Senior to Tor's Position (Gross)"
                        fieldName="total_ranking_drawn_debt"
                        priorDataField={dealPriorMetrics?.total_ranking_drawn_debt}
                        dataField={dealMetrics?.total_ranking_drawn_debt}
                        dataCommentField={dealMetrics?.total_ranking_drawn_debt_comment}
                        handleChange={this.handleChangeMetrics}
                        handleDropDownChange={this.handleDropDownChangeMetrics}
                        textType="number"
                        handleChangeDate={this.handleChangeDate}
                        type="textbox"
                        calcType = "million"
                    />

                    <FormDisplay
                        fieldList={this.props.fieldList}
                        formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                        heading="Total Drawn Debt through to Tor's Position (Gross)"
                        fieldName="total_drawn_debt_tranche"
                        priorDataField={dealPriorMetrics?.total_drawn_debt_tranche}
                        dataField={dealMetrics?.total_drawn_debt_tranche}
                        dataCommentField={dealMetrics?.total_drawn_debt_tranche_comment}
                        handleChange={this.handleChangeMetrics}
                        handleDropDownChange={this.handleDropDownChangeMetrics}
                        textType="number"
                        handleChangeDate={this.handleChangeDate}
                        type="textbox"
                        calcType = "million"
                    />
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Total Net Debt"
                    fieldName="total_debt_entry"
                    priorDataField={dealPriorMetrics?.total_debt_entry}
                    dataField={dealMetrics?.total_debt_entry}
                    dataCommentField={dealMetrics?.total_debt_entry_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    tooltip={true}
                    tooltipText="For distressed tranches please use market value as opposed to book value"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Cash Balance"
                    fieldName="cash_balance_entry"
                    priorDataField={dealPriorMetrics?.cash_balance_entry}
                    dataField={dealMetrics?.cash_balance_entry}
                    dataCommentField={dealMetrics?.cash_balance_entry_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Total Gross Debt"
                    fieldName="gross_debt"
                    priorDataField={grossPriorDebt}
                    dataField={grossDebt}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="textbox-blue"
                    textType="number"
                    noComment={true}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Book Value of Equity"
                    fieldName="equity_book_value"
                    priorDataField={dealPriorMetrics?.equity_book_value}
                    dataField={dealMetrics?.equity_book_value}
                    dataCommentField={dealMetrics?.equity_book_value_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Market Value of Equity (if public)"
                    fieldName="equity_market_value"
                    priorDataField={dealPriorMetrics?.equity_market_value}
                    dataField={dealMetrics?.equity_market_value}
                    dataCommentField={dealMetrics?.equity_market_value_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Equity value derived from DCF, valuation reports or comps (if private)"
                    fieldName="equity_value_dcf"
                    priorDataField={dealPriorMetrics?.equity_value_dcf}
                    dataField={dealMetrics?.equity_value_dcf}
                    dataCommentField={dealMetrics?.equity_value_dcf_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Enterprise Value"
                    fieldName="enterprise_value"
                    priorDataField={dealPriorMetrics?.enterprise_value}
                    dataField={dealMetrics?.enterprise_value}
                    dataCommentField={dealMetrics?.enterprise_value_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Implied % Ownership = Value of TOR's Position / (Enterprise Value + Cash Balance)"
                    fieldName="implied_ownership"
                    priorDataField={dealPriorMetrics?.implied_ownership}
                    dataField={dealMetrics?.implied_ownership}
                    dataCommentField={dealMetrics?.implied_ownership_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "value"
                    calcValue = {impOwn}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Debt/Equity (based on Total Gross Debt)"
                    fieldName="debt_equity_ratio"
                    priorDataField={debtEquityPriorVal}
                    dataField={debtEquityVal}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-blue"
                    noComment={true}
                />


                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Unadjusted LTM EBITDA / NOI"
                    fieldName="unadjusted_ltm_ebitda"
                    priorDataField={dealPriorMetrics?.unadjusted_ltm_ebitda}
                    dataField={dealMetrics?.unadjusted_ltm_ebitda}
                    dataCommentField={dealMetrics?.unadjusted_ltm_ebitda_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Adjusted LTM EBITDA / NOI"
                    fieldName="adjusted_ltm_ebitda"
                    priorDataField={dealPriorMetrics?.adjusted_ltm_ebitda}
                    dataField={dealMetrics?.adjusted_ltm_ebitda}
                    dataCommentField={dealMetrics?.adjusted_ltm_ebitda_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <div className="row pl10">
                    <label className="w200 p5 fsBig">For real estate transactions please provide the following:</label>
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Appraised Value"
                    fieldName="appraised_value"
                    priorDataField={dealPriorMetrics?.appraised_value}
                    dataField={dealMetrics?.appraised_value}
                    dataCommentField={dealMetrics?.appraised_value_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Purchased Price"
                    fieldName="purchased_price"
                    priorDataField={dealPriorMetrics?.purchased_price}
                    dataField={dealMetrics?.purchased_price}
                    dataCommentField={dealMetrics?.purchased_price_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Occupancy (%)"
                    fieldName="occupancy"
                    priorDataField={dealPriorMetrics?.occupancy}
                    dataField={dealMetrics?.occupancy}
                    dataCommentField={dealMetrics?.occupancy_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Cost to Complete"
                    fieldName="cost_to_complete"
                    priorDataField={dealPriorMetrics?.cost_to_complete}
                    dataField={dealMetrics?.cost_to_complete}
                    dataCommentField={dealMetrics?.cost_to_complete_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Status"
                    fieldName="real_estate_status"
                    priorDataField={dealPriorMetrics?.real_estate_status}
                    dataField={dealMetrics?.real_estate_status}
                    dataCommentField={dealMetrics?.real_estate_status_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="dropdown-in"
                    dataList={statusList}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Interest Expense"
                    fieldName="ltm_interest_expense"
                    priorDataField={dealPriorMetrics?.ltm_interest_expense}
                    dataField={dealMetrics?.ltm_interest_expense}
                    dataCommentField={dealMetrics?.ltm_interest_expense_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue"
                    fieldName="ltm_revenue"
                    priorDataField={dealPriorMetrics?.ltm_revenue}
                    dataField={dealMetrics?.ltm_revenue}
                    dataCommentField={dealMetrics?.ltm_revenue_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <div className="row pl10">
                    <label className="w200 p5 fsBig">LTM Revenue by Geography:</label>
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in Oceana (includes Australia and New Zealand)"
                    fieldName="ltm_revenue_oceania"
                    priorDataField={dealPriorMetrics?.ltm_revenue_oceania}
                    dataField={dealMetrics?.ltm_revenue_oceania}
                    dataCommentField={dealMetrics?.ltm_revenue_oceania_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in Africa"
                    fieldName="ltm_revenue_africa"
                    priorDataField={dealPriorMetrics?.ltm_revenue_africa}
                    dataField={dealMetrics?.ltm_revenue_africa}
                    dataCommentField={dealMetrics?.ltm_revenue_africa_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in Asia"
                    fieldName="ltm_revenue_asia"
                    priorDataField={dealPriorMetrics?.ltm_revenue_asia}
                    dataField={dealMetrics?.ltm_revenue_asia}
                    dataCommentField={dealMetrics?.ltm_revenue_asia_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in Europe"
                    fieldName="ltm_revenue_europe"
                    priorDataField={dealPriorMetrics?.ltm_revenue_europe}
                    dataField={dealMetrics?.ltm_revenue_europe}
                    dataCommentField={dealMetrics?.ltm_revenue_europe_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in North America"
                    fieldName="ltm_revenue_north_america"
                    priorDataField={dealPriorMetrics?.ltm_revenue_north_america}
                    dataField={dealMetrics?.ltm_revenue_north_america}
                    dataCommentField={dealMetrics?.ltm_revenue_north_america_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Revenue in South America"
                    fieldName="ltm_revenue_south_america"
                    priorDataField={dealPriorMetrics?.ltm_revenue_south_america}
                    dataField={dealMetrics?.ltm_revenue_south_america}
                    dataCommentField={dealMetrics?.ltm_revenue_south_america_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Cash Flow from Operations"
                    fieldName="ltm_operation_cashflow"
                    priorDataField={dealPriorMetrics?.ltm_operation_cashflow}
                    dataField={dealMetrics?.ltm_operation_cashflow}
                    dataCommentField={dealMetrics?.ltm_operation_cashflow_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="LTM Capital Expenditures"
                    fieldName="ltm_capital_expenditure"
                    priorDataField={dealPriorMetrics?.ltm_capital_expenditure}
                    dataField={dealMetrics?.ltm_capital_expenditure}
                    dataCommentField={dealMetrics?.ltm_capital_expenditure_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox"
                    calcType = "million"
                />

                <div className="row pl10">
                    <label className="w200 p5 fsBig">Leverage Ratios: Please fill out either the leverage multiples or LTV %</label>
                </div>
                <div className="row pl10">
                    <label className="w200 p5 fsBig">•	EBITDA Multiples:</label>
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Total Leverage Multiple = Total Net Debt/ LTM Adjusted EBITDA"
                    fieldName="total_leverage_multiple"
                    priorDataField={dealPriorMetrics?.total_leverage_multiple}
                    dataField={dealMetrics?.total_leverage_multiple}
                    dataCommentField={dealMetrics?.total_leverage_multiple_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "value"
                    calcValue = {totalLeverage}
                    tooltip={true}
                    tooltipText="Total Leverage Multiple includes all the debt in the company’s capital structure, even debt junior to TOR’s investment"
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="TOR Leverage Multiple Attachment Point"
                    fieldName="tor_leverage_attachment"
                    priorDataField={dealPriorMetrics?.tor_leverage_attachment}
                    dataField={dealMetrics?.tor_leverage_attachment}
                    dataCommentField={dealMetrics?.tor_leverage_attachment_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    tooltip={true}
                    tooltipText="TOR Leverage Multiple Attachment Point is the debt / Adjusted EBITDA ratio considering only TOR’s debt, as well as debt senior or pari passu to TOR’s debt, but not debt subordinated to TOR’s investment."
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="TOR Leverage Multiple Detachment Point"
                    fieldName="tor_leverage_detachment"
                    priorDataField={dealPriorMetrics?.tor_leverage_detachment}
                    dataField={dealMetrics?.tor_leverage_detachment}
                    dataCommentField={dealMetrics?.tor_leverage_detachment_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    tooltip={true}
                    tooltipText="TOR Leverage Multiple Detachment Point is the debt / Adjusted EBITDA ratio considering only debt senior to TOR’s debt.  If TOR is the senior most position in the capital structure, it would be 0%."
                />

                <div className="row pl10">
                    <label className="w200 p5 fsBig">•	LTV %:</label>
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Total LTV% = Total net Debt/ Enterprise Value (or Asset Value for Real Estate) (%)"
                    fieldName="total_ltv_percentage"
                    priorDataField={dealPriorMetrics?.total_ltv_percentage}
                    dataField={dealMetrics?.total_ltv_percentage}
                    dataCommentField={dealMetrics?.total_ltv_percentage_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    calcType = "value"
                    calcValue = {totalLtv}
                    suffix = '%'
                    tooltip={true}
                    tooltipText="Total LTV includes all the debt in the company’s capital structure, even debt junior to TOR’s investment."
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="TOR LTV % Attachment Point (%)"
                    fieldName="tor_ltv_attachment"
                    priorDataField={dealPriorMetrics?.tor_ltv_attachment}
                    dataField={dealMetrics?.tor_ltv_attachment}
                    dataCommentField={dealMetrics?.tor_ltv_attachment_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    suffix = '%'
                    tooltip={true}
                    tooltipText="TOR LTV % Attachment Point is the LTV ratio considering only TOR’s debt, as well as debt senior or pari passu to TOR’s debt, but not debt subordinated to TOR’s investment."
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="TOR LTV % Detachment Point (%)"
                    fieldName="tor_ltv_detachment"
                    priorDataField={dealPriorMetrics?.tor_ltv_detachment}
                    dataField={dealMetrics?.tor_ltv_detachment}
                    dataCommentField={dealMetrics?.tor_ltv_detachment_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    textType="number"
                    handleChangeDate={this.handleChangeDate}
                    type="textbox-in"
                    suffix = '%'
                    tooltip={true}
                    tooltipText="TOR LTV % Detachment Point is the LTV ratio considering only debt senior to TOR’s debt.  If TOR is the senior most position in the capital structure, it would be 0%."
                />

                <div className="row pl10">
                    <label className="w200 p5 fsBig">ESG Risk Rating: 1 (best) to 5 (worst)</label>
                </div>

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Environment ESG Rating"
                    fieldName="esg_environment_rating_value"
                    priorDataField={dealPriorMetrics?.esg_environment_rating_value}
                    dataField={dealMetrics?.esg_environment_rating_value}
                    dataCommentField={dealMetrics?.esg_environment_rating_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="dropdown"
                    dataList={esgRatingList}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Social ESG Rating"
                    fieldName="esg_social_rating_value"
                    priorDataField={dealPriorMetrics?.esg_social_rating_value}
                    dataField={dealMetrics?.esg_social_rating_value}
                    dataCommentField={dealMetrics?.esg_social_rating_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="dropdown"
                    dataList={esgRatingList}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Governance ESG Rating"
                    fieldName="esg_governance_rating_value"
                    priorDataField={dealPriorMetrics?.esg_governance_rating_value}
                    dataField={dealMetrics?.esg_governance_rating_value}
                    dataCommentField={dealMetrics?.esg_governance_rating_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="dropdown"
                    dataList={esgRatingList}
                />

                <FormDisplay
                    fieldList={this.props.fieldList}
                    formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                    heading="Overall ESG Risk Score"
                    fieldName="esg_rating_value"
                    priorDataField={dealPriorMetrics?.esg_rating_value}
                    dataField={dealMetrics?.esg_rating_value}
                    dataCommentField={dealMetrics?.esg_rating_comment}
                    handleChange={this.handleChangeMetrics}
                    handleDropDownChange={this.handleDropDownChangeMetrics}
                    type="dropdown"
                    dataList={esgRatingList}
                />

                
                {
                    dealMetrics?.quarter !== "Underwriting"
                        ?
                        null
                        :
                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType={dealMetrics?.quarter !== "Underwriting" ? "commentDispPrior" : "commentDisp"}
                            heading="Are the ESG Risk Scores and analysis included in the IC Memo?"
                            fieldName="is_ESG_in_Memo"
                            dataField={dealMetrics?.is_ESG_in_Memo}
                            dataCommentField={dealMetrics?.is_ESG_in_Memo_comment}
                            handleChange={this.handleChangeMetrics}
                            handleDropDownChange={this.handleDropDownChangeMetrics}
                            handleChangeDate={this.handleChangeDate}
                            type="checkbox"
                        />
                }
            </div>
        )
    }
}

export default Deal_Metrics;