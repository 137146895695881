import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

export const gridLayoutBALService = {
     saveGridLayout
    ,getGridLayout
    ,getAllGridLayout
    ,removeGridLayout
};

function removeGridLayout(gridLayoutId, id) {
    const user = userBALService.getUserInfo()?.user;
    var data = {
          GridLayoutId       :   gridLayoutId 
          ,UserId             :   user?.id
          ,Id                 :   id
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(data) };
    return fetch(`${config.apiDDSUrl}/removegridlayout`, requestOptions).then(Utils.handleResponse);
}
function saveGridLayout(gridLayoutId, id, type, sortModel, filterModel, columnState, columnGroupState, isPivotMode, isGlobal) {
    const user = userBALService.getUserInfo()?.user;
    var data = {
         GridLayoutId       :   gridLayoutId
        ,UserId             :   user?.id
        ,Id                 :   id
        ,Type               :   type
        ,SortModel          :   JSON.stringify(sortModel)
        ,FilterModel        :   JSON.stringify(filterModel)
        ,ColumnState        :   JSON.stringify(columnState)
        ,ColumnGroupState   :   JSON.stringify(columnGroupState)
        ,IsPivotMode        :   isPivotMode
        ,IsGlobal           :   isGlobal ?? false
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(data) };
    return fetch(`${config.apiDDSUrl}/savegridlayout`, requestOptions).then(Utils.handleResponse);
}

function getGridLayout(gridId, type) {
    const user = userBALService.getUserInfo()?.user;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    const requestURL  = `${config.apiDDSUrl}/getgridlayout/gridId=${gridId}/userId=${user.id}/type=${type}`;
    return fetch(requestURL, requestOptions).then(Utils.handleResponse).then(gridLayout => { return gridLayout; });
}

function getAllGridLayout(gridId) {
    const user = userBALService.getUserInfo()?.user;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    const requestURL  = `${config.apiDDSUrl}/getallgridlayout/gridId=${gridId}/userId=${user.id}`;
    return fetch(requestURL, requestOptions).then(Utils.handleResponse).then(gridLayoutList => { return gridLayoutList; });
}