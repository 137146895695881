import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import { Session } from '../helper/session';
const {config} = require('../config');


const getColumnList = async (params) => {
    
    const user = JSON.parse(Session.getItem(config.token));
    const approverRole = user.userRoles.filter(function (item) { return item === "Approver" });
    const isApprover = approverRole.length > 0;

    return  {
        'columnSpecs': [
        {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, headerCheckboxSelection: isApprover, headerCheckboxSelectionFilteredOnly: isApprover, checkboxSelection: isApprover, minWidth : 230, maxWidth : 350 }, 
        {'display': 'Project', 'col': 'projectName', 'agg': 'uniq', 'ag-grid': true}, 
        {'display': 'Live/Expired', 'col': 'hasExpired', 'agg': 'uniq', 'ag-grid': true, 'rowGroup': true, maxWidth : 130 },
        {'display': 'Tor Notional', 'col': 'torNotional', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, maxWidth : 130},
        {'display': 'Tor Market Value', 'col': 'torMarketValue', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, maxWidth : 170}, 
        {'display': 'Status', 'col': 'status', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150}, 
        {'display': 'Status Underwriting', 'col': 'statusUnderwriting', 'agg': 'sum', 'ag-grid': true, maxWidth : 180}, 
        {'display': 'Status Last Quarter', 'col': 'statusLastQuarter', 'agg': 'sum', 'ag-grid': true, maxWidth : 170}, 
        {'display': 'Changes', 'ag-grid': true, maxWidth : 180}, 
        {'display': 'Analyst 1', 'col': 'analyst1', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150, 'hide': true}, 
        {'display': 'Analyst 2', 'col': 'analyst2', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150, 'hide': true}, 
        {'display': 'Analyst 3', 'col': 'analyst3', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150, 'hide': true}, 
        /*{'display': 'Status.Deal Attributes', 'col': 'statusDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 160}, 
        {'display': 'Status.Tranche Attributes', 'col': 'statusTrancheAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 170}, 
        {'display': 'Status Underwriting.Deal Attributes', 'col': 'statusUnderwritingDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150}, 
        {'display': 'Status Underwriting.Deal Risk', 'col': 'statusUnderwritingDealRisk', 'agg': 'uniq', 'ag-grid': true, maxWidth : 120}, 
        {'display': 'Status Underwriting.Deal Metrics', 'col': 'statusUnderwritingDealMetrics', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}, 
        {'display': 'Status Underwriting.Deal Returns', 'col': 'statusUnderwritingDealReturns', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}
        */
    ]};
}

export const dealBALService = {
    getAllDeals,
    getAnalysts,
    getFields,
    getOneField,
    generateEmail,
    getAllAnalysts,
    getAllFunds,
    getAllPositions,
    getAllCountry,
    getGics,
    getNace,
    getNaceActivity,
    getGicsIG,
    getBics,
    getDealAttribute,
    getDealMetrics,
    getPriorDealMetrics,
    getDealRisks,
    getDealReturns,
    getTrancheAttribute,
    getTrancheMetrics,
    getSetting,
    getAllSettings,
    updateDeal,
    insertDealAttribute,
    insertDealAnalyst,
    insertDealMetrics,
    insertDealRisks,
    insertDealReturns,
    insertTrancheAttribute,
    insertTrancheMetrics,
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    approve,
    checkDeal,
    generateEmailConfirmation,
    getAllBbgDeals,
    getBbgMetrics,
    getRates,
    getAllDealLoans
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'deal',
        minWidth: 100,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/dealsummary`, requestOptions).then(Utils.handleResponse).then(deal => { 
        
        return deal.map(row => {
        return {
            ...row
            , hasExpired : row['hasExpired'] ? 'Expired' : 'Live'
        }}); });
}


function getAllDeals() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deals`, requestOptions).then(Utils.handleResponse).then(deal => { return deal; });
}

function getAllDealLoans() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/dealLoans`, requestOptions).then(Utils.handleResponse).then(deal => { return deal; });
}

function getAllBbgDeals() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/allBbgDealFA`, requestOptions).then(Utils.handleResponse).then(deal => { return deal; });
}

function generateEmail(value) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(value) };
    return fetch(`${config.apiDDSUrl}/mail/sendDifference`, requestOptions).then(Utils.handleResponse);
}

function generateEmailConfirmation(value) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(value) };
    return fetch(`${config.apiDDSUrl}/mail/sendQuickSetupConfirmation`, requestOptions).then(Utils.handleResponse);
}

function getAnalysts() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/analysts`, requestOptions).then(Utils.handleResponse).then(analystList => { return analystList; });
}

function getFields() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/user/fields`, requestOptions).then(Utils.handleResponse).then(fieldsList => { return fieldsList; });
}

function getRates() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/exchangeRate`, requestOptions).then(Utils.handleResponse).then(fieldsList => { return fieldsList; });
}

function getOneField(fieldName) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/user/fields/field=${fieldName}`, requestOptions).then(Utils.handleResponse).then(fieldsList => { return fieldsList; });
}

function getAllAnalysts(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/analysts/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(analyst => { return analyst; });
}

function checkDeal(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/check/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(data => { return data; });
}

function getAllFunds(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/fund/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(analyst => { return analyst; });
}

function getAllPositions(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/positions/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(analyst => { return analyst; });
}

function getAllCountry() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/country`, requestOptions).then(Utils.handleResponse).then(countryList => { return countryList; });
}

function getGics() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/gics`, requestOptions).then(Utils.handleResponse).then(gicsList => { return gicsList; });
}

function getNace() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/nace`, requestOptions).then(Utils.handleResponse).then(naceList => { return naceList; });
}

function getNaceActivity() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/naceActivity`, requestOptions).then(Utils.handleResponse).then(activityList => { return activityList; });
}

function getGicsIG() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/gicsIG`, requestOptions).then(Utils.handleResponse).then(gicsIGList => { return gicsIGList; });
}

function getBics() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/bics`, requestOptions).then(Utils.handleResponse).then(bicsList => { return bicsList; });
}

function getDealAttribute(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/Attribute/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(dealAttributeList => { return dealAttributeList; });
}

function getDealMetrics(DealId, Quarter) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/Metrics/deal=${DealId}/quarter=${Quarter}`, requestOptions).then(Utils.handleResponse).then(dealMetricsList => { return dealMetricsList; });
}

function getPriorDealMetrics(DealId, Quarter) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/PriorMetrics/deal=${DealId}/quarter=${Quarter}`, requestOptions).then(Utils.handleResponse).then(dealMetricsList => { return dealMetricsList; });
}

function getBbgMetrics(DealId, Quarter) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/getBbgMetrics/deal=${DealId}/quarter=${Quarter}`, requestOptions).then(Utils.handleResponse).then(dealMetricsList => { return dealMetricsList; });
}

function getDealRisks(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/Risks/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(dealMetricsList => { return dealMetricsList; });
}

function getDealReturns(DealId, Quarter) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/Returns/deal=${DealId}/quarter=${Quarter}`, requestOptions).then(Utils.handleResponse).then(dealReturnslist => { return dealReturnslist; });
}

function getTrancheAttribute(DealId) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/TrancheAttribute/deal=${DealId}`, requestOptions).then(Utils.handleResponse).then(trancheAttributeList => { return trancheAttributeList; });
}

function getTrancheMetrics(DealId, Quarter) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/deal/TrancheMetrics/deal=${DealId}/quarter=${Quarter}`, requestOptions).then(Utils.handleResponse).then(trancheMetricsList => { return trancheMetricsList; });
}

function getSetting(value) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(value) };
    return fetch(`${config.apiDDSUrl}/getSetting`, requestOptions).then(Utils.handleResponse);
}

function getAllSettings() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getSetting`, requestOptions).then(Utils.handleResponse).then(settingsList => { return settingsList; });
}

function insertDealAttribute(dealAttribute) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(dealAttribute) };
    return fetch(`${config.apiDDSUrl}/deal/addAttribute/deal=${dealAttribute.deal_id}`, requestOptions).then(Utils.handleResponse);
}


function insertDealAnalyst(dealAnalyst, dealId) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(dealAnalyst) };
    return fetch(`${config.apiDDSUrl}/deal/addAnalysts/deal=${dealId}`, requestOptions).then(Utils.handleResponse);
}

function insertDealMetrics(dealMetrics) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(dealMetrics) };
    return fetch(`${config.apiDDSUrl}/deal/addMetrics/deal=${dealMetrics.deal_id}`, requestOptions).then(Utils.handleResponse);
}

function updateDeal(body, dealId) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/deal/update/${dealId}`, requestOptions).then(Utils.handleResponse);
}

function insertDealRisks(dealRisk) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(dealRisk) };
    return fetch(`${config.apiDDSUrl}/deal/addRisks/deal=${dealRisk.deal_id}`, requestOptions).then(Utils.handleResponse);
}

function insertDealReturns(dealReturns) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(dealReturns) };
    return fetch(`${config.apiDDSUrl}/deal/addReturns`, requestOptions).then(Utils.handleResponse);
}

function insertTrancheAttribute(trancheAttribute) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(trancheAttribute) };
    return fetch(`${config.apiDDSUrl}/deal/addTrancheAttribute/`, requestOptions).then(Utils.handleResponse);
}

function insertTrancheMetrics(trancheMetrics) {
    const requestOptions = { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(trancheMetrics) };
    return fetch(`${config.apiDDSUrl}/deal/addTrancheMetrics/`, requestOptions).then(Utils.handleResponse);
}

function approve(deals) {
    const promises = deals.map((deal) => { 
       var a = fetch(`${config.apiDDSUrl}/deal/approve/name=${deal.dealName}`, { method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return handleResponseApprove(data, deal) });
       return a;
    });
    return promises;
}

function handleResponseApprove(response, deal) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return {error : `${deal.deal} : ${error}`};
        }
        return data;
    });
}