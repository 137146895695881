import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {

        'SnPRatingMap': 
        {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'ag-grid': true, 'format': 'fundFormat', 'rowGroup': true},
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, hide : true},
            {'display': 'USD IRR', 'col': 'USD IRR', 'agg': 'sum', 'ag-grid': true, sort: 'asc', 'format': 'percentFormat'},
            {'display': 'USD MOIC', 'col': 'USD MOIC', 'agg': 'sum', 'ag-grid': true, sort: 'asc', 'format': 'decimalFormat2'},
            // {'display': 'Local IRR', 'col': 'Local IRR', 'agg': 'sum', 'ag-grid': true, sort: 'asc', 'format': 'percentFormat'},
            // {'display': 'Local MOIC', 'col': 'Local MOIC', 'agg': 'sum', 'ag-grid': true, sort: 'asc', 'format': 'decimalFormat2'},
        ]};
}

export const historicalCashflowTopBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'deal'
        ,pinned: 'left'
        ,resizable: false
        ,hide: false
        ,cellRendererParams:  { suppressCount: true } 
       };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}