import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { dealLegalDetailsBALService } from '../../bal/dealLegalDetails.bal';
import moment from 'moment'; 

class DealLegalDetailsSummary extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            toDate: moment(today).format("YYYY-MM-DD"),
            fromDate: moment(yesterday).format("YYYY-MM-DD"),
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ],
            values: null,
            selectedRows: null
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.getPage = this.getPage.bind(this);
        this.addNew = this.addNew.bind(this);
        this.refresh = this.refresh.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.reportModal = this.reportModal.bind(this);
        this.handleChangeCheckboxValues = this.handleChangeCheckboxValues.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.refresh();
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    generateReport = async() => {
        this.setState({
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'close',
            modalHeading: 'Report is being generated!'
        }); 
    }

    reportModal = () => {
        this.setState({
            modalVisible: true,
            modalType: 'genReport',
            modalHandler: 'close',
            modalHeading: 'Generate Report'
        })
    }

    addNew = async () => {
        this.refresh();
    }

    save () {
        const { values } = this.state; 
         let rows = Object.keys(values).map(function(key){  
            let keys = key.split("_");
            return { dealId : keys[0] , questionId: keys[1], value : values[key]};
        });

        rows = rows.filter(row => row.value !== row.existingValue)

        console.log(rows);
        dealLegalDetailsBALService.save(rows).then( 
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saved data successfully!',
                        open: false
                    });
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while saving marks:`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saving data failed!'
                    }); 
                    console.log(error);
            });
        
        this.refresh();
    }

    refresh() {
        dealLegalDetailsBALService.getRowData().then(data => {
            let showGrid = false;
            if(data && data.length > 0) {
                data = data.map(row => { return {
                            ...row, 
                            existingValue: row?.value
                    }
                });
                showGrid = true;
            }

            let deals = [...new Set(data.filter(function (item) {
                return (item ? true : false);
            }).map(item => item.dealName))];

            let dealList = deals.map(item => { return { key: item, value: item, text: item } });
 
            this.setState({
                gridData: data,
                showGrid: showGrid,
                dealList: dealList
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving data!'
            }); 
            console.log(error);
        });
    }

    handleChange(name, value) {
        console.log(name, value);
        this.setState({ [name]: value });
    }

    handleDropDownChange(e, { name, value }) {
        const { gridData } = this.state;
        let rows = gridData.filter(item => item.dealName === value );

        let values = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));
        let existingValues = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));

        this.setState({ 
            [name]: value,
            selectedRows: rows,
            values : values,
            existingValues : existingValues,
        });
    }
    
    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    handleChangeCheckboxValues(e) {
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRowSelected(e) {
        const { data, action } = e.target;
        if (action === "Edit") {
            this.setState({
                id: data.deal_risk_commentary_id,
                deal: data.deal_id,
                forDate: new Date(data.report_date),
                editorState: data.comment
            })
        }
    }


    getPage() {
        const { modalType, deal, editType, showGrid, fund, fundList, fromDate, toDate, modalVisible, modalHeading, gridReminderData, gridData, existingDealDetails, newDealDetails } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                } 
                <div> 
                {
                    deal !==null && deal !=='' ?
                    <div style={{ float: "right", padding: "5px 5px", cursor: "pointer" }} onClick={this.refresh}>                            
                        <button key='refreshButton' className="blueB-no-margin">Refresh</button>
                    </div>:<div></div>
                }
                </div> 
                { 
                    showGrid? 
                    <div style={{ height: '100%', width: '100%' }}>
                        <b>Deal Questionnaire Summary</b>
                        <TorGrid
                            setAgGridAPI = {this.setAgGridAPI}
                            isHideDateInputs={true}
                            domLayout="normal"

                            isHideSaveGridLayout={true}

                            rowData={gridData}
                            //GridRowsToUpdate={GridRowsToUpdate}

                            className="ag-theme-alpine"
                            rowSelection="multiple"
                            gridIdentityColumn="idDealQuestion"

                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}

                            guikey = "dealLegalDetails"
                            sortColumn = {true}

                            singleClickEdit = {true}
                            //isApprover = {isApprover}
                            approve = {this.approve}
                            save = {this.save}
                            showSave = {false}
                            //saveAndSend = {() => { this.save(true)}}
                            editType = {editType}
                            //isShowLoad = {isShowLoad}
                            isShowGrid = {showGrid}
                            cellValueChanged = {this.cellValueChanged}
                        />
                    </div>:<div><b>Deal Questionnaire Summary - No rows to display</b></div>
                }
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingDealDetails) !== JSON.stringify(newDealDetails)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={fromDate}
                    toDate={toDate}
                    fundList={fundList}
                    fund={fund}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default DealLegalDetailsSummary;