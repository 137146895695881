import React, { Component } from 'react';
import TorGrid from '../torgrid/torgrid';

class PortfolioPerformanceGrid extends Component {
  render() {
    return this.getTemplate();
  }

  getTemplate() {
    const {user} = this.props;
    var groupByColumnList = [];

    if (user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester")) {
      groupByColumnList.push("Fund");
      groupByColumnList.push("Deal");
      groupByColumnList.push("Investment Code");
    } else {
      groupByColumnList.push("Fund");

      groupByColumnList.push(this.props.GroupBy);

      if (user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester")) {}

      if(this.props.GroupBy === "gics_sector") {
        groupByColumnList.push("gics_industry_group");
        groupByColumnList.push("gics_industry");
        groupByColumnList.push("gics_subindustry");

      } else if(this.props.GroupBy === "bics_sector") {
        groupByColumnList.push("bics_industry_group");
        groupByColumnList.push("bics_industry");

      } else if(this.props.GroupBy === "credit_ranking") {
        groupByColumnList.push("holdco_opco");
      }

      groupByColumnList.push("Deal");
      groupByColumnList.push("Investment Code");
    }

    return (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <TorGrid
          groupColumns = {groupByColumnList}
          rowData = {this.props.gridData}
          isHideDateInputs={true}
          className="ag-theme-alpine"
          guikey="portfolioPerformanceGrid" 
          GridTypeSelected = {(user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester")) ? "" : "Simple XTD PNL"}
          gridTitle = "*CTR calculations may not add up to the exact total. CTR calculations are compounding based on start-of-month capital, whereas ROI is non-compounding and based on average capital."
          gridIdentityColumn="pnl_id"
          toggleColumn = {(user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester"))}
        />
      </div>
    );
  }
}

export default PortfolioPerformanceGrid;
