/**<import>****************************************************************************************************/
import React, { Component } from 'react';
//https://react.semantic-ui.com/modules/dropdown/
import { Dropdown, Popup } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Our components
import '../deals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

class FormDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: null,
            fieldList: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRisk = this.handleChangeRisk.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCalcChange = this.handleCalcChange.bind(this);
        this.handleMlnChange = this.handleMlnChange.bind(this);
    };

    handleCalcChange(e) {
        if (this.props?.id !== undefined) {
            this.props.handleChangeDate(this.props.id, this.props.fieldName, this.props.calcValue);
        } else {
            this.props.handleChangeDate(this.props.fieldName, this.props.calcValue);
        }
    }

    handleMlnChange(e) {
        if (this.props?.id !== undefined) {
            this.props.handleChangeDate(this.props.id, this.props.fieldName, this.props.dataField * 1e6);
        } else {
            this.props.handleChangeDate(this.props.fieldName, this.props.dataField * 1e6);
        }
    }

    handleNumberChange = values => {
        var val = values.value;

        if(typeof val === 'string' || val instanceof String) {
            val = parseFloat(val);
        }

        if (this.props?.id !== undefined) {
            this.props.handleChangeDate(this.props.id, this.props.fieldName, val);
        } else {
            this.props.handleChangeDate(this.props.fieldName, val);
        }
    }

    handleChange(e) {
        if (this.props?.id !== undefined) {
            this.props.handleChange(this.props.id, e.target.name, e.target.value);
        } else if (this.props.type === 'checkbox' || this.props.type === 'checkbox-in') {
            if (e.target.value === "true") {
                this.props.handleChangeDate(this.props.fieldName, true);
            } else if (e.target.value === "false") {
                this.props.handleChangeDate(this.props.fieldName, false);
            } else {
                this.props.handleChange(e);
            }
        } else {
            this.props.handleChange(e);
        }
    }

    handleChangeRisk(e) {
        if (e.target.value === "true") {
            this.props.handleChangeDate(`${this.props.fieldName}_is_development`, true);
        } else if (e.target.value === "false") {
            this.props.handleChangeDate(`${this.props.fieldName}_is_development`, false);
        } else {
            this.props.handleChange(e);
        }
    }

    handleDropDownChange(e, { name, value }) {
        if (this.props?.id !== undefined) {
            this.props.handleDropDownChange(this.props.id, name, value);
        } else {
            this.props.handleDropDownChange(e, { name, value });
        }
    }

    handleChangeDate = date => {
        this.setState({ selectedDate: date });
        if (this.props?.id !== undefined) {
            this.props.handleChangeDate(this.props.id, this.props.fieldName, date ? moment(date).format("YYYY-MM-DD") : null);
        } else {
            console.log(this.props.fieldName, date ? moment(date).format("YYYY-MM-DD") : null);
            this.props.handleChangeDate(this.props.fieldName, date ? moment(date).format("YYYY-MM-DD") : null);
        }
    }

    render() {
        var fieldList = [];
        if (this.props?.fieldList && this.props?.fieldName && this.props?.fieldList.length > 0) {
            var field = this.props?.fieldName;
            fieldList = this.props?.fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
        }
        if ((fieldList.length > 0 && fieldList[0].permission !== 0)) {
            if (this.props.formType === "commentDisp") {
                if (this.props.type === "notes") {
                    return (
                        <div className="pt10 lrDisp">
                            {
                                fieldList[0].required === true && (this.props?.required ?? true)
                                    ?
                                    <label className="w600 pr20 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                    :
                                    <label className="w600 pr20 fsBig">{this.props.heading}</label>
                            }
                            {
                                fieldList[0].permission === 1
                                    ?
                                    <textarea
                                        rows="5"
                                        placeholder=""
                                        name={this.props.fieldName}
                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                        autoComplete="off"
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                    :
                                    <textarea
                                        rows="5"
                                        placeholder="Enter Notes"
                                        name={this.props.fieldName}
                                        onChange={this.handleChange}
                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                        autoComplete="off"
                                        className={'form-control input'}
                                    />
                            }
                        </div>
                    )
                } else {
                    if (fieldList[0].permission === 1 && this.props.type !== "textbox-blue") {
                        let dF = '';
                        if ((this.props.type === "dropdown" || this.props.type === "dropdown-in") && this.props.dataField !== null && this.props.dataField !== undefined && this.props.dataList !== undefined) {
                            const { dataField, dataList } = this.props;
                            dF = dataList.filter(function (item) { return item.value === dataField })[0]?.text;
                        } else if ((this.props.type === "checkbox" || this.props.type === "checkbox-in") && this.props.dataField !== null && this.props.dataField !== undefined) {
                            const { dataField } = this.props;
                            if (dataField === true) {
                                dF = 'Yes';
                            } else if (dataField === false) {
                                dF = 'No';
                            }
                        } else {
                            dF = this.props.dataField;
                        }
                        return (
                            <div className="row">
                                <div className="column2 left2">
                                    {
                                        this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                        ?
                                        fieldList[0].required === true && (this.props?.required ?? true)
                                            ?
                                            <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                            :
                                            <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                        :
                                        this.props.tooltip === true
                                            ?
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                <label className="w200 p5 fsBig">
                                                    {this.props.heading} 
                                                    <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                :
                                                <label className="w200 p5 fsBig">
                                                    {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                </label>
                                            :
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                <label className="w200 p5 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                :
                                                <label className="w200 p5 fsBig">{this.props.heading}</label>
                                    }
                                </div>
                                <div className="column2 right2">
                                    <div className="column2 left">
                                        {
                                            this.props?.textType === "number"
                                                ?
                                                <NumberFormat
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    value={this.props.dataField}
                                                    thousandSeparator={true}
                                                    className={'form-control inputDisabledGrey'}
                                                    suffix={this.props?.suffix ?? ''}
                                                    disabled
                                                />
                                                :
                                                this.props.type === 'datepick'
                                                    ?
                                                    <DatePicker
                                                        name="AsOfDate"
                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                        onChange={this.handleChangeDate}
                                                        autoComplete = "off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        disabled
                                                    />
                                                    :
                                                    <input type="text"
                                                        placeholder=""
                                                        name={this.props.fieldName}
                                                        autoComplete="off"
                                                        value={dF === null ? '' : dF}
                                                        className={'form-control inputDisabledGrey'}
                                                        disabled
                                                    />
                                        }
                                    </div>
                                    {
                                        this.props.noComment
                                            ?
                                            null
                                            :
                                            <div className="column2 right">
                                                <input type="text"
                                                    placeholder=""
                                                    name={`${this.props.fieldName}_comment`}
                                                    autoComplete="off"
                                                    value={this.props.dataCommentField === null ? '' : this.props.dataCommentField}
                                                    className={'form-control inputDisabledGrey'}
                                                    disabled
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="row">
                                <div className="column2 left2">
                                    {
                                        this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                            ?
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                this.props.tooltip === true
                                                    ?
                                                    <label className="w200 p5 fsBig ml20 fwItalic">
                                                        {this.props.heading} 
                                                        <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                :
                                                this.props.tooltip === true
                                                    ?
                                                    <label className="w200 p5 fsBig ml20 fwItalic">
                                                        {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                            :
                                            this.props.tooltip === true
                                                ?
                                                fieldList[0].required === true && (this.props?.required ?? true)
                                                    ?
                                                    <label className="w200 p5 fsBig">
                                                        {this.props.heading} 
                                                        <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig">
                                                        {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    </label>
                                                :
                                                fieldList[0].required === true && (this.props?.required ?? true)
                                                    ?
                                                    <label className="w200 p5 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                    :
                                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                    }
                                </div>
                                <div className="column2 right2">
                                    <div className="column2 left">
                                        {
                                            this.props.type === "dropdown" || this.props.type === "dropdown-in"
                                                ?
                                                <Dropdown
                                                    placeholder='Select Values'
                                                    name={this.props.fieldName}
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={this.props.dataList}
                                                    value={this.props.dataField}
                                                />
                                                :
                                                this.props.type === "checkbox" || this.props.type === "checkbox-in"
                                                    ?
                                                    <div className="form-control border-none" onChange={this.handleChange}>
                                                        <input type="radio" id={`${this.props.fieldName}_true`} value={true} name={this.props.fieldName} checked={this.props.dataField === true} /> <label for={`${this.props.fieldName}_true`} className="fsBig">Yes</label>
                                                        <input type="radio" id={`${this.props.fieldName}_false`} value={false} name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === false} /> <label for={`${this.props.fieldName}_false`} className="fsBig">No</label>
                                                    </div>
                                                    :
                                                    this.props.type === "textbox" || this.props.type === "textbox-in"
                                                        ?
                                                        this.props?.textType === "number"
                                                            ?
                                                            this.props?.calcType === "value"
                                                                ?
                                                                <div style = {{display: "flex"}}>
                                                                    <NumberFormat
                                                                        placeholder="Enter Response"
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        onValueChange={this.handleNumberChange}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputLightP'}
                                                                    />
                                                                    <div className = "icon-button-grey" onClick = {this.handleCalcChange}>
                                                                        <FontAwesomeIcon icon={faCalculator}/>
                                                                    </div>
                                                                </div>
                                                                :
                                                                this.props?.calcType === "million"
                                                                    ?
                                                                    <div style = {{display: "flex"}}>
                                                                        <NumberFormat
                                                                            placeholder="Enter Response"
                                                                            name={this.props.fieldName}
                                                                            value={this.props.dataField}
                                                                            thousandSeparator={true}
                                                                            onValueChange={this.handleNumberChange}
                                                                            suffix={this.props?.suffix ?? ''}
                                                                            className={'form-control inputLightP'}
                                                                        />
                                                                        <div className = "icon-button-grey" onClick = {this.handleMlnChange} title = "Click to multiply by million">
                                                                            <span>MM</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <NumberFormat
                                                                        placeholder="Enter Response"
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        onValueChange={this.handleNumberChange}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputLightP'}
                                                                    />
                                                            :
                                                            <input type="text"
                                                                placeholder="Enter Response"
                                                                name={this.props.fieldName}
                                                                autoComplete="off"
                                                                value={this.props.dataField === null ? '' : this.props.dataField}
                                                                className={'form-control inputLightP'}
                                                                onChange={this.handleChange}
                                                            />
                                                        :
                                                        this.props.type === "checkbox-pp"
                                                            ?
                                                            <div className="form-control border-none" onChange={this.handleChange}>
                                                                <input type="radio" value="Public" id={`${this.props.fieldName}_Public`} name={this.props.fieldName} checked={this.props.dataField === "Public"} /> <label for={`${this.props.fieldName}_Public`} className="fsBig">Public</label>
                                                                <input type="radio" value="Private" id={`${this.props.fieldName}_Private`} name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === "Private"} /> <label for={`${this.props.fieldName}_Private`} className="fsBig">Private</label>
                                                            </div>
                                                            :
                                                            this.props.type === "textbox-blue"
                                                                ?
                                                                this.props?.textType === "number"
                                                                    ?
                                                                    <NumberFormat
                                                                        placeholder=""
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputDisabled'}
                                                                        disabled
                                                                    />
                                                                    :
                                                                    <input type="text"
                                                                        placeholder=""
                                                                        name={this.props.fieldName}
                                                                        autoComplete="off"
                                                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                                                        className={'form-control inputDisabled'}
                                                                        disabled
                                                                    />
                                                                :
                                                                this.props.maxDate
                                                                    ?
                                                                    <DatePicker
                                                                        name="AsOfDate"
                                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                                        onChange={this.handleChangeDate}
                                                                        maxDate = {new Date(this.props.maxDate)}
                                                                        autoComplete = "off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                    />
                                                                    :
                                                                    <DatePicker
                                                                        name="AsOfDate"
                                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                                        onChange={this.handleChangeDate}
                                                                        autoComplete = "off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                    />
                                        }
                                    </div>
                                    {
                                        this.props.noComment
                                            ?
                                            null
                                            :
                                            <div className="column2 right">
                                                <input type="text"
                                                    placeholder="Enter Comment"
                                                    name={`${this.props.fieldName}_comment`}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    value={this.props.dataCommentField === null ? '' : this.props.dataCommentField}
                                                    className={'form-control inputLightP'}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        );
                    }
                }
            } else if (this.props.formType === "commentDispPrior") {
                if (this.props.type === "notes") {
                    return (
                        <div className="pt10 lrDisp">
                            {
                                fieldList[0].required === true && (this.props?.required ?? true)
                                    ?
                                    <label className="w600 pr20 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                    :
                                    <label className="w600 pr20 fsBig">{this.props.heading}</label>
                            }
                            {
                                fieldList[0].permission === 1
                                    ?
                                    <textarea
                                        rows="5"
                                        placeholder=""
                                        name={this.props.fieldName}
                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                        autoComplete="off"
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                    :
                                    <textarea
                                        rows="5"
                                        placeholder="Enter Notes"
                                        name={this.props.fieldName}
                                        onChange={this.handleChange}
                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                        autoComplete="off"
                                        className={'form-control input'}
                                    />
                            }
                        </div>
                    )
                } else {
                    if (fieldList[0].permission === 1 && this.props.type !== "textbox-blue") {
                        let dF = '';
                        if ((this.props.type === "dropdown" || this.props.type === "dropdown-in") && this.props.dataField !== null && this.props.dataField !== undefined && this.props.dataList !== undefined) {
                            const { dataField, dataList } = this.props;
                            dF = dataList.filter(function (item) { return item.value === dataField })[0]?.text;
                        } else if ((this.props.type === "checkbox" || this.props.type === "checkbox-in") && this.props.dataField !== null && this.props.dataField !== undefined) {
                            const { dataField } = this.props;
                            if (dataField === true) {
                                dF = 'Yes';
                            } else if (dataField === false) {
                                dF = 'No';
                            }
                        } else {
                            dF = this.props.dataField;
                        }

                        let dfPrior = '';
                        if ((this.props.type === "dropdown" || this.props.type === "dropdown-in") && this.props.priorDataField !== null && this.props.priorDataField !== undefined && this.props.dataList !== undefined) {
                            const { priorDataField, dataList } = this.props;
                            dfPrior = dataList.filter(function (item) { return item.value === priorDataField })[0]?.text;
                        } else if ((this.props.type === "checkbox" || this.props.type === "checkbox-in") && this.props.priorDataField !== null && this.props.priorDataField !== undefined) {
                            const { priorDataField } = this.props;
                            if (priorDataField === true) {
                                dfPrior = 'Yes';
                            } else if (priorDataField === false) {
                                dfPrior = 'No';
                            }
                        } else {
                            dfPrior = this.props.priorDataField;
                        }

                        return (
                            <div className="row">
                                <div className="column2 left2">
                                    {
                                        this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                        ?
                                        fieldList[0].required === true && (this.props?.required ?? true)
                                            ?
                                            <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                            :
                                            <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                        :
                                        this.props.tooltip === true
                                            ?
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                <label className="w200 p5 fsBig">
                                                    {this.props.heading} 
                                                    <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                :
                                                <label className="w200 p5 fsBig">
                                                    {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                </label>
                                            :
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                <label className="w200 p5 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                :
                                                <label className="w200 p5 fsBig">{this.props.heading}</label>
                                    }
                                </div>
                                <div className="column2 right2">
                                    <div className="column2 three">
                                        {
                                            this.props?.textType === "number"
                                                ?
                                                <NumberFormat
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    value={this.props.dataField}
                                                    thousandSeparator={true}
                                                    className={'form-control inputDisabledGrey'}
                                                    suffix={this.props?.suffix ?? ''}
                                                    disabled
                                                />
                                                :
                                                this.props.type === 'datepick'
                                                    ?
                                                    <DatePicker
                                                        name="AsOfDate"
                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                        onChange={this.handleChangeDate}
                                                        autoComplete = "off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        disabled
                                                    />
                                                    :
                                                    <input type="text"
                                                        placeholder=""
                                                        name={this.props.fieldName}
                                                        autoComplete="off"
                                                        value={dF === null ? '' : dF}
                                                        className={'form-control inputDisabledGrey'}
                                                        disabled
                                                    />
                                        }
                                    </div>
                                    <div className="column2 three">
                                        {
                                            this.props?.textType === "number"
                                                ?
                                                <NumberFormat
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    value={this.props.priorDataField}
                                                    thousandSeparator={true}
                                                    className={'form-control inputDisabledGrey'}
                                                    suffix={this.props?.suffix ?? ''}
                                                    disabled
                                                />
                                                :
                                                this.props.type === 'datepick'
                                                    ?
                                                    <DatePicker
                                                        name="AsOfDate"
                                                        selected={this.props.priorDataField ? new Date(this.props.priorDataField) : null}
                                                        onChange={this.handleChangeDate}
                                                        autoComplete = "off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        disabled
                                                    />
                                                    :
                                                    <input type="text"
                                                        placeholder=""
                                                        name={this.props.fieldName}
                                                        autoComplete="off"
                                                        value={dfPrior === null ? '' : dfPrior}
                                                        className={'form-control inputDisabledGrey'}
                                                        disabled
                                                    />
                                        }
                                    </div>
                                    {
                                        this.props.noComment
                                            ?
                                            null
                                            :
                                            <div className="column2 three">
                                                <input type="text"
                                                    placeholder=""
                                                    name={`${this.props.fieldName}_comment`}
                                                    autoComplete="off"
                                                    value={this.props.dataCommentField === null ? '' : this.props.dataCommentField}
                                                    className={'form-control inputDisabledGrey'}
                                                    disabled
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        );
                    } else {
                        let dfPrior = '';
                        if ((this.props.type === "dropdown" || this.props.type === "dropdown-in") && this.props.priorDataField !== null && this.props.priorDataField !== undefined && this.props.dataList !== undefined) {
                            const { priorDataField, dataList } = this.props;
                            dfPrior = dataList.filter(function (item) { return item.value === priorDataField })[0]?.text;
                        } else if ((this.props.type === "checkbox" || this.props.type === "checkbox-in") && this.props.priorDataField !== null && this.props.priorDataField !== undefined) {
                            const { priorDataField } = this.props;
                            if (priorDataField === true) {
                                dfPrior = 'Yes';
                            } else if (priorDataField === false) {
                                dfPrior = 'No';
                            }
                        } else {
                            dfPrior = this.props.priorDataField;
                        }

                        return (
                            <div className="row">
                                <div className="column2 left2">
                                    {
                                        this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                            ?
                                            fieldList[0].required === true && (this.props?.required ?? true)
                                                ?
                                                this.props.tooltip === true
                                                    ?
                                                    <label className="w200 p5 fsBig ml20 fwItalic">
                                                        {this.props.heading} 
                                                        <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                :
                                                this.props.tooltip === true
                                                    ?
                                                    <label className="w200 p5 fsBig ml20 fwItalic">
                                                        {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                            :
                                            this.props.tooltip === true
                                                ?
                                                fieldList[0].required === true && (this.props?.required ?? true)
                                                    ?
                                                    <label className="w200 p5 fsBig">
                                                        {this.props.heading} 
                                                        <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    :
                                                    <label className="w200 p5 fsBig">
                                                        {this.props.heading} <Popup content={this.props.tooltipText} trigger={<FontAwesomeIcon icon={faInfoCircle} />} />
                                                    </label>
                                                :
                                                fieldList[0].required === true && (this.props?.required ?? true)
                                                    ?
                                                    <label className="w200 p5 fsBig">{this.props.heading} <span style={{ color: 'red' }}>*</span></label>
                                                    :
                                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                    }
                                </div>
                                <div className="column2 right2">
                                    <div className="column2 three">
                                        {
                                            this.props.type === "dropdown" || this.props.type === "dropdown-in"
                                                ?
                                                <Dropdown
                                                    placeholder='Select Values'
                                                    name={this.props.fieldName}
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={this.props.dataList}
                                                    value={this.props.dataField}
                                                />
                                                :
                                                this.props.type === "checkbox" || this.props.type === "checkbox-in"
                                                    ?
                                                    <div className="form-control border-none" onChange={this.handleChange}>
                                                        <input type="radio" id={`${this.props.fieldName}_true`} value={true} name={this.props.fieldName} checked={this.props.dataField === true} /> <label for={`${this.props.fieldName}_true`} className="fsBig">Yes</label>
                                                        <input type="radio" id={`${this.props.fieldName}_false`} value={false} name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === false} /> <label for={`${this.props.fieldName}_false`} className="fsBig">No</label>
                                                    </div>
                                                    :
                                                    this.props.type === "textbox" || this.props.type === "textbox-in"
                                                        ?
                                                        this.props?.textType === "number"
                                                            ?
                                                            this.props?.calcType === "value"
                                                                ?
                                                                <div style = {{display: "flex"}}>
                                                                    <NumberFormat
                                                                        placeholder="Enter Response"
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        onValueChange={this.handleNumberChange}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputLightP'}
                                                                    />
                                                                    <div className = "icon-button-grey" onClick = {this.handleCalcChange}>
                                                                        <FontAwesomeIcon icon={faCalculator}/>
                                                                    </div>
                                                                </div>
                                                                :
                                                                this.props?.calcType === "million"
                                                                    ?
                                                                    <div style = {{display: "flex"}}>
                                                                        <NumberFormat
                                                                            placeholder="Enter Response"
                                                                            name={this.props.fieldName}
                                                                            value={this.props.dataField}
                                                                            thousandSeparator={true}
                                                                            onValueChange={this.handleNumberChange}
                                                                            suffix={this.props?.suffix ?? ''}
                                                                            className={'form-control inputLightP'}
                                                                        />
                                                                        <div className = "icon-button-grey" onClick = {this.handleMlnChange} title = "Click to multiply by million">
                                                                            <span>MM</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <NumberFormat
                                                                        placeholder="Enter Response"
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        onValueChange={this.handleNumberChange}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputLightP'}
                                                                    />
                                                            :
                                                            <input type="text"
                                                                placeholder="Enter Response"
                                                                name={this.props.fieldName}
                                                                autoComplete="off"
                                                                value={this.props.dataField === null ? '' : this.props.dataField}
                                                                className={'form-control inputLightP'}
                                                                onChange={this.handleChange}
                                                            />
                                                        :
                                                        this.props.type === "checkbox-pp"
                                                            ?
                                                            <div className="form-control border-none" onChange={this.handleChange}>
                                                                <input type="radio" value="Public" id={`${this.props.fieldName}_Public`} name={this.props.fieldName} checked={this.props.dataField === "Public"} /> <label for={`${this.props.fieldName}_Public`} className="fsBig">Public</label>
                                                                <input type="radio" value="Private" id={`${this.props.fieldName}_Private`} name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === "Private"} /> <label for={`${this.props.fieldName}_Private`} className="fsBig">Private</label>
                                                            </div>
                                                            :
                                                            this.props.type === "textbox-blue"
                                                                ?
                                                                this.props?.textType === "number"
                                                                    ?
                                                                    <NumberFormat
                                                                        placeholder=""
                                                                        name={this.props.fieldName}
                                                                        value={this.props.dataField}
                                                                        thousandSeparator={true}
                                                                        suffix={this.props?.suffix ?? ''}
                                                                        className={'form-control inputDisabled'}
                                                                        disabled
                                                                    />
                                                                    :
                                                                    <input type="text"
                                                                        placeholder=""
                                                                        name={this.props.fieldName}
                                                                        autoComplete="off"
                                                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                                                        className={'form-control inputDisabled'}
                                                                        disabled
                                                                    />
                                                                :
                                                                this.props.maxDate
                                                                    ?
                                                                    <DatePicker
                                                                        name="AsOfDate"
                                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                                        onChange={this.handleChangeDate}
                                                                        maxDate = {new Date(this.props.maxDate)}
                                                                        autoComplete = "off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                    />
                                                                    :
                                                                    <DatePicker
                                                                        name="AsOfDate"
                                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                                        onChange={this.handleChangeDate}
                                                                        autoComplete = "off"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                    />
                                        }
                                    </div>
                                    <div className="column2 three">
                                        {
                                            this.props?.textType === "number"
                                                ?
                                                <NumberFormat
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    value={this.props.priorDataField}
                                                    thousandSeparator={true}
                                                    className={'form-control inputDisabledGrey'}
                                                    suffix={this.props?.suffix ?? ''}
                                                    disabled
                                                />
                                                :
                                                this.props.type === 'datepick'
                                                    ?
                                                    <DatePicker
                                                        name="AsOfDate"
                                                        selected={this.props.priorDataField ? new Date(this.props.priorDataField) : null}
                                                        onChange={this.handleChangeDate}
                                                        autoComplete = "off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        disabled
                                                    />
                                                    :
                                                    <input type="text"
                                                        placeholder=""
                                                        name={this.props.fieldName}
                                                        autoComplete="off"
                                                        value={dfPrior === null ? '' : dfPrior}
                                                        className={'form-control inputDisabledGrey'}
                                                        disabled
                                                    />
                                        }
                                    </div>
                                    {
                                        this.props.noComment
                                            ?
                                            null
                                            :
                                            <div className="column2 three">
                                                <input type="text"
                                                    placeholder="Enter Comment"
                                                    name={`${this.props.fieldName}_comment`}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    value={this.props.dataCommentField === null ? '' : this.props.dataCommentField}
                                                    className={'form-control inputLightP'}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        );
                    }
                }
            } else if (this.props.formType === "riskDisp") {
                if (fieldList[0].permission === 1) {
                    const { dataField, dataList, optionsList, fieldName } = this.props;
                    let df_id = dataList.filter(function (item) { return item.value === dataField[`${fieldName}_id`] })[0]?.text;
                    let df_option = optionsList.filter(function (item) { return item.value === dataField[`${fieldName}_direction`] })[0]?.text;
                    let df_price = dataField[`${fieldName}_is_development`];
                    if (df_price === true)
                        df_price = 'Yes';
                    else if (df_price === false)
                        df_price = 'No'
                    return (
                        <div className="row">
                            <div className="column2 left3">
                                <div className="column2 three">
                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                </div>
                                <div className="column2 three">
                                    <input type="text"
                                        placeholder=""
                                        name={`${this.props.fieldName}_id`}
                                        autoComplete="off"
                                        value={df_id === null ? '' : df_id}
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                </div>

                                <div className="column2 three">
                                    <input type="text"
                                        placeholder=""
                                        name={`${this.props.fieldName}_direction`}
                                        autoComplete="off"
                                        value={df_option === null ? '' : df_option}
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="column2 right3">
                                <div className="vAlign">
                                    <div className="column2 four">
                                        <input type="text"
                                            placeholder=""
                                            name={`${this.props.fieldName}_desc`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_desc`] === null ? '' : this.props.dataField[`${this.props.fieldName}_desc`]}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                    <div className="column2 four">
                                        <input
                                            placeholder=""
                                            name={`${this.props.fieldName}_is_development`}
                                            autoComplete="off"
                                            value={df_price === null ? '' : df_price}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                    <div className="column2 four">
                                        <input type="text"
                                            placeholder=""
                                            name={`${this.props.fieldName}_location`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_location`] === null ? '' : this.props.dataField[`${this.props.fieldName}_location`]}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                    <div className="column2 four">
                                        <input type="text"
                                            placeholder=""
                                            name={`${this.props.fieldName}_deal_percentage`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_deal_percentage`] === null ? '' : this.props.dataField[`${this.props.fieldName}_deal_percentage`]}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="row">
                            <div className="column2 left3">
                                <div className="column2 three">
                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                </div>
                                <div className="column2 three">
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={`${this.props.fieldName}_id`}
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={this.props.dataList}
                                        value={this.props.dataField[`${this.props.fieldName}_id`]}
                                    />
                                </div>

                                <div className="column2 three">
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={`${this.props.fieldName}_direction`}
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={this.props.optionsList}
                                        value={this.props.dataField[`${this.props.fieldName}_direction`]}
                                    />
                                </div>
                            </div>
                            <div className="column2 right3">
                                <div className="vAlign">
                                    <div className="column2 four">
                                        <input type="text"
                                            placeholder="Enter Response"
                                            name={`${this.props.fieldName}_desc`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_desc`] === null ? '' : this.props.dataField[`${this.props.fieldName}_desc`]}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="column2 four">
                                        <div className="form-control border-none" onChange={this.handleChangeRisk}>
                                            <input type="radio" id={`${this.props.fieldName}_true`} value={true} name={`${this.props.fieldName}_is_development`} checked={this.props.dataField[`${this.props.fieldName}_is_development`] === true} /> <label for={`${this.props.fieldName}_true`} className="fsBig">Yes</label>
                                            <input type="radio" id={`${this.props.fieldName}_false`} value={false} name={`${this.props.fieldName}_is_development`} className={'ml20'} checked={this.props.dataField[`${this.props.fieldName}_is_development`] === false} /> <label for={`${this.props.fieldName}_false`} className="fsBig">No</label>
                                        </div>
                                    </div>
                                    <div className="column2 four">
                                        <input type="text"
                                            placeholder="Enter Response"
                                            name={`${this.props.fieldName}_location`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_location`] === null ? '' : this.props.dataField[`${this.props.fieldName}_location`]}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="column2 four">
                                        <input type="number"
                                            placeholder="Enter Values"
                                            name={`${this.props.fieldName}_deal_percentage`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_deal_percentage`] === null ? '' : this.props.dataField[`${this.props.fieldName}_deal_percentage`]}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            } else if (this.props.formType === "reDisp") {
                if (fieldList[0].permission === 1) {
                    const { dataField, dataList, fieldName } = this.props;
                    let df_id = dataList.filter(function (item) { return item.value === dataField[`${fieldName}_type`] })[0]?.text;
                    let df_price = dataField[`${fieldName}_is_development`];
                    if (df_price === true)
                        df_price = 'Yes';
                    else if (df_price === false)
                        df_price = 'No'
                    return (
                        <div className="row">
                            <div className="column2 left">
                                <div className="column2 three">
                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                </div>
                                <div className="column2 three">
                                    <input type="text"
                                        placeholder=""
                                        name={`${this.props.fieldName}_type`}
                                        autoComplete="off"
                                        value={df_id === null ? '' : df_id}
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                </div>

                                <div className="column2 three">
                                    <input type="text"
                                        placeholder=""
                                        name={`${this.props.fieldName}_desc`}
                                        autoComplete="off"
                                        value={this.props.dataField[`${this.props.fieldName}_desc`] === null ? '' : this.props.dataField[`${this.props.fieldName}_desc`]}
                                        className={'form-control inputDisabledGrey'}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="column2 right">
                                <div className="vAlign">
                                    <div className="column2 three">
                                        <input
                                            placeholder=""
                                            name={`${this.props.fieldName}_is_development`}
                                            autoComplete="off"
                                            value={df_price === null ? '' : df_price}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                    <div className="column2 three">
                                        <input type="text"
                                            placeholder=""
                                            name={`${this.props.fieldName}_location`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_location`] === null ? '' : this.props.dataField[`${this.props.fieldName}_location`]}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                    <div className="column2 three">
                                        <input type="text"
                                            placeholder=""
                                            name={`${this.props.fieldName}_deal_percentage`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_deal_percentage`] === null ? '' : this.props.dataField[`${this.props.fieldName}_deal_percentage`]}
                                            className={'form-control inputDisabledGrey'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="row">
                            <div className="column2 left">
                                <div className="column2 three">
                                    <label className="w200 p5 fsBig">{this.props.heading}</label>
                                </div>
                                <div className="column2 three">
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={`${this.props.fieldName}_type`}
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={this.props.dataList}
                                        value={this.props.dataField[`${this.props.fieldName}_type`]}
                                    />
                                </div>

                                <div className="column2 three">
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={`${this.props.fieldName}_desc`}
                                        autoComplete="off"
                                        value={this.props.dataField[`${this.props.fieldName}_desc`] === null ? '' : this.props.dataField[`${this.props.fieldName}_desc`]}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="column2 right">
                                <div className="vAlign">
                                    <div className="column2 three">
                                        <div className="form-control border-none" onChange={this.handleChangeRisk}>
                                            <input type="radio" id={`${this.props.fieldName}_true`} value={true} name={`${this.props.fieldName}_is_development`} checked={this.props.dataField[`${this.props.fieldName}_is_development`] === true} /> <label for={`${this.props.fieldName}_true`} className="fsBig">Yes</label>
                                            <input type="radio" id={`${this.props.fieldName}_false`} value={false} name={`${this.props.fieldName}_is_development`} className={'ml20'} checked={this.props.dataField[`${this.props.fieldName}_is_development`] === false} /> <label for={`${this.props.fieldName}_false`} className="fsBig">No</label>
                                        </div>
                                    </div>
                                    <div className="column2 three">
                                        <input type="text"
                                            placeholder="Enter Response"
                                            name={`${this.props.fieldName}_location`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_location`] === null ? '' : this.props.dataField[`${this.props.fieldName}_location`]}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="column2 three">
                                        <input type="number"
                                            placeholder="Enter Values"
                                            name={`${this.props.fieldName}_deal_percentage`}
                                            autoComplete="off"
                                            value={this.props.dataField[`${this.props.fieldName}_deal_percentage`] === null ? '' : this.props.dataField[`${this.props.fieldName}_deal_percentage`]}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            } else {
                if (fieldList[0].permission === 1 && this.props.type !== "textbox-blue") {
                    let dF = '';
                    if ((this.props.type === "dropdown" || this.props.type === "dropdown-in" || this.props.type === "dropdown-tooltip") && this.props.dataField !== null && this.props.dataField !== undefined && this.props.dataList !== undefined) {
                        const { dataField, dataList } = this.props;
                        dF = dataList.filter(function (item) { return item.value === dataField })[0]?.text;
                    } else if ((this.props.type === "checkbox" || this.props.type === "checkbox-in") && this.props.dataField !== null && this.props.dataField !== undefined) {
                        const { dataField } = this.props;
                        if (dataField === true) {
                            dF = 'Yes';
                        } else if (dataField === false) {
                            dF = 'No';
                        }
                    } else {
                        dF = this.props.dataField;
                    }
                    return (
                        <div className="formDisp">
                            {
                                this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                    ?
                                    <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                    :
                                    this.props.type === "dropdown-tooltip"
                                        ?
                                        <label className="w200 p5 fsBig">
                                            {this.props.heading} <a href={this.props.tooltip} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                                        </label>
                                        :
                                        <label className="w200 p5 fsBig">{this.props.heading}</label>
                            }
                            <input type="text"
                                placeholder=""
                                name={this.props.fieldName}
                                autoComplete="off"
                                value={dF === null ? '' : dF}
                                className={'form-control inputDisabledGrey'}
                                disabled
                            />
                        </div>
                    );
                }
                else {
                    return (
                        <div className="formDisp">
                            {
                                this.props.type === "textbox-in" || this.props.type === "dropdown-in" || this.props.type === "datepick-in" || this.props.type === "checkbox-in"
                                    ?
                                    fieldList[0].required === true
                                        ?
                                        <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading} <span style = {{color: 'red'}}>*</span></label>
                                        :
                                        <label className="w200 p5 fsBig ml20 fwItalic">{this.props.heading}</label>
                                    :
                                    this.props.type === "dropdown-tooltip"
                                        ?
                                        fieldList[0].required === true
                                            ?
                                            <label className="w200 p5 fsBig">{this.props.heading}
                                                <a href={this.props.tooltip} target="_blank" rel="noopener noreferrer"> 
                                                <FontAwesomeIcon icon={faInfoCircle} /></a>
                                                <span style = {{color: 'red'}}>*</span>
                                            </label>
                                            :
                                            <label className="w200 p5 fsBig">{this.props.heading}
                                                <a href={this.props.tooltip} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faInfoCircle} /></a>
                                            </label>
                                        :
                                        fieldList[0].required === true
                                            ?
                                            <label className="w200 p5 fsBig">{this.props.heading} <span style = {{color: 'red'}}>*</span></label>
                                            :
                                            <label className="w200 p5 fsBig">{this.props.heading}</label>
                            }

                            {
                                this.props.type === "dropdown" || this.props.type === "dropdown-in" || this.props.type === "dropdown-tooltip"
                                    ?
                                    this.props.clearable === false
                                        ?
                                        <Dropdown
                                            placeholder='Select Values'
                                            name={this.props.fieldName}
                                            options={this.props.dataList}
                                            onChange={this.handleDropDownChange}
                                            value={this.props.dataField}
                                            selection
                                            fluid
                                            search
                                        />
                                        :
                                        <Dropdown
                                            placeholder='Select Values'
                                            name={this.props.fieldName}
                                            options={this.props.dataList}
                                            onChange={this.handleDropDownChange}
                                            value={this.props.dataField}
                                            clearable
                                            selection
                                            fluid
                                            search
                                        />
                                    :
                                    this.props.type === "checkbox" || this.props.type === "checkbox-in"
                                        ?
                                        <div className="form-control border-none" onChange={this.handleChange}>
                                            <input type="radio" id={`${this.props.fieldName}_true`} value={true} name={this.props.fieldName} checked={this.props.dataField === true} /> <label for={`${this.props.fieldName}_true`} className="fsBig">Yes</label>
                                            <input type="radio" id={`${this.props.fieldName}_false`} value={false} name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === false} /> <label for={`${this.props.fieldName}_false`} className="fsBig">No</label>
                                        </div>
                                        :
                                        this.props.type === "textbox" || this.props.type === "textbox-in"
                                            ?
                                            this.props?.textType === "number"
                                                ?
                                                <NumberFormat
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    value={this.props.dataField}
                                                    thousandSeparator={true}
                                                    onValueChange={this.handleNumberChange}
                                                    suffix={this.props?.suffix ?? ''}
                                                    className={'form-control inputLightP'}
                                                />
                                                :
                                                <input type="text"
                                                    placeholder="Enter Response"
                                                    name={this.props.fieldName}
                                                    autoComplete="off"
                                                    value={this.props.dataField === null ? '' : this.props.dataField}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                />
                                            :
                                            this.props.type === "checkbox-pp"
                                                ?
                                                <div className="form-control border-none" onChange={this.handleChange}>
                                                    <input type="radio" id={`${this.props.fieldName}_Public`} value="Public" name={this.props.fieldName} checked={this.props.dataField === "Public"} /> <label for={`${this.props.fieldName}_Public`} className="fsBig">Public</label>
                                                    <input type="radio" id={`${this.props.fieldName}_Private`} value="Private" name={this.props.fieldName} className={'ml20'} checked={this.props.dataField === "Private"} /> <label for={`${this.props.fieldName}_Private`} className="fsBig">Private</label>
                                                </div>
                                                :
                                                this.props.type === "textbox-blue"
                                                    ?
                                                    <input type="text"
                                                        placeholder="Response"
                                                        name={this.props.fieldName}
                                                        autoComplete="off"
                                                        value={this.props.dataField === null ? '' : this.props.dataField}
                                                        className={'form-control inputDisabled'}
                                                        disabled
                                                    />
                                                    :
                                                    <DatePicker
                                                        name={this.props.fieldName}
                                                        selected={this.props.dataField ? new Date(this.props.dataField) : null}
                                                        onChange={this.handleChangeDate}
                                                        autoComplete = "off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                            }
                        </div>
                    );
                }
            }
        } else {
            return null;
        }
    }
}

export default FormDisplay;