import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');

export const fundBALService = {
    getFunds
   ,getRowData
};

function getRowData(params) {
   return getFunds(params);
}

function getFunds(params) {
    const query = {query : "select fund_id, display_name, [description], case display_name when 'Tor' then 'TACF' else display_name end as code from [fund] union (select 0 as fund_id, 'ALL' as [display_name], 'ALL' as [description], 'ALL' as [code] ) order by [code]"};
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' , width: 100}, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);   
}