import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { covenantTargetBALService } from '../../bal/covenantTarget.bal';
import { covenantMasterBALService } from '../../bal/covenantMaster.bal';
import { dealBALService } from '../../bal/deal.bal';
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faHome } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import TorGrid from '../torgrid/torgrid';
import history from '../../history/history';
import JoditEditor from "jodit-react";

class CovenantNew extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            isShowLoad: false,
            isShowGrid: true,
            includeDate: true, 
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ], 
            covenantFrequencyList: null,
            covenantCategoryList: null,
            covenantTypeList: null,
            oldCovenant : {
                id:null,
                dealId:null,
                covenantTypeId : null,
                covenantFrequencyId : null, 
                covenantCategoryId : null,
                startDate : null, 
                endDate : null, 
                daysDue : 0,
                loan: null,
                covenantCategory : null,
                comment : null
            },
            newCovenant : {
                id:null,
                dealId:null,
                covenantTypeId : null,
                covenantFrequencyId : null, 
                covenantCategoryId : null,
                startDate : null, 
                endDate : null, 
                daysDue : 0,
                loan: null,
                covenantCategory : null,
                comment : null
            },
            joditConfig: {
                readonly: false // all options from https://xdsoft.net/jodit/doc/,
            }
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleDropDownChangeCovenantType = this.handleDropDownChangeCovenantType.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.populateDeals = this.populateDeals.bind(this);
        this.populateCovenantFrequency = this.populateCovenantFrequency.bind(this);
        this.populateCovenantType = this.populateCovenantType.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.getFormatted = this.getFormatted.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.goHome = this.goHome.bind(this);
        this.handleDropDownChangeDeal = this.handleDropDownChangeDeal.bind(this);
 
        this.populateDropDownLists();
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true, modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.saveDB(null, false);
            this.setState({isOpenGrid: true, modalVisible: false});
        } else {
            this.setState({isOpenGrid: true, modalVisible: false});
        }
    }

    goHome() {
        history.push('/covenantdashboard');
        window.location.pathname = '/covenantdashboard';
    }

    handleHome() {
        const { oldCovenant, newCovenant } = this.state;
        if(JSON.stringify(oldCovenant) !== JSON.stringify(newCovenant)) {
            this.setState({
                modalVisible: true,
                modalType: 'saveChanges',
                modalHandler: 'discard',
                modalHeading: 'Do you want to discard changes?'
            })
        } else {
            //this.resetFields(true);
            //this.refresh(null, true);
            //window.open("/covenantdashboard");
            this.goHome();
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(0) : value;
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }
    
    save (rowData) {
        const { newCovenant } = this.state;
        rowData = rowData.map(p=>p.data);

        console.log(newCovenant);
        console.log(rowData);

        covenantMasterBALService.save(newCovenant).then( 
            data => {
                    console.log(data);
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saved data successfully!',
                        open: false,
                        deal:null
                    }, ()=> {
                        rowData = rowData.map(p=> {return {...p, covenantMasterId:data.id}});
                        covenantTargetBALService.save(rowData).then(
                            data => {
                                console.log(data);
                                this.setState({
                                    showMessage: false, 
                                    errorList: null, 
                                    messageHeader : "",
                                    modalVisible: true,
                                    modalType: 'close',
                                    modalHandler: 'close',
                                    modalHeading: 'Saved data successfully!',
                                    open: false,
                                    deal:null,
                                    rowData : null,                              
                                    oldCovenant : {
                                        id:null,
                                        dealId:null,
                                        covenantTypeId : null,
                                        covenantFrequencyId : null,  
                                        startDate : null, 
                                        endDate : null, 
                                        daysDue : 0,
                                        loan : null,
                                        covenantCategory : null,
                                        comment : null
                                    },
                                    newCovenant : {
                                        id:null,
                                        dealId:null,
                                        covenantTypeId : null,
                                        covenantFrequencyId : null,  
                                        startDate : null, 
                                        endDate : null, 
                                        daysDue : 0,
                                        loan : null,
                                        covenantCategory : null,
                                        comment : null
                                    }
                                });
                                });
                            }
                            , error => {
                                this.setState({ 
                                    showMessage: true, 
                                    errorList: [error], 
                                    messageHeader : `Error while saving covenant target:`,
                                    modalVisible: true,
                                    modalType: 'close',
                                    modalHandler: 'close',
                                    modalHeading: 'Saving data failed!'
                                }); 
                                console.log(error);
                        }); 
                    } 
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while saving covenant master :`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saving data failed!'
                    }); 
                    console.log(error);
            });
    }

    populateDeals() {
        dealBALService.getAllDealLoans().then(data => {
            const dealList = [];
            data.map((item) => {
                var findItem = dealList.find((x) => x.value === item.dealId);
                if (!findItem) dealList.push({ key: item.deal, value: item.dealId, text: item.deal }); 
                return item;
            });
            
            const dealListSorted = [].concat(dealList).sort((a, b) => a.text.localeCompare(b.text) );

            const loanList = data.filter(function (item) {
                return (item ? true : false);
            }).map(item => { return { key: item.description, value: item.description, text: item.description, dealId : item.dealId } });
             
            this.setState({ dealList: dealListSorted, loanList : loanList });
        }, error => {
            this.setState({
                showMessage: true,
                messageHeader: 'Error while getting the deals:',
                errorList: [error],
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while getting the deals:'
            });
            console.log(error);
        });
    }

    populateCovenantFrequency() {
        covenantMasterBALService.getAllCovenantFrequency().then(data => {
            let list = data.filter(function (item) {
                return (item ? true : false);
            }).map(item => { return { key: item.name, value: item.id, text: item.name } });

            this.setState({ covenantFrequencyList: list });
        }, error => {
            this.setState({
                showMessage: true,
                messageHeader: 'Error while getting the Covenant Frequency List:',
                errorList: [error],
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while getting the Covenant Frequency List:'
            });
            console.log(error);
        });
    }

    populateCovenantType() {
        covenantMasterBALService.getAllCovenantCategory().then(categories => {
            let categoryList = categories.filter(function (item) {
                return (item ? true : false);
            }).map(item => { return { key: item.name, value: item.id, text: item.name } });

            covenantMasterBALService.getAllCovenantType().then(types => {
                
                var typeCategoryDict = {};

                let typeList = types.filter(function (item) {
                    return (item ? true : false);
                }).map(item => {  
                    var categoryName = categories.filter(p => p.id === item.covenantCategoryId )[0].name;
                    typeCategoryDict = {...typeCategoryDict, [item.id] :  categoryName }
                    return { key: item.name, value: item.id, text: item.name } 
                });

                this.setState({ covenantTypeList: typeList, covenantCategoryList: categoryList, typeCategoryDict : typeCategoryDict });
            }, error => {
                this.setState({
                    showMessage: true,
                    messageHeader: 'Error while getting the Covenant Type List:',
                    errorList: [error],
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Error while getting the Covenant Type List:'
                });
                console.log(error);
            }); 
        }, error => {
            this.setState({
                showMessage: true,
                messageHeader: 'Error while getting the Covenant Category List:',
                errorList: [error],
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while getting the Covenant Category List:'
            });
            console.log(error);
        });
    }

    populateDropDownLists() {
        this.populateDeals();
        this.populateCovenantFrequency();
        this.populateCovenantType();
    } 

    handleChange(name, value) {
        this.setState({ [name]: value });
    }
    
    handleDropDownChangeDeal(e, { name, value }) {
        
        const { loanList } = this.state;

        var dealLoanList = loanList.filter(p=> p.dealId === value);

        this.setState({ dealLoanList : dealLoanList, newCovenant: { ...this.state.newCovenant, [name]: value, loan: null } });
    }
    
    handleDropDownChange(e, { name, value }) {
        this.setState({ newCovenant: { ...this.state.newCovenant, [name]: value } });
    }
    
    handleDropDownChangeCovenantType(e, { name, value }) {
        const { typeCategoryDict } = this.state;
        this.setState({ newCovenant: { ...this.state.newCovenant, [name]: value, covenantCategory : typeCategoryDict[value] } });
    }

    handleChangeNew(name, value) {
        this.setState({
            newCovenant: {
                ...this.state.newCovenant,
                [name]: value 
            }
        });
    }

    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    getPage() {
        const { includeDate, rowData, joditConfig, isShowLoad, isShowGrid, newCovenant, existingCovenant, modalType, dealList, covenantFrequencyList, dealLoanList, covenantTypeList, modalVisible, modalHeading, gridReminderData } = this.state;
        const { id, dealId, covenantTypeId, covenantFrequencyId, loan, covenantCategory, startDate, endDate, daysDue, comment } = newCovenant
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }  
                <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%', overflowY:'scroll' }} >
                    <div>
                        <span style={{ float: "left", paddingLeft: "20px" }}><h4><u>{id=== null ? 'New ' : `Edit`} </u></h4></span>
                        <div style={{ float: "left", paddingLeft: "15px", cursor: "pointer" }} onClick={this.handleHome}><FontAwesomeIcon icon={faHome} /></div>
                    </div> 
                    <div style={{ overflow: 'auto', paddingTop:'15px'}}>
                        <div className="dealForm"> 
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Deal:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <Dropdown
                                        placeholder='Select Deal '
                                        name='dealId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChangeDeal}
                                        options={dealList}
                                        value={dealId}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Loan:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <Dropdown
                                        placeholder='Select Loan'
                                        name='loan'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={dealLoanList}
                                        value={loan}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Type:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <Dropdown
                                        placeholder='Select Covenant Type'
                                        name='covenantTypeId'
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChangeCovenantType}
                                        options={covenantTypeList}
                                        value={covenantTypeId}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Metric:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    {covenantCategory}
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Frequency:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <Dropdown
                                        placeholder='Select Covenant Frequency'
                                        name='covenantFrequencyId'
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={covenantFrequencyList}
                                        value={covenantFrequencyId}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Start Date:</b><br/>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <DatePicker
                                        name="startDate"                                        
                                        selected={startDate ? new Date(startDate) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('startDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown
                                        showMonthDropdown
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>End Date:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <DatePicker
                                        name="endDate"                                        
                                        selected={endDate ? new Date(endDate) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('endDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown
                                        showMonthDropdown
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Days Due:</b>
                                </div>
                                <div style={{ float: "left", width: '250px'}}>
                                    <NumberFormat 
                                        placeholder={"Days Due"}
                                        name='daysDue'
                                        value={this.getFormatted(daysDue)}
                                        thousandSeparator={true}
                                        onValueChange={(values) => { this.handleChangeNew('daysDue', values.floatValue ); }}
                                        //suffix={'M'}
                                        //prefix={'$'}
                                        className={'form-control inputLightP'}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ padding:'3px 0px 3px 0px'}}>
                                <div style={{ float: "left", width: '100px', padding:'3px 0px 3px 0px'}}>
                                    <b>Comment:</b>
                                </div>
                                <div style={{ float: "left", width: '1250px'}}>
                                    <JoditEditor
                                        value={comment}
                                        config={joditConfig}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newComment => this.setState({ newCovenant : {...this.state.newCovenant, comment: newComment} })} // preferred to use only this option to update the content for performance reasons
                                        //disabled={isDisabled}
                                        //hidden={isDisabled}
                                        readonly//={isDisabled}

                                        askBeforePasteHTML={false}
                                        askBeforePasteFromWord={false}
                                        defaultActionOnPaste="insert_clear_html"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: 'auto' ,  width: '100%' }}>
                        <TorGrid
                            gridTitle=''
                            sideBar='none'
                            gridIdentityColumn="id"
                            identitySeed={-9999999999999}
                            showAdd={true}                    
                            rowData={rowData}
                            setAgGridAPI = {this.setAgGridAPI} 
                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}
                            guikey = "covenantTarget"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}
                            includeDate = {includeDate}
                            suppressExpandablePivotGroups={true}
                            className="ag-theme-alpine"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="autoHeight"
                            groupIncludeFooter={true}
                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                            }
                            dateFormat = 'mm/dd/yy'
                            singleClickEdit = {true}
                            isShowLoad = {isShowLoad}
                            isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                            save = {this.save}
                            showSave = {true}
                        />
                    </div> 
                <div>
                    <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.save}>
                        <FontAwesomeIcon icon={faSave}  style = {{cursor: "pointer"}} tooltip = "true" title = "Save"/>
                    </div> 
                </div> 
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingCovenant) !== JSON.stringify(newCovenant)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={startDate}
                    toDate={endDate}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.goHome()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default CovenantNew;