import fileDownload from 'js-file-download'
/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { coInvestmentBALService } from '../../bal/coInvestment.bal';

import { dealBALService } from '../../bal/deal.bal';
import { Prompt } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { Dropdown } from 'semantic-ui-react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';
import { faSave } from '@fortawesome/free-solid-svg-icons';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faHome, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { s3BALService } from '../../bal/s3.bal';

const type = 'CoInvestment';
class CoInvestment extends Component { 
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === type.toLowerCase() });
        const isRiskRole = user?.userRoles?.filter(item => item && item.toLowerCase() === 'risk');

        this.state = { 
            rowData: [],
            isRiskRole: isRiskRole?.length>0,
            isOpenGrid: true, 
            analystList: [],
            deal_id: null,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            existingDeal: {
                id:null,
                employeeId:null,
                projectName:null,
                analyst1Id:null,
                coInvestorName: null,
                dealSize: null,
                currency:"USD",
                introductionDate: moment(new Date()).format("YYYY-MM-DD"),
                closingDate: moment(new Date()).format("YYYY-MM-DD"),
                upfrontFee: null,
                managementFee: null,
                carryHurdle: null,
                carryParticipation: null,
                comment: null,
                dealCoStatusId:null
            },
            newDeal: {
                id:null,
                employeeId:null,
                projectName:null,
                analyst1Id:null,
                coInvestorName: null,
                dealSize: null,
                currency:"USD",
                introductionDate: moment(new Date()).format("YYYY-MM-DD"),
                closingDate: moment(new Date()).format("YYYY-MM-DD"),
                upfrontFee: null,
                managementFee: null,
                carryHurdle: null,
                carryParticipation: null,
                comment: null,
                dealCoStatusId:null
            },
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            open:false
        };
        this.refresh = this.refresh.bind(this);
        //this.resetFields = this.resetFields(this);
        this.addNew = this.addNew.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveDB = this.saveDB.bind(this);
        this.saveData = this.saveData.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleChangeCoInvestment = this.handleChangeCoInvestment.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeMessage = this.closeMessage.bind(this);

        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.setAgGridAPI2 = this.setAgGridAPI2.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.loadAnalysts = this.loadAnalysts.bind(this);
        this.loadCurrency = this.loadCurrency.bind(this);
        this.loadStatus = this.loadStatus.bind(this);

        this.loadAnalysts();
        this.loadCurrency();
        this.loadStatus();
        this.refresh(null, true);
    }

    loadCurrency() {
        dealBALService.getRates().then(data => {
            const currencyList = data.map(item => { return { key: item.Currency, value: item.Currency, text: item.Currency } });
            this.setState({ currencyList: currencyList }); 
        }, error => { console.log(error); });
    }

    loadStatus() {
        coInvestmentBALService.getStatusList().then(data => {
            const statusList = data.map(item => { return { key: item.displayName, value: item.id, text: item.displayName } });
            this.setState({ statusList: statusList }); 
        }, error => { 
            this.setState({ 
            showMessage: true, 
            errorList: [error], 
            messageHeader : "Error while getting the Status list :",
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: 'Status list could not be retrieved, please contact support!'
        }); 
        console.log(error); });
    }

    loadAnalysts() {
        dealBALService.getAnalysts().then(data => { 
            const { user, newDeal } = this.state;
            var analyst1Id = newDeal.analyst1Id ?? (data.filter((e) => e.id === user.id) ? user.id : null );
            this.setState({ 
                    analystList: data.map(item => { return { key: item.name, value: item.id, text: item.name }}),
                    newDeal: {
                        ...newDeal,
                        analyst1Id : analyst1Id
                    }
                })
            }, error => { 
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : "Error while getting the analyst data :",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: 'Analyst data could not be retrieved, please contact support!'
                    }); 
                    console.log(error);
            }
        );
    }
    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    refresh(e, modalVisible = false) {
        coInvestmentBALService.getRowData().then(data => { 
            this.setState({ 
                gridData: data,                 
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Grid data has been refreshed!',
                    isOpenGrid: true
                }
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the Co investments:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true, modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.saveDB(null, false);
            this.setState({isOpenGrid: true, modalVisible: false});
        } else {
            this.setState({isOpenGrid: true, modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }
    
    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    } 

    setAgGridAPI2(api) {
        this.gridApi2 = api;
        this.gridApprovalApi2 = api;
    } 

    resetFields(isOpenGrid = null) {
        this.setState({
            ...(isOpenGrid) && { isOpenGrid: isOpenGrid },
            newDeal: {
                id:null,
                employeeId:null,
                projectName:null,
                analyst1Id:null,
                coInvestorName: null,
                dealSize: null,
                currency:"USD",
                introductionDate: moment(new Date()).format("YYYY-MM-DD"),
                closingDate: moment(new Date()).format("YYYY-MM-DD"),
                upfrontFee: null,
                managementFee: null,
                carryHurdle: null,
                carryParticipation: null,
                comment: null,
                dealCoStatusId:null
            },
            existingDeal: {
                id:null,
                employeeId:null,
                projectName:null,
                analyst1Id:null,
                coInvestorName: null,
                dealSize: null,
                currency:"USD",
                introductionDate: moment(new Date()).format("YYYY-MM-DD"),
                closingDate: moment(new Date()).format("YYYY-MM-DD"),
                upfrontFee: null,
                managementFee: null,
                carryHurdle: null,
                carryParticipation: null,
                comment: null,
                dealCoStatusId:null
            }
        });
    }

    addNew() {
        this.resetFields();
        this.setState({
            isOpenGrid : false
        });
    }
   
    saveData(isSendForApproval, fileNameUploaded) {
        const { newDeal } = this.state;
        coInvestmentBALService.save(newDeal).then(
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: isSendForApproval ? 'Sent for Approval successfully!' : 'Data has been saved successfully!',
                        existingDeal : {
                            ...newDeal,
                            id: data.id
                        },
                        newDeal : {
                            ...newDeal ,
                            id: data.id
                        },                                
                    });
                    this.refresh(null, true);
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : "Error while saving the data :",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: isSendForApproval ? 'Error sending for approval, please contact support!' : 'Data could not be saved, please contact support!'
                    }); 
                    console.log(error); 
                this.refresh(null, true);
            }
        );            
    }
    
    saveDB(e, isSendForApproval=false) {
        const { newDeal, existingDeal } = this.state;
        const { projectName, analyst1Id, dealCoStatusId, coInvestorName, dealSize } = newDeal;
        var errorList = [];
        
        if(JSON.stringify(existingDeal) === JSON.stringify(newDeal)) {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'No changes to save !',
            })
        } else {
            if(!analyst1Id) {
                errorList.push("'Analyst' cannot be blank");
            } 
            if(!dealCoStatusId) {
                errorList.push("'Status' cannot be blank");
            } 
            if(!coInvestorName) {
                errorList.push("'Co Investor Name' cannot be blank");
            } 
            if(!projectName) {
                errorList.push("'Project Name' cannot be blank");
            } 
            if(!dealSize || dealSize <= 0) {
                errorList.push("'Deal Size' must be positive value");
            }

            if(errorList.length > 0) {
                this.setState({ 
                    showMessage: true, 
                    errorList: errorList,
                    messageHeader : "Please fix below errors:",
                });
            } else {
                this.setState({                         
                    showMessage: false,
                    errorList: null,
                    messageHeader : "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: isSendForApproval ? 'Sending for approval...' : 'Data save in progress...'
                });

                this.saveData(isSendForApproval);
            }
        }
    }
    
    handleChange(e) {
        const { name, value } = e.target; 
        this.setState({ 
            newDeal: {
                ...this.state.newDeal,
                [name]: value
            }
        }); 
    }

    handleHome() {
        const { existingDeal, newDeal } = this.state;
        if(JSON.stringify(existingDeal) !== JSON.stringify(newDeal)) {
            this.setState({
                modalVisible: true,
                modalType: 'saveChanges',
                modalHandler: 'discard',
                modalHeading: 'Do you want to discard changes?'
            })
        } else {
            this.resetFields(true);
            this.refresh(null, true);
        }
    }
    
    handleChangeCoInvestment(e, data) { 
        this.setState({
            isOpenGrid: false,
            showMessage: false, 
            errorList: null, 
            messageHeader : "",
            modalVisible: false,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: null,
            existingDeal: {
                ...data
            },
            newDeal: {
                ...data
            }
        });
    }

    handleChangeCheckbox(e) {
        this.setState({
            newDeal: {
                ...this.state.newDeal,
                [e.target.name]: e.target.value === "true"
            }
        });
    }

    handleRowSelected(e) {
        const { data } = e.target;
        this.handleChangeCoInvestment(e, data);
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ newDeal: { ...this.state.newDeal, [name]: value } });
    }

    handleChangeNew(name, value) {
        this.setState({
            newDeal: {
                ...this.state.newDeal,
                [name]: value 
            }
        });
    }

    setFile(e) {    
        this.setState({ 
            newDeal : {
                ...this.state.newDeal,
                file: e.target.files[0]
            },             
            existingDeal : {
                ...this.state.existingDeal,
                file: null
            }
        });
    }
    
    download() {
        const fileName = this.state.newDeal.fileName;
        s3BALService.downloadFile(fileName).then(data => {

            fileDownload(data, fileName.substring(fileName.indexOf('_') + 1));
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while downloading the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be downloaded, please contact support!'
            });
        });
    }

    remove() {
        const {newDeal, existingDeal} = this.state;
        const fileName = newDeal.fileName;
        s3BALService.deleteFile({fileName : fileName, type: type}).then(data => {
            this.setState({
                newDeal : {
                    ...newDeal
                    ,fileName : null
                    ,isSendForApproval : false
                    ,file: null
                },
                existingDeal : {
                    ...existingDeal
                    ,fileName : null
                    ,isSendForApproval : false
                    ,file: null
                }
            });
            this.refresh(null, true);
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while removing the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be removed, please contact support!'
            });
        });
    }
    
    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    getPage() {
        const { newDeal, currencyList, statusList, existingDeal, analystList, modalHeading, gridReminderData, isOpenGrid, gridData, isApprover} = this.state;
        const { id, projectName, coInvestorName, analyst1Id, dealCoStatusId, introductionDate, closingDate, currency, dealSize, upfrontFee, managementFee, carryHurdle, carryParticipation, comment } = newDeal;
             return (
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                    {
                        this.state.showMessage ? 
                            <ToastMessage
                                header={this.state.messageHeader}
                                errorList={this.state.errorList}
                                closeMessage={this.closeMessage}
                            />:<div></div>
                    } 
                    {
                        isOpenGrid
                            ?
                            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                                <div>
                                    <div style={{ float: "Left", padding: "5px 15px 0px 15px", cursor: "pointer" }} ><h4><u>Co Investments</u></h4></div>
                                    {
                                        !isApprover && 
                                            <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.addNew}><FontAwesomeIcon icon={faPlusCircle} /></div>
                                    }
                                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        setAgGridAPI = {this.setAgGridAPI}
                                        isHideDateInputs={true}
                                        domLayout="normal"

                                        rowData={gridData}

                                        className="ag-theme-alpine"
                                        rowSelection="multiple"
                                        groupDefaultExpanded = {-1}
                                        groupIncludeTotalFooter={false}
                                        groupBy='status'

                                        guikey="coInvestment"
                                        gridIdentityColumn="id"
                                        gridLinkColumn="Project Name"
                                        //gridApproveColumn = "Changes"
                                        handleRowSelected={this.handleRowSelected}
                                        //isApprover = {isApprover && inApproval}
                                        approve = {this.approve}
                                        reject = {this.reject}
                                        showReject = {true} 

                                        isRowSelectable={ (node) => { 
                                            return false }}
                                    />
                                </div>
                            </div>
                            :
                            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%', overflowY:'scroll' }} >
                                <div>
                                    <span style={{ float: "left", paddingLeft: "20px" }}><h4><u>{id=== null ? 'New CoInvestment' : `Edit '${projectName}' - '${coInvestorName}' `} </u></h4></span>
                                    <div style={{ float: "left", paddingLeft: "15px", cursor: "pointer" }} onClick={this.handleHome}><FontAwesomeIcon icon={faHome} /></div>
                                </div> 
                                <div style={{ overflow: 'auto', paddingTop:'15px'}}>
                                    <div className="dealForm"> 
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Lead Analyst Name:</b>
                                                <Dropdown
                                                    placeholder='Select Lead Analyst'
                                                    name='analyst1Id'
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={analystList}
                                                    value={analyst1Id}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col"> 
                                                <b>Project Name:</b>
                                                <input type="text"
                                                    disabled={isApprover} 
                                                    placeholder="Project Name"
                                                    name="projectName"
                                                    autoComplete="off"
                                                    value={projectName}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col"> 
                                                <b>Co Investment Name:</b>
                                                <input type="text"
                                                    disabled={isApprover} 
                                                    placeholder="Name"
                                                    name="coInvestorName"
                                                    autoComplete="off"
                                                    value={coInvestorName}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Introduction Date:</b><br/>
                                                <DatePicker
                                                    name="introductionDate"
                                                    disabled={isApprover} 
                                                    selected={introductionDate ? new Date(introductionDate) : null}
                                                    onChange={(date) => { 
                                                        this.handleChangeNew('introductionDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                                        }}
                                                    autoComplete = "off"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Closing Date:</b><br/>
                                                <DatePicker
                                                    name="closingDate"
                                                    disabled={isApprover} 
                                                    selected={closingDate ? new Date(closingDate) : null}
                                                    onChange={(date) => { 
                                                        this.handleChangeNew('closingDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                                        }}
                                                    autoComplete = "off"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Status:</b>
                                                <Dropdown
                                                    placeholder='Select Status'
                                                    name='dealCoStatusId'
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={statusList}
                                                    value={dealCoStatusId}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Currency:</b><br/>
                                                <Dropdown
                                                    placeholder='Select Currency'
                                                    name='currency'
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={currencyList}
                                                    value={currency}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Deal Size (in {currency}):</b>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder={"Deal Size (in " + currency + ")"}
                                                    name='dealSize'
                                                    value={this.getFormatted(dealSize)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('dealSize', values.floatValue ); }}
                                                    //suffix={'M'}
                                                    //prefix={'$'}
                                                    className={'form-control inputLightP'}
                                                /> 
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Upfront Fee %:</b>
                                                    <NumberFormat
                                                        disabled={isApprover} 
                                                        placeholder={`Upfront Fee`}
                                                        name='upfrontFee'
                                                        value={this.getFormatted(upfrontFee)}
                                                        thousandSeparator={true}
                                                        onValueChange={(values) => { this.handleChangeNew('upfrontFee', values.floatValue); }}                                                    
                                                        suffix={'%'}
                                                        className={'form-control inputLightP'}
                                                    />  
                                            </div>
                                        </div>  
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Management Fee %:</b>
                                                    <NumberFormat
                                                        disabled={isApprover} 
                                                        placeholder={`Management Fee`}
                                                        name='managementFee'
                                                        value={this.getFormatted(managementFee)}
                                                        thousandSeparator={true}
                                                        onValueChange={(values) => { this.handleChangeNew('managementFee', values.floatValue); }}
                                                        suffix={'%'}
                                                        className={'form-control inputLightP'}
                                                    />  
                                            </div>
                                        </div>  
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Carry Hurdle %:</b>
                                                    <NumberFormat
                                                        disabled={isApprover} 
                                                        placeholder={`Carry Hurdle`}
                                                        name='carryHurdle'
                                                        value={this.getFormatted(carryHurdle)}
                                                        thousandSeparator={true}
                                                        onValueChange={(values) => { this.handleChangeNew('carryHurdle', values.floatValue); }}
                                                        suffix={'%'}
                                                        className={'form-control inputLightP'}
                                                    />  
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Carry Participation %:</b>
                                                    <NumberFormat
                                                        disabled={isApprover} 
                                                        placeholder={`Carry Participation`}
                                                        name='carryParticipation'
                                                        value={this.getFormatted(carryParticipation)}
                                                        thousandSeparator={true}
                                                        onValueChange={(values) => { this.handleChangeNew('carryParticipation', values.floatValue); }}
                                                        suffix={'%'}
                                                        className={'form-control inputLightP'}
                                                    />  
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col"> 
                                                <b>Comment:</b>
                                                <input type="text"
                                                    disabled={isApprover} 
                                                    placeholder="Comment"
                                                    name="comment"
                                                    autoComplete="off"
                                                    value={comment}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                        <div style={{width:'100%', height:'100%', verticalAlign: 'bottom' } }>
                                            <div style={{ paddingBottom: 15, paddingTop: 10, height: '10px'}}>
                                                <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.saveDB}>
                                                    <FontAwesomeIcon icon={faSave}  style = {{cursor: "pointer"}} tooltip = "true" title = "Save"/>
                                                </div> 
                                            </div>
                                        </div>                           
                            </div>
                    }
                    <Prompt
                        when={this.state.isOpenGrid === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                        message={location => `Do you want to discard changes?`}
                    />
                    <div>
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "270px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>:
                                    this.state.modalType === 'datagrid'?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "400px", height: "auto"}}>
                                            <h1>{modalHeading}</h1>
                                            <TorGrid
                                                setAgGridAPI = {this.setAgGridAPI2} 
                                                domLayout="autoHeight"
                                                enableFilter={false}

                                                isHideDateInputs={true}
                                                isHideSaveGridLayout={true}
                                                rowData={gridReminderData}
                                                rowGroupPanelShow="none"
                                                sideBar='none'
                                                gridTitle=''

                                                className="dashboardgridNoTotal"
                                                rowSelection="multiple"
                                                groupDefaultExpanded = {-1}
                                                groupIncludeTotalFooter={false}
                            
                                                defaultColDef={this.props.defaultColDef ??
                                                {
                                                sortable: false
                                                , filter: false
                                                , floatingFilter: false
                                                , enableRowGroup: true
                                                , resizable: true
                                                , suppressSizeToFit: false
                                                , flex: 3
                                                }
                                                }

                                                guikey="coInvestment"
                                                gridIdentityColumn="id"
                                            />
                                            <button className="btnDes mt100" onClick={this.handleSendReminder}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>:
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                            }
                        </section>
                    </div>
                </div>
            );
    }
}

export default CoInvestment;