/**<import>****************************************************************************************************/
import React, { Component } from 'react';
//https://react.semantic-ui.com/modules/dropdown/
import { Dropdown } from 'semantic-ui-react';

import ProgressBar from './components/progressbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faArrowCircleLeft, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Our components
import './deals.css';

class TrancheAttribute extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            selectedDate: [],
            percent: 0
        }
        
        this.handleDropDownChangeTranche = this.handleDropDownChangeTranche.bind(this);
        this.getFieldName = this.getFieldName.bind(this);
        this.getFieldByName = this.getFieldByName.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getProgress = this.getProgress.bind(this);
    };

    componentDidMount() {
        this.getProgress();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trancheAttributeList !== this.props.trancheAttributeList || prevProps.trancheAttribute !== this.props.trancheAttribute) {
            this.getProgress();
        }
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    getProgress() {
        const { trancheAttribute, trancheAttributeList, fieldList, positionList } = this.props;
        var filterData, permission, required;
        var fillCount = 0;
        var count = 0;

        var columnList = [
            "asset_type",
            "credit_ranking",
            "holdco_vs_opco",
            "has_defaulted",
            "default_date",
            "makewhole",
            "amortization",
            "is_non_recourse"
        ]

        for (const key of columnList) {
            filterData = fieldList.filter(function (item) { return item.field_name === key }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            for (var i = 0; i < trancheAttributeList.length; i++) {
                if(key !== "asset_type" && ['Private Equity Shares', 'Common Stock', 'Warrant'].includes(positionList[i].productType)) {
                    required = false;
                } else {
                    required = true;
                }

                if(permission === 2 && key === "is_non_recourse"){
                    if(['Total Return Swap', 'Swap Financing'].includes(positionList[i].productType)) {
                        count += 1;
                        if (trancheAttributeList[i][key]) {
                            fillCount += 1;
                        } else if (trancheAttribute[key]) {
                            fillCount += 1
                        }
                    }
                } else if (required === true && permission === 2) {
                    if (key === "default_date" && trancheAttributeList[i]["has_defaulted"] === true) {
                        count += 1;
                        if (trancheAttributeList[i][key]) {
                            fillCount += 1;
                        } else if (trancheAttribute[key] || trancheAttribute[key] === false) {
                            fillCount += 1
                        }
                    } else if (key !== "default_date") {
                        count += 1;
                        if (trancheAttributeList[i][key] || trancheAttributeList[i][key] === false) {
                            fillCount += 1;
                        } else if (trancheAttribute[key] || trancheAttribute[key] === false) {
                            fillCount += 1
                        }
                    }
                }
            }
        }

        this.setState({ percent: this.roundToTwo(fillCount / count * 100) });
    }

    handleDropDownChangeTranche(e, { id, name, value }) {
        this.props.handleDropDownChangeTranche(e, { id, name, value });
    }

    getFieldName(l, f) {
        return l.filter(function (item) { return item.value === f })[0]?.text;
    }

    getFieldByName(name) {
        const { fieldList } = this.props;
        return fieldList.filter(
            function (data) {
                return data['field_name'] === name
            }
        )
    }

    getSettingByName(name) {
        const { settingList } = this.props;
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    handleChange(e) {
        if (e.target.value === "true") {
            this.props.handleChangeTranche(parseInt(e.target?.name.split("-")[1]), e.target.name?.split("-")[0], true);
        } else if (e.target.value === "false") {
            this.props.handleChangeTranche(parseInt(e.target?.name.split("-")[1]), e.target.name?.split("-")[0], false);
        } else {
            this.props.handleChangeTranche(e.target?.name, e.target?.name, e.target?.value);
        }
    }

    handleChangeDate = (date, name) => {
        this.props.handleChangeTranche(parseInt(name.split("-")[1]), "default_date", date ? moment(date).format("YYYY-MM-DD") : null);
    }


    render() {
        const { trancheAttribute, trancheAttributeList, positionList } = this.props;

        const assetClassList = this.getSettingByName("Asset Type").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const assetRankingList = this.getSettingByName("Credit Ranking").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const holdcoList = this.getSettingByName("HoldCo vs OpCo").map(item => { return { key: item.value, value: item.id, text: item.value } });

        const percent = this.state.percent;
        const fieldListA = this.getFieldByName("asset_type");
        const fieldListB = this.getFieldByName("credit_ranking");
        const fieldListC = this.getFieldByName("holdco_vs_opco");
        const fieldListD = this.getFieldByName("has_defaulted");
        const fieldListE = this.getFieldByName("default_date");
        const fieldListF = this.getFieldByName("makewhole");
        const fieldListG = this.getFieldByName("amortization");
        const fieldListH = this.getFieldByName("is_non_recourse");

        return (
            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%' }}>
                <div>
                    <ProgressBar percent={percent} />
                </div>
                <div style={{ overflow: 'auto', overflowX: 'scroll'}}>
                    <div className="dealForm">
                        <div className="spacer table-outer">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td className="firstCellTA"><h3>Tranche Attributes</h3></td>
                                        {
                                            positionList.map((item, i) => {
                                                return (
                                                    <td className="minW300" key={i}>
                                                        {
                                                            item.hasExpired === true
                                                                ?
                                                                <h3>Tranche {i + 1} (Expired)</h3>
                                                                :
                                                                <h3>Tranche {i + 1}</h3>
                                                        }
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Tranche Investment Code</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td className="minW300" key={i}><input type="text" placeholder="Tranche Investment Code" name="trancheIC" value={item.investmentCode} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Tranche Description</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td className="minW300" key={i}><input type="text" placeholder="Tranche Description" name="trancheDesc" value={item.trancheDescription} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Inception/ Trade Date</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td className="minW300" key={i}><input type="text" placeholder="Inception/ Trade Date" name="inceptionDate" value={item.inceptionDate === null ? '' : item.inceptionDate.substring(0, 10)} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Face Value (USD)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td className="minW300" key={i}>
                                                    <input type="text"
                                                        placeholder="Face Value (USD)"
                                                        name="faceValue"
                                                        value={item.faceValue !== 0 ? '$' + Number(parseInt(item.faceValue, 10)).toLocaleString('en') : 0}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Market Value (USD)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td className="minW300" key={i}>
                                                    <input type="text"
                                                        placeholder="Market Value (USD)"
                                                        name="marketValue"
                                                        value={item.bookValue !== 0 ? '$' + Number(parseInt(item.bookValue, 10)).toLocaleString('en') : 0}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr>

                                    {
                                        fieldListA[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListA[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Asset Type <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="asset_type"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.asset_type === null ? '' : this.getFieldName(assetClassList, trancheAttributeList[i]?.asset_type)}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Asset Type <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <Dropdown
                                                                    placeholder='Select Asset Type'
                                                                    name="asset_type"
                                                                    id={i}
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    clearable
                                                                    onChange={this.handleDropDownChangeTranche}
                                                                    options={assetClassList}
                                                                    value={trancheAttributeList[i]?.asset_type === null ? '' : trancheAttributeList[i]?.asset_type}
                                                                    className={'form-control maxW400'}
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListB[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListB[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Credit Ranking <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="credit_ranking"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.credit_ranking ? this.getFieldName(assetRankingList, trancheAttributeList[i]?.credit_ranking) : ''}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Credit Ranking <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="credit_ranking"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                                return <td className="minW300" key={i}>
                                                                    <Dropdown
                                                                        placeholder='Select Asset Ranking'
                                                                        name="credit_ranking"
                                                                        id={i}
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        clearable
                                                                        onChange={this.handleDropDownChangeTranche}
                                                                        options={assetRankingList}
                                                                        value={trancheAttributeList[i]?.credit_ranking ?? ''}
                                                                        className={'form-control maxW400'}
                                                                    />
                                                                </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListC[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListC[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">HoldCo vs OpCo <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="holdco_vs_opco"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.holdco_vs_opco ? this.getFieldName(holdcoList, trancheAttributeList[i]?.holdco_vs_opco) : ''}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">HoldCo vs OpCo <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="holdco_vs_opco"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <Dropdown
                                                                    placeholder='Select HoldCo vs Opco'
                                                                    name="holdco_vs_opco"
                                                                    id={i}
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    clearable
                                                                    onChange={this.handleDropDownChangeTranche}
                                                                    options={holdcoList}
                                                                    value={trancheAttributeList[i]?.holdco_vs_opco ?? ''}
                                                                    className={'form-control maxW400'}
                                                                />
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListD[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListD[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Has the tranche defaulted? <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="has_defaulted"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.has_defaulted ?? false ? 'Yes' : 'No'}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Has the tranche defaulted? <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="has_defaulted"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <div className="form-control border-none" onClick={this.handleChange.bind(this)}>
                                                                    <input
                                                                        type="radio"
                                                                        value={true}
                                                                        id={`has_defaulted-${i}_Yes`}
                                                                        name={`has_defaulted-${i}`}
                                                                        checked={trancheAttributeList[i]?.has_defaulted}
                                                                    /> <label for={`has_defaulted-${i}_Yes`} className="fsBig">Yes</label>

                                                                    <input
                                                                        type="radio"
                                                                        value={false}
                                                                        id={`has_defaulted-${i}_No`}
                                                                        name={`has_defaulted-${i}`}
                                                                        className={'ml20'}
                                                                        checked={trancheAttributeList[i]?.has_defaulted !== true}
                                                                    /> <label for={`has_defaulted-${i}_No`} className="fsBig">No</label>
                                                                </div>
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListE[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListE[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Default Date</label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="default_date"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.default_date ?? ''}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Default Date</label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="default_date"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <DatePicker
                                                                    name={`default_date-${i}`}
                                                                    selected={trancheAttributeList[i]?.default_date && trancheAttributeList[i]?.has_defaulted === true ? new Date(trancheAttributeList[i]?.default_date) : '' }
                                                                    onSelect={(date) => this.handleChangeDate(date, `default_date-${i}`)}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    autoComplete = "off"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListF[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListF[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Call Protection/ Make-Whole <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="makewhole"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.makewhole ?? false ? 'Yes' : 'No'}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Call Protection/ Make-Whole <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="makewhole"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <div className="form-control border-none" onClick={this.handleChange.bind(this)}>
                                                                    <input
                                                                        type="radio"
                                                                        id={`makewhole-${i}_Yes`}
                                                                        value={true}
                                                                        name={`makewhole-${i}`}
                                                                        checked={trancheAttributeList[i]?.makewhole ?? trancheAttribute.makewhole ?? false}
                                                                    /> <label for={`makewhole-${i}_Yes`} className="fsBig">Yes</label>

                                                                    <input
                                                                        type="radio"
                                                                        value={false}
                                                                        id={`makewhole-${i}_No`}
                                                                        name={`makewhole-${i}`}
                                                                        className={'ml20'}
                                                                        checked={!(trancheAttributeList[i]?.makewhole ?? trancheAttribute.makewhole ?? true)}
                                                                    /> <label for={`makewhole-${i}_No`} className="fsBig">No</label>
                                                                </div>
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListG[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListG[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Amortization <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="amortization"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.amortization ?? false ? 'Yes' : 'No'}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Amortization <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (item.productType === 'Private Equity Shares' || item.productType === 'Common Stock' || item.productType === 'Warrant') {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="amortization"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <div className="form-control border-none" onClick={this.handleChange.bind(this)}>
                                                                    <input
                                                                        type="radio"
                                                                        id={`amortization-${i}_Yes`}
                                                                        value={true}
                                                                        name={`amortization-${i}`}
                                                                        checked={trancheAttributeList[i]?.amortization ?? trancheAttribute.amortization ?? false}
                                                                    /> <label for={`amortization-${i}_Yes`} className="fsBig">Yes</label>

                                                                    <input
                                                                        type="radio"
                                                                        id={`amortization-${i}_No`}
                                                                        value={false}
                                                                        name={`amortization-${i}`}
                                                                        className={'ml20'}
                                                                        checked={!(trancheAttributeList[i]?.amortization ?? trancheAttribute.amortization ?? true)}
                                                                    /> <label for={`amortization-${i}_No`} className="fsBig">No</label>
                                                                </div>
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {
                                        fieldListH[0]?.permission === 0
                                            ?
                                            null
                                            :
                                            fieldListH[0]?.permission === 1
                                                ?
                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Is Non-recourse Financing? <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            return <td className="minW300" key={i}>
                                                                <input
                                                                    placeholder=""
                                                                    name="is_non_recourse"
                                                                    autoComplete="off"
                                                                    value={trancheAttributeList[i]?.is_non_recourse ?? false ? 'Yes' : 'No'}
                                                                    className={'form-control inputDisabledGrey'}
                                                                    disabled
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                :

                                                <tr>
                                                    <td className="firstCellTA"><label className="fsBig">Is Non-recourse Financing? <span style = {{color: 'red'}}>*</span></label></td>
                                                    {
                                                        positionList.map((item, i) => {
                                                            if (!['Total Return Swap', 'Swap Financing'].includes(item.productType)) {
                                                                return <td className="minW300" key={i}>
                                                                    <input
                                                                        placeholder=""
                                                                        name="is_non_recourse"
                                                                        autoComplete="off"
                                                                        value={""}
                                                                        className={'form-control inputDisabledGrey'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            } else {
                                                            return <td className="minW300" key={i}>
                                                                <div className="form-control border-none" onClick={this.handleChange.bind(this)}>
                                                                    <input
                                                                        type="radio"
                                                                        id={`is_non_recourse-${i}_Yes`}
                                                                        value={true}
                                                                        name={`is_non_recourse-${i}`}
                                                                        checked={trancheAttributeList[i]?.is_non_recourse ?? trancheAttribute.is_non_recourse ?? false}
                                                                    /> <label for={`is_non_recourse-${i}_Yes`} className="fsBig">Yes</label>

                                                                    <input
                                                                        type="radio"
                                                                        id={`is_non_recourse-${i}_No`}
                                                                        value={false}
                                                                        name={`is_non_recourse-${i}`}
                                                                        className={'ml20'}
                                                                        checked={!(trancheAttributeList[i]?.is_non_recourse ?? trancheAttribute.is_non_recourse ?? true)}
                                                                    /> <label for={`is_non_recourse-${i}_No`} className="fsBig">No</label>
                                                                </div>
                                                            </td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                    }

                                    {/* <tr>
                                        <td className="firstCellTA"><label className="fsBig">Facility Size (USD)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}>
                                                    <input type="text"
                                                        placeholder="Facility Size (USD)"
                                                        name="facilitySize"
                                                        value={item.facilitySize !== 0 ? '$' + Number(parseInt(item.facilitySize, 10)).toLocaleString('en') : 0}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Currency</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}><input type="text" placeholder="Currency" name="curr" value={item.curr} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Coupon Type</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}><input type="text" placeholder="Coupon Type" name="couponType" value={item.couponType} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Cash Coupon (%)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}>
                                                    <input type="text"
                                                        placeholder="Cash Coupon (%)"
                                                        name="cashCoupon"
                                                        value={item.cashCoupon && item.cashCoupon !== 0 ? item.cashCoupon.toLocaleString('en') + '%' : '0%'}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">All PIK?</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}><input type="text" placeholder="All PIK?" name="allPik" value={item.allPik} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">PIK (%)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}>
                                                    <input type="text"
                                                        placeholder="PIK (%)"
                                                        name="pik"
                                                        value={item.pik && item.pik !== 0 ? item.pik.toLocaleString('en') + '%' : '0%'}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td className="firstCellTA"><label className="fsBig">Other Fees (USD)</label></td>
                                        {
                                            positionList.map((item, i) => {
                                                return <td key={i}>
                                                    <input type="text"
                                                        placeholder="Other Fees (USD)"
                                                        name="lCost"
                                                        value={item.lCost !== 0 ? '$' + Number(parseInt(item.lCost, 10)).toLocaleString('en') : 0}
                                                        autoComplete="off"
                                                        className={'form-control inputDisabled fs14'}
                                                        disabled
                                                    />
                                                </td>
                                            })
                                        }
                                    </tr> */}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ paddingBottom: 15, height: '10px' }}>
                        
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(3)}><FontAwesomeIcon icon={faArrowCircleRight} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(1)} title="Back"><FontAwesomeIcon icon={faArrowCircleLeft} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.saveDB()} title="Save"><FontAwesomeIcon icon={faSave} /></div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.approveDB()}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit for Approval" />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default TrancheAttribute;