import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');

const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': True, 'format': 'fundFormat', 'rowGroup': true, hide: true},
            {'display': 'MTD PnL', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': True, 'pnl-grid': True},
            {'display': 'QTD PnL', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': True, 'pnl-grid': True},
            {'display': 'YTD PnL', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': True, 'pnl-grid': True},
            {'display': 'LTD PnL', 'col': 'pnl total deal', 'agg': 'sum', 'format': 'millionsFormat2D', 'ag-grid': True, 'pnl-grid': True},
        ]};
}

export const dealPerformanceSummaryTopBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Fund'
       ,field: 'Fund'
       ,pinned: 'left'
       ,resizable: false
       ,hide: false
       ,width: 70
       ,display: 'Fund'
       ,cellRendererParams:  { suppressCount: true } 
      };  
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency, Fund} = params;
    
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    Fund = Fund ?? 'ALL';
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };

    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}&fund=${Fund}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}