import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Status', 'col': 'approver_status', 'agg': 'uniq', 'ag-grid': true, minWidth : 160},
            {'display': 'Name', 'col': 'full_name', 'agg': 'uniq', 'ag-grid': true, minWidth : 160 },
            {'display': 'Role', 'col': 'display_name', 'agg': 'uniq', 'ag-grid': true, minWidth : 180}, 
            {'display': 'Date', 'col': 'approval_date', 'agg': 'uniq', 'ag-grid': true, 'format': 'dateFormat', minWidth : 80},
            {'display': 'Comment', 'col': 'comment', 'agg': 'uniq', 'ag-grid': true, minWidth : 200}, 

            /*{'display': 'Analyst 3', 'col': 'analyst3', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150},
            {'display': 'Status.Deal Attributes', 'col': 'statusDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 160}, 
            {'display': 'Status.Tranche Attributes', 'col': 'statusTrancheAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 170}, 
            {'display': 'Status Underwriting.Deal Attributes', 'col': 'statusUnderwritingDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150}, 
            {'display': 'Status Underwriting.Deal Risk', 'col': 'statusUnderwritingDealRisk', 'agg': 'uniq', 'ag-grid': true, maxWidth : 120}, 
            {'display': 'Status Underwriting.Deal Metrics', 'col': 'statusUnderwritingDealMetrics', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}, 
            {'display': 'Status Underwriting.Deal Returns', 'col': 'statusUnderwritingDealReturns', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}
            */
        ]
    };
}

export const approvalBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    approve,
    approveGrid,
    sendReminder,
    getPendingApproval
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: '',
        maxWidth: 80,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/allApproval/type=${params.type}/id=${params.id ?? ''}`, requestOptions).then(Utils.handleResponse).then(approvals => {         
        return approvals.map(row => {
            return {
                ...row
            }
        }); 
    });
}

function getPendingApproval(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/toReminder/type=${params.type}`, requestOptions).then(Utils.handleResponse).then(approvals => {         
        return approvals.map(row => {
            return {
                ...row
            }
        }); 
    });
}

function approve(params) {
    const {id, type, action, comment} = params;
    return fetch(`${config.apiDDSUrl}/setApproval/id=${id}/type=${type}/action=${action}`, {body: JSON.stringify({id: id, ...(comment) && {comment: comment}}), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function sendReminder(params) {
    const {type} = params;
    return fetch(`${config.apiDDSUrl}/sendReminder/type=${type}`, { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function approveGrid(proposals) {
    const promises = proposals.map((deal) => { 
       var a = fetch(`${config.apiDDSUrl}/setApproval/id=${deal.id}/type=${deal.type}/action=${deal.action}`, {body: JSON.stringify({id:deal.id, ...(deal?.comment) && {comment: deal.comment}}), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return handleResponseApprove(data, deal) });
       return a;
    });
    return promises;
} 

function handleResponseApprove(response, deal) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }
            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return {error : `${deal.deal} : ${error}`};
        }
        return data;
    });
}