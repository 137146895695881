import _ from 'underscore';

// Accepts the array and key
const groupBy = (array, key, value) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    result[currentValue[key]] = (result[currentValue[key]] || 0) + currentValue[value]
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};



const getMaxKey = (param) => {
  var maxKey = '';
  var maxvalue = 0
  maxKey = Object.keys(param)[0]
  maxvalue = param[Object.keys(param)[0]]
  Object.keys(param).forEach(element => {
    if (maxvalue < param[element]) {
      maxvalue = param[element];
      maxKey = element;
    }
  });
  return maxKey === "null" ? '' : maxKey;
}

export const Utils = {
  dominant,
  countUnique,
  getAggDiv,
  uniq,
  isZero,
  agValueFormatter,
  cellClassNegative,
  createValueObject2,
  getDivide,
  getValueFormatted,
  randomBetween,
  groupBy,
  getMaxKey,
  wAvg,
  aggDiv,
  aggDivIf,
  wAvgRating,
  isEmpty,
  getFieldName,
  getColumnList,
  doesMatchFilter,
  getArraysIntersection,
  geometricProduct,
  portfolioReturn,
  notionalSum,
  removeBlanks,
  handleResponse,
  handleResponseFile,
  removeBlanksNotZero,
  removeNegative,
  getDaysArray,
  lookupValue,
  lookupKey,
  extractValues,
  isBlankNotZero,
  isBlank,
  getSettingByName,
  getFormatted
};

function extractValues(mappings) {
  return mappings.map(item => { return { [item.id]: item.name } }); ;
}

function lookupValue(mappings, key) {
  return mappings[key];
}

function lookupKey(mappings, name) {
  for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
              return key;
          }
      }
  }
}

function getArraysIntersection(a1, a2) {
  return a1.filter(function (n) { return a2?.indexOf(n) !== -1; });
}

function dominant(params) {
  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });
    var aggValue = groupBy(rowData, params.colDef.cols[0], params.colDef.cols[1]);
    var maxKey = getMaxKey(aggValue);
    return createValueObject2(0, 0, maxKey);
  }
  return createValueObject2(0, 0, '');
}

function countUnique(params) {
  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var uniqueValues = params.rowNode.allLeafChildren.reduce((result, currentValue) => {
      var val1 = currentValue.data[params.colDef.cols[0]];
      var val2 = currentValue.data[params.colDef.cols[1]];
      if (currentValue?.data && val1 && val2 && val2 !== 0) {
        result[val1] = 1;
      }
      return result;
    }, {});
    return Object.keys(uniqueValues).length;
  }
  return 0;
}

function getAggDiv(rowData, gridColumn) {
  var result = '';

  if (rowData && rowData.length > 0 && gridColumn && gridColumn.denomcols && gridColumn.denomcols.length > 0 && gridColumn.numcols && gridColumn.numcols.length > 0) {
    var denomvalue = rowData.reduce((denominator, row) => denominator + gridColumn.denomcols.reduce((temp, denomCol) => { if (row && row[denomCol]) { return temp + row[denomCol] } else return temp; }, 0), 0);
    var numvalue = rowData.reduce((numerator, row) => numerator + gridColumn.numcols.reduce((temp, numCol) => { if (row && row[numCol]) { return temp + row[numCol] } else return temp }, 0), 0);
    result = getDivide(numvalue, denomvalue);
  }
  
  return result;
}

function uniq(params) {
  var result = '';
  if (params.values && params.values.length > 0) {
    result = params.values[0];
    params.values.forEach(function (value) {
      if (result !== '' && value !== result) {
        result = '';
      }
    });
  }
  return result;
}

function isZero(value) {
  return value < 0.0001 && value > -0.0001
}

function agValueFormatter(params) {
  const agformat = params.format ?? params?.colDef?.format;
  var tempValue = 0;
  if (params.value && params.value !== '' && params.value !== 0) {
    switch (agformat) {
      case 'millionsFormat0':
        tempValue = params.value / 1e6;
        const val = getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 1);
        return (val === '' ? '0.0' : val);
      case 'millionsFormat':
        tempValue = params.value / 1e6;
        return getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 1);
      case 'millionsFormat2':
        tempValue = params.value / 1e6;
        return getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 2);
      case 'millionsFormat2D':
        return getValueFormatted(params.value / 1e6, 2);
      case 'millionsFormat2M':
        tempValue = params.value / 1e6;
        const millionValue = getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 2);
        return `${millionValue}${millionValue && millionValue.toString() !== '' ? 'M' : ''}`;;
      case 'millionsFormat3M':
        tempValue = params.value / 1e6;
        const millionValue3 = getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 3);
        return `${millionValue3}${millionValue3 && millionValue3.toString() !== '' ? 'M' : ''}`;;
      case 'millionsFormat1M':
        tempValue = params.value / 1e6;
        const millionValue1 = getValueFormatted(tempValue, Number.isInteger(tempValue) ? 0 : 1);
        return `${millionValue1}${millionValue1 && millionValue1.toString() !== '' ? 'M' : ''}`;;
      case 'millionsFormat0M':
        const millionValue2 = getValueFormatted(params.value / 1e6, 0);
        return `${millionValue2}${millionValue2 && millionValue2.toString() !== '' ? 'M' : ''}`;;
      case 'percentFormat':
        return getValueFormatted(params.value * 100);
      case 'percentFormat1':
        return getValueFormatted(params.value * 100, Number.isInteger(params.value*100) ? 0 : 1);
      case 'percentFormat2':
        return getValueFormatted(params.value * 100, Number.isInteger(params.value*100) ? 0 : 2);
      case 'percentFormat2P':
        const value = getValueFormatted(params.value, Number.isInteger(params.value) ? 0 : 2);
        return `${value}${value && value.toString() !== '' ? '%' : ''}`
      case 'percentFormat2P-100':
        const value100 = getValueFormatted(params.value * 100, Number.isInteger(params.value*100) ? 0 : 2);
        return `${value100}${value100 && value100.toString() !== '' ? '%' : ''}`
      case 'percentFormat2P-100All':
        const value100All = getValueFormattedNonZero(params.value * 100, Number.isInteger(params.value*100) ? 0 : 2);
        return `${value100All}${value100All && value100All.toString() !== '' ? '%' : ''}`
      case 'percentFormat2P-AllUNCH':
        if(Number.isNaN(params.value)) {
          return params.value;
        }
        const value100allunch = params.value;
        if(value100allunch >-0.01 && value100allunch <0.01) {
          return "UNCH";
        }
        else { 
          const value100All = getValueFormattedNonZero(value100allunch, Number.isInteger(value100allunch) ? 0 : 2);
          return `${value100All}${value100All && value100All.toString() !== '' ? '%' : ''}`
        }
      case 'decimalFormat2-AllUNCH':
        if(Number.isNaN(params.value)) {
          return params.value;
        }
        const value100allunchdecimal = params.value;
        if(value100allunchdecimal >-0.01 && value100allunchdecimal <0.01) {
          return "UNCH";
        }
        else { 
          const value100All = getValueFormattedNonZero(value100allunchdecimal, Number.isInteger(value100allunchdecimal) ? 0 : 2);
          return `${value100All}`
        }
      case 'decimalFormat0':
        return getValueFormatted(params.value, 0);
      case 'decimalFormat1':
        return getValueFormatted(params.value, Number.isInteger(params.value) ? 0 : 1);
      case 'decimalFormat2':
        return getValueFormatted(params.value, Number.isInteger(params.value) ? 0 : 2);
      case 'decimalFormat2All':
        return getValueFormattedNonZero(params.value, Number.isInteger(params.value) ? 0 : 2);
      case 'decimalFormat2M':
        return `$${getValueFormatted(params.value, Number.isInteger(params.value) ? 0 : 2)}M`;
      case 'decimalFormat2ML':
        return `${getValueFormatted(params.value, Number.isInteger(params.value) ? 0 : 2)}M`;
      case 'decimalFormatM':
        return `$${getValueFormatted(params.value, 0)}M`;
      case '{0:.2f}x':
        return getValueFormatted(params.value);
      case 'leverageFormat':
        const displayvalue = getValueFormatted(params.value, 2);
        return `${displayvalue}${displayvalue && displayvalue.toString() !== '' ? 'x' : ''}`;
      case 'dateFormat':
        return getDate(params.value);
      case 'dateFormatShort':
        let date = new Date(params.value);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[date.getMonth()] + "' " + (date.getFullYear() % 1000);
      case 'fundFormat':
        return params.value === 'TOR' ? 'TACF' : params.value
      default:
        return params.value;
    }
  }
  else if (params.value === 0 && (agformat === 'decimalFormatShowZero2' || agformat === 'decimalFormat2All'))
    return getValueFormattedNonZero(params.value, 2);
  return '';
}

function removeBlanks(param) {
  for (let key in param) {
    if (param[key] === null || param[key] === 0 || param[key] === "" || Number.isNaN(param[key]) || param[key] === {}) {
      delete param[key];
    }
  }
  return param;
}

function isBlank(param) {
  if (param === null || param === 0 || param === ""  || param === "undefined"|| typeof(param) === "undefined" || Number.isNaN(param) || param === {}) 
    return true;
return false;
}

function isBlankNotZero(param) {
    if (param === null || param === "" || param === "undefined" || typeof(param) === "undefined" || Number.isNaN(param) || param === {}) 
      return true;
  return false;
}

function removeBlanksNotZero(param) {
  for (let key in param) {
    if (param[key] === null || param[key] === "" || Number.isNaN(param[key]) || param[key] === {}) {
      delete param[key];
    }
  }
  return param;
}

function removeNegative(param, key)  {  
    if (!Number.isNaN(param[key]) && param[key] < -1 ) {
      delete param[key];
    }  
  return param;
}

function handleResponseFile(response) {
  if (!response.ok) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //logout();
      //this.location.reload(true);
    }

    return response.text().then(text => {
      const data = text && JSON.parse(text);

      const error = (data && data.error_message) || (data && data.message) || response.statusText;
      return Promise.reject(error);
    });
  }
  return response.blob();
}

function handleResponse(response) {
  return response.text().then(text => {
    try {
      const data = text && JSON.parse(text);
      if (!response.ok || (data && data.error_message)) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          //logout();
          //this.location.reload(true);
        }

        const error = (data && data.error_message) || (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    } catch (e) {
      console.log(e);
      return Promise.reject(e.message);
    }
  });
}

function cellClassNegative(params) {
  var result = {color: '#000'};
  if (params.value) {
    try {
      var value = params.value?.display_value ?? params.value;
      value = parseFloat(value?.toString()?.replace(',', '').replace(' ', ''));
      if(params.value && params.value.color && params.value.color === "red")
        result = { color: '#FF0000' };
      else if ((!isNaN(value) && value < 0))
        result = { color: '#FF0000' };
      else if (params.value && params.value.color && params.value.color === "green")
        result = { color: '#00FF00' };
      else if(params.value === 'UNCH') 
        result = { color: '#708090' };
    }
    catch (error) {
    }
  }
  return result;
}

function createValueObject2(val1, val2, display_value) {
  return {
    val1: val1,
    val2: val2,
    display_value: display_value,
    toString: function () {
      return display_value;
    }
  }
}

function getDivide(num, denom) {
  var result = ''
  if (num !== 0 && denom !== 0) {
    result = num / denom;
  }
  return result;
}

function getValueFormatted(param, digit = 2) {
  var result = param;
  var val = 0.0;

  if (!isNaN(result)) {
    try {
      val = parseFloat(result).toFixed(digit);
      if (isZero(val)) return '';

      if (!isNaN(val))
        result = val.toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      if (result[0] === '-') {
        result = result.replace('-', '(') + ')';
      }
    } catch (error) {
      val = result;
    }
  }

  return result;
}

function getValueFormattedNonZero(param, digit = 2) {
  var result = param;
  var val = 0.0;

  if (!isNaN(result)) {
    try {
      val = parseFloat(result).toFixed(digit);
      if (!isNaN(val))
        result = val.toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      if (result[0] === '-') {
        result = result.replace('-', '(') + ')';
      }
    } catch (error) {
      val = result;
    }
  }

  return result;
}

function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getDate(params) {
  try {
    var val = new Date(params);
    return val.toISOString().split('T')[0];
  } catch (e) {
    return '';
  }
}

function wAvg(params) {
  var sumQty = 0.0;
  var sum1 = 0.0;
  if (params && params.length > 0) {
    params.rowNode.allLeafChildren.forEach(function (value1) {
      var value = value1.data;
      var val1 = value[params.colDef.cols[0]];
      var val2 = value[params.colDef.cols[1]];
      if (val1 && val2)
        sum1 = sum1 + (val1 * val2);
      if (val2)
        sumQty = sumQty + val2;
    });
  }
  var value = sumQty === 0.0 ? '' : (sum1 / sumQty).toFixed(2);
  return createValueObject2(value === '' ? 0 : value, sumQty, value);
}

function notionalSum(params) {
  const gridColumn = params.colDef;
  var col = gridColumn.col;

  if (params && params.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });

    var sumTotal = 0
    rowData.forEach(item => {
      if (item[col] > 0) {
        sumTotal += item[col]
      }
    })
  }

  return sumTotal
}

function geometricProduct(params) {
  const gridColumn = params.colDef;
  var filterList = null;
  var col = gridColumn.col;

  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });

    if (gridColumn.fltr) {
      var filters = Object.keys(gridColumn.fltr)
      filterList = filters.map(filter => {
        return {
          column: filter
          , value: gridColumn.fltr[filter]
        }
      });
    }

    var rowDataFiltered = rowData;
    if (filterList && filterList.length > 0) {
      filterList.forEach(filter => {
        rowDataFiltered = rowDataFiltered.filter(row => row[filter.column] === filter.value && col && row[col]);
      });
      const val = rowDataFiltered.reduce((denominator, row) => denominator * (1 + row[col]), 1);
      if (val !== 1)
        return val - 1;
    } else {
      const val = rowDataFiltered.reduce((denominator, row) => denominator * (1 + row[col]), 1);
      if (val !== 1)
        return val - 1;
    }
  }
  return '';
}

function portfolioReturn(params) {
  const gridColumn = params.colDef;
  var filterList = null;
  var numcol = gridColumn.args[0];
  var denomcol = gridColumn.args[1];

  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });

    if (gridColumn.fltr) {
      var filters = Object.keys(gridColumn.fltr)
      filterList = filters.map(filter => {
        return {
          column: filter
          , value: gridColumn.fltr[filter]
        }
      });
    }

    var rowDataFiltered = rowData;
    if (filterList && filterList.length > 0) {
      rowDataFiltered = rowData;
      filterList.forEach(filter => {
        rowDataFiltered = rowDataFiltered.filter(row => row[filter.column] === filter.value);
      });

      var reportDate = _.keys(_.countBy(rowDataFiltered, function (rowDataFiltered) { return rowDataFiltered['Report Date']; }));

      var totalReturn = 1;

      for (let i = 0; i < reportDate.length; i++) {
        var reportDateFiltered = rowDataFiltered.filter(row => row['Report Date'] === reportDate[i]);
        const numvalue = reportDateFiltered.reduce((numerator, row) => numerator + row[numcol], 0);
        const denomvalue = reportDateFiltered.reduce((denominator, row) => denominator + row[denomcol], 0);

        if (denomvalue !== 0) {
          var periodReturn = numvalue / denomvalue;
          totalReturn *= (periodReturn + 1);
        }
      }

      if (totalReturn !== 1)
        return totalReturn - 1;
    }
  }
  return '';
}

function aggDiv(params) {
  const gridColumn = params.colDef;
  var filterList = null;
  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });

    if (gridColumn.fltr) {
      var filters = Object.keys(gridColumn.fltr)
      filterList = filters.map(filter => {
        return {
          column: filter
          , value: gridColumn.fltr[filter]
        }
      });
    }

    var rowDataFiltered = rowData;
    if (filterList && filterList.length > 0) {
      filterList.forEach(filter => {
        rowDataFiltered = rowDataFiltered.filter(row => row[filter.column] === filter.value);
      });
    }

    return createValueObject2(0, 0, getAggDiv(rowDataFiltered, params.colDef));
  }
  return createValueObject2(0, 0, '');
}

function aggDivIf(params) {
  const gridColumn = params.colDef;
  var filterList = null;
  var numcol = gridColumn.col;
  var denomcol = gridColumn.col;

  if (params && params.length > 0 && params.values && params.values.length > 0) {
    var rowData = params.rowNode.allLeafChildren.map(value => {
      return value.data;
    });

    if (gridColumn.fltr) {
      var filters = Object.keys(gridColumn.fltr)
      filterList = filters.map(filter => {
        return {
          column: filter
          , value: gridColumn.fltr[filter]
        }
      });
    }

    var rowDataFiltered = rowData;
    if (filterList && filterList.length > 0) {
      rowDataFiltered = rowData;
      filterList.forEach(filter => {
        rowDataFiltered = rowDataFiltered.filter(row => row[filter.column] === filter.value && denomcol && numcol && row[numcol] && row[denomcol]);
      });
      const denomvalue = rowData.reduce((numerator, row) => numerator + row[numcol], 0);
      const numvalue = rowDataFiltered.reduce((denominator, row) => denominator + row[denomcol], 0);
      if (numvalue > 0 && denomvalue > 0)
        return numvalue / denomvalue;
    }
  }
  return '';
}

function wAvgRating(params) {
  var num = 0.0;
  var denom = 0.0;
  const SnPRatingMap = { 'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22 };
  var SnPRatingMapReverse = {};
  Object.keys(SnPRatingMap).forEach(element => {
    SnPRatingMapReverse[SnPRatingMap[element]] = element;
  });
  if (params && params.length > 0) {
    params.rowNode.allLeafChildren.forEach(function (value1) {
      var value = value1.data;
      var val1 = value['rating num'];
      var val2 = value['asset mv'];
      if (val1 && val2 && val1 !== SnPRatingMap['D']) {
        num = num + val1 * val2;
        denom = denom + val2;
      }
    });
  }
  var result = (denom !== 0.0 && num !== 0.0) ? SnPRatingMapReverse[Math.round(num / denom)] : 'NR';
  result = result ? result : 'NR';
  return Utils.createValueObject2(num, denom, result);
}

function isEmpty(value) {
  return (value == null || value.length === 0);
}

function getFieldName(display, agg) {
  var display_temp = display?.replace(")", "").replace("(", "").replace("%", "").replace(".", "").replace("{", "").replace("}", "").replace("[", "").replace("]", "").replace("%", "").replace("$", "").replace(" ", "");
  return `${display_temp}_${agg}`;
}



function getColumnList(columnList) {
  var groupColumnList = columnList;

  if (columnList.length > 1) {
    groupColumnList = [];

    var previousGroupName = '';
    var columnHeader = columnList[0].headerName;
    var index = columnHeader.indexOf(".");
    if (index > -1) {
      previousGroupName = columnHeader.substring(0, index - 1);
      columnHeader = columnHeader.substring(index);
    }

    var previousColumnList = [];
    columnList.forEach((column) => {
      var columnHeader = column.headerName;
      var groupName = "";
      var index = columnHeader.indexOf(".");
      if (index > -1) {
        groupName = columnHeader.substring(0, index);
        columnHeader = columnHeader.substring(index + 1);
        column.headerName = columnHeader;
        column.resizable = true;
        column.suppressSizeToFit = false;
      }
      if (groupName === "") {
        groupColumnList.push(column);
        previousColumnList = [];
      }
      else
        if (previousGroupName !== groupName) {
          previousColumnList = [column];
          groupColumnList.push({ headerName: groupName, children: previousColumnList });
        }
        else {
          previousColumnList.push(column);
        }
      previousGroupName = groupName;
    });
  }

  return groupColumnList;
}

function doesMatchFilter(row, filterList) {
  var match = true;
  filterList.forEach(filter => {
    match = match && (row[filter.column] === filter.value || (Array.isArray(filter.value) && filter.value.includes(row[filter.column])));
  });
  return match;
}


function getDaysArray(start, end) {
  for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
}


function getFormatted(value) {
  return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(0) : value;
}

function getSettingByName(settingList, name) {
  let setting = settingList.filter(
      function (data) {
          return data['value'] === name
      }
  )

  let settingL = setting.length > 0 ? setting[0].childSettings : [];

  settingL.sort(function(a, b) {
      return a.value.localeCompare(b.value);
  })

  return settingL;
}