import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import Moment from 'moment';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, 'rowGroup': true},
            {'display': 'TRS', 'col': 'deal trs mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': true, minWidth : 70, maxWidth : 80, sort: 'asc'},
        ]};
}

export const portfolioOverviewTopTRSBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,pinned: 'left'
        ,resizable: false
        ,hide: true
        ,cellRendererParams:  { suppressCount: true } 
       };    
}

function getRowData(params) {
    return getPositions(params);
}
function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}