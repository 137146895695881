import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Id',                       'col': 'id', 'ag-grid': true, hide:true },
            {'display': 'Project Name',             'col': 'projectName',  'ag-grid': true, minWidth : 230 },
            {'display': 'Co Investor Name',         'col': 'coInvestorName',  'ag-grid': true, minWidth : 230 },
            {'display': 'Status',                   'col': 'status',  'ag-grid': true, minWidth : 230 },
            {'display': 'Analyst 1 Name',           'col': 'analyst1Name',  'ag-grid': true, minWidth : 230 },
            {'display': 'Enter By',                 'col': 'enteredBy',  'ag-grid': true, minWidth : 230 },
            {'display': 'Introduction Date',        'col': 'introductionDate',  'ag-grid': true, 'format': 'datetime', minWidth : 120}, 
            {'display': 'Closing Date',             'col': 'closingDate',  'ag-grid': true, 'format': 'datetime', minWidth : 120}, 
            {'display': 'Currency',                 'col': 'currency',  'ag-grid': true, width : 120 },
            {'display': 'Deal Size',                'col': 'dealSize', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, minWidth : 60 },
            {'display': 'Upfront fee %',            'col': 'upfrontFee',  'ag-grid': true, 'format': 'decimalFormat2', minWidth : 120}, 
            {'display': 'Management fee %',         'col': 'managementFee',  'ag-grid': true, 'format': 'decimalFormat2', minWidth : 120}, 
            {'display': 'Carry Hurdle %',           'col': 'carryHurdle',  'ag-grid': true, 'format': 'decimalFormat2', minWidth : 120}, 
            {'display': 'Carry Participation %',    'col': 'carryParticipation',  'ag-grid': true, 'format': 'decimalFormat2', minWidth : 120}, 
            {'display': 'Comment',                  'col': 'comment',  'ag-grid': true, minWidth : 230 },
        ]};
}

export const coInvestmentBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    getStatusList,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'status',
        minWidth: 100,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/allDealCoinvestment`, requestOptions).then(Utils.handleResponse).then(data => {
        return data;
    });
}

function getStatusList(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/allDealCoStatus`, requestOptions).then(Utils.handleResponse).then(data => {
        return data;
    });
}

function save(params) {
    params = Utils.removeBlanksNotZero(params);
    delete params['file']; 
    delete params['Status']; 
    delete params['analyst1Name']; 
    delete params['addedDate']; 
    delete params['enteredBy']; 
    delete params['updatedDate']; 

    return fetch(`${config.apiDDSUrl}/addDealCoinvestment`, { body: JSON.stringify(params), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

