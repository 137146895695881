import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import Moment from 'moment';
const {config} = require('../config');
const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
            {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Risk Type', 'col': 'Risk Type', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': true, width : 80},
            {'display': 'Capital', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': true, width : 80},

            {'display': 'Stress PNL.Total', 'col': 'asset stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Stress PNL.% Asset', 'numcols': ['asset stress pnl'], 'denomcols': ['asset mv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Stress PNL.% AUM', 'col': 'pnl aum', 'agg': 'sum', 'format': 'percentFormat1', 'ag-grid': True},

            {'display': 'CD Risk.100bp', 'col': 'cs 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'CD Risk.+100%', 'col': 'cswpct_100', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Eq Risk.Delta', 'col': 'eq delta', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Eq Risk.-25%', 'col': 'eq -25', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},

            {'display': 'IR 100bp.Total', 'col': 'ir 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Fixed', 'col': 'ir 100bp', 'fltr': {'is floating': 0}, 'agg': 'sumif', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Floating', 'col': 'ir 100bp', 'fltr': {'is floating': 1}, 'agg': 'sumif', 'format': 'millionsFormat2', 'ag-grid': True},
            
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Stress Type', 'col': 'stress type', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Public/Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': True, hide: True},  
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, hide: True},
        ]};
}

const getColumnListToggle = async (params) => {
    return  {
        'SnPRatingMap': 
            {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Risk Type', 'col': 'Risk Type', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': true, width : 80},
            {'display': 'Capital', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': true, width : 80},

            {'display': 'Stress PNL.Total', 'col': 'asset stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Stress PNL.% Asset', 'numcols': ['asset stress pnl'], 'denomcols': ['asset mv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Stress PNL.% AUM', 'col': 'pnl aum all', 'agg': 'sum', 'format': 'percentFormat1', 'ag-grid': True},

            {'display': 'CD Risk.100bp', 'col': 'cs 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'CD Risk.+100%', 'col': 'cswpct_100', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Eq Risk.Delta', 'col': 'eq delta', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Eq Risk.-25%', 'col': 'eq -25', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},

            {'display': 'IR 100bp.Total', 'col': 'ir 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Fixed', 'col': 'ir 100bp', 'fltr': {'is floating': 0}, 'agg': 'sumif', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Floating', 'col': 'ir 100bp', 'fltr': {'is floating': 1}, 'agg': 'sumif', 'format': 'millionsFormat2', 'ag-grid': True},
            
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Stress Type', 'col': 'stress type', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Public/Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': True, hide: True},  
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, hide: True},
        ]};
}

export const stressSummaryBALService = {
     getPositions
    ,getColumnList
    ,getColumnListToggle
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Risk Type'
        ,field: 'description'
        ,width: 200
        ,pinned: 'left'
        ,resizable: true
        ,sort: 'asc'
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}