import './../../App.css'
import { Dropdown } from 'semantic-ui-react';
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { dealBALService } from '../../bal/deal.bal';
import { assetCommentaryBALService } from '../../bal/assetCommentary.bal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import JoditEditor from "jodit-react";

class AssetCommentaryInput extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isShowInput: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            toDate: moment(today).format("YYYY-MM-DD"),
            fromDate: moment(yesterday).format("YYYY-MM-DD"),
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
            ],
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.getPage = this.getPage.bind(this);
        this.addNew = this.addNew.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.reportModal = this.reportModal.bind(this);
        this.setInput = this.setInput.bind(this);

        dealBALService.getAllDeals().then(data => {
            let dealList = data.filter(function (item) {
                return (item ? true : false);
            }).map(item => { return { key: item.name, value: item.id, text: item.name } });

            this.setState({ dealList: dealList });
        }, error => {
            this.setState({
                showMessage: true,
                messageHeader: 'Error while getting the deals:',
                errorList: [error],
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while getting the deals:'
            });
            console.log(error);
        });

        assetCommentaryBALService.getRowData().then(data => {
            this.setState({
                gridData: data
            })
        });
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    generateReport = async () => {
        let body = {}
        const { fromDate, toDate, fund } = this.state;
        body = { fromDate: fromDate, toDate: toDate, fund: fund }

        this.setState({
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'close',
            modalHeading: 'Report is being generated!'
        });

        assetCommentaryBALService.generateReport(body).then(
            data => {
                this.setState({
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Report generated successfully!'
                });
            }, error => {
                this.setState({
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Error, please contact support!'
                });
            }
        );
    }

    reportModal = () => {
        this.setState({
            modalVisible: true,
            modalType: 'genReport',
            modalHandler: 'close',
            modalHeading: 'Generate Report'
        })
    }

    addNew = async () => {
        const { id, forDate, editorState, deal } = this.state;
        const body = id ? { id: id, deal: deal, comment: editorState, report_date: forDate } : { deal: deal, comment: editorState, report_date: forDate };
        var result = assetCommentaryBALService.addComment(deal, body).then(
            data => {
                this.setState({
                    showMessage: false,
                    errorList: null,
                    messageHeader: "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Saved commentary successfully!',
                    open: false
                });
            }
            , error => {
                this.setState({
                    showMessage: true,
                    errorList: [error],
                    messageHeader: `Error while saving commentary:`,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Saving commentary failed!'
                });
                console.log(error);
            });

        this.setState(prevState => ({
            rowData: [...prevState.rowData, body],
            id: result.id
        }));

        this.refresh();
    }

    refresh() {
        assetCommentaryBALService.getRowData().then(data => {
            this.setState({
                gridData: data
            })
        });
    }

    setInput() {
        const { isShowInput } = this.state;
        this.setState({
            isShowInput: !isShowInput
        })
    }

    handleChange(name, value) {
        console.log(name, value);
        this.setState({ [name]: value });

        if (this.state.deal && value) {
            assetCommentaryBALService.getComment({ deal: this.state.deal, date: value }).then(data => {
                this.setState({
                    id: data.id,
                    editorState: data.comment
                })
            })
        }
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ [name]: value });

        if (this.state.forDate && value) {
            assetCommentaryBALService.getComment({ deal: value, date: this.state.forDate }).then(data => {
                this.setState({
                    id: data.id,
                    editorState: data.comment
                })
            })
        }
    }

    handleRowSelected(e) {
        const { data, action } = e.target;
        if (action === "Edit") {
            this.setState({
                id: data.deal_risk_commentary_id,
                deal: data.deal_id,
                forDate: new Date(data.report_date),
                editorState: data.comment
            })
        }
    }

    getPage() {
        const { modalType, deal, fund, fundList, forDate, fromDate, toDate, editorState, dealList, modalVisible, modalHeading, gridReminderData, gridData, isShowInput, existingDealDetails, newDealDetails } = this.state;
        const isDisabled = !deal || !forDate;

        const config = {
            readonly: isDisabled // all options from https://xdsoft.net/jodit/doc/,
        }
        return (
            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                    {
                        isShowInput
                            ?
                            <div style={{ gridTemplateRows: 'auto auto', display: 'grid', height: '100%', width: '100%' }}>
                                <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                                        Report Date:
                                    </div>
                                    <div style={{ float: "left", marginRight: "15px", marginTop: "12px" }}>
                                        <DatePicker
                                            name="forDate"
                                            selected={forDate ? new Date(forDate) : null}
                                            onChange={(date) => {
                                                this.handleChange('forDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                            filterDate={(date) => {
                                                const day = date.getDay();
                                                return day !== 0 && day !== 6;
                                            }}
                                            maxDate={this.state.maxDate}
                                            autoComplete="off"
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>
                                    <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                                        Deal:
                                    </div>
                                    <div style={{ float: "left", minWidth: "150px", paddingTop: "5px" }}>
                                        <Dropdown
                                            placeholder='Select Values'
                                            name='deal'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={dealList}
                                            value={deal}
                                        />
                                    </div>

                                    <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.reportModal}>
                                        <span className="nav-link login">Generate Report</span>
                                    </div>
                                    <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.refresh}>
                                        <span className="nav-link login">Refresh</span>
                                    </div>
                                    <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.setInput}>
                                        <span className="nav-link login">Hide Input</span>
                                    </div>
                                    <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.addNew}>
                                        <span className="nav-link login">Save Commentary</span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <JoditEditor
                                        value={editorState}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => this.setState({ editorState: newContent })} // preferred to use only this option to update the content for performance reasons
                                        disabled={isDisabled}
                                        hidden={isDisabled}
                                        readonly={isDisabled}


                                        askBeforePasteHTML={false}
                                        askBeforePasteFromWord={false}
                                        defaultActionOnPaste="insert_clear_html"
                                    />
                                </div>
                            </div>
                            :
                            <div style={{ marginTop: "10px" }}>
                                <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.reportModal}>
                                    <span className="nav-link login">Generate Report</span>
                                </div>
                                <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.refresh}>
                                    <span className="nav-link login">Refresh</span>
                                </div>
                                <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.setInput}>
                                    <span className="nav-link login">Show Input</span>
                                </div>
                            </div>
                    }

                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            setAgGridAPI={this.setAgGridAPI}
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}
                            // domLayout="normal"
                            rowData={gridData}
                            className="ag-theme-alpine"
                            // groupDefaultExpanded={-1}
                            // groupIncludeTotalFooter={false}
                            guikey="assetCommentary"
                            gridIdentityColumn="ID"
                            showReject={true}
                            gridTitle="Saved Commentary"
                            gridEditColumn="Edit"
                            handleRowSelected={this.handleRowSelected}
                            isRowSelectable={(node) => {
                                return true;
                            }}
                        />
                    </div>
                </div>


                <Prompt
                    when={this.state.isShowInput === true && JSON.stringify(existingDealDetails) !== JSON.stringify(newDealDetails)}
                    message="Do you want to discard changes?"
                />
                <ModalForm
                    modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={fromDate}
                    toDate={toDate}
                    fundList={fundList}
                    fund={fund}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange}
                />
            </div>
        );
    }
}

export default AssetCommentaryInput;