// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import config from './../../config';
import { Session } from './../../helper/session';
import { Utils } from './../../helper/utils';
import { userBALService } from './../../bal/user.bal';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {

  return (
    <Route {...rest} render={props => {
      // Add your own authentication on the below line.
      const loggedUser = JSON.parse(Session.getItem(config.token));

      if (!loggedUser && props.location.pathname !== '/login') {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      } else if (!loggedUser?.user?.active && props.location.pathname !== '/changepassword') {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/changepassword', state: { from: props.location } }} />
      } 
      
      if (loggedUser?.user && props.location.pathname !== '/login') {           
            
          userBALService.addUserLog(loggedUser.user.id, props.location.pathname).then(result => {
            },
            error => {
              console.log(error.toString());
          });
      }

      // check if route is restricted by role
      if (roles && Utils.getArraysIntersection(roles, loggedUser.userRoles).length === 0 ) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/housing' }} />
      }

      if(props.location.pathname === '/dss'){
        return <Component {...props} guikey={rest.guikey}  gridIdentityColumn={rest.gridIdentityColumn} gridTitle={rest.gridTitle} key = {Math.random()} />
      }

      if(props.location.pathname === '/icApproval'){
        return <Component {...props} guikey={rest.guikey}  gridIdentityColumn={rest.gridIdentityColumn} gridTitle={rest.gridTitle} userRoles = {loggedUser.userRoles} key = {Math.random()} />
      }

      // authorised so return component
      return <Component {...props} guikey={rest.guikey}  gridIdentityColumn={rest.gridIdentityColumn} gridTitle={rest.gridTitle} userRoles = {loggedUser.userRoles} />
    }} />
  )
}

export default PrivateRoute