import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Underlying', 'col': 'Underlying', 'agg': 'uniq', 'ag-grid': true, width : 200},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-20', 'col': '-20', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-10', 'col': '-10', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-5', 'col': '-5', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-4', 'col': '-4', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-3', 'col': '-3', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-2', 'col': '-2', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).-1', 'col': '-1', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).0', 'col': '0', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).1', 'col': '1', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).2', 'col': '2', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).3', 'col': '3', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).4', 'col': '4', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).5', 'col': '5', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).10', 'col': '10', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'P&L Topsheet for FX % Shock (Negative = USD Weakness, Positive = USD Strength).20', 'col': '20', 'agg': 'uniq', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Underlying', 'col': 'Issuer', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Asset Class', 'col': 'AssetClass', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Tag1', 'col': 'Tag1', 'agg': 'uniq', 'ag-grid': true, hide: true},
        ]};
}

export const fxTopsheetBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 250
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}