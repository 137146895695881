const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, minWidth : 120, maxWidth : 140, 'rowGroup': true},
            {displayIndex: 2, 'display': 'Total', 'col': 'pnl total', 'agg': 'sum', 'ag-grid': true, minWidth : 120, maxWidth : 140, 'format': 'decimalFormat0', sort: 'asc' } 
    ]};
}

export const bottomDODpnlBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: '',
        resizable: true,
        width: 250,
        pinned: 'left'
    };
}

function getRowData(params) { 

}

function save(instrumentMarks, isSendForApproval, date, fund) { 
    
}