import { Utils } from '../../helper/utils';
import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom' 
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { covenantInstanceBALService } from '../../bal/covenantInstance.bal'; 
import TorGrid from '../torgrid/torgrid';

class CovenantInstance extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            isSaving : false,
            rowData: [],
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            isShowLoad: false,
            isShowGrid: true,
            includeDate: true,  
            deal: null,
            fund: 'All',
            selectedOption:'Due',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ], 
            covenantFrequencyList: null,
            covenantCategoryList: null,
            covenantTypeList: null,
            newCovenant : {
                id:null,
                dealId:null,
                covenantTypeId : null,
                covenantFrequencyId : null, 
                covenantCategoryId : null,
                startDate : null, 
                endDate : null, 
                daysDue : 0
            }
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.refresh = this.refresh.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.getFormatted = this.getFormatted.bind(this);
        this.updateGridData = this.updateGridData.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.setError = this.setError.bind(this);
        
        this.refresh(this.state.selectedOption);
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(0) : value;
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }
    
    save(rowData) {
        this.setState({isSaving : true, gridData : null});
        rowData = rowData.map(p=>p.data);

        console.log(rowData);
        
        const modifiedRows = rowData.filter(p=>((!Utils.isBlank(p.value1) && Utils.isBlank(p.valueNew1)) || (Utils.isBlank(p.value1) && !Utils.isBlank(p.valueNew1)) || p.value1 !== p.valueNew1 || p.statusObject?.code !== p.covenantStatusId)).map (p =>   {
                    return {
                    ...{id : p.id},
                    ...(p.value1 !== p.valueNew1) && {value: p.valueNew1},                    
                    ...(p.statusObject?.code !== p.covenantStatusId) && {covenantStatusId: p.statusObject?.code},                    
                    } 
                }
            );

        const commentRows = []
        rowData.filter(p=>!Utils.isBlank(p.commentNew)).map(            
            p=> {
                var findItem = commentRows.find((x) => x.id === p.id);
                if (!findItem) commentRows.push({ id : p.id, comment : p.commentNew }); 
                return p;
            }
        );

        const saveInstance = covenantInstanceBALService.save(modifiedRows).then(data => {
            //this.updateGridData(data, this.state.comments, this.state.selectedOption);
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while saving Covenant Instance Data...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while saving Covenant Instance Data!',
                isSaving : false
            }); 
            console.log(error);
        });

        const saveComment = covenantInstanceBALService.saveComment(commentRows).then(data => {
            this.refresh(this.state.selectedOption);
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while saving Covenant Instance Comments...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while saving Covenant Instance Comments!',
                isSaving : false
            }); 
            console.log(error);
        });
        Promise.all([saveInstance, saveComment]);
        console.log(modifiedRows);
        console.log(commentRows);
        
    }

    updateGridData(data, comments, selectedOption) {
        let showGrid = false;
        if(data && data.length > 0) {
            data = data.map(row => { return {
                    ...row, 
                    existingValue: row?.value,
                    statusObject : { name: row.status, code : row.covenantStatusId },
                    valueNew1 : row.value1,
                    commentNew : row.comment,
                    comments : comments?.filter(p => p.covenantInstanceId === row.id),
                    attachments : { path : `covenant~${row.id}`, files : []  } 
                }
            });
            showGrid = true;
        }

        this.setState({
            showMessage: false, 
            gridData: data,
            isShowGrid: showGrid,
            selectedOption : selectedOption,
            isSaving : false
        }, () => {
            console.log(data);
        });        
    }

    setError(error, selectedOption) {
        this.setState({ 
            showMessage: true, 
            selectedOption : selectedOption,
            errorList: [error], 
            messageHeader : `Error while retrieving...`,
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'close',
            modalHeading: 'Error while retrieving Covenants!'
        }); 
        console.log(error);
    }

    refresh(selectedOption) {
        this.setState({ isShowGrid: false, gridData: [] }, () => {
            covenantInstanceBALService.getAllComments().then(comments => {

                covenantInstanceBALService.getRowDataBy(selectedOption).then(data => {
                    this.updateGridData(data, comments, selectedOption);
                }, error => {
                    this.setError(error, selectedOption);
                }); 
            }, error => {
                this.setError(error, selectedOption);
            });
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }
    
    handleDropDownChange(e, { name, value }) {
        this.setState({ newCovenant: { ...this.state.newCovenant, [name]: value } });
    }

    handleChangeNew(name, value) {
        this.setState({
            newCovenant: {
                ...this.state.newCovenant,
                [name]: value 
            }
        });
    }

    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    handleOptionChange (e) {
        //this.setState({           selectedOption: e.target.value         });
        this.refresh(e.target.value);
    }

    getPage() {
        const { isSaving, includeDate, gridData, isShowLoad, isShowGrid, newCovenant, existingCovenant, modalType, modalVisible, modalHeading, gridReminderData } = this.state;
        const {   startDate, endDate } = newCovenant
        return (
        <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%', borderTop: "solid 1px white", position: 'relative' }}>
            <div style ={{ margin: '10px 10px 10px 10px' }}>
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }  
                <label style ={{ margin: '10px 10px 10px 10px' }}>
                    <input type="radio" value="Due" 
                            checked={this.state.selectedOption === 'Due'} 
                            onChange={this.handleOptionChange} />
                    Due
                </label>
                <label style ={{ margin: '10px 10px 10px 10px' }}>
                    <input type="radio" value="DueOrFilled" 
                            checked={this.state.selectedOption === 'DueOrFilled'} 
                            onChange={this.handleOptionChange} />
                    DueOrFilled
                </label>
                <label style ={{ margin: '10px 10px 10px 10px' }}>
                    <input type="radio" value="Filled" 
                            checked={this.state.selectedOption === 'Filled'} 
                            onChange={this.handleOptionChange} />
                    Filled
                </label>
                <label style ={{ margin: '10px 10px 10px 10px' }}>
                    <input type="radio" value="Overdue" 
                            checked={this.state.selectedOption === 'Overdue'} 
                            onChange={this.handleOptionChange} />
                    Overdue
                </label>
                <label style ={{ margin: '10px 10px 10px 10px' }}>
                    <input type="radio" value="All" 
                            checked={this.state.selectedOption === 'All'} 
                            onChange={this.handleOptionChange} />
                    All
                </label>
            </div>

                <div style ={{ margin: 'auto', width: '100%', height: '100%' }} >
                    {
                    isShowGrid ? <TorGrid
                            gridTitle=''
                            sideBar='none'
                            gridIdentityColumn="id"
                            identitySeed={-9999999999999}
                            //showAdd={true}                    
                            rowData={gridData}
                            setAgGridAPI = {this.setAgGridAPI}
                            groupIncludeTotalFooter = {false}
                            guikey = "covenantInstance"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}
                            includeDate = {includeDate}
                            suppressExpandablePivotGroups={true}
                            className="ag-theme-alpine"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="normal"
                            groupIncludeFooter={true}
                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: true
                                    , flex: 1
                                }
                            }
                            dateFormat = 'mm/dd/yy'
                            singleClickEdit = {true}
                            isShowLoad = {isShowLoad}
                            isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                            GridStyle= {{ margin: 'auto', width: '100%', height: '100%' }}
                            isSaving = {isSaving}
                            save = {this.save}
                            showSave = {true}
                            masterDetail= {true}
                            groupDefaultExpanded = {0}
                        />: <div></div>
                    }
                </div>  
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingCovenant) !== JSON.stringify(newCovenant)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={startDate}
                    toDate={endDate}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} /> 
            </div>
        );
    }
}

export default CovenantInstance;