import React, { Component } from 'react';
import TorGrid from '../torgrid/torgrid';

class PositionView extends Component {
  render() {
    return this.getTemplate();
  }

  getTemplate() {
    var groupByColumnList = ['fund'];
    groupByColumnList.push(this.props.GroupBy);

    if(this.props.GroupBy === "gics_sector") {
      groupByColumnList.push("gics_industry_group");
      groupByColumnList.push("gics_industry");
      groupByColumnList.push("gics_subindustry");

    } else if(this.props.GroupBy === "bics_sector") {
      groupByColumnList.push("bics_industry_group");
      groupByColumnList.push("bics_industry");

    } else if(this.props.GroupBy === "credit_ranking") {
      groupByColumnList.push("holdco_opco");

    }

    if(this.props.GroupBy !== "product type") {
      groupByColumnList.push("deal");
      groupByColumnList.push("product type");
    } else {
      groupByColumnList.push("product type");
      groupByColumnList.push("deal");
    }

    return (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <TorGrid
          groupColumns = {groupByColumnList}
          rowData = {this.props.gridData}
          isHideDateInputs={true}
          className="ag-theme-alpine"
          GridTypeSelected = {"Positions Details"}
          guikey="positions1" 
          gridIdentityColumn="positions_id"
        />
      </div>
    );
  }
}

export default PositionView;
