import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

export const profileBALService = {
    saveUserProfile,
    getProfileData
};

function getProfileData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getUserProfile`, requestOptions).then(Utils.handleResponse);
}

function saveUserProfile(value) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(value) };
    return fetch(`${config.apiDDSUrl}/saveUserProfile`, requestOptions).then(Utils.handleResponse);
}
