import React, { Component } from 'react';
import ChartDisplay from "./components/chartDisplay.js"

import { torGridBALService } from '../../bal/torGridBALService.bal';
import TorGrid from './../torgrid/torgrid';

class CashMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: []
        }
    }


    render() {
        return this.getTemplate();
    }

    componentDidMount() {
        const { AsOfDate } = this.props;
        torGridBALService.getRowData('hedgeReport', { query: AsOfDate ? `Exec [sp_get_historical_cash_margin] '${AsOfDate}'` : `Exec [sp_get_historical_cash_margin]` }).then(data => {
            this.setState({ chartData: data });
        });
    }

    componentDidUpdate(prevProps) {
        const { AsOfDate } = this.props;
        if (prevProps.AsOfDate !== AsOfDate) {
            torGridBALService.getRowData('hedgeReport', { query: AsOfDate ? `Exec [sp_get_historical_cash_margin] '${AsOfDate}'` : `Exec [sp_get_historical_cash_margin]` }).then(data => {
                this.setState({ chartData: data });
            });
        }
    }


    getTemplate() {
        const { Fund, AsOfDate } = this.props;
        if (Fund && AsOfDate) {
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: "15px" }}>
                    <div style={{ width: '30%', float: 'left', paddingLeft: "20px" }} >
                        <div style={{ marginTop: "20px", width: '100%' }}>
                            <TorGrid
                                guikey="cashMarginSummary"
                                isHideDateInputs={true}
                                isHideSaveGridLayout={true}

                                query={AsOfDate ? `Exec [sp_get_historical_cash_margin_summary] '${AsOfDate}'` : `Exec [sp_get_historical_cash_margin_summary]`}
                                gridIdentityColumn="position_id"
                                groupIncludeTotalFooter={true}
                                suppressExpandablePivotGroups={true}
                                className="dashboardgrid"
                                rowGroupPanelShow="none"
                                enableFilter={false}
                                domLayout="autoHeight"
                                groupDefaultExpanded = {-1}

                                groupBy = "Fund"

                                defaultColDef={this.props.defaultColDef ??
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                }
                                }

                                handleRowSelected={this.handleRowSelected}
                                sideBar='none'
                                gridTitle='Cash/ Margin Summary Table'
                            />
                        </div>
                    </div>
                    <div style={{ width: '70%', float: 'left', position: 'relative', flexWrap: 'wrap', alignContent: 'left' }} >
                        <div style={{ height: '50%', width: '100%', position: 'relative', marginTop: "20px", marginBottom: "20px" }}>
                            <ChartDisplay
                                chartTitle="Historical Margin"
                                chartType='line-grouped-alt2'
                                xKey='For Report Date'
                                fund={this.props.fund}
                                sumKeys='Margin'
                                yName='Margin'
                                dataList={this.state.chartData}
                                rotationAngle={335}
                            />
                        </div>
                        <div style={{ height: '50%', width: '100%', position: 'relative', marginTop: "20px", marginBottom: "20px" }}>
                            <ChartDisplay
                                chartTitle="Historical Cash"
                                chartType='line-grouped-alt2'
                                xKey='For Report Date'
                                fund={this.props.fund}
                                sumKeys='Cash'
                                yName='Cash'
                                dataList={this.state.chartData}
                                rotationAngle={335}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default CashMargin;