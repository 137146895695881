import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import queryString from 'query-string';
import { approvalBALService } from '../../bal/approval.bal';
import Modal from 'react-awesome-modal';
import ToastMessage from "./../deals/components/toastMessage";

class ApproveReject extends Component {
    constructor(props) {
        super(props);
        
        let params = queryString.parse(this.props.location.search);
        var id = params?.id ?? this.props?.location?.state?.id;
        var action = params?.action ?? this.props?.location?.state?.id;
        var name = params?.name ?? this.props?.location?.state?.name;
        var type = params?.type ?? this.props?.location?.state?.type;

        this.state = {
            id : id,
            action: action,
            name: name,
            type: type,
            display: "none", 
            showMessage: false,
            errorList: null,
            messageHeader : null,
            modalVisible: false,
            modalType: null,
            modalHandler: null,
            modalHeading: null,
            showComment: true,
            approvalRejectionComment : null,
            buttonText : null,
            buttonTextDefault : 'Add Comment',
            redirectTo : null
        }
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.approveReject = this.approveReject.bind(this);
        this.closeErrorMessage = this.closeErrorMessage.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target; 
        this.setState({  
                [name]: value 
        }); 
    }

    render() {
        if(this.state.redirectTo)
          return <Redirect to={ this.state.redirectTo } />;
        return this.getTemplate();
    }

    componentDidMount() {
        this.approveReject();
    }

    approveReject () {
        const { id, action, type, approvalRejectionComment, name, buttonText, buttonTextDefault } = this.state;
        if(id && action && type) {
            approvalBALService.approve({id:id, type:type, action:action, comment:approvalRejectionComment}).then(data => {
                this.setState({ 
                    showMessage: false,
                    errorList: null,
                    messageHeader : null,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: buttonText === buttonTextDefault ? 'Comment has been added' : `${type}:${name} id:${id} is ${action}`,
                    showComment: buttonText !== buttonTextDefault,
                    redirect:buttonText === buttonTextDefault,
                    buttonText: buttonTextDefault,
                });
            }, error => {
                let msg = `Error ${action==='approved'? 'approving' : action==='rejected'? 'rejecting' : action} ${type}:${name} id:${id}:`;
                this.setState({ 
                    showMessage: true, 
                    errorList: [error], 
                    messageHeader : msg,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    showComment: true,
                    modalHeading: msg,
                    buttonText: (action==='approved'? 'Approve' : action==='rejected'? 'Reject' : action)
                }); 
                console.log(error); });
        } else {
            let errorMessage = `Invalid request ${action} ${type}:${name} id:${id}`;
            this.setState({ 
                showMessage: true, 
                errorList: [], 
                messageHeader : errorMessage,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: errorMessage,
                showComment: false
            }); 
        }
    }
    
    handleModalChange() {
        this.setState({
            modalVisible: false
        });
    }

    closeModal() {
        const { id, action, type, redirect, modalHandler } = this.state;
        if (modalHandler === 'refresh') {
            window.location.reload();
        }

        const paths = {'CreditProposal' : '/icApproval', 'InstrumentMark' : '/markingTool' };

        this.setState({
            modalVisible: false,
            ...(redirect) && { redirectTo: {pathname : paths[type], state : {id : id, action : action, type:type } } }
        });
    }

    closeErrorMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    getTemplate() {
        const { id, name, action, type, showComment, approvalRejectionComment, modalHeading, showMessage, messageHeader, errorList, buttonText } = this.state;
        return (
            <div  style={{ padding: "20px 20px" }} >
                {   
                    showMessage ?
                        <ToastMessage
                            header={messageHeader}
                            errorList={errorList}
                            closeMessage={this.closeErrorMessage}
                        />
                    : null
                }
                {
                    showComment ? 
                        <div style={{ padding: '0px 20px 15px 20px', height: 'auto'}}>
                            <h3>{type}: {name}<br/>id:{id}<br/>{action}</h3>
                            <b>Optional - {action==='approved'? 'Approval' : action==='rejected'? 'Rejection' : action} comment(if any):</b>
                            <textarea
                                rows="3"
                                placeholder={action==='approved'? 'Approval Comment' : action==='rejected'? 'Rejection Comment' : action} 
                                name='approvalRejectionComment'
                                onChange={this.handleChange}
                                value={approvalRejectionComment}
                                autoComplete="off"
                                className={'form-control input'}
                            /><br/>
                            <button disabled={approvalRejectionComment === undefined || approvalRejectionComment === null} key='approveButton' className="blueB-no-margin" onClick={() => this.approveReject()}>{buttonText}</button>
                        </div>
                    :<tag></tag>
                }
                <div >
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "500px", height: "320px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "270px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                                :
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default ApproveReject;
