import React, { Component } from 'react';
// import history from '../../history/history';
import config from './../../config';
import './login.css';
import LOGO from '../../images/TOR_LOGO_430_2965.png';
import { Session } from './../../helper/session';
import { userBALService } from '../../bal/user.bal';
import { message } from 'antd'
import { Utils } from '../../helper/utils';
const publicIp = require('public-ip');

class Login extends Component {
  constructor(props) {
    super(props);
    this.timeout = 250;
    this.mounted = false;
    this.state = {
      username: '',
      password: '',
      token: config.token,
      showHide: 'alert alert-danger alert-dismissible fade',
      error_username: null,
      error_password: null,
      error_otp: null,
      Data: {
        __MESSAGE__: 'login',
        key: 'USER_AUTHENTICATION',
        username: '',
        password: '',
        GUID: ''
      },
      userInfo: null,
      display: "none",
      ws: null,
      showOtpSection: false,
      otp: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidateOtp = this.handleValidateOtp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.loginConfirmed = this.loginConfirmed.bind(this);
    this.getPublicIP = this.getPublicIP.bind(this);
    this.validateLoginOtp = this.validateLoginOtp.bind(this); 

  }

  getPublicIP = async () => {
    var ipAddress = await publicIp.v4();
    this.setState({ ipAddress: ipAddress });
  }

  componentWillUnmount() {
  }

  connectServer = () => {
  };

  check = () => {
  };

  handleClose() {
    var _showHide = this.state.showHide === 'alert alert-danger alert-dismissible fade show' ? 'alert alert-danger alert-dismissible fade' : 'alert alert-danger alert-dismissible fade show';
    if (this.mounted) {
      this.setState({ showHide: _showHide });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    if (username && password) {
      this.login(username, password);
    }
  }

  handleValidateOtp(e) {
    e.preventDefault();
    const { username, otp } = this.state;
    if (username && otp) {
      this.validateLoginOtp(username, otp);
    }
  }

  loginConfirmed() {
    localStorage.setItem('loginStatus', 'login');
    Session.setItem(config.token, JSON.stringify(this.state.userInfo));
    //this.props.history.push('/home');

    const { location } = this.props;
    const { state } = location;

    /* Here place request login api   // go to state.from if set before */

    let pathto = Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'Ops', 'Finance', 'Finance', 'Compliance', 'Legal'], this.state.userInfo.userRoles) ? '/dss' : '/housing';

    const newpath = state?.from ?? pathto;
    this.props.history.replace(newpath);
    document.getElementById('userDisplay').click('');
  }

  login(_username, _password) {
    userBALService.login(_username, _password, this.state.ipAddress).then(_user => {
        if(_user?.user?.isOutsideNetwork) {
          this.setState({ userInfo: _user, showOtpSection: true, error_message : '' });
        } else {
          this.resetState();
          this.setState({ userInfo: _user }, () => { this.loginConfirmed(); });
        }
      },
        error => {
          this.error(error.toString());
          console.log(error.toString()); 
          this.setState({ showHide: 'alert alert-danger alert-dismissible fade show', error_message : error });
        }
    );
  }

  validateLoginOtp(_username, _otp) {
    userBALService.validateLoginOtp(_username, _otp).then(_user => {
      console.log(_user);
      this.setState({ userInfo: _user }, () => { this.loginConfirmed(); });
      },
        error => {
          this.error(error.toString());
          console.log(error.toString());
          this.setState({ showHide: 'alert alert-danger alert-dismissible fade show', error_message : error });
        }
    );
  }

  error(_error) {
    message.error(_error);
  };

  logout() {
    userBALService.logout();
    document.getElementById('userDisplay').click();
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.ipAddress !== '82.150.104.2' && this.state.ipAddress !== '82.150.107.2') {
      this.getPublicIP();
    }

    try {
      if (Session.getItem(config.token) && JSON.parse(Session.getItem(config.token))) {
        Session.removeItem(config.token);
        let userDisplay = document.getElementById('userDisplay');
        if(userDisplay)
          userDisplay.click();
      }
    } catch (error) {
      Session.removeItem(config.token);
      let userDisplay = document.getElementById('userDisplay');
      if(userDisplay)
        userDisplay.click();
    }
    this.connectServer();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      ['error_' + name]: value
    });
  }
  handleForgotPassword(e) {
    this.props.history.replace('/forgotPassword');
  }

  resetState() {
    if (this.mounted) {
      this.setState({
        username: '',
        password: '',
        error_username: null,
        error_password: null
      });
    }
  }

  render() {
    return this.showPage();
  }

  showPage() {
    const { username, password, error_username, error_password, error_message, showOtpSection, otp, error_otp } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 bColor">
            <div className='login-left'>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className={this.state.showHide} role="alert">
                  {error_message}
                  <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleClose}>
                    <span aria-hidden="true">&times;</span>
                  </span>
                </div>
                
                { 
                  !showOtpSection ? <div>
                  <span className='login-lable'>LOGIN</span>
                <div className="spacer">
                  <input type="text"
                    placeholder="Windows Username"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                    autoComplete="off"
                    className={'form-control ' + (error_username ? 'is-valid' : 'is-invalid')}
                  />
                </div>
                <div className="spacer">
                  <input type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    className={'form-control ' + (error_password ? 'is-valid' : 'is-invalid')}
                  />
                <div style={ {width:'100%' , float:'right'} }>
                  {/*
                  <a id="forgotPassword" href="#" title="Click to generate new password" onClick={this.handleForgotPassword}>Forgot Password ?</a>
                  */}
                  <button
                    type="button"
                    className="link-button"
                    onClick={this.handleForgotPassword}>
                      Forgot Password ?
                  </button>
                </div>
                </div>
                <div className="spacer">
                  <input className='login-button' type="submit" value="Login" onClick={this.handleSubmit} />
                </div>
                </div> : 
                <div>
                  <span className='login-lable'>Enter login OTP sent to your official email:</span>
                  <div className="spacer">
                    <input type="text"
                      placeholder="Otp"
                      name="otp"
                      value={otp}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className={'form-control ' + (error_otp ? 'is-valid' : 'is-invalid')}
                    />
                  </div>
                  <div className="spacer">
                    <input className='login-button' type="submit" value="Validate Otp" onClick={this.handleValidateOtp} />
                  </div>
                </div>
                }
              </form>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div className = "login-right">
              <img src = {LOGO} alt="Go"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
