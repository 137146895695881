import React, { Component } from 'react';
import TorGrid from '../../torgrid/torgrid';
import ChartDisplay from "../../portfolioDashboard/components/chartDisplay.js";

class DealOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: []
    }

  }

  render() {
    return this.getTemplate();
  }

  componentDidMount() {
    const {AsOfDate, chartData} = this.props;

    let chartDataFiltered = [];

    if(AsOfDate) {
      chartDataFiltered = chartData.filter(row => new Date(row['For Report Date']) <= new Date(AsOfDate));
    }

    this.setState({
      chartData: chartDataFiltered
    });
  }

  componentDidUpdate(prevProps) {
    const {Deal, Fund, AsOfDate, chartData} = this.props;

    if(prevProps.Deal !== Deal || prevProps.AsOfDate !== AsOfDate || prevProps.chartData !== chartData) {
      let chartDataFiltered = [];

      if(AsOfDate) {
        chartDataFiltered = chartData.filter(row => new Date(row['For Report Date']) <= new Date(AsOfDate));
      }

      this.setState({
        chartData: chartDataFiltered
      });
    } else if (prevProps.Fund !== Fund) {
      let chartDataFiltered = [];

      if(AsOfDate) {
        chartDataFiltered = chartData.filter(row => new Date(row['For Report Date']) <= new Date(AsOfDate));
      }

      if(Fund && Fund !== 'All') {
        chartDataFiltered = chartDataFiltered.filter(row => row['Fund'] === Fund);
      }

      this.setState({
        chartData: chartDataFiltered
      });
    }
  }


  getTemplate() {
    if((this.props.gridData && this.props.gridData.length > 0) || (this.state.chartData && this.state.chartData.length > 0)) {
      return (
        <div style={{ height: '100%', width: '100%', paddingTop: '15px' }}>
          <div style = {{margin: 'auto'}}>
            <TorGrid
              guikey="dealDashboardSummary"
              isHideDateInputs={true}
              isHideSaveGridLayout={true}
  
              rowData={this.props.gridData}
              groupBy='deal'
  
              gridIdentityColumn="position_id"
              className="topDisplayGrid"
              rowGroupPanelShow="none"
              enableFilter={false}
              domLayout="autoHeight"
  
              defaultColDef={this.props.defaultColDef ??
              {
                sortable: false
                , filter: false
                , floatingFilter: false
                , enableRowGroup: true
                , resizable: true
                , suppressSizeToFit: false
                , flex: 1
              }
              }
              handleRowSelected={this.handleRowSelected}
              sideBar='none'
              gridTitle=''
            />
          </div>
          <div style={{ paddingTop: '50px' }}>
            <TorGrid
              guikey="dealDashboard"
              isHideDateInputs={true}
              isHideSaveGridLayout={true}
  
              rowData={this.props.gridData}
              groupBy='fund'
              GridWidth='1450px'
  
              gridIdentityColumn="position_id"
              className="dashboardgrid nototal"
              rowGroupPanelShow="none"
              enableFilter={false}
              domLayout="autoHeight"
  
              defaultColDef={this.props.defaultColDef ??
              {
                sortable: false
                , filter: false
                , floatingFilter: false
                , enableRowGroup: true
                , resizable: true
                , suppressSizeToFit: false
                , flex: 1
              }
              }
              handleRowSelected={this.handleRowSelected}
              sideBar='none'
              gridTitle=''
            />
          </div>
          <div style={{ paddingTop: '50px' }}>
            <ChartDisplay
              chartTitle="MV History"
              chartType='bar-grouped'
              xKey='For Report Date'
              yKeys={['Net MV', 'Gross MV']}
              dataList={this.state.chartData}
              rotationAngle = {335}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
    

  }
}

export default DealOverview;