import fileDownload from 'js-file-download'
/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { icApprovalBALService } from '../../bal/icApproval.bal';
import { approvalBALService } from '../../bal/approval.bal';

import { dealBALService } from '../../bal/deal.bal';
import { Prompt } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { Dropdown } from 'semantic-ui-react';
import { faMinusSquare, faPlusSquare, faTrashAlt, faFileDownload, faBell } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';
import {  faCheck, faSave } from '@fortawesome/free-solid-svg-icons';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faHome, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import ApprovalGrid from "../approval/approvalGrid";
import { s3BALService } from '../../bal/s3.bal';

const type = 'CreditProposal';
class IcApproval extends Component { 
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === type.toLowerCase() });
        const isRiskRole = user?.userRoles?.filter(item => item && item.toLowerCase() === 'risk');

        this.state = { 
            rowData: [],
            isRiskRole: isRiskRole?.length>0,
            isOpenGrid: true, 
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            existingDeal: {
                id: null,
                proposalDate: moment(new Date()).format("YYYY-MM-DD"), 
                approvalCondition: null, 
                transactionSizeMax: null, 
                sizeApproved: null, 
                tacfAllocation: null, 
                tifoAllocation: null, 
                tacoiiAllocation: null, 
                tacoiiiAllocation: null, 
                creditProposalName: null,
                coinvest1: null, 
                coinvest2: null,
                coinvest3: null,
                tacfIsdollar: true,
                tifoIsdollar: true,
                tacoiiIsdollar: true,
                tacoiiiIsdollar: true,
                co1Isdollar: true,
                co2Isdollar: true,
                co3Isdollar: true,
                coInvestor1Name: null,
                coInvestor2Name: null,
                coInvestor3Name: null,
                analyst1Id: null,
                analyst2Id: null,
                analyst3Id: null,
                approvalRejectionComment: null,
                approver_status: null,
                status: null,
                file: null,
                fileName: null,
            },
            newDeal: {
                id: null,
                proposalDate: moment(new Date()).format("YYYY-MM-DD"), 
                approvalCondition: null, 
                transactionSizeMax: null, 
                sizeApproved: null, 
                tacfAllocation: null, 
                tifoAllocation: null, 
                tacoiiAllocation: null, 
                tacoiiiAllocation: null, 
                creditProposalName: null,
                coinvest1: null, 
                coinvest2: null,
                coinvest3: null,
                tacfIsdollar: true,
                tifoIsdollar: true,
                tacoiiIsdollar: true,
                tacoiiiIsdollar: true,
                co1Isdollar: true,
                co2Isdollar: true,
                co3Isdollar: true,
                coInvestor1Name: null,
                coInvestor2Name: null,
                coInvestor3Name: null,
                analyst1Id: null,
                analyst2Id: null,
                analyst3Id: null,
                approvalRejectionComment: null,
                approver_status: null,
                status: null,
                file: null,
                fileName: null,
            },
            analystList: [],
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            open:false
        };
        this.refresh = this.refresh.bind(this);
        //this.resetFields = this.resetFields(this);
        this.addNew = this.addNew.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveDB = this.saveDB.bind(this);
        this.saveData = this.saveData.bind(this);
        this.sendReminder = this.sendReminder.bind(this);
        this.handleSendReminder = this.handleSendReminder.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleChangeCreditProposal = this.handleChangeCreditProposal.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.approvalRejection = this.approvalRejection.bind(this);
        this.actionApproved = this.actionApproved.bind(this);
        this.actionRejected = this.actionRejected.bind(this);
        this.approveReject = this.approveReject.bind(this);

        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.setAgGridAPI2 = this.setAgGridAPI2.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.loadAnalysts = this.loadAnalysts.bind(this);
        this.download = this.download.bind(this);
        this.remove = this.remove.bind(this);
        this.setFile = this.setFile.bind(this);
        //getFormatted = getFormatted(this);
        this.loadAnalysts();
        this.refresh(null, true);
    }

    loadAnalysts() {
        dealBALService.getAnalysts().then(data => { 
            const { user, newDeal } = this.state;
            var analyst1Id = newDeal.analyst1Id ?? (data.filter((e) => e.id === user.id) ? user.id : null );
            this.setState({ 
                    analystList: data.map(item => { return { key: item.name, value: item.id, text: item.name }}),
                    newDeal: {
                        ...newDeal,
                        analyst1Id : analyst1Id
                    }
                })
            }, error => { 
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : "Error while getting the analyst data :",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: 'Analyst data could not be retrieved, please contact support!'
                    }); 
                    console.log(error);
            }
        );
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    refresh(e, modalVisible = false) {
        icApprovalBALService.getRowData().then(data => {
            let isApprover = this.state.isApprover;
            let inApproval = [];
            if(isApprover) {
                inApproval = data.filter(p=> p.approver_status === 'In Approval');                
            }
            this.setState({ 
                gridData: data,
                inApproval: inApproval?.length > 1,
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Grid data has been refreshed!'
                }
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the grid data :",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true, modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.saveDB(null, false);
            this.setState({isOpenGrid: true, modalVisible: false});
        } else {
            this.setState({isOpenGrid: true, modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }
    
    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    } 

    setAgGridAPI2(api) {
        this.gridApi2 = api;
        this.gridApprovalApi2 = api;
    } 

    resetFields(isOpenGrid = null) {
        this.setState({
            ...(isOpenGrid) && { isOpenGrid: isOpenGrid },
            newDeal: {
                id: null, 
                proposalDate: moment(new Date()).format("YYYY-MM-DD"),
                approvalCondition: null, 
                transactionSizeMax: null, 
                sizeApproved: null, 
                tacfAllocation: null, 
                tifoAllocation: null, 
                tacoiiAllocation: null, 
                tacoiiiAllocation: null, 
                creditProposalName: null,
                coinvest1: null, 
                coinvest2: null,
                coinvest3: null,
                tacfIsdollar: true,
                tifoIsdollar: true,
                tacoiiIsdollar: true,
                tacoiiiIsdollar: true,
                co1Isdollar: true,
                co2Isdollar: true,
                co3Isdollar: true,
                coInvestor1Name: null,
                coInvestor2Name: null,
                coInvestor3Name: null,
                analyst1Id: null,
                analyst2Id: null,
                analyst3Id: null,
                approvalRejectionComment: null,
                approver_status: null,
                status: null,
                file: null,
                fileName: null,
            },
            existingDeal: {
                id: null, 
                proposalDate: moment(new Date()).format("YYYY-MM-DD"),
                approvalCondition: null, 
                transactionSizeMax: null, 
                sizeApproved: null, 
                tacfAllocation: null, 
                tifoAllocation: null, 
                tacoiiAllocation: null, 
                tacoiiiAllocation: null, 
                creditProposalName: null,
                coinvest1: null, 
                coinvest2: null,
                coinvest3: null,
                tacfIsdollar: true,
                tifoIsdollar: true,
                tacoiiIsdollar: true,
                tacoiiiIsdollar: true,
                co1Isdollar: true,
                co2Isdollar: true,
                co3Isdollar: true,
                coInvestor1Name: null,
                coInvestor2Name: null,
                coInvestor3Name: null,
                analyst1Id: null,
                analyst2Id: null,
                analyst3Id: null,
                approvalRejectionComment: null,
                approver_status: null,
                status: null,
                file: null,
                fileName: null,
            }
        });
    }

    addNew() {
        this.resetFields();
        this.setState({
            isOpenGrid : false
        });
    }

    approveReject = (action) => {
        const {newDeal, num} = this.state;
        const {id, approvalRejectionComment, creditProposalName } = newDeal;
        if(id) {
            approvalBALService.approve({id : id, type : type, action: action, comment: approvalRejectionComment })
            .then(
                data => {
                        this.setState({
                            showMessage: false, 
                            errorList: null, 
                            messageHeader : "",
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'save',
                            modalHeading: `${action} ${creditProposalName} successfully!`,
                            open: false,
                            newDeal: {
                                ...newDeal,
                                approvalRejectionComment: null,
                                approver_status : action
                            },
                            existingDeal :  {
                                ...newDeal,
                                approvalRejectionComment: null,
                                approver_status : action
                            },
                            num : num + 1
                        });
                        this.refresh(null, true);
                    }
                    , error => {
                        this.setState({ 
                            showMessage: true, 
                            errorList: [error], 
                            messageHeader : `Error while ${action} ${creditProposalName}:`,
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'save',
                            modalHeading: `${action} ${creditProposalName} failed!`
                        }); 
                        console.log(error);
                    this.refresh(null, true);
                }
            );
        }
    }
    
    actionApproved() {
        this.approveReject('approved');
    }

    actionRejected() {
        this.approveReject('rejected');
    }

    approvalRejection = (action) => {
        const selectedRows = this.gridApi?.getSelectedNodes();
        if(selectedRows?.length > 0) {
            const proposals = selectedRows.map(deal => {return {id: deal.data['id'], type: type, action: action} });

            var promises = approvalBALService.approveGrid(proposals);
            Promise.all(promises).then(data => {
                    //var d = JSON.parse(data);
                    var errorList = data.filter(element => element.error).map(element => { return element.error });
                    if(errorList && errorList.length > 0)
                        this.setState({ 
                            showMessage: true, 
                            errorList : errorList,
                            messageHeader : `Error while ${ action.toLowerCase().startsWith('approve') ? 'approving' : action.toLowerCase().startsWith('reject') ? 'rejecting' : action } the request :`, 
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'save',
                            modalHeading: `${action} failed, please contact support!`
                        });
                    else {
                        this.setState({ 
                            showMessage: true, 
                            errorList : [], 
                            messageHeader : `${action} successfully!!!`, 
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'save',
                            modalHeading: `${action} sucessfully!`
                        });
                    }
                    this.refresh(null, true);
                }
            );
        } else {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: `First select proposal(s) to ${action?.toLowerCase().startsWith('approve') ? 'Approve' : action?.toLowerCase().startsWith('reject') ? 'Reject' : action  }`
            })
        }
    }


    approve() {
        this.approvalRejection('approved');
    }

    reject() {
        this.approvalRejection('rejected');
    }

    saveData(isSendForApproval, fileNameUploaded) {
        const { newDeal } = this.state;
        const { co1Isdollar, co2Isdollar, co3Isdollar, tacfIsdollar, tifoIsdollar, tacoiiIsdollar, tacoiiiIsdollar, tacfAllocation, tifoAllocation, tacoiiAllocation, tacoiiiAllocation, coinvest1, coinvest2, coinvest3} = newDeal;
        icApprovalBALService.save({
            ...newDeal,
            [tacfIsdollar ? 'tacfAmount' : 'tacfPercentage'] : tacfAllocation,
            [tifoIsdollar ? 'tifoAmount' : 'tifoPercentage'] : tifoAllocation,
            [tacoiiIsdollar ? 'tacoiiAmount' : 'tacoiiPercentage'] : tacoiiAllocation,
            [tacoiiiIsdollar ? 'tacoiiiAmount' : 'tacoiiiPercentage'] : tacoiiiAllocation,
            [co1Isdollar ? 'coinvest1Amount' : 'coinvest1Percentage'] : coinvest1,
            [co2Isdollar ? 'coinvest2Amount' : 'coinvest2Percentage'] : coinvest2,
            [co3Isdollar ? 'coinvest3Amount' : 'coinvest3Percentage'] : coinvest3,
            
            [!tacfIsdollar ? 'tacfAmount' : 'tacfPercentage'] : null,
            [!tifoIsdollar ? 'tifoAmount' : 'tifoPercentage'] : null,
            [!tacoiiIsdollar ? 'tacoiiAmount' : 'tacoiiPercentage'] : null,
            [!tacoiiiIsdollar ? 'tacoiiiAmount' : 'tacoiiiPercentage'] : null,
            [!co1Isdollar ? 'coinvest1Amount' : 'coinvest1Percentage'] : null,
            [!co2Isdollar ? 'coinvest2Amount' : 'coinvest2Percentage'] : null,
            [!co3Isdollar ? 'coinvest3Amount' : 'coinvest3Percentage'] : null,
            'submitted': isSendForApproval,
            'fileName' : fileNameUploaded
        }).then(
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: isSendForApproval ? 'Sent for Approval successfully!' : 'Data has been saved successfully!',
                        existingDeal : {
                            ...newDeal,
                            id: data.id,
                            status: isSendForApproval? 'In Approval' : 'Draft',
                            fileName : fileNameUploaded
                        },
                        newDeal : {
                            ...newDeal,
                            id: data.id,
                            status: isSendForApproval? 'In Approval' : 'Draft',
                            fileName : fileNameUploaded
                        },                                
                    });
                    this.refresh(null, true);
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : "Error while saving the data :",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: isSendForApproval ? 'Error sending for approval, please contact support!' : 'Data could not be saved, please contact support!'
                    }); 
                    console.log(error); 
                this.refresh(null, true);
            }
        );            
    }

    handleSendReminder(e) {        
        this.setState({ 
            showMessage: false, 
            errorList: [], 
            messageHeader : '',
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'close',
            modalHeading: 'Sending reminder...'
        });      
        approvalBALService.sendReminder({type : type}).then(data => {
            this.setState({ 
                showMessage: false, 
                errorList: [], 
                messageHeader : '',
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Reminder sent!'
            });                
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while sending reminder!",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Error sending reminder!'
            }); 
        }
        );
    }

    sendReminder(e) {
        this.setState({ 
            showMessage: false, 
            errorList: [], 
            messageHeader : '',
            modalVisible: true,
            gridReminderData : [],
            modalType: 'datagrid',
            modalHandler: 'close',
            modalHeading: 'Getting pending approvals...'
        });   
        approvalBALService.getPendingApproval({type : type}).then(data => {
            this.setState({ 
                showMessage: false, 
                errorList: [], 
                messageHeader : '',
                modalVisible: true,
                gridReminderData : data,
                modalType: 'datagrid',
                modalHandler: 'save',
                modalHeading: 'Send reminder for below?'
            });              
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error getting pending approvals!",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Error getting pending approvals!'
            }); 
        }
        );
    }

    saveDB(e, isSendForApproval=false) {
        const { newDeal, existingDeal } = this.state;
        const { analyst1Id, proposalDate, co1Isdollar, co2Isdollar, co3Isdollar, tacfIsdollar, tifoIsdollar, tacoiiIsdollar, tacoiiiIsdollar, sizeApproved, tacfAllocation, tifoAllocation, tacoiiAllocation, tacoiiiAllocation, coinvest1, coinvest2, coinvest3, coInvestor1Name, coInvestor2Name, coInvestor3Name, creditProposalName, status, file, fileName} = newDeal;
        var errorList = [];
        
        if(((isSendForApproval && status && status !=='Draft') || !isSendForApproval) && JSON.stringify(existingDeal) === JSON.stringify(newDeal)) {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'No changes to save or submit for approval!',
            })
        } else {
            if(!creditProposalName) {
                errorList.push("'Name' cannot be blank");
            }
            if(!analyst1Id) {
                errorList.push("'Lead Analyst' cannot be blank");
            }

            if(isSendForApproval) {
                if(!fileName && !file) {
                    errorList.push('IC Memo not uploaded');
                }

                if(coInvestor1Name && coInvestor1Name === coInvestor2Name) {
                    errorList.push(`'Co-Investor [1] Name' & 'Co-Investor [2] Name' cannot be same :${coInvestor1Name}`);
                }
                if(coInvestor1Name && coInvestor1Name === coInvestor3Name) {
                    errorList.push(`'Co-Investor [1] Name' & 'Co-Investor [3] Name' cannot be same :${coInvestor1Name}`);
                }
                if(coInvestor2Name && coInvestor3Name === coInvestor2Name) {
                    errorList.push(`'Co-Investor [2] Name' & 'Co-Investor [3] Name' cannot be same :${coInvestor2Name}`);
                }

                if(coinvest1 && !coInvestor1Name) {
                    errorList.push("'Co-Investor [1] Name' cannot be blank");
                }
                if(coinvest2 && !coInvestor2Name) {
                    errorList.push("'Co-Investor [2] Name' cannot be blank");
                }
                if(coinvest3 && !coInvestor3Name) {
                    errorList.push("'Co-Investor [3] Name' cannot be blank");
                }

                if(coInvestor1Name && !coinvest1) {
                    errorList.push(`'Co-Investor [1] ${co1Isdollar ? 'amount':'percentage'}' cannot be blank`);
                }

                if(coInvestor2Name && !coinvest2) {
                    errorList.push(`'Co-Investor [2] ${co2Isdollar ? 'amount':'percentage'}' cannot be blank`);
                }

                if(coInvestor3Name && !coinvest3) {
                    errorList.push(`'Co-Investor [3] ${co3Isdollar ? 'amount':'percentage'}' cannot be blank`);
                }

                if(!proposalDate) {
                    errorList.push("'Proposal Date' is not selected");
                }
                
                if(!tacfAllocation && !tifoAllocation && !tacoiiAllocation && !tacoiiiAllocation) {
                    errorList.push(`Please add fund allocations`);
                } else {
                    let sumallocation = (tacfAllocation ?? 0) + (tifoAllocation ?? 0) + (tacoiiAllocation ?? 0) + (tacoiiiAllocation ?? 0);

                    if(!tacfIsdollar && !tifoIsdollar && !tacoiiIsdollar && !tacoiiiIsdollar && sumallocation !== 100) {
                        errorList.push(`Fund allocation percentage sum : ${sumallocation}% should be 100%`);

                    } else if(tacfIsdollar && tifoIsdollar && tacoiiIsdollar && tacoiiiIsdollar && sumallocation !== sizeApproved) {
                        errorList.push(`Fund allocation sum : $${sumallocation}M should be equal to 'Size Approved' : $${sizeApproved}M`);
                    }
                }

                let sumcoinvest = (coinvest1 ? parseFloat(coinvest1) : 0) + (coinvest2 ? parseFloat(coinvest2) : 0) + (coinvest3 ? parseFloat(coinvest3) : 0);
                if(!co1Isdollar && !co2Isdollar && !co3Isdollar && sumcoinvest > 100) {
                    errorList.push(`Co-investor percentage sum : ${sumcoinvest}% should be less than or equal to 100%`);
                }       
            }

            if(file && !fileName) {
                const sizeFile = (file.size / 1024 / 1024).toFixed(2);
                // The size of the file.
                if (sizeFile >= 20) {
                        errorList.push(`File size ${sizeFile} MB is too big, please select a file less than 20 MB`);
                }
            }

            if(errorList.length > 0) {
                this.setState({ 
                    showMessage: true, 
                    errorList: errorList,
                    messageHeader : "Please fix below errors:",
                });
            } else {
                this.setState({                         
                    showMessage: false,
                    errorList: null,
                    messageHeader : "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: isSendForApproval ? 'Sending for approval...' : 'Data save in progress...'
                });

                let fileNameUploaded = fileName;
                if(file && !fileName) {
                    s3BALService.uploadFile({file: file}).then(data => {
                            fileNameUploaded = data.fileName;
                            this.saveData(isSendForApproval, fileNameUploaded);
                        }, error => {
                            this.setState({ 
                                showMessage: true, 
                                errorList: [error], 
                                messageHeader : "Error while saving the data :",
                                modalVisible: true,
                                modalType: 'close',
                                modalHandler: 'save',
                                modalHeading: isSendForApproval ? 'Error sending for approval, please contact support!' : 'Data could not be saved, please contact support!'
                            }); 
                        }
                    );
                }
                else {
                    this.saveData(isSendForApproval, fileNameUploaded);
                }
            }
        }
    }
    
    handleChange(e) {
        const { name, value } = e.target; 
        this.setState({ 
            newDeal: {
                ...this.state.newDeal,
                [name]: value
            }
        }); 
    }

    handleHome() {
        const { existingDeal, newDeal } = this.state;
        if(JSON.stringify(existingDeal) !== JSON.stringify(newDeal)) {
            this.setState({
                modalVisible: true,
                modalType: 'saveChanges',
                modalHandler: 'discard',
                modalHeading: 'Do you want to discard changes?'
            })
        } else {
            this.resetFields(true);            
        }
    }
    
    handleChangeCreditProposal(e, data) {
        const { tacfAmount, tifoAmount, tacoiiAmount, tacoiiiAmount, tacfPercentage, tifoPercentage, tacoiiPercentage, tacoiiiPercentage, coinvest1Amount, coinvest2Amount, coinvest3Amount, coinvest1Percentage, coinvest2Percentage, coinvest3Percentage  } = data;
        this.setState({
            isOpenGrid: false,
            showMessage: false, 
            errorList: null, 
            messageHeader : "",
            modalVisible: false,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: null,
            existingDeal: {
                ...data,
                tacfAllocation: tacfAmount ?? tacfPercentage, 
                tifoAllocation: tifoAmount ?? tifoPercentage, 
                tacoiiAllocation: tacoiiAmount ?? tacoiiPercentage, 
                tacoiiiAllocation: tacoiiiAmount ?? tacoiiiPercentage, 
                tacfIsdollar: tacfAmount ? true : false,
                tifoIsdollar: tifoAmount ? true : false,
                tacoiiIsdollar: tacoiiAmount ? true : false,
                tacoiiiIsdollar: tacoiiiAmount ? true : false,
                coinvest1: coinvest1Amount ?? coinvest1Percentage, 
                coinvest2: coinvest2Amount ?? coinvest2Percentage,
                coinvest3: coinvest3Amount ?? coinvest3Percentage,
                co1Isdollar: coinvest1Amount ? true : false,
                co2Isdollar: coinvest2Amount ? true : false,
                co3Isdollar: coinvest3Amount ? true : false
            },
            newDeal: {
                ...data,
                tacfAllocation: tacfAmount ?? tacfPercentage, 
                tifoAllocation: tifoAmount ?? tifoPercentage, 
                tacoiiAllocation: tacoiiAmount ?? tacoiiPercentage, 
                tacoiiiAllocation: tacoiiiAmount ?? tacoiiiPercentage, 
                tacfIsdollar: tacfAmount ? true : false,
                tifoIsdollar: tifoAmount ? true : false,
                tacoiiIsdollar: tacoiiAmount ? true : false,
                tacoiiiIsdollar: tacoiiiAmount ? true : false,
                coinvest1: coinvest1Amount ?? coinvest1Percentage, 
                coinvest2: coinvest2Amount ?? coinvest2Percentage,
                coinvest3: coinvest3Amount ?? coinvest3Percentage,
                co1Isdollar: coinvest1Amount ? true : false,
                co2Isdollar: coinvest2Amount ? true : false,
                co3Isdollar: coinvest3Amount ? true : false
            }
        });
    }

    handleChangeCheckbox(e) {
        this.setState({
            newDeal: {
                ...this.state.newDeal,
                [e.target.name]: e.target.value === "true"
            }
        });
    }

    handleRowSelected(e) {
        const { data, action } = e.target;
        if(action === "Show Changes") {
            this.setState({ redirectTo:{pathname : '/approve', state : {id : data.id, name : data.name} } });
        } 
        else {
            this.handleChangeCreditProposal(e, data);
        }
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ newDeal: { ...this.state.newDeal, [name]: value } });
    }

    handleChangeNew(name, value) {
        this.setState({
            newDeal: {
                ...this.state.newDeal,
                [name]: value 
            }
        });
    }

    setFile(e) {    
        this.setState({ 
            newDeal : {
                ...this.state.newDeal,
                file: e.target.files[0]
            },             
            existingDeal : {
                ...this.state.existingDeal,
                file: null
            }
        });
    }
    
    download() {
        const fileName = this.state.newDeal.fileName;
        s3BALService.downloadFile(fileName).then(data => {
            //console.log(data);

            fileDownload(data, fileName.substring(fileName.indexOf('_') + 1));
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while downloading the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be downloaded, please contact support!'
            });
        });
    }

    remove() {
        const {newDeal, existingDeal} = this.state;
        const fileName = newDeal.fileName;
        s3BALService.deleteFile({fileName : fileName, type: type}).then(data => {
            this.setState({
                newDeal : {
                    ...newDeal
                    ,fileName : null
                    ,isSendForApproval : false
                    ,file: null
                },
                existingDeal : {
                    ...existingDeal
                    ,fileName : null
                    ,isSendForApproval : false
                    ,file: null
                }
            });
            this.refresh(null, true);
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while removing the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be removed, please contact support!'
            });
        });
    }
    
    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    getPage() {
        const { isRiskRole, newDeal, existingDeal, analystList, modalHeading, gridReminderData, isOpenGrid, gridData, isApprover, num, inApproval } = this.state;
        const { fileName, analyst1Id, approver_status, approvalRejectionComment,  id, coInvestor1Name, coInvestor2Name, coInvestor3Name, co1Isdollar, co2Isdollar, co3Isdollar, tacfIsdollar, tifoIsdollar, tacoiiIsdollar, tacoiiiIsdollar, proposalDate, approvalCondition, sizeApproved, tacfAllocation, tifoAllocation, tacoiiAllocation, tacoiiiAllocation, coinvest1, coinvest2, coinvest3, creditProposalName } = newDeal;
        const commentText = approver_status && approver_status.toLowerCase() !=='approved' ? 'Approval comment' : 'Rejection comment';        
             return (
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                    {
                        this.state.showMessage ? 
                            <ToastMessage
                                header={this.state.messageHeader}
                                errorList={this.state.errorList}
                                closeMessage={this.closeMessage}
                            />:<div></div>
                    } 
                    {
                        isOpenGrid
                            ?
                            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                                <div>
                                    <div style={{ float: "Left", padding: "5px 15px 0px 15px", cursor: "pointer" }} ><h4><u>IC Approval</u></h4></div>
                                    {
                                        !isApprover && 
                                            <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.addNew}><FontAwesomeIcon icon={faPlusCircle} /></div>
                                    }
                                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                                    {
                                        isRiskRole && 
                                            <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px" }} onClick={this.sendReminder}>
                                                <FontAwesomeIcon style = {{cursor: "pointer"}} icon={faBell} tooltip = "true" title = "Send Reminder" />
                                            </div>
                                    }
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        setAgGridAPI = {this.setAgGridAPI}
                                        isHideDateInputs={true}
                                        domLayout="normal"

                                        rowData={gridData}

                                        className="ag-theme-alpine"
                                        rowSelection="multiple"
                                        groupDefaultExpanded = {-1}
                                        groupIncludeTotalFooter={false}
                                        groupBy='status'

                                        guikey="icApproval"
                                        gridIdentityColumn="id"
                                        gridLinkColumn="Name"
                                        gridApproveColumn = "Changes"
                                        handleRowSelected={this.handleRowSelected}
                                        isApprover = {isApprover && inApproval}
                                        approve = {this.approve}
                                        reject = {this.reject}
                                        showReject = {true}
                                        isRowSelectable={ (node) => { 
                                            return isApprover && (node.data? node.data['approver_status'] === 'In Approval' : false); } }
                                    />
                                </div>
                            </div>
                            :
                            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%', overflowY:'scroll' }} >
                                <div>
                                    <span style={{ float: "left", paddingLeft: "20px" }}><h4><u>{id=== null ? 'New' : `Edit '${creditProposalName}'`} </u></h4></span>
                                    <div style={{ float: "left", paddingLeft: "15px", cursor: "pointer" }} onClick={this.handleHome}><FontAwesomeIcon icon={faHome} /></div>
                                </div> 
                                <div style={{ overflow: 'auto', paddingTop:'15px'}}>
                                    <div className="dealForm"> 
                                        <div className="row">
                                            <div className="col"> 
                                                <b>Name:</b>
                                                <input type="text"
                                                    disabled={isApprover} 
                                                    placeholder="Name"
                                                    name="creditProposalName"
                                                    autoComplete="off"
                                                    value={creditProposalName}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                />
                                            </div>                                            
                                            <div className="col">
                                                <b>Lead Analyst:</b>
                                                <Dropdown
                                                    disabled={isApprover} 
                                                    placeholder='Select Lead Analyst'
                                                    name='analyst1Id'
                                                    fluid
                                                    search
                                                    selection
                                                    clearable
                                                    onChange={this.handleDropDownChange}
                                                    options={analystList}
                                                    value={analyst1Id}
                                                />
                                            </div>
                                            <div className="col">
                                                <b>Proposal Date:</b><br/>
                                                <DatePicker
                                                    name="proposalDate"
                                                    disabled={isApprover} 
                                                    selected={proposalDate ? new Date(proposalDate) : null}
                                                    onChange={(date) => { 
                                                        this.handleChangeNew('proposalDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                                        }}
                                                    autoComplete = "off"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col">
                                                <b>Size Approved(in millions USD):</b>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder="Size Approved(in millions)"
                                                    name='sizeApproved'
                                                    value={this.getFormatted(sizeApproved)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('sizeApproved', values.floatValue ); }}
                                                    suffix={'M'}
                                                    prefix={'$'}
                                                    className={'form-control inputLightP'}
                                                /> 
                                            </div>
                                        </div>
                                        <div style={{textAlign:'center', width:'100%', padding:'5px 0px 5px 0px', backgroundColor:'lightgrey' }}>
                                            <h3><u>Allocations</u></h3>
                                        </div>
                                        <div className="row" style={{ padding:'10px 0px 10px 0px'}}>
                                            <div className="col" >
                                                <div className="form-control border-none" onChange={this.handleChangeCheckbox}>
                                                    <b>TACF&nbsp;&nbsp;</b><input disabled={isApprover} type="radio" id='tacf_dollar'  value={true} name='tacfIsdollar' checked={tacfIsdollar === true} /> <label for='tacf_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                    <input disabled={isApprover} type="radio" id='tacf_percent' value={false} name='tacfIsdollar' checked={tacfIsdollar === false} /><label for='tacf_percent' className="fsBig"><b>&nbsp;%</b></label>
                                                </div>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder={`TACF ${tacfIsdollar?'$ (in millions)' : '%'}`}
                                                    name='tacfAllocation'
                                                    value={this.getFormatted(tacfAllocation)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('tacfAllocation', values.floatValue); }}                                                    
                                                    suffix={tacfIsdollar ? 'M' : '%'}
                                                    prefix={tacfIsdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                />  
                                            </div>
                                            <div className="col">
                                                <div className="form-control border-none" onChange={this.handleChangeCheckbox}>
                                                    <b>TIFO&nbsp;&nbsp;</b><input disabled={isApprover} type="radio" id='tifo_dollar'  value={true} name='tifoIsdollar' checked={tifoIsdollar === true} /> <label for='tifo_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                    <input disabled={isApprover} type="radio" id='tifo_percent' value={false} name='tifoIsdollar' checked={tifoIsdollar === false} /><label for='tifo_percent' className="fsBig"><b>&nbsp;%</b></label>
                                                </div>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder={`TIFO ${tifoIsdollar?'$ (in millions)' : '%'}`}
                                                    name='tifoAllocation'
                                                    value={this.getFormatted(tifoAllocation)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('tifoAllocation', values.floatValue); }}
                                                    suffix={tifoIsdollar ? 'M' : '%'}
                                                    prefix={tifoIsdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                />  
                                            </div>
                                            <div className="col">
                                                <div className="form-control border-none" onChange={this.handleChangeCheckbox}>
                                                    <b>TACO II&nbsp;&nbsp;</b><input disabled={isApprover} type="radio" id='tacoii_dollar'  value={true} name='tacoiiIsdollar' checked={tacoiiIsdollar === true} /> <label for='tacoii_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                    <input disabled={isApprover} type="radio" id='tacoii_percent' value={false} name='tacoiiIsdollar' checked={tacoiiIsdollar === false} /><label for='tacoii_percent' className="fsBig"><b>&nbsp;%</b></label>
                                                </div>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder={`TACO II ${tacoiiIsdollar?'$ (in millions)' : '%'}`}
                                                    name='tacoiiAllocation'
                                                    value={this.getFormatted(tacoiiAllocation)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('tacoiiAllocation', values.floatValue); }}
                                                    suffix={tacoiiIsdollar ? 'M' : '%'}
                                                    prefix={tacoiiIsdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                /> 
                                            </div> 
                                            <div className="col">
                                                <div className="form-control border-none" onChange={this.handleChangeCheckbox}>
                                                    <b>TACO III&nbsp;&nbsp;</b><input disabled={isApprover} type="radio" id='tacoiii_dollar'  value={true} name='tacoiiiIsdollar' checked={tacoiiiIsdollar === true} /> <label for='tacoiii_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                    <input disabled={isApprover} type="radio" id='tacoiii_percent' value={false} name='tacoiiiIsdollar' checked={tacoiiiIsdollar === false} /><label for='tacoiii_percent' className="fsBig"><b>&nbsp;%</b></label>
                                                </div>
                                                <NumberFormat
                                                    disabled={isApprover} 
                                                    placeholder={`TACO III ${tacoiiiIsdollar?'$ (in millions)' : '%'}`}
                                                    name='tacoiiiAllocation'
                                                    value={this.getFormatted(tacoiiiAllocation)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('tacoiiiAllocation', values.floatValue); }}
                                                    suffix={tacoiiiIsdollar ? 'M' : '%'}
                                                    prefix={tacoiiiIsdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                /> 
                                            </div> 
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <b>Co-Investor [1]</b>
                                            </div>
                                            <div className="col" onChange={this.handleChangeCheckbox}> 
                                                    <input disabled={isApprover} type="radio" id='co1_dollar' value={true} name='co1Isdollar' checked={co1Isdollar === true} /> <label for='co1_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                    <input disabled={isApprover} type="radio" id='co1_percent' value={false} name='co1Isdollar' checked={co1Isdollar === false} /><label for='co1_percent' className="fsBig"><b>&nbsp;%</b></label>
                                             </div>
                                            <div className="col">
                                                <b>Co-Investor [2]</b>
                                            </div>
                                            <div className="col" onChange={this.handleChangeCheckbox}>
                                                <input disabled={isApprover} type="radio" id='co2_dollar'  value={true} name='co2Isdollar' checked={co2Isdollar === true} /> <label for='co2_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                <input disabled={isApprover} type="radio" id='co2_percent' value={false} name='co2Isdollar' checked={co2Isdollar === false} /><label for='co2_percent' className="fsBig"><b>&nbsp;%</b></label>
                                            </div>
                                            <div className="col">
                                                <b>Co-Investor [3]</b>
                                            </div>
                                            <div className="col" onChange={this.handleChangeCheckbox}>
                                                <input disabled={isApprover} type="radio" id='co3_dollar'  value={true} name='co3Isdollar' checked={co3Isdollar === true} /> <label for='co3_dollar' className="fsBig"><b>$ (in millions USD)</b></label>&nbsp;&nbsp;&nbsp;
                                                <input disabled={isApprover} type="radio" id='co3_percent' value={false} name='co3Isdollar' checked={co3Isdollar === false} /><label for='co3_percent' className="fsBig"><b>&nbsp;%</b></label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <input type="text"
                                                    placeholder="Co-Investor [1] Name"
                                                    name="coInvestor1Name"
                                                    autoComplete="off"
                                                    value={coInvestor1Name}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                    disabled={isApprover}
                                                /> 
                                            </div>
                                            <div class="col">
                                                <NumberFormat
                                                    placeholder={`${ coInvestor1Name? coInvestor1Name : 'Co-Investor [1]'} ${co1Isdollar?'$ (in millions)' : '%'}`}
                                                    name='coinvest1'
                                                    value={this.getFormatted(coinvest1)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('coinvest1',values.floatValue); }}
                                                    suffix={co1Isdollar ? 'M' : '%'}
                                                    prefix={co1Isdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                    disabled={isApprover}
                                                />   
                                            </div>
                                            <div className="col">
                                                <input type="text"
                                                    placeholder="Co-Investor [2] Name"
                                                    name="coInvestor2Name"
                                                    autoComplete="off"
                                                    value={coInvestor2Name}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                    disabled={isApprover}
                                                /> 
                                            </div>
                                            <div className="col">
                                                <NumberFormat
                                                    placeholder={`${ coInvestor2Name? coInvestor2Name : 'Co-Investor [2]'} ${co2Isdollar?'$ (in millions)' : '%'}`}
                                                    name='coinvest2'
                                                    value={this.getFormatted(coinvest2)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('coinvest2', values.floatValue); }}
                                                    suffix={co2Isdollar ? 'M' : '%'}
                                                    prefix={co2Isdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                    disabled={isApprover}
                                                />  
                                            </div>
                                            <div className="col">
                                                <input type="text"
                                                    placeholder="Co-Investor [3] Name"
                                                    name="coInvestor3Name"
                                                    autoComplete="off"
                                                    value={coInvestor3Name}
                                                    className={'form-control inputLightP'}
                                                    onChange={this.handleChange}
                                                    disabled={isApprover}
                                                /> 
                                            </div>
                                            <div className="col">
                                                <NumberFormat
                                                    placeholder={`${ coInvestor3Name? coInvestor3Name : 'Co-Investor [3]'} ${co3Isdollar?'$ (in millions)' : '%'}`}
                                                    name='coinvest3'
                                                    value={this.getFormatted(coinvest3)}
                                                    thousandSeparator={true}
                                                    onValueChange={(values) => { this.handleChangeNew('coinvest3', values.floatValue); }}
                                                    suffix={co3Isdollar ? 'M' : '%'}
                                                    prefix={co3Isdollar ? '$' : ''}
                                                    className={'form-control inputLightP'}
                                                    disabled={isApprover}
                                                />
                                            </div>
                                        </div> 
                                        <div className="row" style={{ padding:'20px 0px 0px 0px'}}>
                                            <div className="col">
                                                <b>Approval conditions(if any):</b>
                                                <textarea
                                                    rows="3"
                                                    placeholder="Approval conditions"
                                                    name='approvalCondition'
                                                    onChange={this.handleChange}
                                                    value={approvalCondition}
                                                    autoComplete="off"
                                                    className={'form-control input'}
                                                    disabled={isApprover}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding:'20px 0px 0px 0px'}}>
                                            <div className="col">                                                
                                                {
                                                    fileName?
                                                    <div>
                                                        <div style={{ float: "left", paddingDown: "15px", paddingRight: "15px" }}>
                                                            <b>IC Memo : </b>
                                                            {fileName.substring(fileName.indexOf('_') + 1)}
                                                        </div>
                                                        <div style={{ float: "left", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.download}>
                                                            <FontAwesomeIcon icon={faFileDownload} style = {{cursor: "pointer"}} tooltip = "true" title = "Download"/>
                                                        </div>
                                                        <div style={{ float: "left", paddingDown: "15px", paddingRight: "15px" }} onClick={this.remove}>
                                                            <FontAwesomeIcon style = {{cursor: "pointer"}} icon={faTrashAlt} tooltip = "true" title = "Remove" />
                                                        </div>
                                                    </div>
                                                    :
                                                    <b>Upload IC Memo (.pdf) : <input type="file" onChange={this.setFile} />
                                                    </b>                                                     
                                                }
                                            </div>
                                        </div>
                                        {
                                            id &&
                                            <div style = {{width:"100%", height:"100%"}}>
                                                <ApprovalGrid                                                        
                                                    key='CreditProposals'
                                                    id={id}
                                                    num={num}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{width:'100%', height:'100%', verticalAlign: 'bottom' } }>
                                    {
                                        isApprover ?
                                        <div style={{ padding: '0px 20px 15px 20px', height: 'auto'}}>
                                            <div style={{display: 'flex'}} onClick={this.togglePanel} className='headerComponent'>
                                                <div style = {{marginRight: '20px'}}>
                                                    <FontAwesomeIcon icon={this.state.open?faMinusSquare:faPlusSquare}/>
                                                </div>
                                                Approval Panel
                                            </div>
                                            {
                                                this.state.open &&
                                                    <div className = 'content'>
                                                        <b>{commentText} (if any):</b>
                                                            <textarea
                                                                rows="3"
                                                                placeholder={commentText}
                                                                name='approvalRejectionComment'
                                                                onChange={this.handleChange}
                                                                value={approvalRejectionComment}
                                                                autoComplete="off"
                                                                className={'form-control input'}
                                                            /><br/>
                                                            { 
                                                                (approver_status?.toLowerCase() !== 'approved') &&
                                                                    <button key='approveButton' className="blueB-no-margin" onClick={this.actionApproved}>Approve</button>
                                                            }
                                                            {
                                                                (approver_status?.toLowerCase() !== 'rejected') &&
                                                                    <button key='rejectButton' className="blueB-no-margin" onClick={this.actionRejected}>Reject</button>
                                                            }
                                                    </div>
                                            }
                                        </div>
                                        : 
                                        <div style={{ paddingBottom: 15, paddingTop: 10, height: '10px'}}>
                                            <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.saveDB}>
                                                <FontAwesomeIcon icon={faSave}  style = {{cursor: "pointer"}} tooltip = "true" title = "Save"/>
                                            </div>
                                            <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px" }} onClick={(e) => this.saveDB(e,true)}>
                                                <FontAwesomeIcon style = {{cursor: "pointer"}} icon={faCheck} tooltip = "true" title = "Submit for Approval" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                    }
                    <Prompt
                        when={this.state.isOpenGrid === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                        message={location => `Do you want to discard changes?`}
                    />
                    <div>
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "270px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>:
                                    this.state.modalType === 'datagrid'?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "400px", height: "auto"}}>
                                            <h1>{modalHeading}</h1>
                                            <TorGrid
                                                setAgGridAPI = {this.setAgGridAPI2} 
                                                domLayout="autoHeight"
                                                enableFilter={false}

                                                isHideDateInputs={true}
                                                isHideSaveGridLayout={true}
                                                rowData={gridReminderData}
                                                rowGroupPanelShow="none"
                                                sideBar='none'
                                                gridTitle=''

                                                className="dashboardgridNoTotal"
                                                rowSelection="multiple"
                                                groupDefaultExpanded = {-1}
                                                groupIncludeTotalFooter={false}
                            
                                                defaultColDef={this.props.defaultColDef ??
                                                {
                                                sortable: false
                                                , filter: false
                                                , floatingFilter: false
                                                , enableRowGroup: true
                                                , resizable: true
                                                , suppressSizeToFit: false
                                                , flex: 1
                                                }
                                                }

                                                guikey="approvalReminder"
                                                gridIdentityColumn="id"
                                            />
                                            <button className="btnDes mt100" onClick={this.handleSendReminder}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>:
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                            }
                        </section>
                    </div>
                </div>
            );
    }
}

export default IcApproval;