import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');


const getColumnList = async (params) => {
    return {
        'columnSpecs': [
            {'display': 'Asset Class','col':'AssetClass',   'agg':'uniq','ag-grid':true, width: 200},
            {'display': 'P&L Topsheet for IRS Shock.0',          'col':'0',          'agg':'uniq','format':'millionsFormat0','ag-grid':true,width : 40},
            {'display': 'P&L Topsheet for IRS Shock.25',         'col':'25',         'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.50',         'col':'50',         'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.75',         'col':'75',         'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.100',        'col':'100',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.150',        'col':'150',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.200',        'col':'200',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.250',        'col':'250',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.300',        'col':'300',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.350',        'col':'350',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'P&L Topsheet for IRS Shock.400',        'col':'400',        'agg':'uniq','format':'millionsFormat0','ag-grid':true,width: 40},
            {'display': 'Underlying', 'col':'Issuer',     'agg':'uniq','ag-grid':true, hide: true},
            {'display': 'Asset Class', 'col':'AssetClass', 'agg':'uniq','ag-grid':true, hide: true},
            {'display': 'Tag1',       'col':'Tag1',       'agg':'uniq','ag-grid':true, hide: true},
        ]};
}

export const irTopsheetBalService = {
    getPositions,
    getColumnList,
    getRowData,
    getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: 'description',
        width: 250,
        pinned: 'left',
        resizable: true,
        cellRendererParams: {suppressCount: true}
    };
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`,requestOptions).then(Utils.handleResponse);
}