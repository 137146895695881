import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');
const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Gross MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'TRS', 'col': 'deal trs mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Net MV', 'col': 'deal trs mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, valueGetter : function(params) {
                return params.data['asset mv'] ? params.data['asset mv'] : 0 - params.data['deal trs mv'] ? params.data['deal trs mv'] : 0;
            }},
            {'display': 'Leverage (Raw)', 'numcols': ['asset mv'], 'denomcols': ['capital unadj'], 'agg': 'aggDiv', 'stopif': 'deal', 'format': 'leverageFormat', 'ag-grid': True},
            {'display': 'Capital', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Leverage (Adj)', 'numcols': ['asset mv'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': True},
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': True, hide : True, 'rowGroup': true},
        ]};
}

export const dealDashboardSummaryBALService = {
     getPositionsData
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,minWidth: 100
        ,pinned: 'left'
        ,resizable: true
        ,hide : true
      };    
}

function getRowData(params) {
    return getPositionsData(params);
}

function getPositionsData(params) {
    var {AsOfDate, Frequency, Deal, Fund} = params;

    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    Deal = Deal ?? 'ALL';
    Fund = Fund ?? 'ALL';
    
    var body = {
        "frequency": Frequency
        ,"asofdate": asdate
        ,"deal": Deal
        ,"fund": Fund
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };

    return fetch(`${config.apiDDSUrl}/dealdata`, requestOptions).then(handleResponseArray).then(positions => { return positions; });
}

function handleResponseArray(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }
        return JSON.parse(str);
    });
}