import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
import Moment from 'moment';
const {config} = require('../config');
const False = false;
const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Asset Market Value ($m).Total', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},    //# duplicate w/diff display name
            {'display': 'Asset Market Value ($m).TACF', 'col': 'asset mv', 'fltr': {'fund': 'TOR'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset Market Value ($m).TIFO', 'col': 'asset mv', 'fltr': {'fund': 'TIFO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset Market Value ($m).TACO', 'col': 'asset mv', 'fltr': {'fund': 'TACO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset Market Value ($m).TACO II', 'col': 'asset mv', 'fltr': {'fund': 'TACO II'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset Market Value ($m).TACO III', 'col': 'asset mv', 'fltr': {'fund': 'TACO III'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset Market Value (%).TACF', 'numcol': 'asset mv', 'denomcol': 'asset mv', 'fltr': {'fund': 'TOR'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (%).TIFO', 'numcol': 'asset mv', 'denomcol': 'asset mv', 'fltr': {'fund': 'TIFO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (%).TACO', 'numcol': 'asset mv', 'denomcol': 'asset mv', 'fltr': {'fund': 'TACO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (%).TACO II', 'numcol': 'asset mv', 'denomcol': 'asset mv', 'fltr': {'fund': 'TACO II'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (%).TACO III', 'numcol': 'asset mv', 'denomcol': 'asset mv', 'fltr': {'fund': 'TACO III'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (% of AUM).TACF', 'numcol': 'asset mv', 'denomcol': 'mv total', 'fltr': {'fund': 'TOR'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (% of AUM).TIFO', 'numcol': 'asset mv', 'denomcol': 'mv total', 'fltr': {'fund': 'TIFO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (% of AUM).TACO', 'numcol': 'asset mv', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (% of AUM).TACO II', 'numcol': 'asset mv', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO II'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Asset Market Value (% of AUM).TACO III', 'numcol': 'asset mv', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO III'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            //# duplicate w/diff display name
            {'display': 'Market Value ($m).Total', 'col': 'mv total', 'agg': 'sum','format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value ($m).TACF', 'col': 'mv total', 'fltr': {'fund': 'TOR'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value ($m).TIFO', 'col': 'mv total', 'fltr': {'fund': 'TIFO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value ($m).TACO', 'col': 'mv total', 'fltr': {'fund': 'TACO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value ($m).TACO II', 'col': 'mv total', 'fltr': {'fund': 'TACO II'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value ($m).TACO III', 'col': 'mv total', 'fltr': {'fund': 'TACO III'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Market Value (% AUM).TACF', 'numcol': 'mv total', 'denomcol': 'mv total', 'fltr': {'fund': 'TOR'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Market Value (% AUM).TIFO', 'numcol': 'mv total', 'denomcol': 'mv total', 'fltr': {'fund': 'TIFO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Market Value (% AUM).TACO', 'numcol': 'mv total', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Market Value (% AUM).TACO II', 'numcol': 'mv total', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO II'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Market Value (% AUM).TACO III', 'numcol': 'mv total', 'denomcol': 'mv total', 'fltr': {'fund': 'TACO III'}, 'agg': 'pctif', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Market Value %.In Default', 'col': 'asset mv', 'fltr': {'is defaulted': True}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Market Value %.Non-Accruing', 'col': 'asset mv', 'fltr': {'is accruing': False}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Market Value %.Ratable', 'col': 'asset mv', 'fltr': {'is ratable': True}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Market Value %.Credit', 'col': 'asset mv', 'fltr': {'is credit asset': True}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'TRS', 'col': 'deal trs mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'TRS - TACF', 'col': 'deal trs mv', 'fltr': {'fund': 'TOR'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TRS - TIFO', 'col': 'deal trs mv', 'fltr': {'fund': 'TIFO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TRS - TACO', 'col': 'deal trs mv', 'fltr': {'fund': 'TACO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TRS - TACO II', 'col': 'deal trs mv', 'fltr': {'fund': 'TACO II'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TRS - TACO III', 'col': 'deal trs mv', 'fltr': {'fund': 'TACO III'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': '$FV.Total', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS']}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': True},//#duplicate w/diff display name
            {'display': '$FV.TACF', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS'], 'fund': 'TOR'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},  //# duplicate w/diff display name
            {'display': '$FV.TIFO', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS'], 'fund': 'TIFO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False}, // # duplicate w/diff display name
            {'display': '$FV.TACO', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS'], 'fund': 'TACO'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},  //# duplicate w/diff display name
            {'display': '$FV.TACO II', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS'], 'fund': 'TACO II'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},  //# duplicate w/diff display name
            {'display': '$FV.TACO III', 'col': 'quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS'], 'fund': 'TACO III'}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': False},  //# duplicate w/diff display name
            {'display': 'Mark', 'cols': ['ending local price', 'credit mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'wAvg Mark', 'cols': ['ending local price', 'credit mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'wAvg Rating', 'aggfunc': 'wAvgRating', 'ag-grid': True},
            {'display': '10Y UST Equiv.Total', 'col': 'ct10equiv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': '10Y UST Equiv.>3y', 'col': 'ct10equiv3y', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Duration.Modified', 'cols': ['modified duration', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Duration.Macaulay', 'cols': ['macaulay duration', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'CD Risk.100bp', 'col': 'cs 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'CD Risk.1bp', 'col': 'cs01', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'CD Risk.+100%', 'col': 'cswpct_100', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Eq Risk.-25%', 'col': 'eq -25', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
        
            {'display': 'CS 100bp.Total', 'col': 'cs 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'CS 100bp.Public', 'col': 'cs 100bp', 'agg': 'sumif', 'format': 'millionsFormat2', 'fltr': {'public private': 'Public'}, 'ag-grid': False},
            {'display': 'CS 100bp.Private', 'col': 'cs 100bp', 'agg': 'sumif', 'format': 'millionsFormat2', 'fltr': {'public private': 'Private'}, 'ag-grid': False},
            {'display': 'CS 100%.Total', 'col': 'cswpct_100', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'CS 100%.Public', 'col': 'cswpct_100', 'agg': 'sumif', 'format': 'millionsFormat2', 'fltr': {'public private': 'Public'}, 'ag-grid': False},
            {'display': 'CS 100%.Private', 'col': 'cswpct_100', 'agg': 'sumif', 'format': 'millionsFormat2', 'fltr': {'public private': 'Private'}, 'ag-grid': False},
            {'display': 'IR DV01', 'col': 'ir dv01', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': True},
            {'display': 'IR 100bp.Total', 'col': 'ir 100bp', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Fixed', 'col': 'ir 100bp fixed', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Floating', 'col': 'ir 100bp floating', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'IR 100bp.Public Fixed', 'col': 'ir 100bp', 'agg': 'sumif', 'format': 'millionsFormat2',
                            'fltr': {'is floating': 0, 'public private': 'Public'}, 'ag-grid': False},
            {'display': 'IR 100bp.Public Float', 'col': 'ir 100bp', 'agg': 'sumif', 'format': 'millionsFormat2',
                                'fltr': {'is floating': 1, 'public private': 'Public'}, 'ag-grid': False},
            {'display': 'IR 100bp.Private Fixed', 'col': 'ir 100bp', 'agg': 'sumif', 'format': 'millionsFormat2',
                                'fltr': {'is floating': 0, 'public private': 'Private'}, 'ag-grid': False},
            {'display': 'IR 100bp.Private Float', 'col': 'ir 100bp', 'agg': 'sumif', 'format': 'millionsFormat2',
                                'fltr': {'is floating': 1, 'public private': 'Private'}, 'ag-grid': False},
            {'display': '10Y UST Equiv.Total', 'col': 'ct10equiv', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': '10Y UST Equiv.Public Fixed', 'col': 'ct10equiv', 'agg': 'sumif', 'format': 'millionsFormat',
             'fltr': {'capital type': 'Asset', 'is floating': 0, 'public private': 'Public'}, 'ag-grid': False},
            {'display': '10Y UST Equiv.Public Float', 'col': 'ct10equiv', 'agg': 'sumif', 'format': 'millionsFormat',
             'fltr': {'capital type': 'Asset', 'is floating': 1, 'public private': 'Public'}, 'ag-grid': False},
            {'display': '10Y UST Equiv.Private Fixed', 'col': 'ct10equiv', 'agg': 'sumif', 'format': 'millionsFormat',
             'fltr': {'capital type': 'Asset', 'is floating': 0, 'public private': 'Private'}, 'ag-grid': False},
            {'display': '10Y UST Equiv.Private Float', 'col': 'ct10equiv', 'agg': 'sumif', 'format': 'millionsFormat',
             'fltr': {'capital type': 'Asset', 'is floating': 1, 'public private': 'Private'}, 'ag-grid': False},
        
            {'display': 'Asset MV.Curr', 'col': 'asset mv curr', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset MV.Prior', 'col': 'asset mv prior', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset MV.Chg', 'col': 'asset mv chg', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Quantity.Curr', 'col': 'quantity curr', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Quantity.Prior', 'col': 'quantity prior', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Quantity.Chg', 'col': 'quantity chg', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': '$Qty', 'col': 'quantity usd', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Next Coupon', 'col': 'BBG_NXT_CPN_DT', 'ag-grid': True},
            {'display': 'CCY', 'col': 'curr', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Next Maturity', 'col': 'maturity date', 'agg': 'min', 'format': 'dateFormat', 'ag-grid': False},
            {'display': 'Maturity Date', 'col': 'maturity date', 'agg': 'uniq', 'format': 'dateFormat', 'ag-grid': True},
            {'display': 'Investment Code', 'col': 'investment code', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Description', 'col': 'description', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Underwrite.Performing?', 'col': 'performing underwriting', 'agg': 'uniq', 'ag-grid': False},
            {'display': 'Public', 'col': 'public private', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'format': 'fundFormat', 'ag-grid': True},
            {'display': 'Custodian', 'col': 'custodian account', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Rating.SP', 'col': 'BBG_RTG_SP', 'ag-grid': True},
            {'display': 'Rating.Moody', 'col': 'BBG_RTG_MOODY', 'ag-grid': True},
            {'display': 'Quantity', 'col': 'quantity', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Next Earning Date', 'col': 'BBG_EXPECTED_REPORT_DT', 'format': 'dateFormat', 'ag-grid': True},
            {'display': 'Next Call Date', 'col': 'BBG_NXT_CALL_DT', 'format': 'dateFormat', 'ag-grid': True},
            {'display': 'Next Call Price', 'col': 'BBG_NXT_CALL_PX', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Call Days', 'cols': ['BBG_CALL_DAYS_NOTICE', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat0', 'ag-grid': True},
            {'display': 'Tor Mark.Price', 'cols': ['ending local price', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Tor Mark.ZSpread', 'cols': ['zspread', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'Tor Mark.YTM', 'cols': ['yield to maturity', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
        
            {'display': 'BBG Mark.Price', 'cols': ['BBG_PX_LAST', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'BBG Mark.ZSpread', 'cols': ['BBG_SPREAD', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'BBG Mark.YTM', 'cols': ['BBG_YLD_YTM_MID', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'BBG Mark.YTC', 'cols': ['BBG_YLD_YTC_MID', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'BBG Mark.YTW', 'cols': ['BBG_YTW', 'asset mv'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': True},
        
            {'display': 'Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Asset MV.Clean', 'col': 'asset mv clean', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Asset MV.Accrued Interest', 'col': 'asset accrued interest', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
        
            {'display': 'Asset MV.Total', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset MV.Private', 'col': 'private asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Asset MV.Public', 'col': 'public asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
        
            {'display': 'MV Decomposition.Parked Capital Alloc', 'col': 'capital pb parked', 'agg': 'sum',
             'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Pub Asset', 'col': 'public asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Priv Asset', 'col': 'private asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Asset', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Gross', 'col': 'gross asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Short', 'col': 'short asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Deal TRS', 'col': 'deal trs mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Facility', 'col': 'facility mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.PB Cash', 'col': 'pb cash mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Hedge MTM', 'col': 'hedge mtm mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.OTC Margin', 'col': 'otc margin mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Other Cash', 'col': 'other cash mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Decomposition.Total', 'col': 'mv total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'MV Total', 'col': 'mv total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
        
            //#this is a bit messy.
            {'display': 'MV Decomposition.Warrant', 'col': 'mv warrant', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Claim', 'col': 'mv claim', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Equity', 'col': 'mv equity', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Bilateral Loan', 'col': 'mv bilateral loan', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.CB', 'col': 'mv cb', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Corp Bond', 'col': 'mv corp bond', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Syndicated Loan', 'col': 'mv syndicated loan', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV Decomposition.Club Deal', 'col': 'mv club deal', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
        
            {'display': 'Stress PNL.Credit', 'col': 'credit stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Stress PNL.Total', 'col': 'asset stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Stress PNL.Equity', 'col': 'equity stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Stress PNL.Asset', 'col': 'asset stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Stress PNL.Tail Hedge', 'col': 'tail hedge stress pnl', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Stress PNL.% Asset', 'numcols': ['asset stress pnl'], 'denomcols': ['asset mv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Stress PNL.% AUM', 'cols': ['asset stress pnl', 'capital total'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Capital.Asset', 'col': 'capital asset', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.TRS', 'col': 'capital financing', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.TRS Buffer', 'col': 'capital trs buffer', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.Parked Capital', 'col': 'capital pb parked', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.PB Lev', 'col': 'capital pb cash', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.Unalloc', 'col': 'capital unallocated', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.FX', 'col': 'capital fx', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.FX Margin', 'col': 'capital fx margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.FX Buffer', 'col': 'capital fx buffer', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital.Total', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Capital Usage', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
        
            {'display': 'Capital Usage (unadj)', 'col': 'capital unadj', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Cash', 'col': 'cash amount', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': False},
        
            {'display': 'Risk Buffer wAvg%.TRS Buffer', 'numcols': ['capital trs buffer'], 'denomcols': ['capital financing'], 'agg': 'aggDiv', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Risk Buffer wAvg%.FX Buffer', 'numcols': ['capital fx buffer'], 'denomcols': ['fx forward gross mv'], 'agg': 'aggDiv', 'format': 'millionsFormat', 'ag-grid': True},
        
            {'display': 'Current Broker.Margin', 'col': 'broker margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Current Broker.Lending Value', 'col': 'broker lending value', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Current Broker.LMV', 'col': 'broker lmv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Current Broker.LTV', 'numcols': ['broker lending value'], 'denomcols': ['broker lmv prior'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Prior Broker.Margin', 'col': 'broker margin prior', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Prior Broker.Lending Value', 'col': 'broker lending value prior', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Prior Broker.LMV', 'col': 'broker lmv prior', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Prior Broker.LTV', 'numcols': ['broker lending value prior'], 'denomcols': ['broker lmv prior'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Change.Margin', 'col': 'broker margin chg', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Change.Lending Value', 'col': 'broker lending value chg', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Change.LMV', 'col': 'broker lmv chg', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
        
            {'display': 'Broker.Margin', 'col': 'broker margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Broker.Margin Position', 'col': 'broker margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Broker.LMV', 'col': 'broker lmv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Broker.Lending Value', 'col': 'broker lending value', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
        
            {'display': 'Broker.LTV', 'numcols': ['broker lending value'], 'denomcols': ['broker lmv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'TOR.LTV', 'numcols': ['broker lending value'], 'denomcols': ['asset mv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'Broker.Deriv MTM', 'col': 'derivatives mtm', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Deriv VM', 'col': 'derivatives vm', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Cash', 'col': 'cash', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Margin FX', 'col': 'fx margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Excess', 'col': 'total excess', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Equity', 'col': 'broker equity', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'Broker.Margin %', 'numcols': ['broker margin', 'fx margin'], 'denomcols': ['broker lmv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Broker.Margin % (ex FX)', 'numcols': ['broker margin'], 'denomcols': ['broker lmv'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Broker.Leverage', 'numcols': ['broker lmv'], 'denomcols': ['broker equity'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': False},
            {'display': 'Broker.Leverage (ex FX)', 'numcols': ['broker lmv'], 'denomcols': ['broker equity', 'fx stress loss'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': False},
            {'display': 'TOR.Asset Clean', 'col': 'asset mv clean', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TOR.Accrued', 'col': 'asset accrued interest', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TOR.Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TOR.Equity', 'col': 'tor equity', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'TOR.Leverage', 'numcols': ['asset mv'], 'denomcols': ['tor equity'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': False},
            {'display': 'Broker Funding.Rate', 'cols': ['funding cost', 'broker funding balance'], 'agg': 'wAvg', 'format': 'percentFormat', 'ag-grid': False},
            {'display': 'Broker Funding.Annual Cost', 'col': 'annual funding cost', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
        
            {'display': 'Credit Beta', 'cols': ['credit beta', 'asset mv'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Debit Credit Alloc', 'col': 'debit credit alloc', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': False},
            {'display': 'MV (%)', 'cols': ['asset mv', 'asset mv'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'AUM (%)', 'cols': ['asset mv', 'mv total'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Capital (%)', 'cols': ['capital total', 'capital total'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Stress (% of Asset MV)', 'numcols': ['asset stress pnl'], 'denomcols': ['asset mv'], 'agg': 'aggDiv', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'Stress (% of AUM)', 'cols': ['asset stress pnl', 'capital total'], 'agg': 'percent', 'format': 'percentFormat', 'ag-grid': False},
            {'display': 'Public / Private', 'col': 'public private', 'agg': 'uniq', 'ag-grid': False},
            {'display': 'Risk Type', 'col': 'Risk Type', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Stress Type', 'col': 'stress type', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Substrategy', 'col': 'sub strategy', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Strategy', 'col': 'strat', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Amount', 'col': 'amount', 'format': 'decimalFormat0', 'ag-grid': False},
            {'display': 'Coupon Override.Cash', 'col': 'cash', 'format': 'decimalFormatShowZero2', 'ag-grid': False},
            {'display': 'Coupon Override.PIK', 'col': 'pik', 'format': 'decimalFormatShowZero2', 'ag-grid': False},
            {'display': 'Coupon Override.Other', 'col': 'other', 'format': 'decimalFormatShowZero2', 'ag-grid': False},
            {'display': 'Sector', 'col': 'sector', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Asset Class', 'col': 'asset class', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Count.Deal', 'cols': ['deal', 'asset mv'], 'agg': 'count', 'format': 'decimalFormat0', 'ag-grid': True},
            {'display': 'Count.Asset', 'cols': ['strat', 'asset mv'], 'agg': 'count', 'format': 'decimalFormat0', 'ag-grid': True},
            {'display': 'Count.Financing', 'cols': ['description', 'deal trs mv'], 'agg': 'count', 'format': 'decimalFormat0', 'ag-grid': True},
            {'display': 'Cost of Capital.%', 'numcols': ['annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat', 'ag-grid': True},
            {'display': 'Cost of Capital.Annual $', 'col': 'annual cost of capital', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True},
            {'display': 'Annual Carry.Theta', 'col': 'tail hedge theta carry', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Annual Carry.Facility Cost', 'col': 'gross facility cost', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Annual Carry.Deal TRS Cost', 'col': 'gross deal trs cost', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
        
            {'display': 'Annual Carry.Capital Cost', 'col': 'annual cost of capital', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.Parked Capital', 'col': 'annual parked capital cost', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.Funding', 'col': 'annual funding cost', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.Cash', 'col': 'gross annual cash coupon', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.PIK', 'col': 'gross annual pik coupon', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.Other', 'col': 'gross annual other coupon', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.FX', 'col': 'gross annual fx carry', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
            {'display': 'Annual Carry.All In', 'col': 'gross annual total coupon', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True},
        
            {'display': 'Funding Cost.Blended Rate', 'numcols': ['annual funding cost'], 'denomcols': ['debit credit alloc'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Funding Cost.Cost/Benefit', 'numcols': ['annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'Gross Levered Yield.Cash', 'numcols': ['gross annual cash coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.Funding', 'numcols': ['annual funding cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.Parked Capital', 'numcols': ['annual parked capital cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.Capital Cost', 'numcols': ['annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.PIK', 'numcols': ['gross annual pik coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.Other', 'numcols': ['gross annual other coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.FX', 'numcols': ['gross annual fx carry'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Gross Levered Yield.Net', 'numcols': ['gross annual total coupon', 'gross annual fx carry', 'annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'Other Carry.Tail Theta', 'numcols': ['tail hedge theta carry'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Other Carry.Biz Cost', 'numcols': ['annual business cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Net Levered Yield.Cash', 'numcols': ['gross annual cash coupon', 'gross annual fx carry', 'annual cost of capital', 'tail hedge theta carry', 'annual business cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Net Levered Yield.Total', 'numcols': ['gross annual cash coupon', 'gross annual fx carry', 'annual cost of capital', 'gross annual pik coupon', 'tail hedge theta carry', 'annual business cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Leverage', 'numcols': ['asset mv'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': True},
            {'display': 'Leverage Metrics (Adj).Cost', 'numcols': ['annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Leverage Metrics (Adj).Lev Ratio', 'numcols': ['asset mv'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': False},
            {'display': 'Leverage Metrics (Adj).Yield', 'numcols': ['gross annual total coupon', 'gross annual fx carry', 'annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Leverage (unadj)', 'numcols': ['asset mv'], 'denomcols': ['capital unadj'], 'agg': 'aggDiv', 'stopif': 'deal', 'format': 'leverageFormat', 'ag-grid': True},
            {'display': 'Leverage Metrics (Unadj).Cost', 'numcols': ['annual funding cost'], 'denomcols': ['capital unadj'], 'agg': 'aggDiv', 'stopif': 'deal', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Leverage Metrics (Unadj).Lev Ratio', 'numcols': ['asset mv'], 'denomcols': ['capital unadj'], 'agg': 'aggDiv', 'stopif': 'deal', 'format': 'leverageFormat', 'ag-grid': True},
            {'display': 'Leverage Metrics (Unadj).Yield', 'numcols': ['gross annual total coupon', 'gross annual fx carry', 'annual funding cost'], 'denomcols': ['capital unadj'], 'agg': 'aggDiv', 'stopif': 'deal', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'Current Asset Yield.Cash', 'numcols': ['gross annual cash coupon'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Current Asset Yield.PIK', 'numcols': ['gross annual pik coupon'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Current Asset Yield.Other', 'numcols': ['gross annual other coupon'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Current Asset Yield.FX', 'numcols': ['gross annual fx carry'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
            {'display': 'Current Asset Yield.All In', 'numcols': ['gross annual total coupon', 'gross annual fx carry'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True},
        
            {'display': 'Current Yield.Unlevered', 'numcols': ['gross annual total coupon'], 'denomcols': ['asset mv clean'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Current Yield.Levered', 'numcols': ['gross annual total coupon', 'annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': False},
        
            {'display': 'Current Coupon.Cash', 'cols': ['cash rate', 'credit or financing mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Current Coupon.PIK', 'cols': ['pik rate', 'credit or financing mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Current Coupon.Other', 'cols': ['other rate', 'credit or financing mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Current Coupon.All In', 'cols': ['all in rate', 'credit or financing mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Asset Coupon.Cash', 'cols': ['cash rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Asset Coupon.PIK', 'cols': ['pik rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Asset Coupon.Other', 'cols': ['other rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Asset Coupon.All In', 'cols': ['all in rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
        
            {'display': 'Coupon Flags.Is Accruing Geneva', 'col': 'is accruing geneva', 'agg': 'uniq', 'ag-grid': False},
            {'display': 'Coupon Flags.Is Accruing', 'col': 'is accruing', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Coupon Flags.Is Delay Draw', 'col': 'is delay draw', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Coupon Flags.Is Defaulted', 'col': 'is defaulted', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Coupon Geneva.Cash', 'col': 'cash raw', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Coupon Geneva.PIK', 'col': 'pik raw', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Coupon Used.Cash', 'col': 'cash rate', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Coupon Used.PIK', 'col': 'pik rate', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Coupon Used.All In', 'col': 'all in rate', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Coupon Used.Other', 'col': 'other rate', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': True},
        
            {'display': 'Duration.wAvgYrs', 'cols': ['years to maturity', 'credit mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Maturity.Years', 'cols': ['years to maturity', 'credit or financing mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True},
            {'display': 'Maturity.Date', 'aggfunc': 'maturityDatesUniq', 'ag-grid': False},
            {'display': 'Ccy', 'aggfunc': 'currenciesUniq', 'ag-grid': False},
        
            //#MARS / Tail Hedge table
            {'display': 'Valuation.Notional', 'col': 'notional', 'aggfunc': 'hedgeNotionalSum', 'format': 'millionsFormat0', 'ag-grid': False}, //#can make separate ag-grid table later, not part of position table
            {'display': 'Valuation.Maturity', 'col': 'maturitydate', 'agg': 'uniq', 'ag-grid': False},
            {'display': 'Valuation.Strike', 'col': 'strike', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Valuation.Spot', 'col': 'spot', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': False},
            {'display': 'Valuation.Moneyness', 'col': 'moneyness', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Valuation.NPV', 'col': 'mktvalportccy', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Risk.Vol', 'col': 'implied vol', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Risk.% Delta', 'col': '% delta', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': False},
            {'display': 'Risk.$Delta', 'col': '$delta', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Risk.IR DV01', 'col': 'dv01', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Risk.CS01', 'col': 'cs01', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Risk.Theta/AUM', 'col': 'theta aum annual', 'agg': 'sum', 'format': 'percentFormat2', 'ag-grid': False},
            {'display': 'Risk.$Theta Annual', 'col': 'annual theta carry', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Risk.Stress', 'col': 'stress pnl', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
        
            {'display': 'P&L Topsheet.-50%', 'col': 'pl_-50.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-25%', 'col': 'pl_-25.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-20%', 'col': 'pl_-20.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-15%', 'col': 'pl_-15.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-10%', 'col': 'pl_-10.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-5%', 'col': 'pl_-5.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.-1%', 'col': 'pl_-1.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.+1%', 'col': 'pl_1.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.+5%', 'col': 'pl_5.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.+10%', 'col': 'pl_10.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.+15%', 'col': 'pl_15.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'P&L Topsheet.+20%', 'col': 'pl_20.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
        
            {'display': 'CDS Topsheet (BP).-50', 'col': 'pl_-50.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).-25', 'col': 'pl_-25.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).25', 'col': 'pl_25.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).50', 'col': 'pl_50.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).75', 'col': 'pl_75.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).100', 'col': 'pl_100.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).150', 'col': 'pl_150.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).200', 'col': 'pl_200.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).250', 'col': 'pl_250.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).300', 'col': 'pl_300.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).350', 'col': 'pl_350.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).400', 'col': 'pl_400.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).450', 'col': 'pl_450.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (BP).500', 'col': 'pl_500.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (%).+10%', 'col': 'cswpct_10', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (%).+25%', 'col': 'cswpct_25', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (%).+50%', 'col': 'cswpct_50', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Topsheet (%).+100%', 'col': 'cswpct_100', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'CDS Spread.Mark', 'col': 'creditsprd', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': False},
        
            {'display': 'Delta Topsheet.-50%', 'col': 'delta_-50.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-25%', 'col': 'delta_-25.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-20%', 'col': 'delta_-20.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-15%', 'col': 'delta_-15.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-10%', 'col': 'delta_-10.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-5%', 'col': 'delta_-5.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.-1%', 'col': 'delta_-1.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.0%', 'col': 'delta_0.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.+1%', 'col': 'delta_1.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.+5%', 'col': 'delta_5.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
            {'display': 'Delta Topsheet.+10%', 'col': 'delta_10.0', 'agg': 'sum', 'format': 'millionsFormat1', 'ag-grid': False},
        
            {'display': 'Local CCY Exposure.Long', 'col': 'LMV (Local)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Local CCY Exposure.Short', 'col': 'SMV (Local)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Local CCY Exposure.Net', 'col': 'NMV (Local)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'USD Equivalent.Long', 'col': 'LMV (USD)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'USD Equivalent.Short', 'col': 'SMV (USD)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'USD Equivalent.Net', 'col': 'NMV (USD)', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
        
            {'display': 'Notional', 'col': 'Quantity', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': False},
            {'display': 'Settle Date', 'col': 'Settle Date', 'agg': 'uniq', 'ag-grid': False},
            {'display': 'Capital Type', 'col': 'capital type', 'agg': 'uniq', 'ag-grid': True},

            {'display': 'Maturity Bucket', 'col': 'maturity bucket', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Vintage Year', 'col': 'vintage year', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Vintage Quarter', 'col': 'vintage quarter', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Coupon Flags.Is Floating', 'col': 'is floating', 'agg': 'uniq', 'ag-grid': True},

            {'display': 'Corporate Structure.Analyst 1', 'col': 'Analyst 1', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Corporate Structure.Analyst 2', 'col': 'Analyst 2', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Corporate Structure.Analyst 3', 'col': 'Analyst 3', 'agg': 'uniq', 'ag-grid': True},
            {'display': 'Corporate Structure.Analyst 4', 'col': 'Analyst 4', 'agg': 'uniq', 'ag-grid': True},


            //custom grouping
            {'display': 'Public/Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': True, hide: True, sort: 'asc'},           
        ]};
}


export const positionBALService = {
     getPositions
    ,getPositionsData
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getIndentClass(params) {
    var indent = 0;
    var node = params.node;
    while (node && node.parent) {
      indent++;
      node = node.parent;
    }
    return ['indent-' + indent];
}

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Group'
        ,field: 'description'
        ,minWidth: 100
        ,pinned: 'left'
        ,resizable: true
        ,cellClass: getIndentClass
      };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiDDSUrl}/allposition/frequency=${Frequency}/asofdate=${asdate}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}

function getPositionsData(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiDDSUrl}/allpositioncache/frequency=${Frequency}/asofdate=${asdate}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}
