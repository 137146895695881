/**<import>****************************************************************************************************/
import React, { Component } from 'react';
//https://react.semantic-ui.com/modules/dropdown/
import FormDisplay from './components/formDisplay.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';


// Our components
import './deals.css';

class DealRisks extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
    };

    handleChange(e) {
        this.props.handleChange(e);
    }

    handleDropDownChange(e, { name, value }) {
        this.props.handleDropDownChange(e, { name, value });
    }

    handleChangeDate(name, value) {
        this.props.handleChangeDate(name, value);
    }

    getSettingByName(name) {
        const { settingList } = this.props;
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    render() {
        const { dealRisk } = this.props;

        const commodityList = this.getSettingByName("Commodity Risk").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const realEstateList = this.getSettingByName("Property Type").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const optionsList = this.getSettingByName("Risk Price Direction").map(item => { return { key: item.value, value: item.id, text: item.value } });

        return (
            <div style={{ gridTemplateRows: '1fr auto', display: 'grid', height: '100%', width: '100%' }}>
                <div style={{ overflow: 'auto' }}>
                    <div className="dealForm">
                        <div className="row">
                            <div className="column2 left3">
                                <div className="column2 three">
                                    <h3>Commodity Risks</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>{""}</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>Direction</h3>
                                </div>
                            </div>
                            <div className="column2 right3">
                                <div className="vAlign">
                                    <div className="column2 four">
                                        <h3>Description</h3>
                                    </div>
                                    <div className="column2 four">
                                        <h3>Is Development Asset?</h3>
                                    </div>
                                    <div className="column2 four">
                                        <h3>Location</h3>
                                    </div>
                                    <div className="column2 four">
                                        <h3>% of Deal Asset</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="riskDisp"
                            heading="Commodity 1 Name"
                            dataList={commodityList}
                            dataField={dealRisk}
                            fieldName="commodity_1"
                            optionsList={optionsList}
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="riskDisp"
                            heading="Commodity 2 Name"
                            dataList={commodityList}
                            dataField={dealRisk}
                            fieldName="commodity_2"
                            optionsList={optionsList}
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="riskDisp"
                            heading="Commodity 3 Name"
                            dataList={commodityList}
                            dataField={dealRisk}
                            fieldName="commodity_3"
                            optionsList={optionsList}
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />

                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <div className="row">
                            <div className="column2 left">
                                <div className="column2 three">
                                    <h3>Real-Estate Risks</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>{""}</h3>
                                </div>
                                <div className="column2 three">
                                    <h3>Description</h3>
                                </div>
                            </div>
                            <div className="column2 right">
                                <div className="vAlign">
                                    <div className="column2 three">
                                        <h3>Is Development Asset?</h3>
                                    </div>
                                    <div className="column2 three">
                                        <h3>Location</h3>
                                    </div>
                                    <div className="column2 three">
                                        <h3>% of Deal Asset</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="reDisp"
                            heading="Real Estate Asset 1"
                            dataList={realEstateList}
                            dataField={dealRisk}
                            fieldName="real_estate_1"
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="reDisp"
                            heading="Real Estate Asset 2"
                            dataList={realEstateList}
                            dataField={dealRisk}
                            fieldName="real_estate_2"
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="reDisp"
                            heading="Real Estate Asset 3"
                            dataList={realEstateList}
                            dataField={dealRisk}
                            fieldName="real_estate_3"
                            handleChange={this.handleChange}
                            handleChangeDate={this.handleChangeDate}
                            handleDropDownChange={this.handleDropDownChange}
                        />
                    </div>
                </div>
                <div>
                    <div style={{ paddingBottom: 15, height: '10px' }}>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(4)} title="Back"><FontAwesomeIcon icon={faArrowCircleLeft} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.saveDB()} title="Save"><FontAwesomeIcon icon={faSave} /></div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.approveDB()}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit for Approval" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealRisks;