import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import Moment from 'moment';
const {config} = require('../config');
const True = true;


const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': '# Deals', 'cols': ['deal', 'asset mv'], 'agg': 'count', 'format': 'decimalFormat0', 'ag-grid': True, minWidth : 50},
            {'display': 'Asset MV',     'col': 'asset mv', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth : 67},
            {'display': 'Capital', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth : 65},
            {'display': 'Leverage', 'numcols': ['asset mv'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'leverageFormat', 'ag-grid': True, minWidth : 63},
            {'display': 'MV (%)', 'cols': ['asset mv', 'asset mv'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid':True, minWidth : 52},
            {'display': 'Capital (%)', 'cols': ['capital total', 'capital total'], 'agg': 'percent', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 75},
            {'display': 'Gross Levered Yield.Cash', 'numcols': ['gross annual cash coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 30},
            {'display': 'Gross Levered Yield.PIK', 'numcols': ['gross annual pik coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 30},
            {'display': 'Gross Levered Yield.Other', 'numcols': ['gross annual other coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 30},
            {'display': 'Gross Levered Yield.FX', 'numcols': ['gross annual fx carry'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 30},
            {'display': 'Gross Levered Yield.Funding', 'numcols': ['annual funding cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 55},
            {'display': 'Gross Levered Yield.Parked Capital', 'numcols': ['annual parked capital cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 95},
            {'display': 'Gross Levered Yield.Net', 'numcols': ['gross annual total coupon', 'gross annual fx carry', 'annual cost of capital'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 25},
            {'display': 'Other Carry.Tail Theta', 'numcols': ['tail hedge theta carry'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 65},
            {'display': 'Other Carry.Biz Cost', 'numcols': ['annual business cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 58},
            {'display': 'Net Yield', 'numcols': ['gross annual cash coupon', 'gross annual fx carry', 'annual cost of capital', 'gross annual pik coupon', 'tail hedge theta carry', 'annual business cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 65},
            {'display': 'wAvg Mark', 'cols': ['ending local price', 'credit mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth : 76},
            {'display': 'wAvg Rating', 'aggfunc': 'wAvgRating', 'ag-grid': true, minWidth : 83},
            {'display': 'Market Value %.In Default', 'col': 'asset mv', 'fltr': {'is defaulted': true}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 68},
            {'display': 'Market Value %.Non-Accruing', 'col': 'asset mv', 'fltr': {'is accruing': false}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 90},
            {'display': 'Market Value %.Ratable', 'col': 'asset mv', 'fltr': {'is ratable': true}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 55},
            {'display': 'Market Value %.Credit', 'col': 'asset mv', 'fltr': {'is credit asset': true}, 'agg': 'aggDivIf', 'format': 'percentFormat1', 'ag-grid': True, minWidth : 50},

            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Public/Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': True, hide: True},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': True, hide: True},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': True, hide: True},  
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, hide: True},
    ]};
}

export const portfolioOverviewSummaryBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,minWidth: 90
        ,maxWidth: 95
        ,pinned: 'left'
        ,resizable: true
        ,sort: 'asc'
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(params) {
    return getPositions(params);
}
function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}