import { userBALService } from './user.bal'; 
import { Utils } from '../helper/utils'; 
const {config} = require('../config');
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Id', 'col': 'id', 'agg': 'uniq', 'ag-grid': true, hide:true },
            {displayIndex: 2, 'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, 'rowGroup' : true, hide:true, width : 90 },
            {displayIndex: 3, 'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, width : 160 },
            {displayIndex: 5, 'display': 'Description', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width : 330 },
            {displayIndex: 6, 'display': 'Currency', 'col': 'Currency', 'agg': 'uniq', 'ag-grid': true, width : 80 },
            {displayIndex: 8, 'display': 'Notional', 'col': 'Quantity', 'agg': 'uniq', 'ag-grid': true, 'format': 'millionsFormat2M', width : 100, type: 'rightAligned'}, 
            {displayIndex: 9, 'display': 'Status', 'col': 'status', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {displayIndex: 10, 'display': 'Current.!{col001}', 'col': '!{col001}', 'format': 'decimalFormat2All', editable: true, 'ag-grid': true, width : 140, cellEditor: 'numericEditor', type: 'rightAligned', cellClass: 'rag-green'},
            {displayIndex: 11, 'display': 'Previous.!{col002}', 'col': '!{col002}', 'format': 'decimalFormat2', 'ag-grid': true, width : 140, type: 'rightAligned' },
            {displayIndex: 12, 'display': 'Change', 'col': 'change', 'format': 'decimalFormat2', 'ag-grid': true, width : 100, type: 'rightAligned' 
                , valueGetter : params => {
                    if(params.data) {
                        let col1 = Object.keys(params.data).filter(p => p.startsWith("col001_"));
                        let col2 = Object.keys(params.data).filter(p => p.startsWith("col002_"));
                        if(col1 && col2 && params.data[col1] && params.data[col2] && params.data[col1] !== 0 && params.data[col2] !== 0 && params.data[col1] !== params.data[col2]) {
                            return (params.data[col1] - params.data[col2]).toFixed(2);
                        } else if(col1 && col2 && (params.data[col1] === 0 || params.data[col2] === 0)) {
                            return (params.data[col1] - params.data[col2]).toFixed(2);
                        }
                    }
                    return (0).toFixed(2);
                }
            },
            {displayIndex: 13, 'display': 'Pnl (USD)', 'col': 'change', 'agg': 'sum', 'format': 'decimalFormat2', 'ag-grid': true, width : 140, type: 'rightAligned'
                , valueGetter : params => {
                    if(params.data) {
                        let col1 = Object.keys(params.data).filter(p => p.startsWith("col001_"));
                        let col2 = Object.keys(params.data).filter(p => p.startsWith("col002_"));
                        if(col1 && col2 && params.data[col1] && params.data[col2] && params.data[col1] !== 0 && params.data[col2] !== 0 && params.data[col1] !== params.data[col2]) {
                            if (params.data['Deal'] === "CROWN RESORTS") {
                                return (params.data[col1] - params.data[col2]).toFixed(2) * params.data['Quantity'] * params.data['Exchange Rate'];
                            } else {
                                return (params.data[col1] - params.data[col2]).toFixed(2) * params.data['Quantity'] * params.data['Exchange Rate'] / 100.0;
                            }
                        } else if(col1 && col2 && (params.data[col1] === 0 || params.data[col2] === 0)) {
                            if (params.data['Deal'] === "CROWN RESORTS") {
                                return (params.data[col1] - params.data[col2]).toFixed(2) * params.data['Quantity'] * params.data['Exchange Rate'];
                            } else {
                                return (params.data[col1] - params.data[col2]).toFixed(2) * params.data['Quantity'] * params.data['Exchange Rate'] / 100.0;
                            }
                        }
                    }
                    return (0).toFixed(2);
                }
            },
            {displayIndex: 14, 'display': 'Use BB', 'col': 'isBloomberg', 'ag-grid': true, width : 120, type: 'rightAligned', 
                cellClass: 'rag-green', cellRenderer: "checkboxRenderer",
                onUpdate: params => 
                {
                    if(params.data) {
                        let col1 = Object.keys(params.data).filter(p => p.startsWith("col001_"));
                        let col3 = Object.keys(params.data).filter(p => p.startsWith("col003_"));
                        if(params.data.isBloomberg && params.data[col3])
                            params.node.setDataValue(col1, params.data[col3]);
                    }
                }
            },
            {displayIndex: 15, 'display': 'BB.!{col003}', 'col': '!{col003}', 'format': 'decimalFormat2', 'ag-grid': true, width : 140, maxWidth : 170, type: 'rightAligned'},
            {displayIndex: 16, 'display': 'Instrument', 'col': 'Investment Code', 'agg': 'uniq', 'ag-grid': true, width : 230 },
            {displayIndex: 17, 'display': 'ISIN', 'col': 'ISIN', 'agg': 'uniq', 'ag-grid': true, width : 150 }, 
]};
}

export const markingToolBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Fund',
        field: '',
        width: 150,
        pinned: 'left'
    };
}

function getRowData(params) {
    const requestOptions = { body: JSON.stringify(params), method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json'  } };
    return fetch(`${config.apiDDSUrl}/allInstrumentMark`, requestOptions).then(Utils.handleResponse).then(data => {
        return data;
    });
}

function save(instrumentMarks, isSendForApproval, date, fund) {
    instrumentMarks = instrumentMarks.map(instrumentMark => {
        return Utils.removeBlanksNotZero(instrumentMark);
    });
    return fetch(`${config.apiDDSUrl}/addInstrumentMark/date=${date}/fund=${fund}/approval=${isSendForApproval}`, { body: JSON.stringify(instrumentMarks), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}