/**<import>****************************************************************************************************/
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { torGridBALService } from '../../bal/torGridBALService.bal';
import { fundBALService } from '../../bal/fund.bal';
import { Prompt } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class Pnl extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === "markingtool" });

        this.state = {
            rowData: [],
            isChecked: false,
            isShowLoad: false,
            isShowGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            filterData: [],
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            open: false,
            fundList: [],
            fund: 'TOR',
            forDate: moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        //getFormatted = getFormatted(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.loadFunds = this.loadFunds.bind(this);
        this.cellValueChanged = this.cellValueChanged.bind(this);
        this.filterAsset = this.filterAsset.bind(this);
        this.loadFunds();
    }

    componentDidMount() {
        //this.worker = new WebWorker(torgridworker);
        //this.startWorker();
        this.mounted = true;
        if (this.mounted) {
            torGridBALService.getRowData('hedgeReport', { query: `exec [sp_get_position_summary_pnl] '20210824', '${this.state.fund}';` }).then(data => {
                const { fund } = this.state;

                var filterData = data.filter(function (item) {
                    return item['Fund'] === fund
                });
                var filterData1 = filterData.map(row1 => {
                    return {
                        ...row1
                        , 'cellcss': 'cell-normal'
                    }
                });
                var gridData = data.map(row1 => {
                    return {
                        ...row1
                        , 'cellcss': 'cell-normal'
                    }
                });
                this.setState({ gridData: gridData, filterData: filterData1 }, () => {
                    this.connectServer();
                });
            });

            this.connectServer();
        }
    }

    connectServer = () => {
        var ws = new WebSocket(config.wsUrl);
        var connectInterval;

        ws.onopen = () => {
            console.log("Connected...");

            this.timeout = 250;
            clearTimeout(connectInterval);

            if (this.mounted) {
                console.log("<trader>Setting state...");
                this.setState({ ws: ws, display: "none" });
                //var _updateConnection = this.getData("updateConnection", JSON.parse(user));
                //ws.send(_updateConnection);
            }
        };

        ws.onmessage = e => {
            let data = JSON.parse(e.data);
            console.log(data);
            const { fund } = this.state;

            if (fund === data.Fund) {
                switch (data.__MESSAGE__) {
                    case "security_tick":
                        var res = data['Current Price'] && !isNaN(data['Current Price']);
                        if (res) {

                            var rows = this.state.gridData?.filter(row => row['position_id'] === data['position_id'])

                            if (rows && rows.length > 0) {
                                var rowsFound = rows.map(row1 => {
                                    return {
                                        ...row1
                                        , 'Book': data['Book']
                                        , 'Country': data['Country']
                                        , 'Credit Sub-Strategy': data['Credit Sub-Strategy']
                                        , 'Curr': data['Curr']
                                        , 'Current Price': data['Current Price']
                                        , 'Current Quantity': data['Current Quantity']
                                        , 'Deal': data['Deal']
                                        , 'Description': data['Description']
                                        , 'Entry Cost': data['Entry Cost']
                                        , 'Factor': data['Factor']
                                        , 'Fund': data['Fund']
                                        , 'MTD FX': data['MTD FX']
                                        , 'MTD Interest': data['MTD Interest']
                                        , 'MTD Other': data['MTD Other']
                                        , 'MTD Price': data['MTD Price']
                                        , 'MV': data['MV']
                                        , 'Position P&L': data['Position P&L']
                                        , 'Prev Price': data['Prev Price']
                                        , 'Prev Quantity': data['Prev Quantity']
                                        , 'Product Type': data['Product Type']
                                        , 'Sector': data['Sector']
                                        , 'Trade P&L': data['Trade P&L']
                                        , '__MESSAGE__': data['__MESSAGE__']
                                        , 'position_id': data['position_id']
                                        , 'cellcss': (row1['Current Price'] < data['Current Price'] ? 'cell-green' : row1['Current Price'] > data['Current Price'] ? 'cell-red' : 'cell-blue')
                                    };
                                });

                                /*rowsFound.forEach(row => {
                                    this.props.GridRowsToUpdate.push(row);                    
                                });*/
                                if (JSON.stringify(rowsFound) !== JSON.stringify(rows)) {
                                    this.setState({
                                        GridRowsToUpdate: rowsFound
                                    });
                                }
                                //this.gridApi.batchUpdateRowData({update: rows});
                            } else {
                                const rows = [{
                                    'Book': data['Book']
                                    , 'Country': data['Country']
                                    , 'Credit Sub-Strategy': data['Credit Sub-Strategy']
                                    , 'Curr': data['Curr']
                                    , 'Current Price': data['Current Price']
                                    , 'Current Quantity': data['Current Quantity']
                                    , 'Deal': data['Deal']
                                    , 'Description': data['Description']
                                    , 'Entry Cost': data['Entry Cost']
                                    , 'Factor': data['Factor']
                                    , 'Fund': data['Fund']
                                    , 'MTD FX': data['MTD FX']
                                    , 'MTD Interest': data['MTD Interest']
                                    , 'MTD Other': data['MTD Other']
                                    , 'MTD Price': data['MTD Price']
                                    , 'MV': data['MV']
                                    , 'Position P&L': data['Position P&L']
                                    , 'Prev Price': data['Prev Price']
                                    , 'Prev Quantity': data['Prev Quantity']
                                    , 'Product Type': data['Product Type']
                                    , 'Sector': data['Sector']
                                    , 'Trade P&L': data['Trade P&L']
                                    , '__MESSAGE__': data['__MESSAGE__']
                                    , 'position_id': data['position_id']
                                    , 'cellcss': 'cell-normal'
                                }];
                                this.state.gridData = [...this.state.gridData, ...rows];
                                this.setState({
                                    GridRowsToAdd: rows
                                });
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
            //console.log(tradersList);
        };

        ws.onerror = (e) => {
            //console.log("On error message...");
            if (this.mounted) { this.setState({ display: 'block', error: `Socket is closed. Reconnecting in  ${Math.min(10000 / 1000, (this.timeout + this.timeout) / 1000)} second.` }); }
            //console.log();
            this.timeout = this.timeout + this.timeout;
            connectInterval = setTimeout(this.check, Math.min(10000, this.timeout));
            //console.log("End On error message...");
        };
        ws.onclose = (e) => {
            if (ws != null) { ws.close(); }
            //console.log("completed on close message...");
        };
    };

    loadFunds() {
        fundBALService.getRowData().then(data => {
            if (data) {
                this.setState({
                    fundList: data.map(item => { return { key: item.display_name, value: item.display_name, text: item.code } })
                });
            }
        }, error => {
            this.setState({
                showMessage: true,
                errorList: [error],
                messageHeader: "Error while getting the fund list:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Fund list could not be retrieved, please contact support!'
            });
            console.log(error);
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open });
    }

    refresh(e, modalVisible = false) {
        torGridBALService.getRowData('hedgeReport', { query: `exec [sp_get_position_summary_pnl] '20210824', '${this.state.fund}';` }).then(data => {
            const { fund } = this.state;

            var filterData = data.filter(function (item) {
                return item['Fund'] === fund
            });
            var filterData1 = filterData.map(row1 => {
                return {
                    ...row1
                    , 'cellcss': 'cell-normal'
                }
            });
            var gridData = data.map(row1 => {
                return {
                    ...row1
                    , 'cellcss': 'cell-normal'
                }
            });
            this.setState({ gridData: gridData, filterData: filterData1 }, () => {
                this.connectServer();
            });
        });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
            this.setState({ modalVisible: false });
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false });
        } else {
            this.setState({ modalVisible: false });
        }
    }

    getFormatted = (value) => {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(Number.isInteger(value) ? 0 : 2) : value;
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ [name]: value });

        const { gridData } = this.state;

        var filterData = gridData.filter(function (item) {
            return item['Fund'] === value
        });

        this.setState({
            filterData: filterData
        })
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    filterAsset() {
        const { isChecked, gridData, fund } = this.state;

        this.setState({
            isChecked: !isChecked
        });
        let filterData = [];
        if (isChecked === false) {
            filterData = gridData.filter(function (item) {
                return ["Cash and Equivalents", "Swap Financing", "FX Forward"].includes(item['Product Type']) === false
            });

            filterData = filterData.filter(function (item) {
                return item['Deal'] !== "MACRO HEDGE"
            });

            this.setState({
                filterData: filterData
            });
        } else {
            filterData = gridData.filter(function (item) {
                return item['Fund'] === fund
            });

            this.setState({
                filterData: filterData
            });
        }
    }

    cellValueChanged(e) {
        if (e && e.data) {
            let col1 = Object.keys(e.data).filter(p => p.startsWith("col001_"));
            let data = this.state.gridData.filter(p => p['Investment Code'] === e.data['Investment Code'] && p.instrument_id !== e.data.instrument_id);

            data = data.map(row => {
                return {
                    ...row,
                    [col1]: e.data[col1],
                    isBloomberg: e.data.isBloomberg
                }
            })

            this.setState({
                GridRowsToUpdate: data
            });
        }
    }

    getPage() {
        const { newDeal, GridRowsToUpdate, existingDeal, modalHeading, isShowLoad, isShowGrid, filterData, isApprover, fundList, fund, showMessage, messageHeader, errorList } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    showMessage ?
                        <ToastMessage
                            header={messageHeader}
                            errorList={errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                    <div>
                        <div style={{ float: "Left", padding: "5px 15px 0px 15px" }} ><h4><u>Pnl</u></h4></div>
                        <div style={{ float: "Left", padding: "10px 15px 0px 15px" }} >
                            <label>Filter Asset-line items</label>
                        </div>
                        <div style={{ float: "Left", padding: "10px 15px 0px 15px" }} >
                            <input type="checkbox" onChange={this.filterAsset} />
                        </div>
                        <div style={{ float: "right", padding: "15px 15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                        <div style={{ float: "right", padding: "15px 15px 0px 15px" }}>

                            { /*<b>For Date: </b>
                            <DatePicker
                                name="forDate"                                        
                                selected={forDate ? new Date(forDate) : null}
                                onChange={(date) => { 
                                    this.handleChange('forDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                }}
                                filterDate={(date) => {
                                    const day = date.getDay();
                                    return day !== 0 && day !== 6;
                                }}
                                maxDate = {this.state.maxDate}
                                autoComplete = "off"
                                showYearDropdown
                                showMonthDropdown
                            />*/}
                        </div>
                        <div style={{ float: "right", height: "50px", padding: "10px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                            <Dropdown
                                placeholder='Select Fund'
                                name="fund"
                                fluid
                                search
                                selection
                                onChange={this.handleDropDownChange}
                                options={fundList}
                                value={fund}
                                className={'form-control'}
                            />
                        </div>
                    </div>
                    <div style={{ gridTemplateRows: '3fr 1fr', display: 'grid', width: '100%' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TorGrid
                                setAgGridAPI={this.setAgGridAPI}
                                isHideDateInputs={true}
                                domLayout="normal"

                                isHideSaveGridLayout={true}

                                rowData={filterData}
                                GridRowsToUpdate={GridRowsToUpdate}

                                className="ag-theme-alpine"
                                rowSelection="multiple"
                                gridIdentityColumn="position_id"

                                groupDefaultExpanded={1}
                                groupIncludeTotalFooter={false}

                                guikey="pnl"
                                sortColumn={true}

                                singleClickEdit={true}
                                isApprover={isApprover}
                                groupColumns={['Fund', 'Book', 'Deal']}

                                isShowLoad={isShowLoad}
                                isShowGrid={isShowGrid}
                                cellValueChanged={this.cellValueChanged}
                            />
                        </div>

                        <div style={{ width: '100%', paddingBottom: '30px', paddingLeft: '50px', paddingRight: '50px' }}>
                            <div style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', display: 'grid', width: '100%' }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        guikey="bottomDODpnl"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={filterData}
                                        ignoreBlanks='DOD Total'
                                        domLayout="autoHeight"


                                        suppressExpandablePivotGroups={true}
                                        className="dashboardTopGrid"
                                        rowGroupPanelShow="none"
                                        enableFilter={false}

                                        sideBar='none'
                                        gridTitle='Bottom DoD'
                                        pagination={true}
                                        paginationPageSize={5}
                                        gridIdentityColumn="position_id"
                                    />
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        guikey="topDODpnl"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={filterData}
                                        ignoreBlanks='DOD Total'
                                        domLayout="autoHeight"


                                        suppressExpandablePivotGroups={true}
                                        className="dashboardTopGrid"
                                        rowGroupPanelShow="none"
                                        enableFilter={false}

                                        sideBar='none'
                                        gridTitle='Top DoD'
                                        pagination={true}
                                        paginationPageSize={5}
                                        gridIdentityColumn="position_id"
                                    />
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        guikey="bottomMTDpnl"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={filterData}
                                        ignoreBlanks='MTD Total'
                                        domLayout="autoHeight"


                                        suppressExpandablePivotGroups={true}
                                        className="dashboardTopGrid"
                                        rowGroupPanelShow="none"
                                        enableFilter={false}

                                        sideBar='none'
                                        gridTitle='Bottom MTD'
                                        pagination={true}
                                        paginationPageSize={5}
                                        gridIdentityColumn="position_id"
                                    />
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        guikey="topMTDpnl"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={filterData}
                                        ignoreBlanks='MTD Total'
                                        domLayout="autoHeight"


                                        suppressExpandablePivotGroups={true}
                                        className="dashboardTopGrid"
                                        rowGroupPanelShow="none"
                                        enableFilter={false}

                                        sideBar='none'
                                        gridTitle='Top MTD'
                                        pagination={true}
                                        paginationPageSize={5}
                                        gridIdentityColumn="position_id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Prompt
                    when={this.state.isShowLoad === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal)}
                    message={location => 'Do you want to discard changes?'}
                />
                <div>
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                        <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                                    :
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                        <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default Pnl;