import React, { Component } from 'react';
import ChartDisplay from "./components/chartDisplay.js"

import { torGridBALService } from '../../bal/torGridBALService.bal';
import TorGrid from './../torgrid/torgrid';

class HedgeOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thetaChartData: []
        }
    }


    render() {
        return this.getTemplate();
    }

    componentDidMount() {
        const {Fund, AsOfDate} = this.props;
        torGridBALService.getRowData('hedgeReport', { query: AsOfDate ? `Exec [sp_get_historical_theta] '${Fund}', '${AsOfDate}'` : `Exec [sp_get_historical_theta] '${Fund}'` }).then(data => {
            this.setState({ thetaChartData: data });
        });
    }

    componentDidUpdate(prevProps) {
        const {Fund, AsOfDate} = this.props;
        if(prevProps.Fund !== Fund || prevProps.AsOfDate !== AsOfDate) {
            torGridBALService.getRowData('hedgeReport', { query: AsOfDate ? `Exec [sp_get_historical_theta] '${Fund}', '${AsOfDate}'` : `Exec [sp_get_historical_theta] '${Fund}'` }).then(data => {
                this.setState({ thetaChartData: data });
            });
        }
    }


    getTemplate() {
        const { Fund, AsOfDate } = this.props;
        const {thetaChartData} = this.state;
        if(Fund && AsOfDate) {
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: "15px" }}>
                    <div>
                        
                        <TorGrid
                            guikey="hedgeReport"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}

                            query={AsOfDate ? `Exec [sp_get_hedge_report] '${Fund}', '${AsOfDate}'` : null}
                            gridIdentityColumn="position_id"
                            groupIncludeTotalFooter={true}
                            suppressExpandablePivotGroups={true}
                            className="dashboardgrid"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            domLayout="autoHeight"
                            groupDefaultExpanded = {-1}

                            groupBy = "AssetClass"

                            defaultColDef={this.props.defaultColDef ??
                            {
                                sortable: false
                                , filter: false
                                , floatingFilter: false
                                , enableRowGroup: true
                                , resizable: true
                                , suppressSizeToFit: false
                            }
                            }

                            handleRowSelected={this.handleRowSelected}
                            sideBar='none'

                            gridTitle='Hedge Summary'
                        />
                    </div>
                    <div style={{ height: '100%', width: '100%', position: 'relative', marginTop: "20px", marginBottom: "20px" }}>
                        <div style = {{width : '69%', float: 'left'}} >
                            <div style={{marginTop: "20px", width : '100%'}}>
                                <TorGrid
                                    guikey="cdsTopsheet"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    query={AsOfDate ? `Exec [sp_cds_topsheet] '${Fund}', '${AsOfDate}'` : null}
                                    gridIdentityColumn="position_id"
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupBy={this.props.GroupBy}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                    }
                                    }

                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'

                                    gridTitle='Hedge Topsheets'
                                />
                            </div>
                            
                            <div style={{marginTop: "20px", width : '100%'}}>
                                <TorGrid
                                    guikey="equityTopsheet"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    query={AsOfDate ? `Exec [sp_equity_topsheet] '${Fund}', '${AsOfDate}'` : null}
                                    gridIdentityColumn="position_id"
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupBy={this.props.GroupBy}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                    }
                                    }

                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'
                                />
                            </div>

                            <div style={{marginTop: "20px", width : '100%'}}>
                                <TorGrid
                                    guikey="fxTopsheet"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    query={AsOfDate ? `Exec [sp_fx_topsheet] '${Fund}', '${AsOfDate}'` : null}
                                    gridIdentityColumn="position_id"
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupBy={this.props.GroupBy}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , resizable: true
                                        , suppressSizeToFit: false
                                    }
                                    }

                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'
                                />
                            </div>

                            <div style={{marginTop: "20px", width : '100%'}}>
                                <TorGrid
                                    guikey="irTopSheet"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    query={AsOfDate ? `Exec [sp_ir_topsheet] '${Fund}', '${AsOfDate}'` : null}
                                    gridIdentityColumn="position_id"
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupBy={this.props.GroupBy}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , resizable: true
                                        , suppressSizeToFit: false
                                    }
                                    }

                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'
                                />
                            </div>
                        </div>
                        <div style = {{width : '31%', float: 'left', position: 'relative', flexWrap: 'wrap', alignContent:'left'}} >
                            <ChartDisplay
                                chartTitle="Historical Theta/ AUM"
                                chartType='area'
                                GroupBy="AssetClass"
                                xKey="ReportCOBDate"
                                yKeys="Theta/AUM"
                                dataList={thetaChartData}
                                rotationAngle = {335}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default HedgeOverview;