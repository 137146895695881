import React, { useState, forwardRef, useImperativeHandle } from 'react';
import JoditEditor from "jodit-react";
export const RichTextEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    useImperativeHandle(ref, () => ({
      getValue() {
        return value?.replace("<p>", "<div>")?.replace("</p>", "</div>");
      },
      isPopup() {
        return true;
      },
      afterGuiAttached() {
        // Optionally focus or set up other functionality
      },
    })); 
  
      return <JoditEditor
        value={value}
        //config={config}
        tabIndex={1} // tabIndex of textarea
        onChange={newContent => setValue(newContent)} // preferred to use only this option to update the content for performance reasons
        askBeforePasteHTML={false}
        askBeforePasteFromWord={false}
        defaultActionOnPaste="insert_clear_html"
      />
    //return <ReactQuill value={value} onChange={handleValueChange} onKeyPress={handleKeyPress} ref={(el) => (quillRef = el ? el.getEditor() : null)}/>;
  });