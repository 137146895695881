import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Id', 'col': 'name', 'ag-grid': true, hide:true, width: 50, editable: true},
            /*{'display': 'Currency', 'col': 'currency', 'ag-grid': true, maxWidth: 120, editable: true, cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                cellHeight: 50,
                values: ['HKD','SGD','USD'],
              }
            },*/
            {'display': 'Amount(HKD)', 'col': 'amount', 'ag-grid': true, 'agg': 'sum', width: 200, editable: true, 'format': 'decimalFormat2', cellEditor: 'numericEditor'},
            {'display': 'End Date', 'col': 'dateEnd', 'ag-grid': true, maxWidth: 200, editable: true, cellEditor: 'datePicker'},
            {'display': 'Start Date', 'col': 'dateStart','ag-grid': true, maxWidth: 200, editable: true, cellEditor: 'datePicker' },
            {'display': 'Address', 'col': 'address', 'ag-grid': true, width: 200, editable: true},
            {'display': 'Name', 'col': 'name', 'ag-grid': true, width: 200, editable: true},
            {'display': 'UserId', 'col': 'userId', 'ag-grid': true, hide:true, width: 50, editable: true},
         ]};
}

export const housingSummaryBALService = {
     getPositions
    ,getColumnList 
    ,getRowData
    ,getAutoGroupColumnDef
    ,save
    ,saveFile
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' , width: 100}, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}

function save(housingRows) {
    housingRows = housingRows.map(housingRow => {
        return Utils.removeBlanksNotZero(housingRow);
    });
    return fetch(`${config.apiDDSUrl}/addHousingRows/`, { body: JSON.stringify(housingRows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function saveFile(data) { 
    return fetch(`${config.apiDDSUrl}/addHousingFile/`, { body: JSON.stringify(data), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}