import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Underlying', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {'display': 'Valuation.$Notional', 'col': 'Short $Notional', 'agg': 'notionalSum', 'format': 'millionsFormat2M', 'ag-grid': true, width : 80},
            {'display': 'Valuation.Maturity Date', 'col': 'MaturityDate', 'agg': 'uniq', 'ag-grid': true, width : 95},
            {'display': 'Valuation.Strike/Rate', 'col': 'Strike', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 80},
            {'display': 'Valuation.ATM Rate', 'col': 'CreditSprd', 'agg': 'uniq', 'format': 'percentFormat2', 'ag-grid': true, width : 90},
            {'display': 'Valuation.Moneyness', 'col': 'Moneyness', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': true, width : 80},
            {'display': 'Valuation.NMV', 'col': 'MktValPortCcy', 'agg': 'sum', 'format': 'millionsFormat2M', 'ag-grid': true, width : 80},
            {'display': 'Risk Metrics.Imp. Vol', 'col': 'Implied Vol', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': true, width : 60},
            {'display': 'Risk Metrics.% Delta', 'col': '% Delta', 'agg': 'uniq', 'format': 'percentFormat1', 'ag-grid': true, width : 60},
            {'display': 'Risk Metrics.$ Delta', 'col': '$Delta', 'agg': 'sum', 'format': 'millionsFormat2M', 'ag-grid': true, width : 80},
            {'display': 'Risk Metrics.IR DV01', 'col': 'IR01 Port Ccy', 'agg': 'uniq', 'format': 'decimalFormat0', 'ag-grid': true, width : 60},
            {'display': 'Risk Metrics.CS01', 'col': 'CreditDV01PortCcy', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 60},
            {'display': 'Risk Metrics.Theta/AUM', 'col': 'unitTheta', 'agg': 'sum', 'format': 'percentFormat2', 'ag-grid': true, width : 80},
            {'display': 'Risk Metrics.Annual $Theta', 'col': 'Monthly Theta + Carry', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 100},
            {'display': 'Risk Metrics.Stress PnL', 'col': 'Stress1 PnL', 'agg': 'sum', 'format': 'millionsFormat2M', 'ag-grid': true, width : 80},
            {'display': 'P&L ($).DoD PnL', 'col': 'DoD PnL', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 80},
            {'display': 'P&L ($).MTD PnL', 'col': 'MTD PnL', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 80},
            {'display': 'Asset Class', 'col': 'AssetClass', 'agg': 'uniq', 'ag-grid': true, hide: true}
        ]};
}

export const hedgeReportBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Asset Class'
        ,field: 'description'
        ,width: 120
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}