import { userBALService } from '../user.bal';
import { Session } from '../../helper/session';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    const user = JSON.parse(Session.getItem(config.token)); 
    const approverRole = user.userRoles.filter(function (item) { return item === "Approver" });
    const analystRole = user.userRoles.filter(function (item) { return item === "Analyst" || item === "Approver" });
    const isApprover = approverRole.length > 0;
    const isAnalyst = analystRole.length > 0    

    return  {
        'columnSpecs': [
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'format': 'fundFormat', 'ag-grid': true, 'rowGroup': true, width: 100},
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, minWidth: 180},
            {'display': 'Approval.Status', 'col': 'Approval Status', 'agg': 'uniq', 'ag-grid': true, width: 150, checkboxSelection: (node) => {
                return true && (node.data ? node.data['Approval Status'] !== "Approved" : false) && (node.data['Analyst 1'] === user?.user?.name || isApprover)
            }, headerCheckboxSelection: isAnalyst, headerCheckboxSelectionFilteredOnly: isAnalyst},
            {'display': 'Deal.Status', 'col': 'Deal Status', 'agg': 'uniq', 'ag-grid': true, width: 100},
            {'display': '!{col001}', 'col': '!{col001}', 'col2': '!{col001}', 'agg': 'uniq', 'format': 'percentFormat2P-100',  'ag-grid': true, width: 100},
            {'display': '!{col002}', 'col': '!{col002}', 'col2': '!{col002}', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 100},
            {'display': '!{col003}', 'col': '!{col003}', 'col2': '!{col003}', 'agg': 'uniq', 'format': 'dateFormatShort', 'ag-grid': true, width: 140},
            {'display': '!{col004}', 'col': '!{col004}', 'col2': '!{col004}', 'agg': 'uniq', 'ag-grid': true, width: 100},
            {'display': '!{col005}', 'col': '!{col005}', 'col2': '!{col005}', 'agg': 'uniq', 'ag-grid': true, width: 100},

            {'display': '!{col006}', 'col': '!{col006}', 'col2': '!{col006}', 'agg': 'uniq', 'format': 'percentFormat2P-100',  'ag-grid': true, width: 100},
            {'display': '!{col007}', 'col': '!{col007}', 'col2': '!{col007}', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 100},
            {'display': '!{col008}', 'col': '!{col008}', 'col2': '!{col008}', 'agg': 'uniq', 'format': 'dateFormatShort', 'ag-grid': true, width: 140},
            {'display': '!{col009}', 'col': '!{col009}', 'col2': '!{col009}', 'agg': 'uniq', 'ag-grid': true, width: 100},
            {'display': '!{col010}', 'col': '!{col010}', 'col2': '!{col010}', 'agg': 'uniq', 'ag-grid': true, width: 100},
            {'display': 'Change.IRR', 'col': 'Change IRR', 'agg': 'uniq', 'format': 'percentFormat2P-100',  'ag-grid': true, width: 100},
            {'display': 'Change.MOIC', 'col': 'Change MOIC', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 100},
            {'display': 'Change.Exit Date', 'col': 'Change Exit Date', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 100},
        ]};
}

export const portfolioProjectionBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
    ,approveGrid
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' , width: 100}, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}

function approveGrid(proposals) {
    const promises = proposals.map((deal) => { 
       var a = fetch(`${config.apiDDSUrl}/setProjectionApproval/id=${deal.id}/status=${deal.action}`, {method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return handleResponseApprove(data, deal) });
       return a;
    });
    return promises;
} 

function handleResponseApprove(response, deal) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }
            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return {error : `${deal.deal} : ${error}`};
        }
        return data;
    });
}