import React, { Component } from 'react';

export default class RemoveButton extends Component {
    buttonClick = (e) => {
        // e is getting the current node (Row Node)*
        this.setState({
            visible:true
        });
        let deletedRow = this.props.node.data;
        e.gridApi.updateRowData({ remove: [deletedRow] })  // It will update the row
    };
    render() {
        return (
            <span><button onClick={() => this.buttonClick(this.props.node)}>X</button></span>
        );
    }
}
