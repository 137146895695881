import React, { Component } from 'react';
import ChartDisplay from "../portfolioDashboard/components/chartDisplay.js"
import { torGridBALService } from '../../bal/torGridBALService.bal';
import TorGrid from './../torgrid/torgrid';
import moment from 'moment';

class ProjectedCashflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            gridData: [],
            filterData: [],
            irrGridData: [],
            irrFilterData: [],
            gridTopData: []
        }

        this.isZero = this.isZero.bind(this);
        this.getDate = this.getDate.bind(this);
        this.agValueFormatter = this.agValueFormatter.bind(this);
        this.getValueFormatted = this.getValueFormatted.bind(this);
        this.getData = this.getData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    isZero(value) {
        return value < 0.0001 && value > -0.0001
    }

    getDate(params) {
        var val = Date(params.value);
        return val;
    }

    agValueFormatter(value, format) {
        var agformat = format;
        if (value && value !== '' && value !== 0) {
            switch (agformat) {
                case 'millionsFormat':
                    return this.getValueFormatted(value / 1e6, 1);
                case 'millionsFormat2':
                    return this.getValueFormatted(value / 1e6, 2);
                case 'percentFormat':
                    return this.getValueFormatted(value * 100);
                case 'percentFormat1':
                    return this.getValueFormatted(value * 100, 1);
                case 'decimalFormat0':
                    return this.getValueFormatted(value, 0);
                case 'decimalFormat2':
                    return this.getValueFormatted(value, 2);
                case '{0:.2f}x':
                    return this.getValueFormatted(value);
                case 'leverageFormat':
                    const displayvalue = this.getValueFormatted(value, 2);
                    return `${displayvalue}${displayvalue && displayvalue.toString() !== '' ? 'x' : ''}`;
                case 'dateFormat':
                    return this.getDate(value);
                default:
                    return value;
            }
        }
        else if (value && value !== '' && agformat === 'decimalFormatShowZero2')
            return this.getValueFormatted(value, 2);
        return '';
    }

    getValueFormatted(param, digit = 2) {
        var result = param;
        var val = 0.0;

        if (!isNaN(result)) {
            try {
                val = parseFloat(result).toFixed(digit);
                if (this.isZero(val)) return '';

                if (!isNaN(val))
                    result = val.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            } catch (error) {
                val = result;
            }
        }
        return result;
    }

    filterData(colName, value) {
        var data = this.state.gridData;
        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                filterData: arr
            });
        } else {
            this.setState({
                filterData: this.state.gridData
            });
        }
    }

    filterDataIRR(colName, value) {
        var data = this.state.irrGridData;
        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                irrFilterData: arr
            });
        } else {
            this.setState({
                irrFilterData: data
            });
        }
    }

    getData = async () => {
        const { Deal, AsOfDate } = this.props;
        await torGridBALService.getRowData("pCashflow", { query: AsOfDate ? `Exec [sp_projected_cashflow] '${Deal}', '${AsOfDate}'` : `Exec [sp_projected_cashflow] '${Deal}'` }).then(data => {
            this.setState({ gridData: data });
            this.setState({ isLoading: false });
            this.filterData('Fund', this.props.fund);
        }, error => {
            this.setState({ isLoading: false });
            this.setState({ gridData: [], filterData: [] });
        });

        await torGridBALService.getRowData("dealProjectionTop", { query: AsOfDate ? `Exec [sp_get_irr_MOIC] '${Deal}', '${AsOfDate}'` : `Declare @date as varchar(10) = (Select CONVERT(varchar, MAX([date_captured]), 112) from irr_summary) Exec [sp_get_irr_MOIC] '${Deal}', @date` }).then(data => {
            this.setState({ irrGridData: data });
            this.setState({ isLoading: false });
            this.filterDataIRR('Fund', this.props.fund);
        }, error => {
            this.setState({ isLoading: false });
            this.setState({ irrGridData: [], irrFilterData: [] });
        });
    }

    componentDidMount() {
        if (this.props.Deal) {
            this.setState({ isLoading: true, gridData: [], filterData: [], irrGridData: [], irrFilterData: [] });
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.Deal ?? '') !== (this.props.Deal ?? '') || (prevProps.AsOfDate ?? '') !== (this.props.AsOfDate ?? '')) {
            this.setState({ isLoading: true, gridData: [], filterData: [], irrGridData: [], irrFilterData: [] })
            this.getData();
        }

        if (prevProps.fund !== this.props.fund) {
            this.filterData('Fund', this.props.fund);
            this.filterDataIRR('Fund', this.props.fund);
        }
    }


    getTemplate() {
        const {AsOfDate} = this.props;
        let quarterDate = '';
        if(AsOfDate) {
            quarterDate = new Date(AsOfDate.substring(2), (parseInt(AsOfDate.substring(1, 2)) * 3).toString(), '01');
            quarterDate = new Date(quarterDate - 1);

            quarterDate = moment(quarterDate).format("YYYY-MM-DD");
        }
        
        if (this.state.isLoading === true) {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        } else {
            if (this.props.Deal && this.state.filterData.length > 0) {
                return (
                    <div style={{ height: '100%', width: '100%' }}>
                        <div style={{marginTop: '20px', marginLeft: '20px'}}>
                            <h4>{`Whole Life Projection as-of ${this.props.AsOfDate} for date ${quarterDate}`}</h4>
                        </div>
                        <div style={{ margin: 'auto' }}>
                            <TorGrid
                                guikey="dealProjectionTop"
                                isHideDateInputs={true}
                                isHideSaveGridLayout={true}

                                className="topDisplayGrid"
                                rowGroupPanelShow="none"
                                enableFilter={false}
                                domLayout="autoHeight"
                                rowData={this.state.irrFilterData}

                                defaultColDef={this.props.defaultColDef ??
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                                }
                                handleRowSelected={this.handleRowSelected}
                                sideBar='none'
                            />
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <div style={{ gridTemplateColumns: '1fr 1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                                <div style={{ height: "100%" }}>
                                    <TorGrid
                                        guikey="pCashflow"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={this.state.filterData}
                                        gridIdentityColumn="position_id"
                                        groupIncludeFooter={true}
                                        groupIncludeTotalFooter={true}
                                        suppressExpandablePivotGroups={true}
                                        className="ag-theme-alpine"
                                        enableFilter={false}
                                        domLayout="autoHeight"
                                        groupDefaultExpanded={-1}

                                        defaultColDef={this.props.defaultColDef ??
                                        {
                                            sortable: false
                                            , filter: false
                                            , floatingFilter: false
                                            , enableRowGroup: true
                                            , resizable: true
                                            , suppressSizeToFit: false
                                        }
                                        }

                                        handleRowSelected={this.handleRowSelected}
                                    />
                                </div>
                                <div style={{ maxHeight: "700px" }}>
                                    <ChartDisplay
                                        chartType="bar-stacked"
                                        chartTitle="Projected CF (USD)"
                                        xKey="Cashflow Date"
                                        GroupBy="Fund"
                                        yKeys="Cashflow Book Amount"
                                        dataList={this.state.filterData}
                                        rotationAngle={335}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }
    }
}
export default ProjectedCashflow;