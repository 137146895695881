/*
Ref:
https://trekinbami.medium.com/using-environment-variables-in-react-6b0a99d83cf5
https://betterprogramming.pub/using-environment-variables-in-reactjs-9ad9c5322408
    "apiDDSUrl" : process.env.REACT_APP_apiDDSUrl || "https://uatapi.torinvest.com",
    "apiDDSUrl" : process.env.REACT_APP_apiDDSUrl || "https://spring.torinvest.com",
    "apiDDSUrl" : process.env.REACT_APP_apiDDSUrl || "http://localhost:8000",
*/
var config = {
    "token"     : process.env.REACT_APP_token || "tor-token",
    "apiDDSUrl" : process.env.REACT_APP_apiDDSUrl || "http://localhost:8000",
    "wsUrl"     : process.env.REACT_APP_wsUrl || "wss://wss.torinvest.com:7080",
    "environment"       : process.env.REACT_APP_environment || "local",
    "setAsApprover" : process.env.REACT_APP_setAsApprover || false,
}
export {config};
export default config;
