import React, { Component } from 'react';
import TorGrid from './../torgrid/torgrid';

class RealEstateRisk extends Component {
    render() {
        return this.getTemplate();
    }


    getTemplate() {
        if(this.props.AsOfDate && this.props.Frequency) {
            return (
                <div style={{ height: '100%', width: '100%'}}>
                    <div style={{ height: '100%', width: '100%'}}>
                        <TorGrid
                            guikey="exposureRisk"
                            query={ `exec [sp_get_real_estate_risk] '${this.props.AsOfDate}', '${this.props.Frequency}', '${this.props.username}', '${this.props.Fund}'`}
                            isHideDateInputs={true}
                            gridIdentityColumn="dealID"
                            groupBy = "fund"
                            className="ag-theme-alpine"
                            groupDefaultExpanded = {-1}
                            toggleColumn = {true}
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default RealEstateRisk;