/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { dealBALService } from '../../bal/deal.bal';
import { profileBALService } from '../../bal/userProfile.bal';
import { userBALService } from '../../bal/user.bal';
import { Dropdown } from 'semantic-ui-react';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-awesome-modal';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            countryList: [],
            settingList: [],
            dataSaved: false,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            name: "",
            profileSummary: {}
        }
        dealBALService.getAllCountry().then(data => { this.setState({ countryList: data }); }, error => { console.log(error); });
        dealBALService.getAllSettings().then(data => { this.setState({ settingList: data }); }, error => { console.log(error); });
        

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.approveDB = this.approveDB.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
    };

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }

        if (this.state.dataSaved) {
            this.setState({
                modalVisible: false
            })
        } else {
            this.setState({
                modalVisible: false
            });
        }
    }

    componentDidMount() {
        const fullname = userBALService.getUserInfo()?.user?.name;
        this.setState({name: fullname});

        profileBALService.getProfileData().then(data => {
            this.setState({profileSummary: data})
        }, error => { 
            console.log(error); 
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true, modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.saveDB(null, false);
            this.setState({isOpenGrid: true, modalVisible: false});
        } else {
            this.setState({isOpenGrid: true, modalVisible: false});
        }
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ profileSummary: { ...this.state.profileSummary, [name]: value } });
    }

    handleChange(e) {
        this.setState({ profileSummary: { ...this.state.profileSummary, [e.target.name]: e.target.value } });
    }

    getSettingByName(name) {
        const { settingList } = this.state;
        let setting = settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        let settingL = setting.length > 0 ? setting[0].childSettings : [];

        settingL.sort(function(a, b) {
            return a.value.localeCompare(b.value);
        })

        return settingL;
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    approveDB() {
        var {profileSummary} = this.state;
        var errorList = [];
        console.log(profileSummary);

        const columnsToCheck = [
            {label: "Gender", id: "gender"},
            {label: "Career Start Date", id: "career_start_date"},
            {label: "Degree 1", id: "degree_1"},
            {label: "Degree 1 Major", id: "degree_1_major"},
            {label: "Degree 1 Institution", id: "degree_1_school"},
            {label: "Degree 1 Country", id: "degree_1_country"},
            {label: "Home Country 1", id: "home_country_1"},
            {label: "Citizenship 1", id: "citizenship_1"}
        ]

        var flag = true;

        for (const key of columnsToCheck) {
            if (!profileSummary[key.id]) {
                flag = false;
                errorList.push(key.label);
            }
        }

        console.log(errorList);

        if(flag === false) {
            this.setState({                         
                showMessage: false,
                messageHeader : "",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Please fill out the missing sections in red !'
            });
        } else {
            profileBALService.saveUserProfile(profileSummary).then(data => {
                this.setState({                         
                    showMessage: false,
                    messageHeader : "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Thank you - your changes have been saved',
                    dataSaved: true
                });
            }, error => {
                this.setState({                         
                    showMessage: false,
                    messageHeader : "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Error saving data, please contact support !'
                });
            })
        }
    }

    getPage() {
        const { profileSummary, modalHeading, name } = this.state;
        const countryList = this.state.countryList
            .filter(item => item.id !== 418 && item.id !== 419)
            .map(item => { return { key: item.id, value: item.id, text: item.name } });

        const nonDisclose = this.state.countryList
            .filter(item => item.id === 419)
            .map(item => { return { key: item.id, value: item.id, text: item.name } });

        const countryListFull = countryList.concat(nonDisclose);

        const genderList = this.getSettingByName("Gender").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const languageList = this.getSettingByName("Language").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const levelList = this.getSettingByName("Language Level").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const degreeList = this.getSettingByName("Degree").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const qualificationList = this.getSettingByName("Professional Qualification").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const universityList = this.getSettingByName("University & Institution").map(item => { return { key: item.value, value: item.id, text: item.value } });

        return (
            <div style={{ gridTemplateRows: '1fr auto auto', display: 'grid', height: '100%', width: '100%' }}>
                <div style={{ overflow: 'auto' }}>
                    <label className="p5 fsBig2 ml20 fwBold"><u>{'User Profile'}</u></label>
                    <br/>
                    <label className="p5 fs16 ml20 fwBold">{'Review'}</label>
                    <p className="p5 fsBig ml20 ">
                        Please review your profile below. To update your profile, you may directly edit each field below.  Once you have made your updates, 
                        please save your changes by clicking on the “Submit” button at the bottom of the page. 
                    </p>

                    <div className="dealForm">
                        <div className='container-fluid'>
                            <div className='row'>
                                <label className="p5 fs16 ml20"><b>{'Name: '}</b>{name}</label>
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Gender: '} <span style = {{color: "red"}}>*</span></label>
                            </div>

                            {/* Gender */}

                            <div className='row col-3'>
                                <Dropdown
                                    placeholder='Select Values'
                                    name={'gender'}
                                    options={genderList}
                                    onChange={this.handleDropDownChange}
                                    value={profileSummary['gender']}
                                    clearable
                                    selection
                                    fluid
                                    search
                                />
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Career Start Date: '}</label>
                            </div>

                            {/* Career Start Date */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'When did you start your career? '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <input type="number"
                                        placeholder="Year (YYYY)"
                                        name={'career_start_date'}
                                        autoComplete="off"
                                        value={profileSummary['career_start_date'] === null ? '' : profileSummary['career_start_date']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                        min="1900"
                                        max="2100"
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Education: '}</label>
                            </div>

                            {/* Degree 1 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Degree 1: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_1'}
                                        options={degreeList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_1']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'degree_1_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_1_other'] === null ? '' : profileSummary['degree_1_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Major: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_1_major'}
                                        autoComplete="off"
                                        value={profileSummary['degree_1_major'] === null ? '' : profileSummary['degree_1_major']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Institution: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_1_school'}
                                        options={universityList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_1_school']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_1_school_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_1_school_other'] === null ? '' : profileSummary['degree_1_school_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_1_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_1_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Degree 2 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Degree 2: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_2'}
                                        options={degreeList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_2']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'degree_2_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_2_other'] === null ? '' : profileSummary['degree_2_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Major: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_2_major'}
                                        autoComplete="off"
                                        value={profileSummary['degree_2_major'] === null ? '' : profileSummary['degree_2_major']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Institution: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_2_school'}
                                        options={universityList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_2_school']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_2_school_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_2_school_other'] === null ? '' : profileSummary['degree_2_school_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_2_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_2_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Degree 3 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Degree 3: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_3'}
                                        options={degreeList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_3']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'degree_3_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_3_other'] === null ? '' : profileSummary['degree_3_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Major: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_3_major'}
                                        autoComplete="off"
                                        value={profileSummary['degree_3_major'] === null ? '' : profileSummary['degree_3_major']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Institution: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_3_school'}
                                        options={universityList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_3_school']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_3_school_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_3_school_other'] === null ? '' : profileSummary['degree_3_school_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_3_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_3_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Degree 4 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Degree 4: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_4'}
                                        options={degreeList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_4']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'degree_4_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_4_other'] === null ? '' : profileSummary['degree_4_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Major: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_4_major'}
                                        autoComplete="off"
                                        value={profileSummary['degree_4_major'] === null ? '' : profileSummary['degree_4_major']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Institution: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_4_school'}
                                        options={universityList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_4_school']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="Enter Response"
                                        name={'degree_4_school_other'}
                                        autoComplete="off"
                                        value={profileSummary['degree_4_school_other'] === null ? '' : profileSummary['degree_4_school_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'degree_4_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['degree_4_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Professional Qualifications & Certifications: '}</label>
                            </div>
                            <br/>

                            {/* Qualification 1 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Qualification 1: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_1'}
                                        options={qualificationList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_1']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'qualification_1_other'}
                                        autoComplete="off"
                                        value={profileSummary['qualification_1_other'] === null ? '' : profileSummary['qualification_1_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_1_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_1_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Qualification 2 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Qualification 2: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_2'}
                                        options={qualificationList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_2']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'qualification_2_other'}
                                        autoComplete="off"
                                        value={profileSummary['qualification_2_other'] === null ? '' : profileSummary['qualification_2_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_2_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_2_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Qualification 3 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Qualification 3: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_3'}
                                        options={qualificationList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_3']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'qualification_3_other'}
                                        autoComplete="off"
                                        value={profileSummary['qualification_3_other'] === null ? '' : profileSummary['qualification_3_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Country: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'qualification_3_country'}
                                        options={countryList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['qualification_3_country']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Languages Spoken (other than English): '}</label>
                            </div>
                            <br/>

                            {/* Language 1 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 1: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_1'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_1']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_1_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_1_other'] === null ? '' : profileSummary['language_1_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_1_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_1_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Language 2 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 2: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_2'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_2']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_2_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_2_other'] === null ? '' : profileSummary['language_2_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_2_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_2_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Language 3 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 3: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_3'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_3']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_3_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_3_other'] === null ? '' : profileSummary['language_3_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_3_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_3_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Language 4 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 4: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_4'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_4']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_4_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_4_other'] === null ? '' : profileSummary['language_4_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_4_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_4_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Language 5 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 5: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_5'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_5']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_5_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_5_other'] === null ? '' : profileSummary['language_5_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_5_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_5_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Language 6 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Language 6: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_6'}
                                        options={languageList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_6']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                                <div className = 'col-3'>
                                    <input type="text"
                                        placeholder="If Other, please specify"
                                        name={'language_6_other'}
                                        autoComplete="off"
                                        value={profileSummary['language_6_other'] === null ? '' : profileSummary['language_6_other']}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className = 'col-2 offset-1'>
                                    <label className="p5 fsBig ml20 fwBold">{'Level: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'language_6_level'}
                                        options={levelList}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['language_6_level']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            <div className='row'>
                                <label className="p5 fs16 ml20 fwBold">{'Personal Background: '}</label>
                            </div>
                            <br/>

                            {/* Home Country 1 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 1: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_1'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_1']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Home Country 2 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 2: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_2'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_2']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Home Country 3 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 3: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_3'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_3']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Home Country 4 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 4: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_4'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_4']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Home Country 5 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 5: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_5'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_5']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Home Country 6 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Home Country 6: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'home_country_6'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['home_country_6']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            {/* Citizenship 1 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Citizenship 1: '} <span style = {{color: "red"}}>*</span></label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'citizenship_1'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['citizenship_1']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Citizenship 2 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Citizenship 2: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'citizenship_2'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['citizenship_2']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Citizenship 3 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Citizenship 3: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'citizenship_3'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['citizenship_3']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>

                            {/* Citizenship 4 */}

                            <div className='row'>
                                <div className = 'col-2'>
                                    <label className="p5 fsBig ml20 fwBold">{'Citizenship 4: '}</label>
                                </div>
                                <div className = 'col-3'>
                                    <Dropdown
                                        placeholder='Select Values'
                                        name={'citizenship_4'}
                                        options={countryListFull}
                                        onChange={this.handleDropDownChange}
                                        value={profileSummary['citizenship_4']}
                                        clearable
                                        selection
                                        fluid
                                        search
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>

                </div>
                <div style={{ height: 'auto' }}>
                    <div>
                        <div style={{ textAlign: "center", cursor: "pointer", color: "forestgreen" }} onClick={() => this.approveDB()}>
                            <FontAwesomeIcon style={{ height: "20px", cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit for Approval" />
                            <label style = {{cursor: "pointer", color: "forestgreen"}} className="p5 fsBig2 ml20 fwBold">Submit</label>
                        </div>
                    </div>
                </div>
                <div>
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "400px", height: "250px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    <div></div>
                            }
                        </section>
                    </div>
            </div>
        )
    }
}

export default UserProfile;