/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import FormDisplay from './formDisplay.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Our components
import '../deals.css';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

class Deal_Returns extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            open: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
    };

    handleChange(id, name, value) {
        this.props.handleChangeReturns(id, name, value);
    }

    handleDropDownChange(id, name, value) {
        this.props.handleChangeReturns(id, name, value);
    }

    handleChangeDate(id, name, value) {
        this.props.handleChangeReturns(id, name, value);
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    componentDidMount() {
        if(this.props.scenarioName === 'Tor Base Case') {
            this.setState({open: true})
        }
    }

    render() {
        const {dealReturnList} = this.props;
        return (
            <div className="spacer">

                <div style={{display: 'flex'}} onClick={(e) => this.togglePanel(e)} className='headerComponent'>
                    {
                        !this.state.open
                            ?
                            <div style = {{marginRight: '20px'}}>
                                <FontAwesomeIcon icon={faPlusSquare}/>
                            </div>
                            :
                            <div style = {{marginRight: '20px'}}>
                                <FontAwesomeIcon icon={faMinusSquare}/>
                            </div>
                    }

                    {`Deal Level: Return Components: ${this.props.scenarioName}`}
                </div>

                {
                    !this.state.open
                    ?
                        null
                    :
                        <div className = 'content'>
                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading="Unlevered IRR (%)"
                                fieldName="unlevered_irr_percentage"
                                dataField={dealReturnList[this.props.id]?.unlevered_irr_percentage}
                                dataCommentField={dealReturnList[this.props.id]?.unlevered_irr_percentage_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox"
                                suffix = '%'
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />

                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading="Expected Return From Equity Upside (%)"
                                fieldName="expected_return_percentage"
                                dataField={dealReturnList[this.props.id]?.expected_return_percentage}
                                dataCommentField={dealReturnList[this.props.id]?.expected_return_percentage_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox-in"
                                suffix = '%'
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />

                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading="Levered IRR (%)"
                                fieldName="levered_irr_percentage"
                                dataField={dealReturnList[this.props.id]?.levered_irr_percentage}
                                dataCommentField={dealReturnList[this.props.id]?.levered_irr_percentage_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox-in"
                                suffix = '%'
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />

                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading="Unlevered MOIC"
                                fieldName="unlevered_moic"
                                dataField={dealReturnList[this.props.id]?.unlevered_moic}
                                dataCommentField={dealReturnList[this.props.id]?.unlevered_moic_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox-in"
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />

                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading="Levered MOIC"
                                fieldName="levered_moic"
                                dataField={dealReturnList[this.props.id]?.levered_moic}
                                dataCommentField={dealReturnList[this.props.id]?.levered_moic_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox-in"
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />

                            <FormDisplay 
                                fieldList = {this.props.fieldList} 
                                formType = "commentDisp"
                                heading={`Probability of Scenario ${this.props.id + 1} (%)`}
                                fieldName="return_scenario_probability"
                                dataField={dealReturnList[this.props.id]?.return_scenario_probability}
                                dataCommentField={dealReturnList[this.props.id]?.return_scenario_probability_comment}
                                handleChangeDate={this.handleChange} 
                                id = {this.props.id}
                                handleDropDownChange={this.handleDropDownChange} 
                                handleChange={this.handleChange}
                                textType = "number"
                                type="textbox"
                                suffix = '%'
                                required = {dealReturnList[this.props.id]?.quarter !== 'Underwriting' ? false : true}
                            />
                        </div>
                }
            </div>
        )
    }
}

export default Deal_Returns;