/**<import>****************************************************************************************************/
import React, { Component } from 'react';
//https://react.semantic-ui.com/modules/dropdown/
import FormDisplay from './components/formDisplay.js';

import ProgressBar from './components/progressbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faArrowCircleLeft, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';

// Our components
import './deals.css';

class Deal_Attribute_2 extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            projectName: "",
            faceValue: 0,
            marketValue: 0,
            percent: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
    };

    componentDidMount() {
        this.getProgress();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dealAttribute !== this.props.dealAttribute) {
            this.getProgress();
        }
    }

    getSettingByName(name) {
        const { settingList } = this.props;
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    handleChange(e) {
        this.props.handleChange(e);
    }

    handleDropDownChange(e, { name, value }) {
        this.props.handleDropDownChange(e, { name, value });
    }

    handleChangeDate(name, value) {
        this.props.handleChangeDate(name, value);
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    getProgress() {
        const { dealAttribute, fieldList } = this.props;
        var filterData, permission, required;
        var fillCount = 0;
        var count = 0;

        var columnList = ["is_assetbased_cashflow",
            "is_loan_underwritten",
            "is_hard_asset_collateral",
            "hard_asset_collateral_list",
            "is_cov_lite",
            "covenants_list",
            "source_of_deal",
            "has_financial_sponsor",
            "financial_sponsor_list",
            "has_leadership_role",
            "primary_deal_purpose",
            "secondary_deal_purpose",
            "expected_exit_date",
            "primary_exit_type_id",
            "primary_exit_type",
            "secondary_exit_type_id",
            "secondary_exit_type",
            "num_syndicate_leaders",
            "is_tor_only_lender"
        ]

        for (const key of columnList) {
            filterData = fieldList.filter(function (item) { return item.field_name === key }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (dealAttribute.portfolio === "Public") {
                required = false;
            }

            if (required === true && permission === 2) {
                if (key === "hard_asset_collateral_list" && dealAttribute["is_hard_asset_collateral"] === true) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if (key === "covenants_list" && dealAttribute["is_cov_lite"] === false) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if (key === "financial_sponsor_list" && dealAttribute["has_financial_sponsor"] === true) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if (key === "primary_exit_type" && dealAttribute["primary_exit_type_id"] === 72) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if (key === "secondary_exit_type" && dealAttribute["secondary_exit_type_id"] === 80) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if (key !== "hard_asset_collateral_list" && key !== "covenants_list" && key !== "financial_sponsor_list" && key !== "primary_exit_type" && key !== "secondary_exit_type") {
                    count += 1;
                    if (dealAttribute[key] || dealAttribute[key] === false) {
                        fillCount += 1;
                    }
                }
            }
        }

        this.setState({ percent: count === 0 ? 100 : this.roundToTwo(fillCount / count * 100) });
    }

    render() {
        const { dealAttribute } = this.props;

        const assetvscashList = this.getSettingByName("Asset Based vs. Cash Flow").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const loanunderwrittenList = this.getSettingByName("Was the loan underwritten based on LTV or cash flows, or both?").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const dealSourceList = this.getSettingByName("Source of Deal").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const structuringRoleList = this.getSettingByName("Did we have a leadership/structuring role?").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const primaryDealPurposeList = this.getSettingByName("Purpose of Deal (Primary)").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const secondaryDealPurposeList = this.getSettingByName("Purpose of Deal (Secondary)").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const primaryExitTypeList = this.getSettingByName("Expected Exit Type (Primary)").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const secondaryExitTypeList = this.getSettingByName("Expected Exit Type (Secondary)").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const syndicateLenderList = this.getSettingByName("How many lenders are in the syndicate?").map(item => { return { key: item.value, value: item.id, text: item.value } });

        return (
            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%' }}>
                <div>
                    <ProgressBar percent={this.state.percent} />
                </div>
                <div style={{ overflow: 'auto' }}>
                    <div className="dealForm">
                        <div className="spacer">
                            <div className="row">
                                <div className="column2 left2">
                                    <h3>General Information at Inception</h3>
                                </div>
                                <div className="column2 right2">
                                    <div className="column2 left">
                                        <h3>Response</h3>
                                    </div>
                                    <div className="column2 right">
                                        <h3>Comments</h3>
                                    </div>
                                </div>
                            </div>

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Day 1 Total Facility Amount"
                                fieldName="total_facility_amount"
                                dataField={dealAttribute?.total_facility_amount}
                                noComment={true}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                type="textbox"
                                textType="number"
                                calcType="million"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Asset Based vs. Cash Flow"
                                dataList={assetvscashList}
                                fieldName="is_assetbased_cashflow"
                                dataField={dealAttribute?.is_assetbased_cashflow}
                                dataCommentField={dealAttribute?.is_assetbased_cashflow_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Was the loan underwritten based on LTV, cashflows, or both?"
                                dataList={loanunderwrittenList}
                                fieldName="is_loan_underwritten"
                                dataField={dealAttribute?.is_loan_underwritten}
                                dataCommentField={dealAttribute?.is_loan_underwritten_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Is there hard asset collateral?"
                                fieldName="is_hard_asset_collateral"
                                dataField={dealAttribute?.is_hard_asset_collateral}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                handleChangeDate={this.handleChangeDate}
                                type="checkbox"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="If yes to the above, please list"
                                fieldName="hard_asset_collateral_list"
                                dataField={dealAttribute?.hard_asset_collateral_list}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox-in"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Is the position cov-lite?"
                                fieldName="is_cov_lite"
                                dataField={dealAttribute?.is_cov_lite}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                handleChangeDate={this.handleChangeDate}
                                type="checkbox"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="If no to the above, please list all financial and operational covenants"
                                fieldName="covenants_list"
                                dataField={dealAttribute?.covenants_list}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox-in"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Source of Deal"
                                dataList={dealSourceList}
                                fieldName="source_of_deal"
                                dataField={dealAttribute?.source_of_deal}
                                dataCommentField={dealAttribute?.source_of_deal_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Does this deal have a financial sponsor?"
                                fieldName="has_financial_sponsor"
                                dataField={dealAttribute?.has_financial_sponsor}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                handleChangeDate={this.handleChangeDate}
                                type="checkbox"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="If yes to the above, please list the sponsor."
                                fieldName="financial_sponsor_list"
                                dataField={dealAttribute?.financial_sponsor_list}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox-in"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Is Tor the only lender for this deal (excluding any co-investors brought in by Tor)?"
                                fieldName="is_tor_only_lender"
                                dataField={dealAttribute?.is_tor_only_lender}
                                dataCommentField={dealAttribute?.is_tor_only_lender_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                handleChangeDate={this.handleChangeDate}
                                type="checkbox"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Did we have a leadership/structuring role?"
                                dataList={structuringRoleList}
                                fieldName="has_leadership_role"
                                dataField={dealAttribute?.has_leadership_role}
                                dataCommentField={dealAttribute?.has_leadership_role_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Purpose of Deal (Primary)"
                                dataList={primaryDealPurposeList}
                                fieldName="primary_deal_purpose"
                                dataField={dealAttribute?.primary_deal_purpose}
                                dataCommentField={dealAttribute?.primary_deal_purpose_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Purpose of Deal (Secondary)"
                                dataList={secondaryDealPurposeList}
                                fieldName="secondary_deal_purpose"
                                dataField={dealAttribute?.secondary_deal_purpose}
                                dataCommentField={dealAttribute?.secondary_deal_purpose_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Expected Exit Date"
                                fieldName="expected_exit_date"
                                dataField={dealAttribute?.expected_exit_date}
                                dataCommentField={dealAttribute?.expected_exit_date_comment}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                type="datepick"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Expected Exit Type (Primary)"
                                dataList={primaryExitTypeList}
                                fieldName="primary_exit_type_id"
                                dataField={dealAttribute?.primary_exit_type_id}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="If 'Other' to the above, please explain"
                                fieldName="primary_exit_type"
                                dataField={dealAttribute?.primary_exit_type}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox-in"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Expected Exit Type (Secondary)"
                                dataList={secondaryExitTypeList}
                                fieldName="secondary_exit_type_id"
                                dataField={dealAttribute?.secondary_exit_type_id}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="If 'Other' to the above, please explain"
                                fieldName="secondary_exit_type"
                                dataField={dealAttribute?.secondary_exit_type}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox-in"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="How many lenders are in the syndicate?"
                                dataList={syndicateLenderList}
                                fieldName="num_syndicate_leaders"
                                dataField={dealAttribute?.num_syndicate_leaders}
                                dataCommentField={dealAttribute?.num_syndicate_leaders_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="dropdown"
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                heading="Please list some of the largest HF involved as PE-sponsors"
                                fieldName="counterparty"
                                dataField={dealAttribute?.counterparty}
                                dataCommentField={dealAttribute?.counterparty_comment}
                                handleChange={this.handleChange}
                                handleDropDownChange={this.handleDropDownChange}
                                type="textbox"
                                formType="commentDisp"
                                noComment={true}
                            />

                            <FormDisplay
                                fieldList={this.props.fieldList}
                                formType="commentDisp"
                                heading="Additional Notes: Please include any disclosures that you may have for this deal."
                                fieldName="deal_information_notes"
                                dataField={dealAttribute?.deal_information_notes}
                                handleChange={this.handleChange}
                                type="notes"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ paddingBottom: 15, height: '10px' }}>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(4)}><FontAwesomeIcon icon={faArrowCircleRight} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(2)} title="Back"><FontAwesomeIcon icon={faArrowCircleLeft} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.saveDB()} title="Save"><FontAwesomeIcon icon={faSave} /></div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.approveDB()}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit for Approval" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Deal_Attribute_2;