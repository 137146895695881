import React, { Component } from 'react';
import { Utils } from './../../../helper/utils';

class DealCreditMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData : []
        }
    }

    render() {
        return this.getTemplate();
    }
 
    getTemplate() {
        var chartData = null;
        // var data1 = [
        //     ['Type', 'Parent', 'Value'],
        //     ['Asset', null, 0],
        // ];
        // var data2 = [
        //     ['Type', 'Parent', 'Value'],
        //     ['Liabilities', null, 0],
        // ];

        if(this.props.chartData && this.props.chartData.length > 0) {
            
            chartData = this.props.chartData[0];
            var ltmebitda = chartData['LTM EBITDA']
            var seniorDebt = chartData['Senior Debt'];
            var pariPasu = chartData['Pari Pasu'];
            var torDebt = chartData['TOR Debt'];
            var equityValue = chartData['Equity Value'];

            seniorDebt = seniorDebt ?? 0;
            pariPasu = pariPasu ?? 0;
            torDebt = torDebt ?? 0;
            var enterpriseValue = seniorDebt + pariPasu + torDebt + equityValue;

            // data1 = [
            //     ['Type', 'Parent', 'Value'],
            //     ['Asset', null, 100],
            //     [`Enterprise Value ${Utils.agValueFormatter({format : 'millionsFormat1M', value :enterpriseValue})}`, 'Asset', enterpriseValue]
            // ];

            // data2 = [
            //     ['Type', 'Parent', 'Value'],
            //     ['Liabilities', null, 0],
            //     [`Senior Debt ${Utils.agValueFormatter({format : 'millionsFormat1M', value :seniorDebt})}`, 'Liabilities', seniorDebt],
            //     [`Pari Pasu ${Utils.agValueFormatter({format : 'millionsFormat1M', value : pariPasu})}`, 'Liabilities', pariPasu],
            //     [`TOR ${Utils.agValueFormatter({format : 'millionsFormat1M', value : torDebt})}`, 'Liabilities', torDebt],
            //     [`Equity Value ${Utils.agValueFormatter({format : 'millionsFormat1M', value : equityValue})}`, 'Liabilities', equityValue]
            // ];
            var seniorDebtValue = `Senior Debt ${Utils.agValueFormatter({format : 'millionsFormat1M', value :seniorDebt})}`;
            var pariPasuValue = `Pari Pasu ${Utils.agValueFormatter({format : 'millionsFormat1M', value : pariPasu})}`;
            var torDebtValue = `TOR ${Utils.agValueFormatter({format : 'millionsFormat1M', value : torDebt})}`;
            var equityValueValue = `Equity Value ${Utils.agValueFormatter({format : 'millionsFormat1M', value : equityValue})}`;
            var enterpriseValueValue = `Enterprise Value ${Utils.agValueFormatter({format : 'millionsFormat1M', value :enterpriseValue})}`;

            var seniorDebtPer = {height: `${seniorDebt / enterpriseValue * 100}%`, width: "100%", background : '#5A89E6', display: 'flex', justifyContent: 'center', alignItems: 'center'};
            var pariPasuTorPer = {height: `${(pariPasu + torDebt) / enterpriseValue * 100}%`, width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' };
            var pariPasuPer = {width: `${pariPasu / (pariPasu + torDebt) * 100}%`, height: '100%', background : '#91B7FF', float:'left', display: 'flex', justifyContent: 'center', alignItems: 'center' };
            var torDebtPer = {width: `${torDebt / (pariPasu + torDebt) * 100}%`, height: '100%', background : '#73B9EE', float:'left', display: 'flex', justifyContent: 'center', alignItems: 'center' };
            var equityPer = {height: `${equityValue / enterpriseValue * 100}%`, width: "100%", background : '#4A71BD', display: 'flex', justifyContent: 'center', alignItems: 'center' };
        }
        return (
            <tag>
                { (this.props.chartData && this.props.chartData.length > 0) ? 
                        <div style={{height: '100%', width: '100%'}}>
                        <div style = {{display: 'flex', justifyContent: 'center'  }}>
                            <h3 style = {{color: "gray",margin: "0"}}>LTM EBITDA</h3> <br/>
                        </div>
                        <div style = {{display: 'flex', justifyContent: 'center'  }}>
                            <h3 style = {{margin: "0"}}>{`${Utils.agValueFormatter({format : 'millionsFormat1M', value :ltmebitda})}`}</h3>
                        </div>
                        <div style={{height: '90%', width: '100%',  display: 'flex', justifyContent: 'center'}}>
                        <div style = {{height: '90%', width: '50%', display: 'flex', padding: '0px 0px 0px 0px'}}>
                            <div style = {{height: '100%', width: '600px', float:'left'}}>
                                <div style = {{ background : '#A587D9', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <b>Assets</b>
                                </div>
                                <div style = {{height: '100%', background : '#387CFF', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <b>{enterpriseValueValue}</b>
                                </div>
                            </div>
                            <div style = {{height: '100%', width: '600px', float:'left'}}>
                                <div style = {{ background : '#A5B7D9', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <b>Liabilities</b>
                                </div>
                                <div style = {{height: '100%'}}>
                                    <div style = {seniorDebtPer} >
                                        <b>{seniorDebtValue}</b>
                                    </div>
                                    <div style = {pariPasuTorPer} >
                                            <div style = {pariPasuPer} >
                                                <b>{pariPasuValue}</b>
                                            </div>
                                            <div style = {torDebtPer} >
                                                <b>{torDebtValue}</b>
                                            </div> 
                                    </div>
                                    <div style = {equityPer} >
                                        <b>{equityValueValue}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    </div>
                    : null 
                }
            </tag>
        );
    }
}
export default DealCreditMetrics;