import React, { Component } from 'react';
import TorGrid from './../torgrid/torgrid';

class FxOverview extends Component {
    render() {
        return this.getTemplate();
    }


    getTemplate() {
        const { Fund } = this.props;
        if(Fund) {
            return (
                <div style={{ height: '100%', width: '100%'}}>
                    <div style={{ height: '100%', width: '100%'}}>
                        <TorGrid
                            guikey="fxReport"
                            rowData = {this.props.gridData}
                            genReport={true}
                            emailReport={this.props.emailReport}
                            isHideDateInputs={true}
                            GridTypeSelected = {"Currency Summary"}
                            gridIdentityColumn="dealID"
                            className="ag-theme-alpine"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default FxOverview;