import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import TorGrid from './../torgrid/torgrid';
import {approvalBALService} from '../../bal/approval.bal';

class ApprovalGrid extends Component {
    constructor(props) {
        super(props); 
        this.state = {
          id : this.props.id,
          type: this.props.type,
          num: this.props.num,
          gridData: []
        };

        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refresh();
    }
    
    componentDidUpdate(prevProps) { 
        if (prevProps.num !== this.props.num) {
            this.refresh();
        }
    }

    refresh(modalVisible = false) {
        approvalBALService.getRowData({type : 'CreditProposal', id : this.state.id}).then(data => {            
            this.setState({ 
                gridData: data,
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Grid data has been refreshed!'
                }
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the grid data :",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); });
    }

    render() {
        if(this.state.redirectTo)
          return <Redirect to={ this.state.redirectTo } />;
        return this.getTemplate();
    }

    componentDidMount() {

    }
    
    setAgGridAPI(api) {
        this.gridApi = api; 
    }

    getTemplate() {
        const { gridData } = this.state;
        return (
            gridData.length>0 ?
            <div style={{ width:"100%", height:"100%" }} >
                <TorGrid
                    setAgGridAPI = {this.setAgGridAPI} 
                    domLayout="autoHeight"
                    enableFilter={false}

                    isHideDateInputs={true}
                    isHideSaveGridLayout={true}
                    rowData={gridData}
                    rowGroupPanelShow="none"
                    sideBar='none'
                    gridTitle=''

                    className="dashboardgridNoTotal"
                    rowSelection="multiple"
                    groupDefaultExpanded = {-1}
                    groupIncludeTotalFooter={false}
  
                    defaultColDef={this.props.defaultColDef ??
                    {
                      sortable: false
                      , filter: false
                      , floatingFilter: false
                      , enableRowGroup: true
                      , resizable: true
                      , suppressSizeToFit: false
                      , flex: 1
                    }
                    }

                    guikey="approvals"
                    gridIdentityColumn="id"
                />
            </div>
                :<tag></tag>
        );
    }
}

export default ApprovalGrid;