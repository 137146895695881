import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');

const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Public Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'Country', 'col': 'Country', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'Levered Unhedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Levered Unhedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Levered Unhedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Levered Hedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Levered Hedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Levered Hedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Unhedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Unhedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Unhedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Hedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Hedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80},
            {'display': 'Unlevered Hedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, minWidth : 70, maxWidth : 80}
        ]};
}

export const portfolioPerformanceSummaryBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'deal'
        ,pinned: 'left'
        ,resizable: false
        ,hide: false
        ,cellRendererParams:  { suppressCount: true } 
       };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}