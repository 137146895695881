/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Collapse } from 'reactstrap';
import { dealBALService } from '../../bal/deal.bal';
import { Prompt } from "react-router-dom";

import Modal from 'react-awesome-modal';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faHome } from '@fortawesome/free-solid-svg-icons';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from './../../config';
import { Session } from '../../helper/session';


import './deals.css';
import DealAttributes from './deal_attributes_1.js';
import DealAttributes2 from './deal_attributes_2.js';
import TrancheAttributes from './tranche_attribute.js';
import CreditMetrics from './credit_metrics.js';
import TorGrid from './../torgrid/torgrid';
import DealRisks from './deal_risks';
import ToastMessage from "./components/toastMessage";

class Deals extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));        
        const approverRole = user.userRoles.filter(function (item) { return item === "Approver" });

        this.state = {
            columnDefs: [
                {
                    headerName: "Deal Name", field: "instrument_code", width: 245, sortable: true, filter: true, resizable: true,
                    cellRenderer: (params) => {
                        var link = document.createElement('a');
                        link.href = '#';
                        link.innerText = params.value;
                        link.addEventListener('click', (e) => {
                            e.preventDefault();
                            this.state.isOpenGrid = false;
                            this.setState({ dealAttribute: { ...this.state.dealAttribute, InvestmentCode: params.data.instrument_code } });
                            this.setState({ tabindex: 1 });
                        });
                        return link;
                    }
                },
                { headerName: "Company Description", field: "company_description", width: 245, sortable: true, filter: true, resizable: true },
            ],
            rowData: [],
            ws: null,
            dealDictionary: {},
            user: user,
            isApprover: approverRole.length > 0,
            showPopup: false,
            display: "none",
            deal: null,
            quarter: "Underwriting",
            bbg_quarter: null,
            dealName: "",
            isOpenGrid: true,
            projectName: "",
            analystDate: null,
            analyst1Weight: null,
            analyst1Name: null,
            analyst2Weight: null,
            analyst2Name: null,
            analyst3Weight: null,
            analyst3Name: null,
            analyst4Weight: null,
            analyst4Name: null,
            faceValue: 0,
            marketValue: 0,
            torPositionVal: 0,
            genevaDealName: "",
            dealAttributeList: [],
            positionList: [],
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            dealList: [],
            bbgDealList: [],
            dealAttribute: {
                deal_id: null,
                country_of_risk_id: null,
                gics_sector_id: null,
                gics_industry_group_id: null,
                gics_industry_id: null,
                gics_sub_industry_id: null,
                gics_nace_id: null,
                eligible_climate_mitigation_activity: null,
                eligible_climate_adaptation_activity: null,
                bics_sector_id: null,
                bics_industry_group_id: null,
                bics_industry_id: null,
                bics_sub_industry_id: null,
                snp_corporate_family_rating_id: null,
                moodys_corporate_family_rating_id: null,
                portfolio: null,
                tacf_sub_strategy: null,
                tacof_sub_strategy: null,
                market_risk_sensitivity: null,
                equity_upside: null,
                equity_upside_form: null,
                is_new_issue: null,
                upfront_fee: null,
                total_facility_amount: null,
                company_description: null,


                is_assetbased_cashflow: null,
                is_loan_underwritten: null,
                is_hard_asset_collateral: null,
                hard_asset_collateral_list: null,
                is_cov_lite: null,
                covenants_list: null,
                source_of_deal: null,
                has_financial_sponsor: null,
                financial_sponsor_list: null,
                has_leadership_role: null,
                primary_deal_purpose: null,
                secondary_deal_purpose: null,
                expected_exit_date: null,
                primary_exit_type_id: null,
                primary_exit_type: null,
                secondary_exit_type_id: null,
                secondary_exit_type: null,
                num_syndicate_leaders: null,
                is_tor_only_lender: null,


                is_assetbased_cashflow_comment: null,
                is_loan_underwritten_comment: null,
                is_hard_asset_collateral_comment: null,
                hard_asset_collateral_list_comment: null,
                is_cov_lite_comment: null,
                covenants_list_comment: null,
                source_of_deal_comment: null,
                has_financial_sponsor_comment: null,
                financial_sponsor_list_comment: null,
                has_leadership_role_comment: null,
                primary_deal_purpose_comment: null,
                secondary_deal_purpose_comment: null,
                expected_exit_date_comment: null,
                primary_exit_type_id_comment: null,
                primary_exit_type_comment: null,
                secondary_exit_type_id_comment: null,
                secondary_exit_type_comment: null,
                num_syndicate_leaders_comment: null,
                is_tor_only_lender_comment: null,

                deal_information_notes: null
            },
            trancheAttribute: {
                deal_id: null,
                investment_code: null,
                asset_type: null,
                credit_ranking: null,
                holdco_vs_opco: null,
                has_defaulted: null,
                default_date: null,
                makewhole: null,
                amortization: null,
                is_non_recourse: null
            },
            trancheAttributeList: [],
            dealMetrics: {
                deal_id: null,
                is_underwriting: true,
                quarter: "Underwriting",
                currency: "USD",
                rate: 1.0,
                dispRate: 1.0,

                senior_debt: null,
                secured_debt: null,
                parri_passu_debt: null,
                senior_debt_tranche: null,
                total_debt_entry: null,
                distressed_market_value: null,
                cash_balance_entry: null,
                gross_debt: null,
                equity_book_value: null,
                equity_market_value: null,
                equity_value_dcf: null,
                unadjusted_ltm_ebitda: null,
                adjusted_ltm_ebitda: null,
                appraised_value: null,
                implied_ownership: null,
                purchased_price: null,
                ltm_interest_expense: null,
                ltm_revenue: null,
                ltm_revenue_oceania: null,
                ltm_revenue_africa: null,
                ltm_revenue_asia: null,
                ltm_revenue_europe: null,
                ltm_revenue_north_america: null,
                ltm_revenue_south_america: null,
                ltm_operation_cashflow: null,
                ltm_capital_expenditure: null,
                total_leverage_multiple: null,
                total_ltv_percentage: null,
                tor_leverage_attachment: null,
                tor_leverage_detachment: null,
                tor_ltv_detachment: null,
                tor_ltv_attachment: null,
                esg_rating_value: null,
                is_ESG_in_Memo: null,

                senior_debt_comment: null,
                secured_debt_comment: null,
                parri_passu_debt_comment: null,
                senior_debt_tranche_comment: null,
                total_debt_entry_comment: null,
                distressed_market_value_comment: null,
                cash_balance_entry_comment: null,
                gross_debt_comment: null,
                equity_book_value_comment: null,
                equity_market_value_comment: null,
                equity_value_dcf_comment: null,
                unadjusted_ltm_ebitda_comment: null,
                adjusted_ltm_ebitda_comment: null,
                appraised_value_comment: null,
                implied_ownership_comment: null,
                purchased_price_comment: null,
                ltm_interest_expense_comment: null,
                ltm_revenue_comment: null,
                ltm_revenue_oceania_comment: null,
                ltm_revenue_africa_comment: null,
                ltm_revenue_asia_comment: null,
                ltm_revenue_europe_comment: null,
                ltm_revenue_north_america_comment: null,
                ltm_revenue_south_america_comment: null,
                ltm_operation_cashflow_comment: null,
                ltm_capital_expenditure_comment: null,
                total_leverage_multiple_comment: null,
                total_ltv_percentage_comment: null,
                tor_leverage_attachment_comment: null,
                tor_leverage_detachment_comment: null,
                tor_ltv_detachment_comment: null,
                tor_ltv_attachment_comment: null,
                esg_rating_comment: null,
                is_ESG_in_Memo_comment: null,

                total_ranking_drawn_debt: null,
                total_drawn_debt_tranche: null,
                has_borrower_missed_payment: null,
                has_borrower_breach_covenant: null,
                enterprise_value: null,
                debt_equity_ratio: null,
                esg_environment_rating_value: null,
                esg_social_rating_value: null,
                esg_governance_rating_value: null,

                total_ranking_drawn_debt_comment: null,
                total_drawn_debt_tranche_comment: null,
                has_borrower_missed_payment_comment: null,
                has_borrower_breach_covenant_comment: null,
                enterprise_value_comment: null,
                debt_equity_ratio_comment: null,
                esg_environment_rating_comment: null,
                esg_social_rating_comment: null,
                esg_governance_rating_comment: null,

                as_of_date: null,
                as_of_date_comment: null,

                deal_metrics_notes: null
            },
            dealPriorMetrics: {
                deal_id: null,
                is_underwriting: true,
                quarter: "Underwriting",

                senior_debt: null,
                secured_debt: null,
                parri_passu_debt: null,
                senior_debt_tranche: null,
                total_debt_entry: null,
                distressed_market_value: null,
                cash_balance_entry: null,
                gross_debt: null,
                equity_book_value: null,
                equity_market_value: null,
                equity_value_dcf: null,
                unadjusted_ltm_ebitda: null,
                adjusted_ltm_ebitda: null,
                appraised_value: null,
                implied_ownership: null,
                purchased_price: null,
                ltm_interest_expense: null,
                ltm_revenue: null,
                ltm_revenue_oceania: null,
                ltm_revenue_africa: null,
                ltm_revenue_asia: null,
                ltm_revenue_europe: null,
                ltm_revenue_north_america: null,
                ltm_revenue_south_america: null,
                ltm_operation_cashflow: null,
                ltm_capital_expenditure: null,
                total_leverage_multiple: null,
                total_ltv_percentage: null,
                tor_leverage_attachment: null,
                tor_leverage_detachment: null,
                tor_ltv_detachment: null,
                tor_ltv_attachment: null,
                esg_rating_value: null,
                is_ESG_in_Memo: null,

                senior_debt_comment: null,
                secured_debt_comment: null,
                parri_passu_debt_comment: null,
                senior_debt_tranche_comment: null,
                total_debt_entry_comment: null,
                distressed_market_value_comment: null,
                cash_balance_entry_comment: null,
                gross_debt_comment: null,
                equity_book_value_comment: null,
                equity_market_value_comment: null,
                equity_value_dcf_comment: null,
                unadjusted_ltm_ebitda_comment: null,
                adjusted_ltm_ebitda_comment: null,
                appraised_value_comment: null,
                implied_ownership_comment: null,
                purchased_price_comment: null,
                ltm_interest_expense_comment: null,
                ltm_revenue_comment: null,
                ltm_revenue_oceania_comment: null,
                ltm_revenue_africa_comment: null,
                ltm_revenue_asia_comment: null,
                ltm_revenue_europe_comment: null,
                ltm_revenue_north_america_comment: null,
                ltm_revenue_south_america_comment: null,
                ltm_operation_cashflow_comment: null,
                ltm_capital_expenditure_comment: null,
                total_leverage_multiple_comment: null,
                total_ltv_percentage_comment: null,
                tor_leverage_attachment_comment: null,
                tor_leverage_detachment_comment: null,
                tor_ltv_detachment_comment: null,
                tor_ltv_attachment_comment: null,
                esg_rating_comment: null,
                is_ESG_in_Memo_comment: null,

                total_ranking_drawn_debt: null,
                total_drawn_debt_tranche: null,
                has_borrower_missed_payment: null,
                has_borrower_breach_covenant: null,
                enterprise_value: null,
                debt_equity_ratio: null,
                esg_environment_rating_value: null,
                esg_social_rating_value: null,
                esg_governance_rating_value: null,

                total_ranking_drawn_debt_comment: null,
                total_drawn_debt_tranche_comment: null,
                has_borrower_missed_payment_comment: null,
                has_borrower_breach_covenant_comment: null,
                enterprise_value_comment: null,
                debt_equity_ratio_comment: null,
                esg_environment_rating_comment: null,
                esg_social_rating_comment: null,
                esg_governance_rating_comment: null,

                as_of_date: null,
                as_of_date_comment: null,

                deal_metrics_notes: null
            },
            dealRisk: {
                deal_id: null,
                is_underwriting: true,
                quarter: "Underwriting",
                energy_1_id: null,
                energy_1_direction: null,
                energy_1_price: null,
                energy_1_comment: null,

                energy_2_id: null,
                energy_2_direction: null,
                energy_2_price: null,
                energy_2_comment: null,

                metal_1_id: null,
                metal_1_direction: null,
                metal_1_price: null,
                metal_1_comment: null,

                metal_2_id: null,
                metal_2_direction: null,
                metal_2_price: null,
                metal_2_comment: null,

                currency_1_id: null,
                currency_1_direction: null,
                currency_1_price: null,
                currency_1_comment: null,

                currency_2_id: null,
                currency_2_direction: null,
                currency_2_price: null,
                currency_2_comment: null,

                gics_ig_1_id: null,
                gics_ig_1_direction: null,
                gics_ig_1_price: null,
                gics_ig_1_comment: null,

                gics_ig_2_id: null,
                gics_ig_2_direction: null,
                gics_ig_2_price: null,
                gics_ig_2_comment: null,

                miscellanious_1: null,
                miscellanious_1_comment: null,

                miscellanious_2: null,
                miscellanious_2_comment: null,

                counterparty: null,
                counterparty_comment: null,

                real_estate_1_type: null,
                real_estate_1_desc: null,
                real_estate_1_is_development: null,
                real_estate_1_location: null,
                real_estate_1_deal_percentage: null,

                real_estate_2_type: null,
                real_estate_2_desc: null,
                real_estate_2_is_development: null,
                real_estate_2_location: null,
                real_estate_2_deal_percentage: null,

                real_estate_3_type: null,
                real_estate_3_desc: null,
                real_estate_3_is_development: null,
                real_estate_3_location: null,
                real_estate_3_deal_percentage: null
            },
            gridData: [],
            dealReturnList: [],
            dealAttributeDb: {},
            dealMetricsDb: {},
            dealReturnListDb: [],
            trancheAttributeListDb: [],
            dealRiskDb: {},
            fieldList: [],
            settingList: [],
            percent: 0,
            totalPercent: 0,
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            isDataChanged: false,
            isDealChange: false,
            toggleRefresh: true
        }

        dealBALService.getAllBbgDeals().then(data => {
            this.setState({ bbgDealList: data });
        }, error => { console.log(error); });

        dealBALService.getRowData().then(data => {
            this.setState({ gridData: data, dealList: data });
        }, error => { console.log(error); });

        dealBALService.getFields().then(data => { this.setState({ fieldList: data }); }, error => { console.log(error); });
        dealBALService.getAllSettings().then(data => { this.setState({ settingList: data }); }, error => { console.log(error); });

        this.refresh = this.refresh.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.addDeal = this.addDeal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleChangeRisk = this.handleChangeRisk.bind(this);
        this.handleChangeMetrics = this.handleChangeMetrics.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleDropDownChangeMetrics = this.handleDropDownChangeMetrics.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleDropDownChangeRisk = this.handleDropDownChangeRisk.bind(this);
        this.handleDropDownChangeDeal = this.handleDropDownChangeDeal.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeDateRisk = this.handleChangeDateRisk.bind(this);
        this.handleChangeDateMetrics = this.handleChangeDateMetrics.bind(this);
        this.setTabIndex = this.setTabIndex.bind(this);
        this.saveDB = this.saveDB.bind(this);
        this.handleDropDownChangeTranche = this.handleDropDownChangeTranche.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeReturns = this.handleChangeReturns.bind(this);
        this.handleChangeTranche = this.handleChangeTranche.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeErrorMessage = this.closeErrorMessage.bind(this);
        this.approveDB = this.approveDB.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.approveDeals = this.approveDeals.bind(this);
        this.round = this.round.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleClickBbg = this.handleClickBbg.bind(this);
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
        this.roundToTwo = this.roundToTwo.bind(this);
    };

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    refresh() {
        if (this.state.isOpenGrid === false && this.state.dealAttribute.deal_id !== null && this.state.isDataChanged === true) {
            this.setState({
                modalVisible: true,
                modalType: 'saveClose',
                modalHandler: 'refresh',
                modalHeading: 'Do you want to save changes?'
            })
        } else if (this.state.isOpenGrid === true) {
            dealBALService.getRowData().then(data => {
                this.setState({ gridData: data, dealList: data });
            }, error => { console.log(error); });
        }
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true});
        } else if (this.state.modalHandler === 'save') {
            this.saveDB();
            this.setState({isOpenGrid: true});
        } else {
            this.setState({isOpenGrid: true});
        }

        this.setState({
            modalVisible: false
        });
    }

    addDeal() {
        this.setState({ isOpenGrid: false, tabindex: 1 });
    }

    getProgress() {
        const { dealAttribute, analyst1Name, projectName, analyst1Weight, trancheAttribute, trancheAttributeList, dealMetrics, dealReturnList, fieldList, positionList } = this.state;
        const { analyst2Name, analyst2Weight, analyst3Name, analyst3Weight, analyst4Name, analyst4Weight, analystDate } = this.state;
        let field, filterData, permission, required;
        var fillCount = 0;
        var totalCount = 0;
        var count = 0;
        var totalFill = 0;

        var listVal = "";
        var errorList = [];

        var columnList = [
            { label: "Deal", id: "deal_id" },
            { label: "Project Name", id: "projectName" },
            { label: "Country of Risk", id: "country_of_risk_id" },
            { label: "GICS Sector", id: "gics_sector_id" },
            { label: "Gics Industry Group", id: "gics_industry_group_id" },
            { label: "GICS Industry", id: "gics_industry_id" },
            { label: "GICS Sub Industry", id: "gics_sub_industry_id" },
            { label: "BICS Sector", id: "bics_sector_id" },
            { label: "BICS Industry Group", id: "bics_industry_group_id" },
            { label: "BICS Industry", id: "bics_industry_id" },
            { label: "BICS Sub Industry", id: "bics_sub_industry_id" },
            { label: "S&P Corporate Family Rating", id: "snp_corporate_family_rating_id" },
            { label: "Moody's Corporate Family rating", id: "moodys_corporate_family_rating_id" },
            { label: "Portfolio", id: "portfolio" },
            { label: "TACF Sub Strategy", id: "tacf_sub_strategy" },
            { label: "TACOF Sub Strategy", id: "tacof_sub_strategy" },
            { label: "Analyst 1 Name", id: "analyst1Name" },
            { label: "Analyst Weighting Date", id: "analystDate" },
            { label: "Analyst 1 Weight", id: "analyst1Weight" },
            { label: "Analyst 2 Name", id: "analyst2Name" },
            { label: "Analyst 3 Name", id: "analyst3Name" },
            { label: "Analyst 4 Name", id: "analyst4Name" },
            { label: "Market Risk Sensitivity", id: "market_risk_sensitivity" },
            { label: "Equity Upside?", id: "equity_upside" },
            { label: "Form of Equity Upside", id: "equity_upside_form" },
            { label: "Company Description", id: "company_description" }
        ]

        for (const key of columnList) {
            field = key.id;
            filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (field === "analyst1Weight" && permission !== 2) {
                required = false;
            } else if(field === "analyst1Weight" && permission === 2) {
                var sum = parseInt(Number.isNaN(analyst1Weight) ? 0 : analyst1Weight ?? 0) + parseInt(Number.isNaN(analyst2Weight) ? 0 : analyst2Weight ?? 0) + parseInt(Number.isNaN(analyst3Weight) ? 0 : analyst3Weight ?? 0) + parseInt(Number.isNaN(analyst4Weight) ? 0 : analyst4Weight ?? 0);
                if(((sum <= 99.9 || sum > 100) && sum !== 0) || Number.isNaN(sum)) {
                    errorList.push("Analyst Weights should sum to 100%");
                }

                if(((analyst1Name ?? '111') === (analyst2Name ?? '222')) || ((analyst1Name ?? '111') === (analyst3Name ?? '333')) || ((analyst1Name ?? '111') === (analyst4Name ?? '444'))
                 || ((analyst2Name ?? '222') === (analyst3Name ?? '333'))  || ((analyst2Name ?? '222') === (analyst4Name ?? '444'))  || ((analyst3Name ?? '333') === (analyst4Name ?? '444')))  
                {
                    errorList.push("Please enter different analysts for position 1, 2, 3, 4");
                }
            }

            if (required && permission === 2) {
                if (field === "equity_upside_form" && dealAttribute["equity_upside"] === true) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if(field !== "equity_upside_form") {
                    if(field !== "analyst2Name" && field !== "analyst3Name" && field !== "analyst4Name") {
                        count += 1;
                    }
                    
                    if (field === "tacf_sub_strategy" || field === "tacof_sub_strategy") {
                        if (dealAttribute["tacf_sub_strategy"] || dealAttribute["tacof_sub_strategy"]) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    } else if (field === "projectName") {
                        if (projectName) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analystDate") {
                        if (analystDate) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analyst1Name") {
                        if (analyst1Name) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analyst1Weight") {
                        if (analyst1Weight || analyst1Weight === 0) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analyst2Name") {
                        if (analyst2Weight && (analyst2Name === null || analyst2Name === undefined || analyst2Name === '')) {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analyst3Name") {
                        if (analyst3Weight && (analyst3Name === null || analyst3Name === undefined || analyst3Name === '')) {
                            listVal += key.label + ", "
                        }
                    } else if (field === "analyst4Name") {
                        if (analyst4Weight && (analyst4Name === null || analyst4Name === undefined || analyst4Name === '')) {
                            listVal += key.label + ", "
                        }
                    } else {
                        if (dealAttribute[field] || dealAttribute[field] === false || dealAttribute[field] === 0) {
                            fillCount += 1;
                        } else {
                            listVal += key.label + ", "
                        }
                    }
                }
            }

            if (required) {
                if (field === "equity_upside_form" && dealAttribute["equity_upside"] === true) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } else {
                        console.log(field + " Missing");
                    }
                } else if(field !== "equity_upside_form" && field !== "analyst2Name" && field !== "analyst3Name" && field !== "analyst4Name") {
                    totalCount += 1;
                    if (field === "tacf_sub_strategy" || field === "tacof_sub_strategy") {
                        if (dealAttribute["tacf_sub_strategy"] || dealAttribute["tacof_sub_strategy"]) {
                            totalFill += 1;
                        } 
                    } else if (field === "projectName") {
                        if (projectName) {
                            totalFill += 1;
                        } 
                    } else if (field === "analystDate") {
                        if (analystDate) {
                            totalFill += 1;
                        } 
                    } else if (field === "analyst1Name") {
                        if (analyst1Name) {
                            totalFill += 1;
                        } 
                    } else if (field === "analyst1Weight") {
                        if (analyst1Weight || analyst1Weight === 0) {
                            totalFill += 1;
                        } 
                    } else {
                        if (dealAttribute[field] || dealAttribute[field] === false || dealAttribute[field] === 0) {
                            totalFill += 1;
                        } 
                    }
                }
            }
        }

        if (listVal !== "") {
            listVal = "Executive Summary: " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        listVal = "";

        columnList = [
            { label: "Asset Based vs. Cashflow", id: "is_assetbased_cashflow" },
            { label: "Is loan underwritten?", id: "is_loan_underwritten" },
            { label: "Is there hard-asset collateral?", id: "is_hard_asset_collateral" },
            { label: "List of Hard Asset Collateral", id: "hard_asset_collateral_list" },
            { label: "Is the position cov-lite?", id: "is_cov_lite" },
            { label: "List of Covenants", id: "covenants_list" },
            { label: "Source of Deal", id: "source_of_deal" },
            { label: "Has Financial Sponsor?", id: "has_financial_sponsor" },
            { label: "List of Financial Sponsor", id: "financial_sponsor_list" },
            { label: "leadership/ Structuring role?", id: "has_leadership_role" },
            { label: "Purpose of Deal (Primary)", id: "primary_deal_purpose" },
            { label: "Purpose of Deal (Secondary)", id: "secondary_deal_purpose" },
            { label: "Expected Exit Date", id: "expected_exit_date" },
            { label: "Expected Exit Type (Primary)", id: "primary_exit_type_id" },
            { label: "Other Primary Exit Type", id: "primary_exit_type" },
            { label: "Expected Exit Type (Secondary)", id: "secondary_exit_type_id" },
            { label: "Other Secondary Exit Type", id: "secondary_exit_type" },
            { label: "Number of Syndicate Lenders", id: "num_syndicate_leaders" },
            { label: "Is Tor the only lender for this deal?", id: "is_tor_only_lender" }
        ]

        for (const key of columnList) {
            field = key.id;
            filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (dealAttribute.portfolio === "Public") {
                required = false;
            }

            if (permission === 2 && required === true) {
                if (field === "hard_asset_collateral_list" && dealAttribute["is_hard_asset_collateral"] === true) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "covenants_list" && dealAttribute["is_cov_lite"] === false) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "financial_sponsor_list" && dealAttribute["has_financial_sponsor"] === true) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "primary_exit_type" && dealAttribute["primary_exit_type_id"] === 72) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "secondary_exit_type" && dealAttribute["secondary_exit_type_id"] === 80) {
                    count += 1;
                    if (dealAttribute[field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field !== "hard_asset_collateral_list" && field !== "covenants_list" && field !== "financial_sponsor_list"
                    && field !== "primary_exit_type" && field !== "secondary_exit_type") {
                    count += 1;
                    if (dealAttribute[field] || dealAttribute[field] === false) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", "
                    }
                }
            }

            if (required === true) {
                if (field === "hard_asset_collateral_list" && dealAttribute["is_hard_asset_collateral"] === true) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } 
                } else if (field === "covenants_list" && dealAttribute["is_cov_lite"] === false) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } 
                } else if (field === "financial_sponsor_list" && dealAttribute["has_financial_sponsor"] === true) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } 
                } else if (field === "primary_exit_type" && dealAttribute["primary_exit_type_id"] === 72) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } 
                } else if (field === "secondary_exit_type" && dealAttribute["secondary_exit_type_id"] === 80) {
                    totalCount += 1;
                    if (dealAttribute[field]) {
                        totalFill += 1;
                    } 
                } else if (field !== "hard_asset_collateral_list" && field !== "covenants_list" && field !== "financial_sponsor_list"
                    && field !== "primary_exit_type" && field !== "secondary_exit_type") {
                    totalCount += 1;
                    if (dealAttribute[field] || dealAttribute[field] === false) {
                        totalFill += 1;
                    } 
                }
            }
        }

        if (listVal !== "") {
            listVal = "Deal Attributes: " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        listVal = "";

        columnList = [
            { label: "Asset Type", id: "asset_type" },
            { label: "Credit ranking", id: "credit_ranking" },
            { label: "HoldCo vs OpCo", id: "holdco_vs_opco" },
            { label: "Has Defaulted?", id: "has_defaulted" },
            { label: "Default Date", id: "default_date" },
            { label: "Make-Whole", id: "makewhole" },
            { label: "Amortization", id: "amortization" },
            { label: "Is Non-Recourse Financing?", id: "is_non_recourse" }
        ]

        for (const key of columnList) {
            field = key.id;
            filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;
            var flag = false;

            for (var i = 0; i < trancheAttributeList.length; i++) {
                if(key !== "asset_type" && ['Private Equity Shares', 'Common Stock', 'Warrant'].includes(positionList[i].productType)) {
                    required = false;
                } else {
                    required = true;
                }

                if(permission === 2 && field === "is_non_recourse"){
                    if(['Total Return Swap', 'Swap Financing'].includes(positionList[i].productType)) {
                        count += 1;
                        if (trancheAttributeList[i][field]) {
                            fillCount += 1;
                        } else if (trancheAttribute[field]) {
                            fillCount += 1
                        } else {
                            flag = true;
                        }
                    }
                } else if (permission === 2 && required === true) {
                    if (field === "default_date" && trancheAttributeList[i]["has_defaulted"] === true) {
                        count += 1;
                        if (trancheAttributeList[i][field]) {
                            fillCount += 1;
                        } else if (trancheAttribute[field]) {
                            fillCount += 1
                        } else {
                            flag = true;
                        }
                    } else if (field !== "default_date") {
                        count += 1;
                        if (trancheAttributeList[i][field] || trancheAttributeList[i][field] === false) {
                            fillCount += 1;
                        } else if (trancheAttribute[field]) {
                            fillCount += 1
                        } else {
                            flag = true;
                        }
                    }
                }

                if (required === true) {
                    if (field === "default_date" && trancheAttributeList["has_defaulted"] === true) {
                        totalCount += 1;
                        if (trancheAttributeList[i][field]) {
                            totalFill += 1;
                        } else if (trancheAttribute[field]) {
                            totalFill += 1
                        } 
                    } else if (field === "is_non_recourse") {
                        if(['Total Return Swap', 'Swap Financing'].includes(positionList[i].productType)) {
                            totalCount += 1;
                            if (trancheAttributeList[i][field] || trancheAttributeList[i][field] === false) {
                                totalFill += 1;
                            } else if (trancheAttribute[field]) {
                                totalFill += 1
                            }
                        }
                    } else if (field !== "default_date") {
                        totalCount += 1;
                        if (trancheAttributeList[i][field] || trancheAttributeList[i][field] === false) {
                            totalFill += 1;
                        } else if (trancheAttribute[field]) {
                            totalFill += 1
                        } 
                    }
                }
            }

            if (flag) {
                listVal += key.label + ", "
            }
        }

        if (listVal !== "") {
            listVal = "Tranche Attributes: " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        listVal = "";

        columnList = [
            { label: "Total Value of All Prior Ranking Drawn Debt", id: "total_ranking_drawn_debt" },
            { label: "Total Drawn Debt through Tranche", id: "total_drawn_debt_tranche" },
            { label: "As Of Date", id: "as_of_date" },
            { label: "Currency", id: "currency" },
            { label: "Rate", id: "rate" },
            { label: "Has the borrower missed any payments?", id: "has_borrower_missed_payment" },
            { label: "Is the borrower in breach of any covenants?", id: "has_borrower_breach_covenant" },
            { label: "Total Net Debt", id: "total_debt_entry" },
            { label: "Cash Balance", id: "cash_balance_entry" },
            // { label: "Book Value of Equity", id: "equity_book_value" },
            // { label: "Market Value of Equity", id: "equity_market_value" },
            // { label: "Equity Value from DCF", id: "equity_value_dcf" },
            { label: "Unadjusted LTM EBITDA", id: "unadjusted_ltm_ebitda" },
            { label: "Adjusted LTM EBITDA", id: "adjusted_ltm_ebitda" },
            { label: "Appraised Value", id: "appraised_value" },
            { label: "Implied Ownership", id: "implied_ownership" },
            { label: "Purchased Price", id: "purchased_price" },
            { label: "LTM Interest Expense", id: "ltm_interest_expense" },
            { label: "LTM revenue", id: "ltm_revenue" },
            { label: "LTM Revenue in Oceana", id: "ltm_revenue_oceania" },
            { label: "LTM Revenue in Africa", id: "ltm_revenue_africa" },
            { label: "LTM Revenue in Asia", id: "ltm_revenue_asia" },
            { label: "LTM Revenue in Europe", id: "ltm_revenue_europe" },
            { label: "LTM Revenue in North America", id: "ltm_revenue_north_america" },
            { label: "LTM Revenue in South America", id: "ltm_revenue_south_america" },
            // { label: "LTM Cashflow from Operations", id: "ltm_operation_cashflow" },
            { label: "LTM Capital Expenditure", id: "ltm_capital_expenditure" },
            { label: "Overall ESG Risk Rating", id: "esg_rating_value" },
            { label: "Is ESG Risk score in IC Memo?", id: "is_ESG_in_Memo" },
            { label: "Enterprise Value", id: "enterprise_value"},
            { label: "ESG Environment Score", id: "esg_environment_rating_value"},
            { label: "ESG Social Score", id: "esg_social_rating_value"},
            { label: "ESG Governance Score", id: "esg_governance_rating_value"},
            { label: "Deal Metrics Comments", id: "deal_metrics_notes"}
        ]

        for (const key of columnList) {
            field = key.id;
            filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (permission === 2 && required) {
                count += 1;
                if(field === "deal_metrics_notes") {
                    if(dealMetrics[field] && dealMetrics[field].length > 0) {
                        fillCount += 1;

                        if(dealMetrics[field].length < 20) {
                            errorList.push('Please ensure the note for “Quarterly Performance Update: Please provide a brief summary of any developments during the quarter” is at least 20 characters')
                        }
                    } else {
                        listVal += key.label + ", ";
                    }
                } else if (field === "esg_environment_rating_value" || field === "esg_social_rating_value" || field === "esg_governance_rating_value" || field === "esg_rating_value") {
                    if(dealMetrics[field] || dealMetrics[field] === 0) {
                        fillCount += 1;

                        if (dealMetrics[field] < 1 || dealMetrics[field] > 5) {
                            errorList.push(`Please ensure the ESG values for “${key.label}” is between 1 and 5`)
                        }

                        if (!Number.isInteger(dealMetrics[field])) {
                            errorList.push(`Please ensure the ESG values for “${key.label}” is a whole number`)
                        }
                    } else {
                        listVal += key.label + ", ";
                    }
                } else {
                    if (dealMetrics[field] || dealMetrics[field] === false || dealMetrics[field] === 0) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", ";
                    }
                }
            }

            if (required) {
                totalCount += 1;
                if(field === "deal_metrics_notes") {
                    if(dealMetrics[field] && dealMetrics[field].length > 0) {
                        totalFill += 1;
                    } 
                } else {
                    if (dealMetrics[field] || dealMetrics[field] === false || dealMetrics[field] === 0) {
                        totalFill += 1;
                    } 
                }
            }
        }

        if (dealMetrics['occupancy'] || dealMetrics['occupancy'] === 0) {
            if(dealMetrics['occupancy'] < 0 || dealMetrics['occupancy'] > 100) {
                errorList.push('Occupancy (%) for real estate assets should be between 0 and 100 %')
            }
        }

        if ((dealMetrics['total_ranking_drawn_debt'] || dealMetrics['total_ranking_drawn_debt'] === false) 
            && (dealMetrics['total_drawn_debt_tranche'] || dealMetrics['total_drawn_debt_tranche'] === false)) {
                if(dealMetrics['total_drawn_debt_tranche'] < dealMetrics['total_ranking_drawn_debt']) {
                    errorList.push('Please ensure “Total Drawn Debt through to Tor’s Position” is >= “Total Drawn Debt Senior to Tor’s Position”')
                }
        }

        if ((dealMetrics['tor_leverage_attachment'] || dealMetrics['tor_leverage_attachment'] === false) 
            && (dealMetrics['tor_leverage_detachment'] || dealMetrics['tor_leverage_detachment'] === false)) {
                if(dealMetrics['tor_leverage_attachment'] < dealMetrics['tor_leverage_detachment']) {
                    errorList.push('Please ensure “Tor Leverage Multiple Attachment Point” is >= “Tor Leverage Multiple Detachment Point”')
                }
        }

        if ((dealMetrics['tor_ltv_attachment'] || dealMetrics['tor_ltv_attachment'] === false) 
            && (dealMetrics['tor_ltv_detachment'] || dealMetrics['tor_ltv_detachment'] === false)) {
                if(dealMetrics['tor_ltv_attachment'] < dealMetrics['tor_ltv_detachment']) {
                    errorList.push('Please ensure “Tor LTV Multiple Attachment Point” is >= “Tor LTV Multiple Detachment Point”')
                }
        }

        if ((dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) ||
            (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) ||
            (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0)
        ) {
            totalCount += 3;

            field = "total_leverage_multiple";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1;
                if (dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Total Leverage Multiple, "
                }
            }

            if (dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) {
                totalFill += 1;
            }

            field = "tor_leverage_attachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor Leverage Attachment, "
                }
            }

            if (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) {
                totalFill += 1;
            }

            field = "tor_leverage_detachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor Leverage Detachment, "
                }
            }

            if (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0) {
                totalFill += 1;
            }

        } else {
            totalCount += 3;

            field = "total_ltv_percentage";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["total_ltv_percentage"] || dealMetrics["total_ltv_percentage"] === false || dealMetrics["total_ltv_percentage"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Total LTV %, "
                }
            }

            if (dealMetrics["total_ltv_percentage"] || dealMetrics["total_ltv_percentage"] === false || dealMetrics["total_ltv_percentage"] === 0) {
                totalFill += 1;
            }

            field = "tor_ltv_detachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_ltv_detachment"] || dealMetrics["tor_ltv_detachment"] === false || dealMetrics["tor_ltv_detachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor LTV Detachment, "
                }
            }

            if (dealMetrics["tor_ltv_detachment"] || dealMetrics["tor_ltv_detachment"] === false || dealMetrics["tor_ltv_detachment"] === 0) {
                totalFill += 1;
            }

            field = "tor_ltv_attachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_ltv_attachment"] || dealMetrics["tor_ltv_attachment"] === false || dealMetrics["tor_ltv_attachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor LTV Attachment, "
                }
            }

            if (dealMetrics["tor_ltv_attachment"] || dealMetrics["tor_ltv_attachment"] === false || dealMetrics["tor_ltv_attachment"] === 0) {
                totalFill += 1;
            }

        }

        if (listVal !== "") {
            listVal = "Credit Metrics: " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        listVal = "";

        columnList = [
            { label: "Unlevered IRR %", id: "unlevered_irr_percentage" },
            // { label: "Expected Return from Equity", id: "expected_return_percentage" },
            { label: "Levered IRR %", id: "levered_irr_percentage" },
            { label: "Unlevered MOIC", id: "unlevered_moic" },
            { label: "Levered MOIC", id: "levered_moic" },
            { label: "Probability of Scenario", id: "return_scenario_probability" }
        ]

        listVal = "";

        if (dealReturnList.length > 0) {
            for (const key of columnList) {
                field = key.id;
                filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
                permission = filterData[0]?.permission;
                required = true;

                if (permission === 2 && required) {
                    count += 1;
                    if (dealReturnList[0][field]) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + " cannot be 0 or empty, "
                    }
                }

                if (required) {
                    totalCount += 1;
                    if (dealReturnList[0][field]) {
                        totalFill += 1;
                    }
                }
            }
        } else {
            count += 6;
            totalCount += 6;
            for (const key of columnList) {
                listVal += key.label + ", "
            }
        }

        if (listVal !== "") {
            listVal = "Deal Returns (Tor Base Case): " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        listVal = "";

        return { percent: fillCount / count * 100, totalPercent: totalFill / totalCount * 100, errorList: errorList };
    }

    getQuarterlyProgress() {
        const { dealMetrics, fieldList } = this.state;
        var field, filterData, permission, required;
        var fillCount = 0;
        var totalCount = 0;
        var count = 0;
        var totalFill = 0;

        var listVal = "";
        var errorList = [];

        var columnList = [
            { label: "Total Value of All Prior Ranking Drawn Debt", id: "total_ranking_drawn_debt" },
            { label: "Total Drawn Debt through Tranche", id: "total_drawn_debt_tranche" },
            { label: "As Of Date", id: "as_of_date" },
            { label: "Currency", id: "currency" },
            { label: "Rate", id: "rate" },
            { label: "Has the borrower missed any payments?", id: "has_borrower_missed_payment" },
            { label: "Is the borrower in breach of any covenants?", id: "has_borrower_breach_covenant" },
            { label: "Total Net Debt", id: "total_debt_entry" },
            { label: "Cash Balance", id: "cash_balance_entry" },
            // { label: "Book Value of Equity", id: "equity_book_value" },
            // { label: "Market Value of Equity", id: "equity_market_value" },
            // { label: "Equity Value from DCF", id: "equity_value_dcf" },
            { label: "Unadjusted LTM EBITDA", id: "unadjusted_ltm_ebitda" },
            { label: "Adjusted LTM EBITDA", id: "adjusted_ltm_ebitda" },
            { label: "Appraised Value", id: "appraised_value" },
            { label: "Implied Ownership", id: "implied_ownership" },
            { label: "Purchased Price", id: "purchased_price" },
            { label: "LTM Interest Expense", id: "ltm_interest_expense" },
            { label: "LTM revenue", id: "ltm_revenue" },
            { label: "LTM Revenue in Oceana", id: "ltm_revenue_oceania" },
            { label: "LTM Revenue in Africa", id: "ltm_revenue_africa" },
            { label: "LTM Revenue in Asia", id: "ltm_revenue_asia" },
            { label: "LTM Revenue in Europe", id: "ltm_revenue_europe" },
            { label: "LTM Revenue in North America", id: "ltm_revenue_north_america" },
            { label: "LTM Revenue in South America", id: "ltm_revenue_south_america" },
            // { label: "LTM Cashflow from Operations", id: "ltm_operation_cashflow" },
            { label: "LTM Capital Expenditure", id: "ltm_capital_expenditure" },
            { label: "Overall ESG Risk Rating", id: "esg_rating_value" },
            { label: "Enterprise Value", id: "enterprise_value"},
            { label: "ESG Environment Score", id: "esg_environment_rating_value"},
            { label: "ESG Social Score", id: "esg_social_rating_value"},
            { label: "ESG Governance Score", id: "esg_governance_rating_value"},
            { label: "Deal Metrics Comments", id: "deal_metrics_notes"}
        ]

        if (dealMetrics['occupancy'] || dealMetrics['occupancy'] === 0) {
            if(dealMetrics['occupancy'] < 0 || dealMetrics['occupancy'] > 100) {
                errorList.push('Occupancy (%) for real estate assets should be between 0 and 100 %')
            }
        }

        if ((dealMetrics['total_ranking_drawn_debt'] || dealMetrics['total_ranking_drawn_debt'] === false) 
            && (dealMetrics['total_drawn_debt_tranche'] || dealMetrics['total_drawn_debt_tranche'] === false)) {
                if(dealMetrics['total_drawn_debt_tranche'] < dealMetrics['total_ranking_drawn_debt']) {
                    errorList.push('Please ensure “Total Drawn Debt through to Tor’s Position” is >= “Total Drawn Debt Senior to Tor’s Position”')
                }
        }

        if ((dealMetrics['tor_leverage_attachment'] || dealMetrics['tor_leverage_attachment'] === false) 
            && (dealMetrics['tor_leverage_detachment'] || dealMetrics['tor_leverage_detachment'] === false)) {
                if(dealMetrics['tor_leverage_attachment'] < dealMetrics['tor_leverage_detachment']) {
                    errorList.push('Please ensure “Tor Leverage Multiple Attachment Point” is >= “Tor Leverage Multiple Detachment Point”')
                }
        }

        if ((dealMetrics['tor_ltv_attachment'] || dealMetrics['tor_ltv_attachment'] === false) 
            && (dealMetrics['tor_ltv_detachment'] || dealMetrics['tor_ltv_detachment'] === false)) {
                if(dealMetrics['tor_ltv_attachment'] < dealMetrics['tor_ltv_detachment']) {
                    errorList.push('Please ensure “Tor LTV Multiple Attachment Point” is >= “Tor LTV Multiple Detachment Point”')
                }
        }

        for (const key of columnList) {
            field = key.id;
            filterData = fieldList.filter(function (item) { return item.field_name === key.id }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (permission === 2 && required) {
                count += 1;
                if(field === "deal_metrics_notes") {
                    if(dealMetrics[field] && dealMetrics[field].length > 0) {
                        fillCount += 1;

                        if(dealMetrics[field].length < 20) {
                            errorList.push('Please ensure the note for “Quarterly Performance Update: Please provide a brief summary of any developments during the quarter” is at least 20 characters')
                        }
                    } else {
                        listVal += key.label + ", ";
                    }
                } else if (field === "esg_environment_rating_value" || field === "esg_social_rating_value" || field === "esg_governance_rating_value" || field === "esg_rating_value") {
                    if(dealMetrics[field]) {
                        fillCount += 1;

                        if (dealMetrics[field] < 1 || dealMetrics[field] > 5) {
                            errorList.push(`Please ensure the ESG values for “${key.label}” is between 1 and 5`)
                        }
                        if (!Number.isInteger(dealMetrics[field])) {
                            errorList.push(`Please ensure the ESG values for “${key.label}” is a whole number`)
                        }
                    } else {
                        listVal += key.label + ", ";
                    }
                } else {
                    if (dealMetrics[field] || dealMetrics[field] === false || dealMetrics[field] === 0) {
                        fillCount += 1;
                    } else {
                        listVal += key.label + ", ";
                    }
                }
            }

            if (required) {
                totalCount += 1;
                if(field === "deal_metrics_notes") {
                    if(dealMetrics[field] && dealMetrics[field].length > 0) {
                        totalFill += 1;
                    }
                } else {
                    if (dealMetrics[field] || dealMetrics[field] === false || dealMetrics[field] === 0) {
                        totalFill += 1;
                    }
                }
            }
        }

        if ((dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) ||
            (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) ||
            (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0)
        ) {
            totalCount += 3;

            field = "total_leverage_multiple";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1;
                if (dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Total Leverage Multiple, "
                }
            }

            if (dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) {
                totalFill += 1;
            }

            field = "tor_leverage_attachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor Leverage Attachment, "
                }
            }

            if (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) {
                totalFill += 1;
            }

            field = "tor_leverage_detachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor Leverage Detachment, "
                }
            }

            if (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0) {
                totalFill += 1;
            }

        } else {
            totalCount += 3;

            field = "total_ltv_percentage";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["total_ltv_percentage"] || dealMetrics["total_ltv_percentage"] === false || dealMetrics["total_ltv_percentage"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Total LTV %, "
                }
            }

            if (dealMetrics["total_ltv_percentage"] || dealMetrics["total_ltv_percentage"] === false || dealMetrics["total_ltv_percentage"] === 0) {
                totalFill += 1;
            }

            field = "tor_ltv_detachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_ltv_detachment"] || dealMetrics["tor_ltv_detachment"] === false || dealMetrics["tor_ltv_detachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor LTV Detachment, "
                }
            }

            if (dealMetrics["tor_ltv_detachment"] || dealMetrics["tor_ltv_detachment"] === false || dealMetrics["tor_ltv_detachment"] === 0) {
                totalFill += 1;
            }

            field = "tor_ltv_attachment";
            filterData = fieldList.filter(function (item) { return item.field_name === field }).map(item => { return item });
            permission = filterData[0]?.permission;

            if (permission === 2) {
                count += 1
                if (dealMetrics["tor_ltv_attachment"] || dealMetrics["tor_ltv_attachment"] === false || dealMetrics["tor_ltv_attachment"] === 0) {
                    fillCount += 1;
                } else {
                    listVal += "Tor LTV Attachment, "
                }
            }

            if (dealMetrics["tor_ltv_attachment"] || dealMetrics["tor_ltv_attachment"] === false || dealMetrics["tor_ltv_attachment"] === 0) {
                totalFill += 1;
            }

        }

        if (listVal !== "") {
            listVal = "Credit Metrics: " + listVal.substring(0, listVal.length - 2);
            errorList.push(listVal);
        }

        return { percent: fillCount / count * 100, totalPercent: totalFill / totalCount * 100, errorList: errorList };
    }

    closeMessage() {
        this.setState({ showMessage: false, messageHeader: "Following Fields have not been filled in: " });
    }

    closeErrorMessage() {
        this.setState({ showErrorMessage: false, errorList: [] });
    }

    approveDB = async () => {
        var progress;

        if(this.state.quarter === "Underwriting"){
            progress = this.getProgress();
        } else if (this.state.quarter !== null) {
            progress = this.getQuarterlyProgress();
        }

        console.log("Progress: ", progress);

        if (progress.totalPercent !== 100 || progress.errorList.length > 0) {
            if(progress.percent !== 100 || progress.errorList.length > 0) {
                this.setState({ showMessage: true, errorList: progress.errorList });
            } else {
                await this.saveDB();
                this.setState({
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Data has been submitted. Risk to fill in remaining fields for submission.'
                })
                dealBALService.generateEmail({deal: this.state.dealName, quarter: this.state.quarter, allFilled: false});
            }
        } else {
            const {dealAttribute, dealMetrics, dealReturnList, dealRisk, trancheAttributeList} = this.state;
            if(this.state.isDataChanged === true || dealAttribute.status === 'Draft' || dealMetrics.status === 'Draft' || dealRisk.status === 'Draft'
                || JSON.stringify(trancheAttributeList).includes('Draft') || JSON.stringify(dealReturnList).includes('Draft')) 
            {
                await this.saveDB(false);

                this.setState({
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Data has been submitted for approval'
                });

                dealBALService.generateEmail({deal: this.state.dealName, quarter: this.state.quarter})
            } else {
                this.setState({
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'No changes for submission'
                });
            }
            
            
        }
    }

    round(value, decimals) {
        return Number(Math.round(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
    }

    saveDB = async (isDraft = true) => {
        try {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data save in progress !!'
            });

            this.closeMessage();

            var i, key, errorList = [];
            var { dealAttribute, dealAttributeDb, dealMetrics, dealMetricsDb, dealRisk, dealRiskDb, projectName, fieldList } = this.state;
            const {analyst1Name, analyst1Weight, analyst2Name, analyst2Weight, analyst3Name, analyst3Weight, analyst4Name, analyst4Weight} = this.state;

            var filterData = fieldList.filter(function (item) { return item.field_name === "analyst1Weight" }).map(item => { return item });
            var permission = filterData[0]?.permission;

            if(permission === 2) {
                var sum = parseInt(Number.isNaN(analyst1Weight) ? 0 : analyst1Weight ?? 0) + parseInt(Number.isNaN(analyst2Weight) ? 0 : analyst2Weight ?? 0) + parseInt(Number.isNaN(analyst3Weight) ? 0 : analyst3Weight ?? 0) + parseInt(Number.isNaN(analyst4Weight) ? 0 : analyst4Weight ?? 0);
                if(((sum <= 99.9 || sum > 100) && sum !== 0) || Number.isNaN(sum)) {
                    errorList.push("Analyst Weights should sum to 100%");
                }

                if(((analyst1Name ?? '111') === (analyst2Name ?? '222')) || ((analyst1Name ?? '111') === (analyst3Name ?? '333')) || ((analyst1Name ?? '111') === (analyst4Name ?? '444'))
                 || ((analyst2Name ?? '222') === (analyst3Name ?? '333'))  || ((analyst2Name ?? '222') === (analyst4Name ?? '444'))  || ((analyst3Name ?? '333') === (analyst4Name ?? '444')))  
                {
                    errorList.push("Please enter different analysts for position 1, 2, 3, 4");
                }  
            }

            if(errorList.length === 0){
                var body = {};
                body['project'] = projectName;


                await dealBALService.updateDeal(body, dealAttribute.deal_id).then(data => {

                }, error => {
                    errorList.push(error);
                });
            }


            if(errorList.length === 0){
                var analystDeal = {};

                analystDeal['analystDate'] = this.state.analystDate;
                analystDeal['analyst1Name'] = this.state.analyst1Name;
                analystDeal['analyst1Weight'] = this.state.analyst1Weight;
                analystDeal['analyst2Name'] = this.state.analyst2Name;
                analystDeal['analyst2Weight'] = this.state.analyst2Weight;
                analystDeal['analyst3Name'] = this.state.analyst3Name;
                analystDeal['analyst3Weight'] = this.state.analyst3Weight;
                analystDeal['analyst4Name'] = this.state.analyst4Name;
                analystDeal['analyst4Weight'] = this.state.analyst4Weight;

                await dealBALService.insertDealAnalyst(analystDeal, dealAttribute.deal_id).then(data => {
                    
                }, error => {
                    errorList.push(error);
                });
            }

            if(errorList.length === 0){
                if(JSON.stringify(dealAttribute) !== JSON.stringify(dealAttributeDb) || (dealAttribute.status === 'Draft' && isDraft === false)) {
                    for (key in dealAttribute) {
                        if (dealAttribute[key] === null || dealAttribute[key] === 0 || dealAttribute[key] === "" || Number.isNaN(dealAttribute[key])) {
                            delete dealAttribute[key];
                        }
                    }

                    if(isDraft) {
                        dealAttribute.status = 'Draft';
                    } else {
                        dealAttribute.status = 'Submitted';
                    }

                    await dealBALService.insertDealAttribute(dealAttribute).then(data => {
                        data['deal_id'] = data['deal']
                        delete (data['deal']);
                        this.setState({
                            dealAttribute: data, 
                            dealAttributeDb: data
                        });
                    }, error => {
                        errorList.push(error);
                    });
                }
            }


            var {trancheAttributeList, trancheAttributeListDb} = this.state;

            if(errorList.length === 0){
                if(JSON.stringify(trancheAttributeList) !== JSON.stringify(trancheAttributeListDb) || (JSON.stringify(trancheAttributeList).includes('Draft') && isDraft === false)) {
                    for (i = 0; i < trancheAttributeList.length; i++) {
                        if(JSON.stringify(trancheAttributeList[i]) !== JSON.stringify(trancheAttributeListDb[i]) || (trancheAttributeList[i].status === 'Draft' && isDraft === false)){
                            for (key in trancheAttributeList[i]) {
                                if (trancheAttributeList[i][key] === null || trancheAttributeList[i][key] === 0 || trancheAttributeList[i][key] === "") {
                                    delete trancheAttributeList[i][key];
                                }
                            }
                            trancheAttributeList[i]['isChanged'] = true;

                            if(isDraft === true) {
                                trancheAttributeList[i].status = 'Draft';
                            } else {
                                trancheAttributeList[i].status = 'Submitted';
                            }
                        } else {
                            trancheAttributeList[i]['isChanged'] = false;
                        }
                    }
        
                    await dealBALService.insertTrancheAttribute(trancheAttributeList).then(data => {
                        data = data.data;
                        for (var i = 0; i < data.length; i++) {
                            data[i]['deal_id'] = data[i]['deal']
                            data[i]['investment_code'] = data[i]['investmentCode']
                            delete (data[i]['deal']);
                            delete (data[i]['investmentCode']);
                        }
                        this.setState({
                            trancheAttributeList: data,
                            trancheAttributeListDb: data,
                            trancheAttribute: {
                                deal_id: null,
                                investment_code: null,
                                asset_type: null,
                                credit_ranking: null,
                                holdco_vs_opco: null,
                                has_defaulted: null,
                                default_date: null,
                                makewhole: null,
                                amortization: null,
                                is_non_recourse: null
                            }
                        });    
                    }, error => {
                        errorList.push(error);
                    });
                }
            }            


            if(errorList.length === 0){
                if(JSON.stringify(dealMetrics) !== JSON.stringify(dealMetricsDb) || (dealMetrics.status === 'Draft' && isDraft === false)) {
                    for (key in dealMetrics) {
                        if (dealMetrics[key] === null || dealMetrics[key] === "" || Number.isNaN(dealMetrics[key])) {
                            delete dealMetrics[key];
                        }
                    }

                    if(isDraft) {
                        dealMetrics.status = 'Draft';
                    } else {
                        dealMetrics.status = 'Submitted';
                    }
        
                    await dealBALService.insertDealMetrics(dealMetrics).then(data => {
                        data['deal_id'] = data['deal']
                        delete (data['deal']);
                        this.setState({
                            dealMetrics: data,
                            dealMetricsDb: data
                        });
                    }, error => {
                        errorList.push(error);
                    });
                }
            }
            


            var { dealReturnList, dealReturnListDb } = this.state;

            if(errorList.length === 0){
                if(JSON.stringify(dealReturnList) !== JSON.stringify(dealReturnListDb) || (JSON.stringify(dealReturnList).includes('Draft') && isDraft === false)) {
                    for (i = 0; i < dealReturnList.length; i++) {
                        if(JSON.stringify(dealReturnList[i]) !== JSON.stringify(dealReturnListDb[i]) || (dealReturnList[i].status === 'Draft' && isDraft === false)){
                            for (key in dealReturnList[i]) {
                                if (dealReturnList[i][key] === null || dealReturnList[i][key] === "" || Number.isNaN(dealReturnList[i][key])) {
                                    delete dealReturnList[i][key];
                                }
                            }
                            dealReturnList[i]['isChanged'] = true;

                            if(isDraft) {
                                dealReturnList[i].status = 'Draft';
                            } else {
                                dealReturnList[i].status = 'Submitted';
                            }
                        } else {
                            dealReturnList[i]['isChanged'] = false;
                        }
                    }
        
                    await dealBALService.insertDealReturns(dealReturnList).then(data => {
                        data = data.data;
                        for (var i = 0; i < data.length; i++) {
                            data[i]['deal_id'] = data[i]['deal']
                            data[i]['return_scenario'] = data[i]['returnScenario']
                            delete (data[i]['deal']);
                            delete (data[i]['returnScenario']);
                        }
                        this.setState({
                            dealReturnList: data,
                            dealReturnListDb: data
                        });
                    }, error => {
                        errorList.push(error);
                    });    
                }
            }

            if(errorList.length === 0){
                if(JSON.stringify(dealRisk) !== JSON.stringify(dealRiskDb) || (dealRisk.status === 'Draft' && isDraft === false)) {
                    for (key in dealRisk) {
                        if (dealRisk[key] === null || dealRisk[key] === 0 || dealRisk[key] === "") {
                            delete dealRisk[key];
                        }
                    }

                    if(isDraft) {
                        dealRisk.status = 'Draft';
                    } else {
                        dealRisk.status = 'Submitted';
                    }
        
                    await dealBALService.insertDealRisks(dealRisk).then(data => {
                        data['deal_id'] = data['deal'];
                        delete (data['deal']);
                        this.setState({
                            dealRisk: data,
                            dealRiskDb: data
                        });
                    }, error => {
                        errorList.push(error);
                    });
                }
            }
            

            if(errorList.length === 0){
                dealBALService.getRowData().then(data => {
                    this.setState({ gridData: data, dealList: data });
                }, error => { console.log(error); });

                // dealBALService.getAllDeals().then(data => {
                //     this.setState({ dealList: data });
                // }, error => { console.log(error); });

                this.setState({
                    isDataChanged: false,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Data has been saved successfully'
                });
            } else {
                console.log(errorList);
                this.setState({
                    showMessage: true, 
                    errorList: errorList, 
                    messageHeader : "Error while saving the data :",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Data could not be saved, please contact support!'
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data could not be saved, please retry!'
            });
        }
    };

    setTabIndex = param => e => {
        this.setState({ tabindex: param });
    };

    componentDidMount() {
        this.mounted = true;
    }

    handleChange(e) {
        const { name, value } = e.target;

        this.setState({ isDataChanged: true });

        if (name === "projectName" || name === "analyst1Weight" || name === "analyst2Weight" || name === "analyst3Weight" || name === "analyst4Weight") {
            if(Number.isNaN(value)){
                this.setState({
                    [name]: null
                })
            } else {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                dealAttribute: {
                    ...this.state.dealAttribute,
                    [name]: value
                }
            });
        }
    }

    handleChangeNumber(name, value) {
        this.setState({ isDataChanged: true });

        if (name === "projectName" || name === "analyst1Weight" || name === "analyst2Weight" || name === "analyst3Weight" || name === "analyst4Weight" || name === "analystDate") {
            this.setState({
                [name]: value
            });
        } else {
            this.setState({
                dealAttribute: {
                    ...this.state.dealAttribute,
                    [name]: value
                }
            });
        }
    }

    handleChangeMetrics(e) {
        this.setState({ isDataChanged: true });

        const { name, value } = e.target;
        // if(name === "rate" && ["AUD", "NZD", "EUR", "GBP"].includes(this.state.dealMetrics?.currency)) {
        //     this.setState({
        //         dealMetrics: {
        //             ...this.state.dealMetrics,
        //             [name]: 1/ value,
        //             dispRate: value
        //         }
        //     });
        // } else {
            this.setState({
                dealMetrics: {
                    ...this.state.dealMetrics,
                    [name]: value
                }
            });
        // }
    }

    handleRowSelected(e) {
        const { value, data, action } = e.target;
        const name = 'deal_id';

        if(action === "Show Changes") {
            this.setState({ redirectTo:{pathname : '/dealchanges', state : {deal : data.deal, dealId : value} } });
        } 
        else { 
            this.setState({ isOpenGrid: false, tabindex: data.status === "Complete" ? 4 : 1 });
            this.handleDropDownChangeDeal(e, { name, value });
        }
    }

    handleChangeDate(name, date) {
        this.setState({ isDataChanged: true });
        this.setState({ dealAttribute: { ...this.state.dealAttribute, [name]: date } });
    };

    handleChangeCurrency(name, currency, rate) {
        // if(["AUD", "NZD", "EUR", "GBP"].includes(currency)) {
        //     this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: currency, "rate": rate, dispRate: this.roundToTwo(1/ rate) } });
        // } else {
            this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: currency, "rate": rate, dispRate: rate } });
        // }
        
    }

    handleChangeDateMetrics(name, date) {
        this.setState({ isDataChanged: true });
        this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: date } });
    };

    handleChangeDateRisk(name, date) {
        this.setState({ isDataChanged: true });
        this.setState({ dealRisk: { ...this.state.dealRisk, [name]: date } });
    };

    handleDropDownChange(e, { name, value }) {
        this.setState({ isDataChanged: true });
        this.setState({ dealAttribute: { ...this.state.dealAttribute, [name]: value } });
        if (name === "analyst1Name" || name === "analyst2Name" || name === "analyst3Name" || name === "analyst4Name") {
            this.setState({ [name]: value });
        } else if (name === "gics_sector_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, gics_sector_id: null, gics_industry_group_id: null, gics_industry_id: null, gics_sub_industry_id: null  } });
        } else if (name === "gics_industry_group_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, gics_industry_group_id: null, gics_industry_id: null, gics_sub_industry_id: null  } });
        } else if (name === "gics_industry_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, gics_industry_id: null, gics_sub_industry_id: null  } });
        } else if (name === "gics_sub_industry_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, gics_sub_industry_id: null, gics_nace_id: null  } });
        } else if (name === "gics_nace_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, gics_nace_id: null, eligible_climate_mitigation_activity: null, eligible_climate_adaptation_activity: null } });
        } else if (name === "bics_sector_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, bics_sector_id: null, bics_industry_group_id: null, bics_industry_id: null} });
        } else if (name === "bics_industry_group_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, bics_industry_group_id: null, bics_industry_id: null } });
        } else if (name === "bics_industry_id" && (value === "" || value === null)) {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, bics_industry_id: null, bics_sub_industry_id: null  } });
        }
    }

    handleDropDownChangeRisk(e, { name, value }) {
        this.setState({ isDataChanged: true });
        this.setState({ dealRisk: { ...this.state.dealRisk, [name]: value } });
    }

    handleChangeRisk(e) {
        const { name, value } = e.target;

        this.setState({ isDataChanged: true });
        this.setState({
            dealRisk: {
                ...this.state.dealRisk,
                [name]: value
            }
        });
    }

    handleClickBbg = async(e) => {
        if(this.state.bbg_quarter === null) {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Please select FY Quarter before loading data'
              });
        }

        await dealBALService.getBbgMetrics(this.state.dealMetrics.deal_id, this.state.bbg_quarter).then(data => {
            console.log(data[0]);
            this.setState({
                dealMetrics: {
                    ...this.state.dealMetrics,
                    ...data[0]
                }
            }, () => {
                console.log(this.state.dealMetrics)
            })
        })
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2")  + "e-2");     
    }

    handleClickPriorMetrics = async(e) => {
        await dealBALService.getPriorDealMetrics(this.state.dealMetrics.deal_id, this.state.dealMetrics.quarter).then(data => {
            data['deal_id'] = data['deal']
            delete (data['deal']);

            // let values = [
            //     'total_debt_entry'
            //     ,'distressed_market_value'
            //     ,'cash_balance_entry'
            //     ,'gross_debt'
            //     ,'equity_book_value'
            //     ,'equity_market_value'
            //     ,'equity_value_dcf'
            //     ,'unadjusted_ltm_ebitda'
            //     ,'adjusted_ltm_ebitda'
            //     ,'appraised_value'
            //     ,'purchased_price'
            //     ,'ltm_interest_expense'
            //     ,'ltm_revenue'
            //     ,'ltm_revenue_oceania'
            //     ,'ltm_revenue_africa'
            //     ,'ltm_revenue_asia'
            //     ,'ltm_revenue_europe'
            //     ,'ltm_revenue_north_america'
            //     ,'ltm_revenue_south_america'
            //     ,'ltm_operation_cashflow'
            //     ,'ltm_capital_expenditure'
            //     ,'total_ranking_drawn_debt'
            //     ,'total_drawn_debt_tranche'
            //     ,'enterprise_value'
            // ];

            // const rate = this.state.dealMetrics?.rate;

            // if(data['currency'] === "USD" && this.state.dealMetrics?.currency !== "USD") {
            //     for(let i = 0; i < values.length; i++) {
            //         data[values[i]] = +(Math.round(data[values[i]] * rate));
            //     }
            // }

            // data['currency'] = this.state.dealMetrics?.currency;
            // data['rate'] = this.state.dealMetrics?.rate;
            data['dispRate'] = data['rate'];
            
            this.setState({
                dealMetrics: data,
                dealMetricsDb: data
            });
        }, error => {
            this.setState({
                dealMetrics: {
                    ...this.state.dealMetrics,
                    is_underwriting: true,
                    quarter: "Underwriting",

                    senior_debt: null,
                    secured_debt: null,
                    parri_passu_debt: null,
                    senior_debt_tranche: null,
                    total_debt_entry: null,
                    distressed_market_value: null,
                    cash_balance_entry: null,
                    gross_debt: null,
                    equity_book_value: null,
                    equity_market_value: null,
                    equity_value_dcf: null,
                    unadjusted_ltm_ebitda: null,
                    adjusted_ltm_ebitda: null,
                    appraised_value: null,
                    implied_ownership: null,
                    purchased_price: null,
                    ltm_interest_expense: null,
                    ltm_revenue: null,
                    ltm_revenue_oceania: null,
                    ltm_revenue_africa: null,
                    ltm_revenue_asia: null,
                    ltm_revenue_europe: null,
                    ltm_revenue_north_america: null,
                    ltm_revenue_south_america: null,
                    ltm_operation_cashflow: null,
                    ltm_capital_expenditure: null,
                    total_leverage_multiple: null,
                    total_ltv_percentage: null,
                    tor_leverage_attachment: null,
                    tor_leverage_detachment: null,
                    tor_ltv_detachment: null,
                    tor_ltv_attachment: null,
                    esg_rating_value: null,
                    is_ESG_in_Memo: null,

                    senior_debt_comment: null,
                    secured_debt_comment: null,
                    parri_passu_debt_comment: null,
                    senior_debt_tranche_comment: null,
                    total_debt_entry_comment: null,
                    distressed_market_value_comment: null,
                    cash_balance_entry_comment: null,
                    gross_debt_comment: null,
                    equity_book_value_comment: null,
                    equity_market_value_comment: null,
                    equity_value_dcf_comment: null,
                    unadjusted_ltm_ebitda_comment: null,
                    adjusted_ltm_ebitda_comment: null,
                    appraised_value_comment: null,
                    implied_ownership_comment: null,
                    purchased_price_comment: null,
                    ltm_interest_expense_comment: null,
                    ltm_revenue_comment: null,
                    ltm_revenue_oceania_comment: null,
                    ltm_revenue_africa_comment: null,
                    ltm_revenue_asia_comment: null,
                    ltm_revenue_europe_comment: null,
                    ltm_revenue_north_america_comment: null,
                    ltm_revenue_south_america_comment: null,
                    ltm_operation_cashflow_comment: null,
                    ltm_capital_expenditure_comment: null,
                    total_leverage_multiple_comment: null,
                    total_ltv_percentage_comment: null,
                    tor_leverage_attachment_comment: null,
                    tor_leverage_detachment_comment: null,
                    tor_ltv_detachment_comment: null,
                    tor_ltv_attachment_comment: null,
                    esg_rating_comment: null,
                    is_ESG_in_Memo_comment: null,

                    total_ranking_drawn_debt: null,
                    total_drawn_debt_tranche: null,
                    has_borrower_missed_payment: null,
                    has_borrower_breach_covenant: null,
                    enterprise_value: null,
                    debt_equity_ratio: null,
                    esg_environment_rating_value: null,
                    esg_social_rating_value: null,
                    esg_governance_rating_value: null,

                    total_ranking_drawn_debt_comment: null,
                    total_drawn_debt_tranche_comment: null,
                    has_borrower_missed_payment_comment: null,
                    has_borrower_breach_covenant_comment: null,
                    enterprise_value_comment: null,
                    debt_equity_ratio_comment: null,
                    esg_environment_rating_comment: null,
                    esg_social_rating_comment: null,
                    esg_governance_rating_comment: null,

                    as_of_date: null,
                    as_of_date_comment: null,

                    deal_metrics_notes: null
                }
            });
            console.log(error);
        });
    }

    handleDropDownChangeDeal = async (e, { name, value }) => {
        this.setState({isDealChange: true, bbg_quarter: null});
        this.setState({ dealAttribute: { ...this.state.dealAttribute, [name]: value } });
        this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: value } });
        this.setState({ dealRisk: { ...this.state.dealRisk, [name]: value } });
        var dealReturnList = [];

        var project = this.state.dealList.filter(function (item) { return item.dealID === value }).map(item => { return item.projectName });
        if (project.length > 0) {
            this.setState({ projectName: project[0] });
        }

        var dealName = this.state.dealList.filter(function (item) { return item.dealID === value }).map(item => { return item.deal });
        if (project.length > 0) {
            this.setState({ dealName: dealName[0] });
        }

        await dealBALService.getAllAnalysts(value).then(data => {
            if (data.length >= 4) {
                this.setState({ analyst4Name: data[3].analyst.id, analyst4Weight: data[3].dealWeighting });
            } else {
                this.setState({ analyst4Name: null, analyst4Weight: null });
            }

            if (data.length >= 3) {
                this.setState({ analyst3Name: data[2].analyst.id, analyst3Weight: data[2].dealWeighting });
            } else {
                this.setState({ analyst3Name: null, analyst3Weight: null });
            }

            if (data.length >= 2) {
                this.setState({ analyst2Name: data[1].analyst.id, analyst2Weight: data[1].dealWeighting })
            } else {
                this.setState({ analyst2Name: null, analyst2Weight: null });
            }

            if (data.length >= 1) {
                this.setState({ analyst1Name: data[0].analyst.id, analyst1Weight: data[0].dealWeighting, analystDate: data[0].fromDate })
            } else {
                this.setState({ analyst1Name: null, analyst1Weight: null });
            }
        }, error => {
            console.log(error);
        });

        for (var i = 0; i < 3; i++) {
            var dealReturn = {
                deal_id: null,
                is_underwriting: true,
                quarter: "Underwriting",
                return_scenario: null,

                unlevered_irr_percentage: null,
                expected_return_percentage: null,
                levered_irr_percentage: null,
                unlevered_moic: null,
                levered_moic: null,
                return_scenario_probability: null,

                unlevered_irr_percentage_comment: null,
                expected_return_percentage_comment: null,
                levered_irr_percentage_comment: null,
                unlevered_moic_comment: null,
                levered_moic_comment: null,
                return_scenario_probability_comment: null
            };

            dealReturn.deal_id = value;

            if (i === 0) {
                dealReturn.return_scenario = "Tor Base Case";
            } else if (i === 1) {
                dealReturn.return_scenario = "Tor Downside Case";
            } else {
                dealReturn.return_scenario = "Management Base Case";
            }

            dealReturnList.push(dealReturn);
        }

        this.setState({ dealReturnList: dealReturnList });


        await dealBALService.getDealAttribute(value).then(data => {
            data['deal_id'] = data['deal']
            delete (data['deal']);
            this.setState({
                dealAttribute: data, 
                dealAttributeDb: data
            });
        }, error => {
            this.setState({
                dealAttribute: {
                    ...this.state.dealAttribute,
                    country_of_risk_id: null,
                    gics_sector_id: null,
                    gics_industry_group_id: null,
                    gics_industry_id: null,
                    gics_sub_industry_id: null,
                    gics_nace_id: null,
                    eligible_climate_mitigation_activity: null,
                    eligible_climate_adaptation_activity: null,
                    bics_sector_id: null,
                    bics_industry_group_id: null,
                    bics_industry_id: null,
                    bics_sub_industry_id: null,
                    snp_corporate_family_rating_id: 264,
                    moodys_corporate_family_rating_id: 265,
                    portfolio: null,
                    tacf_sub_strategy: null,
                    tacof_sub_strategy: null,
                    market_risk_sensitivity: null,
                    equity_upside: null,
                    equity_upside_form: null,
                    is_new_issue: null,
                    upfront_fee: null,
                    company_description: null,


                    is_assetbased_cashflow: null,
                    is_loan_underwritten: null,
                    is_hard_asset_collateral: null,
                    hard_asset_collateral_list: null,
                    is_cov_lite: null,
                    covenants_list: null,
                    source_of_deal: null,
                    has_financial_sponsor: null,
                    financial_sponsor_list: null,
                    has_leadership_role: null,
                    primary_deal_purpose: null,
                    secondary_deal_purpose: null,
                    expected_exit_date: null,
                    primary_exit_type_id: null,
                    primary_exit_type: null,
                    secondary_exit_type_id: null,
                    secondary_exit_type: null,
                    num_syndicate_leaders: null,
                    is_tor_only_lender: null,


                    is_assetbased_cashflow_comment: null,
                    is_loan_underwritten_comment: null,
                    is_hard_asset_collateral_comment: null,
                    hard_asset_collateral_list_comment: null,
                    is_cov_lite_comment: null,
                    covenants_list_comment: null,
                    source_of_deal_comment: null,
                    has_financial_sponsor_comment: null,
                    financial_sponsor_list_comment: null,
                    has_leadership_role_comment: null,
                    primary_deal_purpose_comment: null,
                    secondary_deal_purpose_comment: null,
                    expected_exit_date_comment: null,
                    primary_exit_type_id_comment: null,
                    primary_exit_type_comment: null,
                    secondary_exit_type_id_comment: null,
                    secondary_exit_type_comment: null,
                    num_syndicate_leaders_comment: null,
                    is_tor_only_lender_comment: null,

                    deal_information_notes: null
                }
            });
            console.log(error);
        });

        await dealBALService.getDealMetrics(value, this.state.dealMetrics.quarter).then(data => {
            data['deal_id'] = data['deal']
            delete (data['deal']);
            data['dispRate'] = data['rate']
            this.setState({
                dealMetrics: data,
                dealMetricsDb: data
            });
        }, error => {
            this.setState({
                dealMetrics: {
                    ...this.state.dealMetrics,
                    is_underwriting: true,
                    quarter: "Underwriting",
                    currency: "USD",
                    rate: 1.0,
                    dispRate: 1.0,

                    senior_debt: null,
                    secured_debt: null,
                    parri_passu_debt: null,
                    senior_debt_tranche: null,
                    total_debt_entry: null,
                    distressed_market_value: null,
                    cash_balance_entry: null,
                    gross_debt: null,
                    equity_book_value: null,
                    equity_market_value: null,
                    equity_value_dcf: null,
                    unadjusted_ltm_ebitda: null,
                    adjusted_ltm_ebitda: null,
                    appraised_value: null,
                    implied_ownership: null,
                    purchased_price: null,
                    ltm_interest_expense: null,
                    ltm_revenue: null,
                    ltm_revenue_oceania: null,
                    ltm_revenue_africa: null,
                    ltm_revenue_asia: null,
                    ltm_revenue_europe: null,
                    ltm_revenue_north_america: null,
                    ltm_revenue_south_america: null,
                    ltm_operation_cashflow: null,
                    ltm_capital_expenditure: null,
                    total_leverage_multiple: null,
                    total_ltv_percentage: null,
                    tor_leverage_attachment: null,
                    tor_leverage_detachment: null,
                    tor_ltv_detachment: null,
                    tor_ltv_attachment: null,
                    esg_rating_value: null,
                    is_ESG_in_Memo: null,

                    senior_debt_comment: null,
                    secured_debt_comment: null,
                    parri_passu_debt_comment: null,
                    senior_debt_tranche_comment: null,
                    total_debt_entry_comment: null,
                    distressed_market_value_comment: null,
                    cash_balance_entry_comment: null,
                    gross_debt_comment: null,
                    equity_book_value_comment: null,
                    equity_market_value_comment: null,
                    equity_value_dcf_comment: null,
                    unadjusted_ltm_ebitda_comment: null,
                    adjusted_ltm_ebitda_comment: null,
                    appraised_value_comment: null,
                    implied_ownership_comment: null,
                    purchased_price_comment: null,
                    ltm_interest_expense_comment: null,
                    ltm_revenue_comment: null,
                    ltm_revenue_oceania_comment: null,
                    ltm_revenue_africa_comment: null,
                    ltm_revenue_asia_comment: null,
                    ltm_revenue_europe_comment: null,
                    ltm_revenue_north_america_comment: null,
                    ltm_revenue_south_america_comment: null,
                    ltm_operation_cashflow_comment: null,
                    ltm_capital_expenditure_comment: null,
                    total_leverage_multiple_comment: null,
                    total_ltv_percentage_comment: null,
                    tor_leverage_attachment_comment: null,
                    tor_leverage_detachment_comment: null,
                    tor_ltv_detachment_comment: null,
                    tor_ltv_attachment_comment: null,
                    esg_rating_comment: null,
                    is_ESG_in_Memo_comment: null,

                    total_ranking_drawn_debt: null,
                    total_drawn_debt_tranche: null,
                    has_borrower_missed_payment: null,
                    has_borrower_breach_covenant: null,
                    enterprise_value: null,
                    debt_equity_ratio: null,
                    esg_environment_rating_value: null,
                    esg_social_rating_value: null,
                    esg_governance_rating_value: null,

                    total_ranking_drawn_debt_comment: null,
                    total_drawn_debt_tranche_comment: null,
                    has_borrower_missed_payment_comment: null,
                    has_borrower_breach_covenant_comment: null,
                    enterprise_value_comment: null,
                    debt_equity_ratio_comment: null,
                    esg_environment_rating_comment: null,
                    esg_social_rating_comment: null,
                    esg_governance_rating_comment: null,

                    as_of_date: null,
                    as_of_date_comment: null,

                    deal_metrics_notes: null
                }
            });
            console.log(error);
        });

        await dealBALService.getDealRisks(value).then(data => {
            data['deal_id'] = data['deal']
            delete (data['deal']);
            this.setState({
                dealRisk: data,
                dealRiskDb: data
            });
        }, error => {
            this.setState({
                dealRisk: {
                    ...this.state.dealRisk,
                    is_underwriting: true,
                    quarter: "Underwriting",
                    energy_1_id: null,
                    energy_1_direction: null,
                    energy_1_price: null,
                    energy_1_comment: null,

                    energy_2_id: null,
                    energy_2_direction: null,
                    energy_2_price: null,
                    energy_2_comment: null,

                    metal_1_id: null,
                    metal_1_direction: null,
                    metal_1_price: null,
                    metal_1_comment: null,

                    metal_2_id: null,
                    metal_2_direction: null,
                    metal_2_price: null,
                    metal_2_comment: null,

                    currency_1_id: null,
                    currency_1_direction: null,
                    currency_1_price: null,
                    currency_1_comment: null,

                    currency_2_id: null,
                    currency_2_direction: null,
                    currency_2_price: null,
                    currency_2_comment: null,

                    gics_ig_1_id: null,
                    gics_ig_1_direction: null,
                    gics_ig_1_price: null,
                    gics_ig_1_comment: null,

                    gics_ig_2_id: null,
                    gics_ig_2_direction: null,
                    gics_ig_2_price: null,
                    gics_ig_2_comment: null,

                    miscellanious_1: null,
                    miscellanious_1_comment: null,

                    miscellanious_2: null,
                    miscellanious_2_comment: null,

                    counterparty: null,
                    counterparty_comment: null,

                    real_estate_1_type: null,
                    real_estate_1_desc: null,
                    real_estate_1_is_development: null,
                    real_estate_1_location: null,
                    real_estate_1_deal_percentage: null,

                    real_estate_2_type: null,
                    real_estate_2_desc: null,
                    real_estate_2_is_development: null,
                    real_estate_2_location: null,
                    real_estate_2_deal_percentage: null,

                    real_estate_3_type: null,
                    real_estate_3_desc: null,
                    real_estate_3_is_development: null,
                    real_estate_3_location: null,
                    real_estate_3_deal_percentage: null
                }
            });
            console.log(error);
        });

        await dealBALService.getAllPositions(value).then(data => {
            this.setState({
                positionList: data
            });

            var faceV = 0.0;
            var marketV = 0.0;
            var torPos = 0.0;

            for (var i = 0; i < data.length; i++) {
                if (data[i].hasExpired === false) {
                    faceV += data[i].faceValue;
                    marketV += data[i].bookValue;
                    torPos += data[i].torPosition;
                }
            }

            console.log(torPos);

            this.setState({
                faceValue: faceV,
                marketValue: marketV,
                torPositionVal: torPos
            });
        }, error => {
            console.log(error);
        });

        await dealBALService.getTrancheAttribute(value).then(data => {
            for (var i = 0; i < data.length; i++) {
                data[i]['deal_id'] = data[i]['deal']
                data[i]['investment_code'] = data[i]['investmentCode']
                delete (data[i]['deal']);
                delete (data[i]['investmentCode']);
            }
            this.setState({
                trancheAttributeList: data,
                trancheAttributeListDb: data,
                trancheAttribute: {
                    deal_id: null,
                    investment_code: null,
                    asset_type: null,
                    credit_ranking: null,
                    holdco_vs_opco: null,
                    has_defaulted: null,
                    default_date: null,
                    makewhole: null,
                    amortization: null,
                    is_non_recourse: null
                }
            });
        }, error => {
            this.setState({
                trancheAttribute: {
                    deal_id: null,
                    investment_code: null,
                    asset_type: null,
                    credit_ranking: null,
                    holdco_vs_opco: null,
                    has_defaulted: null,
                    default_date: null,
                    makewhole: null,
                    amortization: null,
                    is_non_recourse: null
                }
            });
            console.log(error);
        });

        await dealBALService.getDealReturns(value, this.state.dealMetrics.quarter).then(data => {
            for (var i = 0; i < data.length; i++) {
                data[i]['deal_id'] = data[i]['deal']
                data[i]['return_scenario'] = data[i]['returnScenario']
                delete (data[i]['deal']);
                delete (data[i]['returnScenario']);
            }
            this.setState({
                dealReturnList: data,
                dealReturnListDb: data
            });
        }, error => {
            console.log(error);
        });

        if(this.state.dealMetrics.quarter !== "Underwriting") {
            await dealBALService.getPriorDealMetrics(value, this.state.dealMetrics.quarter).then(data => {
                data['deal_id'] = data['deal']
                delete (data['deal']);
                this.setState({
                    dealPriorMetrics: data
                });
            });
        }

        this.setState({ isDataChanged: false, isDealChange: false });
    }

    handleDropDownChangeTranche(e, { id, name, value }) {
        this.setState({ isDataChanged: true });

        if (this.state.trancheAttribute[name] === null && name !== "has_defaulted") {
            this.setState({ trancheAttribute: { ...this.state.trancheAttribute, [name]: value } });
            let trancheAttributeList = [...this.state.trancheAttributeList];

            for(var i = 0; i < trancheAttributeList.length; i++) {
                if(i === id || trancheAttributeList[i][name] === null || trancheAttributeList[i][name] === undefined) {
                    let trancheAttribute = {
                        ...trancheAttributeList[i],
                        [name]: value
                    }
                    trancheAttributeList[i] = trancheAttribute;
                }
            }

            this.setState({
                trancheAttributeList: trancheAttributeList,
            });

        } else{
            let trancheAttributeList = [...this.state.trancheAttributeList];
            let trancheAttribute = {
                ...trancheAttributeList[id],
                [name]: value
            }
            trancheAttributeList[id] = trancheAttribute;

            this.setState({
                trancheAttributeList: trancheAttributeList,
            });
        }
        
    }

    handleChangeTranche(id, name, value) {
        this.setState({ isDataChanged: true });

        if (this.state.trancheAttribute[name] === null && name !== "has_defaulted") {
            this.setState({ trancheAttribute: { ...this.state.trancheAttribute, [name]: value } });
            let trancheAttributeList = [...this.state.trancheAttributeList];

            for(var i = 0; i < trancheAttributeList.length; i++) {
                if(i === id || trancheAttributeList[i][name] === null || trancheAttributeList[i][name] === undefined) {
                    let trancheAttribute = {
                        ...trancheAttributeList[i],
                        [name]: value
                    }
                    trancheAttributeList[i] = trancheAttribute;
                }
            }

            this.setState({
                trancheAttributeList: trancheAttributeList,
            });

        } else{
            let trancheAttributeList = [...this.state.trancheAttributeList];
            let trancheAttribute = {
                ...trancheAttributeList[id],
                [name]: value
            }
            trancheAttributeList[id] = trancheAttribute;

            this.setState({
                trancheAttributeList: trancheAttributeList,
            });
        }
    }

    handleChangeReturns(id, name, value) {
        let dealReturnList = [...this.state.dealReturnList];
        let dealReturn = {
            ...dealReturnList[id],
            [name]: value
        }
        dealReturnList[id] = dealReturn;
        this.setState({
            dealReturnList: dealReturnList,
        });
        this.setState({ isDataChanged: true });
    }

    handleDropDownChangeMetrics(e, { name, value }) {
        var i;
        if (name === "quarter" && value === "Underwriting") {
            this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: value, is_underwriting: true } });
            this.setState({ dealRisk: { ...this.state.dealRisk, [name]: value, is_underwriting: true } });

            let dealReturnList = [...this.state.dealReturnList];

            for (i = 0; i < 3; i++) {
                let dealReturn = {
                    ...dealReturnList[i],
                    [name]: value,
                    is_underwriting: true
                }
                dealReturnList[i] = dealReturn;
            }

            this.setState({
                dealReturnList: dealReturnList
            });
        } else if (name === "quarter") {
            this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: value, is_underwriting: false } });
            this.setState({ dealRisk: { ...this.state.dealRisk, [name]: value, is_underwriting: false } });

            let dealReturnList = [...this.state.dealReturnList];

            for (i = 0; i < 3; i++) {
                let dealReturn = {
                    ...dealReturnList[i],
                    [name]: value,
                    is_underwriting: false
                }
                dealReturnList[i] = dealReturn;
            }

            this.setState({
                dealReturnList: dealReturnList
            });

            dealBALService.getPriorDealMetrics(this.state.dealMetrics.deal_id, value).then(data => {
                data['deal_id'] = data['deal']
                delete (data['deal']);
                this.setState({
                    dealPriorMetrics: data
                });
            });

        } else if (name  === "bbg_quarter") {
            this.setState({
                bbg_quarter: value 
            })
        } else {
            this.setState({ isDataChanged: true });
            this.setState({ dealMetrics: { ...this.state.dealMetrics, [name]: value } });
        }

        if (name === "quarter") {
            this.setState({quarter: value});
            dealBALService.getDealMetrics(this.state.dealMetrics.deal_id, value).then(data => {
                data['deal_id'] = data['deal']
                delete (data['deal']);
                // data['currency'] = this.state.dealMetrics?.currency;
                // data['rate'] = this.state.dealMetrics?.rate;
                // data['dispRate'] = this.state.dealMetrics?.dispRate;

                data['dispRate'] = data['rate']

                this.setState({
                    dealMetrics: data,
                    dealMetricsDb: data
                });
            }, error => {
                this.setState({
                    dealMetrics: {
                        ...this.state.dealMetrics,
                        is_underwriting: true,
                        quarter: value,
                        currency: "USD",
                        rate: 1.0,
                        dispRate: 1.0,

                        senior_debt: null,
                        secured_debt: null,
                        parri_passu_debt: null,
                        senior_debt_tranche: null,
                        total_debt_entry: null,
                        distressed_market_value: null,
                        cash_balance_entry: null,
                        gross_debt: null,
                        equity_book_value: null,
                        equity_market_value: null,
                        equity_value_dcf: null,
                        unadjusted_ltm_ebitda: null,
                        adjusted_ltm_ebitda: null,
                        appraised_value: null,
                        implied_ownership: null,
                        purchased_price: null,
                        ltm_interest_expense: null,
                        ltm_revenue: null,
                        ltm_revenue_geography: null,
                        ltm_revenue_oceania: null,
                        ltm_revenue_africa: null,
                        ltm_revenue_asia: null,
                        ltm_revenue_europe: null,
                        ltm_revenue_north_america: null,
                        ltm_revenue_south_america: null,
                        ltm_operation_cashflow: null,
                        ltm_capital_expenditure: null,
                        total_leverage_multiple: null,
                        ltv_percentage: null,
                        esg_rating_value: null,
                        is_ESG_in_Memo: null,

                        senior_debt_comment: null,
                        secured_debt_comment: null,
                        parri_passu_debt_comment: null,
                        senior_debt_tranche_comment: null,
                        total_debt_entry_comment: null,
                        distressed_market_value_comment: null,
                        cash_balance_entry_comment: null,
                        gross_debt_comment: null,
                        equity_book_value_comment: null,
                        equity_market_value_comment: null,
                        equity_value_dcf_comment: null,
                        unadjusted_ltm_ebitda_comment: null,
                        adjusted_ltm_ebitda_comment: null,
                        appraised_value_comment: null,
                        implied_ownership_comment: null,
                        purchased_price_comment: null,
                        ltm_interest_expense_comment: null,
                        ltm_revenue_comment: null,
                        ltm_revenue_geography_comment: null,
                        ltm_revenue_oceania_comment: null,
                        ltm_revenue_africa_comment: null,
                        ltm_revenue_asia_comment: null,
                        ltm_revenue_europe_comment: null,
                        ltm_revenue_north_america_comment: null,
                        ltm_revenue_south_america_comment: null,
                        ltm_operation_cashflow_comment: null,
                        ltm_capital_expenditure_comment: null,
                        total_leverage_multiple_comment: null,
                        ltv_percentage_comment: null,
                        esg_rating_comment: null,
                        is_ESG_in_Memo_comment: null,

                        total_ranking_drawn_debt: null,
                        total_drawn_debt_tranche: null,
                        has_borrower_missed_payment: null,
                        has_borrower_breach_covenant: null,
                        enterprise_value: null,
                        debt_equity_ratio: null,
                        esg_environment_rating_value: null,
                        esg_social_rating_value: null,
                        esg_governance_rating_value: null,

                        total_ranking_drawn_debt_comment: null,
                        total_drawn_debt_tranche_comment: null,
                        has_borrower_missed_payment_comment: null,
                        has_borrower_breach_covenant_comment: null,
                        enterprise_value_comment: null,
                        debt_equity_ratio_comment: null,
                        esg_environment_rating_comment: null,
                        esg_social_rating_comment: null,
                        esg_governance_rating_comment: null,

                        as_of_date: null,
                        as_of_date_comment: null,

                        deal_metrics_notes: null
                    }
                });
                console.log(error);
            });

            dealBALService.getDealReturns(this.state.dealMetrics.deal_id, value).then(data => {
                for (var i = 0; i < data.length; i++) {
                    data[i]['deal_id'] = data[i]['deal']
                    data[i]['return_scenario'] = data[i]['returnScenario']
                    delete (data[i]['deal']);
                    delete (data[i]['returnScenario']);
                }
                this.setState({
                    dealReturnList: data,
                    dealReturnListDb: data
                });
            }, error => {
                console.log(error);
                var dealReturnList = [];

                for (var i = 0; i < 3; i++) {
                    var dealReturn = {
                        deal_id: null,
                        is_underwriting: true,
                        quarter: "Underwriting",
                        return_scenario: null,

                        unlevered_irr_percentage: null,
                        expected_return_percentage: null,
                        levered_irr_percentage: null,
                        unlevered_moic: null,
                        levered_moic: null,
                        return_scenario_probability: null,

                        unlevered_irr_percentage_comment: null,
                        expected_return_percentage_comment: null,
                        levered_irr_percentage_comment: null,
                        unlevered_moic_comment: null,
                        levered_moic_comment: null,
                        return_scenario_probability_comment: null
                    };

                    dealReturn.deal_id = this.state.dealMetrics.deal_id;
                    dealReturn.quarter = value;

                    if (value !== 'Underwriting') {
                        dealReturn.is_underwriting = false;
                    }

                    if (i === 0) {
                        dealReturn.return_scenario = "Tor Base Case";
                    } else if (i === 1) {
                        dealReturn.return_scenario = "Tor Downside Case";
                    } else {
                        dealReturn.return_scenario = "Management Base Case";
                    }

                    dealReturnList.push(dealReturn);
                }

                this.setState({ dealReturnList: dealReturnList });
            });
        }
    }

    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    invokeErgonomy(_errorPanicList) {
        Session.setItem("ergonomy", JSON.stringify(_errorPanicList));
        this.getPage();
    }

    setAgGridAPI(api) {
        this.gridApi = api;
    }

    approveDeals = async () => {
        const selectedRows = this.gridApi?.getSelectedNodes();
        if(selectedRows.length > 0) {
            const deals = selectedRows.map(deal => {return {dealID: deal.data['dealID'], dealName: deal.data['deal']} });

            var promises = dealBALService.approve(deals);
            Promise.all(promises).then(data => {
                    //var d = JSON.parse(data);
                    var errorList = data.filter(element => element.error).map(element => { return element.error });
                    if(errorList && errorList.length > 0)
                        this.setState({ showErrorMessage: true, errorList : errorList, errorHeader : "Error while approving the request :", toggleRefresh : !this.state.toggleRefresh });
                    else 
                        this.setState({ showErrorMessage: true, errorList : [], errorHeader : "Approved Successfully", toggleRefresh : !this.state.toggleRefresh });
                  }
            );

            await dealBALService.getRowData().then(data => {
                this.setState({ gridData: data, dealList: data });
            }, error => { console.log(error); });
        }
        else {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: 'First select deal(s) to approve'
            })
        }
    }

    handleHome() {
        if(this.state.isDataChanged) {
            this.setState({
                modalVisible: true,
                modalType: 'saveChanges',
                modalHandler: 'discard',
                modalHeading: 'Do you want to discard changes?'
            })
        } else {
            this.setState({isOpenGrid: true, isDataChanged: false});
        }
    }

    getPage() {
        const { dealAttribute, isApprover, trancheAttribute, trancheAttributeList, modalHeading, dealMetrics, dealPriorMetrics, dealReturnList, dealRisk, dealName, isDataChanged } = this.state;
        const { projectName, faceValue, marketValue, analystDate, analyst1Name, analyst1Weight, analyst2Name, analyst2Weight, analyst3Name, analyst3Weight, analyst4Name, analyst4Weight, torPositionVal } = this.state;
        const elements = ['Executive Summary', 'Tranche Attributes', 'Deal Attributes', 'Credit Metrics', 'Macro Risks']
        if(this.state.gridData.length > 0 && this.state.isDealChange === false) {
            return (
                <div style={{ height: '100%', width: '100%' }} >
                    {
                        this.state.isOpenGrid
                            ?
                            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                                <div>
                                    {
                                        this.state.showErrorMessage ?
                                            <ToastMessage
                                                header={this.state.errorHeader}
                                                errorList={this.state.errorList}
                                                closeMessage={this.closeErrorMessage}
                                            />
                                        : null
                                    } 
                                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                                </div>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TorGrid
                                        setAgGridAPI = {this.setAgGridAPI}
                                        isHideDateInputs={true}
                                        domLayout="normal"

                                        rowData={this.state.gridData}

                                        className="ag-theme-alpine"
                                        rowSelection="multiple"
                                        toggleRefresh= {this.state.toggleRefresh}
                                        groupDefaultExpanded = {-1}
                                        groupIncludeTotalFooter={true}

                                        guikey="deals_dashboard"
                                        gridIdentityColumn="dealID"
                                        gridLinkColumn="Deal"
                                        gridApproveColumn = "Changes"
                                        handleRowSelected={this.handleRowSelected}
                                        isApprover = {isApprover}
                                        approve = {this.approveDeals}
                                        isRowSelectable={ (node) => { 
                                            return isApprover && (node.data? node.data['status'] === 'Submitted' || node.data['statusUnderwriting'] === 'Submitted' || node.data['statusLastQuarter'] === 'Submitted' : false); 
                                        } }
                                    />
                                </div>
                            </div>
                            :
                            <div style={{ gridTemplateRows: 'auto auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                                <div>
                                    <span style={{ float: "left", paddingLeft: "15px" }}>Deals {dealName === "" ? null : `/ ${dealName}`}</span>
                                    <div style={{ float: "left", paddingLeft: "15px", cursor: "pointer" }} onClick={this.handleHome}><FontAwesomeIcon icon={faHome} /></div>
                                </div>
                                <div className="centerElem mb10">
                                    {
                                        elements.map((value, index) => {
                                            if (index + 1 !== this.state.tabindex) {
                                                return <button key={index} className="greyB" onClick={this.setTabIndex(index + 1)}>{value}</button>
                                            } else {
                                                return <button key={index} className="blueB">{value}</button>
                                            }
                                        })
                                    }
                                </div>
                                <div style={{ paddingBottom: "5px", paddingLeft: "40px", paddingRight: "40px" }} >
                                    {
                                        this.state.showMessage === false
                                            ?
                                            null
                                            :
                                            <ToastMessage
                                                header={this.state.messageHeader}
                                                errorList={this.state.errorList}
                                                closeMessage={this.closeMessage}
                                            />
                                    }
                                </div>
                                <div style={{ overflow: 'auto' }}>
                                    <Collapse isOpen={!this.state.isOpenGrid && this.state.tabindex === 1} style={{ height: '100%', width: '100%' }}>
                                        <DealAttributes
                                            dealAttribute={dealAttribute}
                                            projectName={projectName}
                                            analyst1Name={analyst1Name}
                                            analyst2Name={analyst2Name}
                                            analyst3Name={analyst3Name}
                                            analyst3Weight={analyst3Weight}
                                            analyst4Name={analyst4Name}
                                            analyst4Weight={analyst4Weight}
                                            analyst2Weight={analyst2Weight}
                                            analyst1Weight={analyst1Weight}
                                            analystDate = {analystDate}
                                            faceValue={faceValue}
                                            marketValue={marketValue}
                                            setTabIndex={this.setTabIndex}
                                            saveDB={this.saveDB}
                                            approveDB={this.approveDB}
                                            handleChangeNumber={this.handleChangeNumber}
                                            handleChange={this.handleChange}
                                            handleDropDownChange={this.handleDropDownChange}
                                            handleDropDownChangeDeal={this.handleDropDownChangeDeal}
                                            dealList={this.state.dealList}
                                            fieldList={this.state.fieldList}
                                            settingList={this.state.settingList}
                                            isDealChange = {this.state.isDealChange}
                                        />
                                    </Collapse>
                                    <Collapse isOpen={!this.state.isOpenGrid && this.state.tabindex === 2} style={{ height: '100%', width: '100%' }}>
                                        <TrancheAttributes
                                            trancheAttribute={trancheAttribute}
                                            trancheAttributeList={trancheAttributeList}
                                            setTabIndex={this.setTabIndex}
                                            saveDB={this.saveDB}
                                            approveDB={this.approveDB}
                                            handleDropDownChangeTranche={this.handleDropDownChangeTranche}
                                            handleChangeTranche={this.handleChangeTranche}
                                            positionList={this.state.positionList}
                                            fieldList={this.state.fieldList}
                                            settingList={this.state.settingList}
                                            isDealChange = {this.state.isDealChange}
                                        />
                                    </Collapse>
                                    <Collapse isOpen={!this.state.isOpenGrid && this.state.tabindex === 3} style={{ height: '100%', width: '100%' }}>
                                        <DealAttributes2
                                            dealAttribute={dealAttribute}
                                            setTabIndex={this.setTabIndex}
                                            saveDB={this.saveDB}
                                            approveDB={this.approveDB}
                                            handleChange={this.handleChange}
                                            handleDropDownChange={this.handleDropDownChange}
                                            handleChangeDate={this.handleChangeDate}
                                            fieldList={this.state.fieldList}
                                            settingList={this.state.settingList}
                                            isDealChange = {this.state.isDealChange}
                                        />
                                    </Collapse>
                                    <Collapse isOpen={!this.state.isOpenGrid && this.state.tabindex === 4} style={{ height: '100%', width: '100%' }}>
                                        <CreditMetrics
                                            dealMetrics={dealMetrics}
                                            dealPriorMetrics={dealPriorMetrics}
                                            dealReturnList={dealReturnList}
                                            positionList={this.state.positionList}
                                            torPositionVal={torPositionVal}
                                            setTabIndex={this.setTabIndex}
                                            saveDB={this.saveDB}
                                            approveDB={this.approveDB}
                                            handleChangeMetrics={this.handleChangeMetrics}
                                            handleDropDownChangeMetrics={this.handleDropDownChangeMetrics}
                                            handleChangeReturns={this.handleChangeReturns}
                                            handleChangeDateMetrics={this.handleChangeDateMetrics}
                                            handleClickPriorMetrics={this.handleClickPriorMetrics}
                                            handleClickBbg={this.handleClickBbg}
                                            handleChangeCurrency={this.handleChangeCurrency}
                                            fieldList={this.state.fieldList}
                                            settingList={this.state.settingList}
                                            isDealChange = {this.state.isDealChange}
                                            bbgDealList = {this.state.bbgDealList}
                                            bbg_quarter = {this.state.bbg_quarter}
                                        />
                                    </Collapse>
                                    <Collapse isOpen={!this.state.isOpenGrid && this.state.tabindex === 5} style={{ height: '100%', width: '100%' }}>
                                        <DealRisks
                                            dealRisk={dealRisk}
                                            setTabIndex={this.setTabIndex}
                                            saveDB={this.saveDB}
                                            approveDB={this.approveDB}
                                            handleChange={this.handleChangeRisk}
                                            handleDropDownChange={this.handleDropDownChangeRisk}
                                            handleChangeDate={this.handleChangeDateRisk}
                                            fieldList={this.state.fieldList}
                                            settingList={this.state.settingList}
                                            isDealChange = {this.state.isDealChange}
                                        />
                                    </Collapse>
                                </div>
                            </div>
                    }

                    <Prompt
                        when={this.state.isOpenGrid === false && dealAttribute.deal_id !== null && isDataChanged === true}
                        message={location => `Do you want to discard changes?`}
                    />

                    <div >
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "420px", height: "270px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                                    :
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style= {{width: "350px", height: "220px"}}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                            }
                        </section>
                    </div>
                    <div style={{ display: this.state.display, position: 'relative' }} className="alert alert-danger alert-dismissible fade show" role="alert">
                        <span>Cannot connect to server.</span>
                        <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        }
    }  /**</getPage>*************************************************************************************************/
} /**</class Deals>***************************************************************************************/

export default Deals;
