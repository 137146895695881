import { positionBALService } from './position.bal';
import { dealBALService } from './deal.bal';
import { portfolioOverviewSummaryBALService } from './portfolioOverviewSummary.bal'
import { portfolioOverviewTopExposureBALService } from './portfolioOverviewTopExposure.bal'
import { portfolioOverviewTopTRSBALService } from './portfolioOverviewTopTRS.bal'
import { cdsTopsheetBalService } from './Hedge/cdsTopsheet.bal'
import { equityTopsheetBalService } from './Hedge/equityTopsheet.bal'
import { fxTopsheetBalService } from './Hedge/fxTopsheet.bal'
import { irTopsheetBalService } from './Hedge/irTopsheet.bal'
import { hedgeReportBalService } from './Hedge/hedgeReport.bal'
import { fxReportBalService } from './fxReport.bal'
import { portfolioProjectionBalService } from './Portfolio-Dashboard/portfolioProjection.bal'
import { dealStressBALService } from './Deal-Dashboard/dealStress.bal'
import { iRRiskBALService } from './Deal-Dashboard/iRRisk.bal'
import { stressSummaryBALService } from './stressSummary.bal'
import { cDRiskBALService } from './Deal-Dashboard/cDRisk.bal'
import { eqRiskBALService } from './Deal-Dashboard/eqRisk.bal'
import { cDRisk100BALService } from './Deal-Dashboard/cdRisk100percent.bal'
import { hCashflowBalService } from './Deal-Dashboard/historicalCashflow.bal'
import { pCashflowBalService } from './Deal-Dashboard/projectedCashflow.bal'
import { dTradesBalService } from './Deal-Dashboard/dealTrades.bal'
import { dealMarksBalService } from './Deal-Dashboard/dealMarks.bal'
import { dealDashboardBALService } from './Deal-Dashboard/dealDashboard.bal'
import { dealDashboardSummaryBALService } from './Deal-Dashboard/dealDashboardSummary.bal'
import { dealRiskBALService } from './Deal-Dashboard/dealRisk.bal'
import { dealAttributeChangesBALService } from './Deal-Dashboard/dealAttributeChanges.bal'
import { dealTrancheAttributeChangesBALService } from './Deal-Dashboard/dealTrancheAttributeChanges.bal'
import { dealCreditMetricsChangesBALService } from './Deal-Dashboard/dealCreditMetricsChanges.bal'
import { dealMacroRiskChangesBALService } from './Deal-Dashboard/dealMacroRiskChanges.bal'
import { dealReturnsChangesBALService } from './Deal-Dashboard/dealReturnsChanges.bal'
import { dealPerformanceSummaryBALService } from './Deal-Dashboard/dealPerformanceSummary.bal'
import { dealPerformanceSummaryCTRBALService } from './Deal-Dashboard/dealPerformanceSummaryCTR.bal'
import { dealPerformanceSummaryTopBALService } from './Deal-Dashboard/dealPerformanceSummaryTop.bal' 
import { dealProjectionTopBALService } from  './Deal-Dashboard/dealProjectionTop.bal' 
import { portfolioPerformanceTopMTDBALService } from './Portfolio-Dashboard/portfolioPerformanceTopMTD.bal'
import { portfolioPerformanceTopQTDBALService } from './Portfolio-Dashboard/portfolioPerformanceTopQTD.bal'
import { portfolioPerformanceTopYTDBALService } from './Portfolio-Dashboard/portfolioPerformanceTopYTD.bal'
import { portfolioPerformanceBottomMTDBALService } from './Portfolio-Dashboard/portfolioPerformanceBottomMTD.bal'
import { portfolioPerformanceBottomQTDBALService } from './Portfolio-Dashboard/portfolioPerformanceBottomQTD.bal'
import { portfolioPerformanceBottomYTDBALService } from './Portfolio-Dashboard/portfolioPerformanceBottomYTD.bal'
import { portfolioPerformanceDecompositionBALService } from './Portfolio-Dashboard/portfolioPerformanceDecomposition.bal'
import { portfolioPerformanceSummaryBALService } from './Portfolio-Dashboard/portfolioPerformanceSummary.bal'
import { portfolioPerformanceGridBALService } from './Portfolio-Dashboard/portfolioPerformanceGrid.bal'
import { portfolioPerformanceSummaryTopBALService} from './Portfolio-Dashboard/portfolioPerformanceSummaryTop.bal'
import { cashMarginBalService} from './Portfolio-Dashboard/cashMarginOverview.bal'
import { historicalCashflowTopBALService } from './Deal-Dashboard/historicalCashflowTop.bal'
import { icApprovalBALService } from './icApproval.bal'
import { approvalBALService } from './approval.bal'
import { approvalReminderBALService } from './approvalReminder.bal'
import { markingToolBALService } from './markingTool.bal'
import { exposureRiskBalService } from './Portfolio-Dashboard/exposureRisk.bal';
import { creditMetricBalService } from './Portfolio-Dashboard/creditMetrics.bal';
import { assetCommentaryBALService } from './assetCommentary.bal'
import { assetCommentaryReviewBALService } from './assetCommentaryReview.bal'
import { pnlBALService } from './pnl.bal'
import { topMTDpnlBALService } from './topMTDpnl.bal'
import { bottomMTDpnlBALService } from './bottomMTDpnl.bal'
import { topDODpnlBALService } from './topDODpnl.bal'
import { bottomDODpnlBALService } from './bottomDODpnl.bal'
import { housingBALService } from './housing.bal'
import { housingSummaryBALService } from './housingSummary.bal'
import { userLogBALService } from './userLog.bal'
import { dealLegalDetailsBALService } from './dealLegalDetails.bal'
import { dealPipelineSummaryBALService } from './dealPipelineSummary.bal'
import { restrictedListBALService } from './restrictedList.bal'
import { coInvestmentBALService } from './coInvestment.bal'
import { covenantInstanceBALService } from './covenantInstance.bal'
import { covenantTargetBALService } from './covenantTarget.bal'
import { covenantMasterBALService } from './covenantMaster.bal'
import { publicPnLBALService } from './publicPnL.bal'
import { taskDetailBALService } from './taskDetail.bal'
import { taskInstanceBALService } from './taskInstance.bal'

export const torGridBALService = {
     getRowData
     ,getDashboardData
     ,getChartData
     ,getColumnList
     ,getColumnListToggle
    ,getAutoGroupColumnDef
    ,getDetailCellRendererParams
};

var services = {
     'positions1'                                       : positionBALService
    ,'deals_dashboard'                                  : dealBALService
    ,'portfolioOverviewSummary'                         : portfolioOverviewSummaryBALService
    ,'portfolioOverviewTopExposure'                     : portfolioOverviewTopExposureBALService
    ,'portfolioOverviewTopTRS'                          : portfolioOverviewTopTRSBALService
    ,'cdsTopsheet'                                      : cdsTopsheetBalService
    ,'equityTopsheet'                                   : equityTopsheetBalService
    ,'fxTopsheet'                                       : fxTopsheetBalService
    ,'irTopSheet'                                       : irTopsheetBalService
    ,'hedgeReport'                                      : hedgeReportBalService
    ,'fxReport'                                         : fxReportBalService
    ,'portfolioProjection'                              : portfolioProjectionBalService
    ,'dealStress'                                       : dealStressBALService
    ,'iRRisk'                                           : iRRiskBALService
    ,'stressSummary'                                    : stressSummaryBALService
    ,'cDRisk'                                           : cDRiskBALService
    ,'cDRisk100'                                        : cDRisk100BALService
    ,'eqRisk'                                           : eqRiskBALService
    ,'hCashflow'                                        : hCashflowBalService
    ,'pCashflow'                                        : pCashflowBalService
    ,'dealDashboard'                                    : dealDashboardBALService
    ,'dealDashboardSummary'                             : dealDashboardSummaryBALService
    ,'dealRisk'                                         : dealRiskBALService
    ,'dealTrades'                                       : dTradesBalService
    ,'dealMarks'                                        : dealMarksBalService
    ,'dealAttributeChanges'                             : dealAttributeChangesBALService
    ,'dealTrancheAttributeChanges'                      : dealTrancheAttributeChangesBALService
    ,'dealCreditMetricsChanges'                         : dealCreditMetricsChangesBALService
    ,'dealMacroRiskChanges'                             : dealMacroRiskChangesBALService
    ,'dealReturnsChanges'                               : dealReturnsChangesBALService
    ,'dealPerformanceSummary'                           : dealPerformanceSummaryBALService
    ,'dealPerformanceSummaryCTR'                        : dealPerformanceSummaryCTRBALService
    ,'dealPerformanceSummaryTop'                        : dealPerformanceSummaryTopBALService
    ,'dealProjectionTop'                                : dealProjectionTopBALService             
    ,'portfolioPerformanceTopMTD'                       : portfolioPerformanceTopMTDBALService
    ,'portfolioPerformanceTopYTD'                       : portfolioPerformanceTopYTDBALService
    ,'portfolioPerformanceTopQTD'                       : portfolioPerformanceTopQTDBALService
    ,'portfolioPerformanceBottomMTD'                    : portfolioPerformanceBottomMTDBALService
    ,'portfolioPerformanceBottomYTD'                    : portfolioPerformanceBottomYTDBALService
    ,'portfolioPerformanceBottomQTD'                    : portfolioPerformanceBottomQTDBALService
    ,'portfolioPerformanceDecomposition'                : portfolioPerformanceDecompositionBALService
    ,'portfolioPerformanceSummary'                      : portfolioPerformanceSummaryBALService
    ,'portfolioPerformanceGrid'                         : portfolioPerformanceGridBALService
    ,'portfolioPerformanceSummaryTop'                   : portfolioPerformanceSummaryTopBALService
    ,'historicalCashflowTop'                            : historicalCashflowTopBALService
    ,'icApproval'                                       : icApprovalBALService
    ,'approvals'                                        : approvalBALService
    ,'markingTool'                                      : markingToolBALService
    ,'exposureRisk'                                     : exposureRiskBalService
    ,'creditMetrics'                                    : creditMetricBalService
    ,'approvalReminder'                                 : approvalReminderBALService
    ,'assetCommentary'                                  : assetCommentaryBALService
    ,'pnl'                                              : pnlBALService
    ,'topMTDpnl'                                        : topMTDpnlBALService
    ,'bottomMTDpnl'                                     : bottomMTDpnlBALService
    ,'topDODpnl'                                        : topDODpnlBALService
    ,'bottomDODpnl'                                     : bottomDODpnlBALService
    ,'housing'                                          : housingBALService
    ,'housingSummary'                                   : housingSummaryBALService
    ,'userLog'                                          : userLogBALService
    ,'assetCommentaryReview'                            : assetCommentaryReviewBALService
    ,'dealLegalDetails'                                 : dealLegalDetailsBALService
    ,'restrictedList'                                   : restrictedListBALService
    ,'coInvestment'                                     : coInvestmentBALService
    ,'cashMarginSummary'                                : cashMarginBalService
    ,'covenantTarget'                                   : covenantTargetBALService
    ,'covenantInstance'                                 : covenantInstanceBALService
    ,'covenantMaster'                                   : covenantMasterBALService
    ,'publicPnL'                                        : publicPnLBALService
    ,'dealPipelineDetails'                              : dealPipelineSummaryBALService
    ,'taskDetail'                                       : taskDetailBALService
    ,'taskInstance'                                     : taskInstanceBALService
}

function getRowData(gridId, params) {
    return services[gridId]?.getRowData(params);
}

function getDashboardData(gridId, params) {
    return services[gridId].getPositionsData(params);
}

function getChartData(gridId, params) {
    return services[gridId].getChartData(params);
}

function getColumnList(gridId) {
    return services[gridId]?.getColumnList(gridId);
}

function getColumnListToggle(gridId) {
    return services[gridId]?.getColumnListToggle(gridId);
}

function getAutoGroupColumnDef(gridId) {
    return services[gridId]?.getAutoGroupColumnDef(gridId);
}

function getDetailCellRendererParams(gridId) {
    if (typeof services[gridId]?.getDetailCellRendererParams !== "undefined") { 
        return services[gridId]?.getDetailCellRendererParams(gridId);
    }
    return null;
}