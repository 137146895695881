import { Utils } from './../../helper/utils';
import { userBALService } from './../user.bal';
const {config} = require('../../config');
const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
            {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': True, minWidth : 180, maxWidth: 280},
            {'display': 'Investment Code', 'col': 'investmentCode', 'agg': 'uniq', 'ag-grid': True, minWidth : 280, maxWidth: 380},
            {'display': 'Column', 'col': 'column', 'agg': 'uniq', 'ag-grid': True, minWidth :180, maxWidth: 280},
            {'display': 'New Value', 'col': 'newValue', 'agg': 'uniq', 'ag-grid': True, minWidth : 180, maxWidth: 380},
            {'display': 'Existing Value', 'col': 'existingValue', 'agg': 'uniq', 'ag-grid': True, minWidth : 180, maxWidth: 380}
]};
}

export const dealTrancheAttributeChangesBALService = {
     getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 250
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(params) {
    var { Deal } = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/trancheattributesdiff?deal=${Deal}`, requestOptions).then(Utils.handleResponse).then(changes => { return changes; });
}