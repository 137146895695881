import React, { Component } from 'react';
import TorGrid from '../torgrid/torgrid';
import ChartDisplay from "../portfolioDashboard/components/chartDisplay.js";
import _ from 'underscore';


class DealPerformanceOverview extends Component {
    constructor(props) {
        super(props);

        this.isZero = this.isZero.bind(this);
        this.getDate = this.getDate.bind(this);
        this.agValueFormatter = this.agValueFormatter.bind(this);
        this.getValueFormatted = this.getValueFormatted.bind(this);
        this.getData = this.getData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    isZero(value) {
        return value < 0.0001 && value > -0.0001
    }

    getDate(params) {
        var val = Date(params.value);
        return val;
    }

    agValueFormatter(value, format) {
        var agformat = format;
        if (value && value !== '' && value !== 0) {
            switch (agformat) {
                case 'millionsFormat':
                    return this.getValueFormatted(value / 1e6, 1);
                case 'millionsFormat2':
                    return this.getValueFormatted(value / 1e6, 2);
                case 'percentFormat':
                    return this.getValueFormatted(value * 100);
                case 'percentFormat1':
                    return this.getValueFormatted(value * 100, 1);
                case 'decimalFormat0':
                    return this.getValueFormatted(value, 0);
                case 'decimalFormat2':
                    return this.getValueFormatted(value, 2);
                case '{0:.2f}x':
                    return this.getValueFormatted(value);
                case 'leverageFormat':
                    const displayvalue = this.getValueFormatted(value, 2);
                    return `${displayvalue}${displayvalue && displayvalue.toString() !== '' ? 'x' : ''}`;
                case 'dateFormat':
                    return this.getDate(value);
                default:
                    return value;
            }
        }
        else if (value && value !== '' && agformat === 'decimalFormatShowZero2')
            return this.getValueFormatted(value, 2);
        return '';
    }

    getValueFormatted(param, digit = 2) {
        var result = param;
        var val = 0.0;

        if (!isNaN(result)) {
            try {
                val = parseFloat(result).toFixed(digit);
                if (this.isZero(val)) return '';

                if (!isNaN(val))
                    result = val.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            } catch (error) {
                val = result;
            }
        }
        return result;
    }

    getData() {
        var grossMV = 0.0;
        var capital = 0.0;
        var cYield = 0.0;

        var data = this.props.gridData;
        if(data) {
            for (var i = 0; i < data.length; i++) {
                grossMV += data[i]['asset mv'];
                capital += data[i]['capital total'];
                cYield += (data[i]['gross annual total coupon'] + data[i]['gross annual fx carry'] + data[i]['annual cost of capital'])
            }
        }
        return [grossMV, capital, grossMV / capital, cYield / capital];
    }


    getTemplate() {
        if (this.props.gridData && this.props.gridData.length > 0) {
            const {gridData} = this.props;
            var fundList = _.keys(_.countBy(gridData, function(gridData) { return gridData['Fund']; }));
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: '15px'}}>
                    <h4>*Deal level Contribution-To-Return is approximated and may not add up to the fund total return.</h4>
                    <div>
                            <TorGrid
                                guikey="dealPerformanceSummaryTop"
                                isHideDateInputs={true}
                                isHideSaveGridLayout={true}

                                rowData={this.props.gridData}
                                groupBy='Fund' 

                                className="topDisplayGrid-group"
                                rowGroupPanelShow="none"
                                enableFilter={false}
                                domLayout="autoHeight"

                                groupIncludeFooter={fundList.length > 1 ? true : false}
                                groupIncludeTotalFooter={fundList.length > 1 ? true : false}

                                defaultColDef={this.props.defaultColDef ??
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                                }
                                handleRowSelected={this.handleRowSelected}
                                sideBar='none'
                                gridTitle='PNL Overview (Pnl in MM, excluding non-deal allocations):'
                                gridIdentityColumn="pnl_id"
                            />
                    </div>
                    <div style={{ paddingTop: '50px' }}>
                        <TorGrid
                            guikey="dealPerformanceSummary"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}

                            rowData={this.props.gridData}

                            suppressExpandablePivotGroups={true}
                            className="dashboardgrid"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="autoHeight"

                            groupIncludeFooter={true}
                            groupIncludeTotalFooter={true}

                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                            }
                            gridTitle=''

                            handleRowSelected={this.handleRowSelected}
                            sideBar='none'
                            gridIdentityColumn="pnl_id"
                        />
                    </div>
                    <div style={{ paddingTop: '50px' }}>
                        <TorGrid
                            guikey="dealPerformanceSummaryCTR"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}

                            rowData={this.props.gridData}

                            suppressExpandablePivotGroups={true}
                            className="dashboardgridNoTotal"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="autoHeight"

                            groupIncludeFooter={true}

                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                            }
                            gridTitle=''

                            handleRowSelected={this.handleRowSelected}
                            sideBar='none'
                            gridIdentityColumn="pnl_id"
                        />
                    </div>
                    <div style={{ paddingTop: '50px' }}>
                        <ChartDisplay
                            chartType='bar-stacked'
                            legend={false}
                            yKeys= {"gross profit"}
                            GroupBy="Fund"
                            xKey="Report Date"
                            dataList={this.props.gridData}
                            rotationAngle={335}
                            chartTitle="Monthly Gross PnL"
                        />
                    </div>
                </div>
            );
        } else {
             return null;
        }
    }
}

export default DealPerformanceOverview;