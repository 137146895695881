import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { dealPipelineSummaryBALService } from '../../bal/dealPipelineSummary.bal';
import moment from 'moment'; 
import { faSync, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcelExport from '../excelExport/excelExport';
import ExcelImport from '../excelImport/excelImport';
import { dealBALService } from '../../bal/deal.bal'; 

class DealPipelineDashboard extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            gridDataExcel: [],
            settingList: [],
            assetTypeList : [],
            creditRankingList : [],
            statusList : [],
            currencyList: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            toDate: moment(today).format("YYYY-MM-DD"),
            fromDate: moment(yesterday).format("YYYY-MM-DD"),
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ],
            values: null,
            selectedRows: null
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.getPage = this.getPage.bind(this);
        this.addNew = this.addNew.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.reportModal = this.reportModal.bind(this);
        this.handleChangeCheckboxValues = this.handleChangeCheckboxValues.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.loadSettings = this.loadSettings.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
        this.saveDB = this.saveDB.bind(this);
        this.loadSettings();
        this.refresh();
    }

    getSettingByName(settingList, name) {        
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    loadSettings() {
        dealBALService.getAnalysts().then(data => { this.setState({ analystList: data.map(item => { return { key: item.name, value: item.id, text: item.name }}) });}, error => { console.log(error); });
        dealBALService.getGics().then(data => {
            const gicsSectorList = data.map(item => { return { key: item.name, value: item.id, text: item.name } });
            this.setState({
                gicsSectorListRaw : data, 
                gicsSectorList: gicsSectorList 
            }); 
        },         
            error => { console.log(error); });
        dealBALService.getAllCountry().then(data => { this.setState({ countryList: data.map(item => { return { key: item.id, value: item.id, text: item.name }}) }); }, error => { console.log(error); });

        dealBALService.getAllSettings().then(settingList => {  
            const assetTypeList = this.getSettingByName(settingList, "Asset Type").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const creditRankingList = this.getSettingByName(settingList, "Credit Ranking").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const statusList = this.getSettingByName(settingList, "Deal Pipe Status").map(item => { return { key: item.value, value: item.id, text: item.value } });          

            dealBALService.getRates().then(data => {
                const currencyList = data.map(item => { return { key: item.Currency, value: item.Currency, text: item.Currency } });
                this.setState({
                    settingList: settingList,
                    assetTypeList : assetTypeList,
                    creditRankingList : creditRankingList,
                    statusList : statusList,
                    currencyList: currencyList
                });
            }, error => { console.log(error); });

        }, error => { console.log(error); });
    }

    

    handleRowSelected(e) {
        const { data } = e.target;
        console.log(data);
        this.setState({ redirectTo:{pathname : '/dealPipeline', state : {data : data } } });
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    generateReport = async() => {
        this.setState({
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'close',
            modalHeading: 'Report is being generated!'
        }); 
    }

    reportModal = () => {
        this.setState({
            modalVisible: true,
            modalType: 'genReport',
            modalHandler: 'close',
            modalHeading: 'Generate Report'
        })
    }

    addNew = async () => {
        this.setState( { redirectTo : 'dealPipeline' })
        this.refresh();
    }

    refresh() {
        dealPipelineSummaryBALService.getRowData().then(data => {
            let showGrid = false;
            let gridDataExcel = [];
            if(data && data.length > 0) {
                data = data.map(row => { return {
                            ...row, 
                            existingValue: row?.value
                    }
                });
                showGrid = true;
                gridDataExcel =  data?.map(row => {
                    row = JSON.parse(JSON.stringify( row, ["projectName","leadAnalystName","analyst2Name","assetType","publicPrivate","creditRanking","status","expectedExecutionDate","countryOfRisk","gicsSector","purchaseIssuePrice","cashCoupon","pikCoupon","equityUpside","expectedTenor","expectedTorSize","totalDealSize","expectedIRR","comment"],1))
                    return row;
                });
            }

            this.setState({
                gridData: data,
                gridDataExcel: gridDataExcel,
                showGrid: showGrid 
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving data!'
            }); 
            console.log(error);
        });
    }

    handleChange(name, value) {
        console.log(name, value);
        this.setState({ [name]: value });
    }

    handleDropDownChange(e, { name, value }) {
        const { gridData } = this.state;
        let rows = gridData.filter(item => item.dealName === value );

        let values = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));
        let existingValues = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));

        this.setState({ 
            [name]: value,
            selectedRows: rows,
            values : values,
            existingValues : existingValues,
        });
    }
    
    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    handleChangeCheckboxValues(e) {
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });
    }
    
    saveDB (newDealPipeSummary) {
        const {analystList, gicsSectorList, countryList, assetTypeList, creditRankingList, statusList} = this.state
        var error = '';
        if(newDealPipeSummary.projectName === null || newDealPipeSummary.projectName === '') { 
            error += ', Project Name is mandatory!';
        }
        const analyst2Name = newDealPipeSummary['analyst2Name']
        if(analyst2Name !== undefined) {
            const analyst2 = analystList.find(x => x.key === analyst2Name)
            if(analyst2 !== undefined) {
                newDealPipeSummary['analyst2Id'] = analyst2.value;
            }
            else {
                error += `, Analyst 2 with name ${analyst2Name} does not exists`;
            }
        }

        const assetTypeName = newDealPipeSummary['assetType']
        if(assetTypeName !== undefined) {
            const assetType = assetTypeList.find(x => x.key === assetTypeName)
            if(assetType !== undefined) {
                newDealPipeSummary['assetTypeId'] = assetType.value;
            }
            else {
                error += `, Asset Type with name ${assetTypeName} does not exists`;
            }
        }

        const countryOfRiskName = newDealPipeSummary['countryOfRisk']
        if(countryOfRiskName !== undefined) {
            const countryOfRisk = countryList.find(x => x.text === countryOfRiskName)
            if(countryOfRisk !== undefined) {
                newDealPipeSummary['countryOfRiskId'] = countryOfRisk.value;
            }
            else {
                error += `, Country Of Risk with name ${countryOfRiskName} does not exists`;
            }
        }

        const creditRankingName = newDealPipeSummary['creditRanking']
        if(creditRankingName !== undefined) {
            const creditRanking = creditRankingList.find(x => x.key === creditRankingName)
            if(creditRanking !== undefined) {
                newDealPipeSummary['creditRankingId'] = creditRanking.value;
            }
            else {
                error += `, Credit Ranking with name ${creditRankingName} does not exists`;
            }
        }

        const gicsSectorName = newDealPipeSummary['gicsSector']
        if(gicsSectorName !== undefined) {
            const gicsSector = gicsSectorList.find(x => x.key === gicsSectorName)
            if(gicsSector !== undefined) {
                newDealPipeSummary['gicsSectorId'] = gicsSector.value;
            }
            else {
                error += `, GICS Sector with name ${gicsSectorName} does not exists`;
            }
        }

        const leadAnalystName = newDealPipeSummary['leadAnalystName']
        if(leadAnalystName !== undefined) {
            const leadAnalyst = analystList.find(x => x.key === leadAnalystName)
            if(leadAnalyst !== undefined) {
                newDealPipeSummary['leadAnalystId'] = leadAnalyst.value;
            }
            else {
                error += `, Lead Analyst with name ${leadAnalystName} does not exists`;
            }
        }

        const statusName = newDealPipeSummary['status']
        if(statusName !== undefined) {
            const status = statusList.find(x => x.key === statusName)
            if(status !== undefined) {
                newDealPipeSummary['statusId'] = status.value;
            }
            else {
                error += `, Status with name ${statusName} does not exists`;
            }
        }
        //const {analystList, gicsSectorList, countryList, assetTypeList, creditRankingList, statusList, currencyList} = this.state

        if(error === '') {
            //console.log(newDealPipeSummary);
            dealPipelineSummaryBALService.save(newDealPipeSummary).then( 
                data => { 
                    }
                    , error => {
                        error += `, ${error}`;
            });
        }
            
        if(error !== '') {
            error = newDealPipeSummary.projectName + ": " +  error.substring(2);
            console.log('Error while saving Deal Pipeline:');
            console.log(newDealPipeSummary);
            console.log(error);
        }
        return error;
    }

    handleUpload(jsonData) {
        this.refresh();
        const { gridData } = this.state;

        let dictionary = Object.assign({}, ...jsonData.map((x) => ({[x.projectName]: x})));
        console.log(dictionary);

        let dictionaryGridData = Object.assign({}, ...gridData.map((x) => ({[x.projectName]: x})));
        console.log(dictionaryGridData); 
        let newRows = Object.keys(dictionary).filter((x) => dictionaryGridData[x] === undefined).map((x) => {return dictionary[x]});
        console.log(newRows); 

        let updatedRows = Object.keys(dictionary).filter((x) => dictionaryGridData[x] !== undefined).map(
            (x) => {
                return Object.keys(dictionary[x]).filter((column) => dictionary[x][column] !== dictionaryGridData[x][column]).map( (column) => {return {'projectName' : x, 'column':column, 'old': dictionaryGridData[x][column], 'new': dictionary[x][column] }} )
            });
        updatedRows = updatedRows.flat(1);
        console.log(updatedRows);

        let updatedDeals = Object.keys(dictionary).filter((x) => dictionaryGridData[x] !== undefined).map(
            (x) => {
                return Object.keys(dictionary[x]).filter((column) => dictionary[x][column] !== dictionaryGridData[x][column])
                .reduce((dealPipe, column) => {
                    dealPipe[column] = dictionary[x][column];
                    return dealPipe;
                }, {'projectName' : x, 'dealPipeSummaryId': dictionaryGridData[x]['dealPipeSummaryId'], 'isOnlyUpdatedFields' : 1}  )
            });
        console.log(updatedDeals);

        const result = [newRows , updatedDeals].flat(1).map((dealPipeline) => this.saveDB(dealPipeline));
        const errors = result.filter(error => error !=='');
        if(errors !== undefined && errors.length > 0) {
            this.setState({
                showMessage: true, 
                errorList: errors, 
                messageHeader : "Errors while saving the data:",
                modalVisible: true,
                modalType: 'close', 
                modalHandler: 'close',
                modalHeading: 'Errors while saving the data!',
                open: true,
                deal:null
            }, ()=> {
                
            });
        } else {
            this.setState({
                showMessage: true, 
                errorList: [], 
                messageHeader : "",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Excel data saved successfully!',
                open: false,
                deal:null
            }, ()=> {
                
            });

        }

        console.log(errors);

    }

    getPage() {
        const { modalType, deal, showGrid, fund, fundList, fromDate, toDate, modalVisible, modalHeading, gridReminderData, gridData, gridDataExcel, existingDealDetails, newDealDetails } = this.state;

        console.log(JSON.stringify(gridDataExcel));
        return (
            <div style={{ gridTemplateRows: 'auto auto auto 1fr auto', display: 'grid', height: '100%', width: '100%'   }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                } 
                <div> 
                {
                    deal !==null && deal !=='' ?
                    <div style={{ float: "right", padding: "5px 5px", cursor: "pointer" }} onClick={this.refresh}>                            
                        <button key='refreshButton' className="blueB-no-margin">Refresh</button>
                    </div>:<div></div>
                }
                </div> 
                <div>
                    <div style={{ float: "Left", padding: "5px 15px 0px 15px", cursor: "pointer" }} ><h4><u>Deal Pipeline</u></h4></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.addNew}><FontAwesomeIcon icon={faPlusCircle} /></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                
                        <ExcelExport data={gridDataExcel} fileName="dealPipelines" />
                        <ExcelImport handleUpload ={this.handleUpload} buttonCaption="UploadXls"  />
                            
                        </div>
                { 
                    showGrid? 
                    <div style={{ height: '100%', width: '100%' }}>
                         <TorGrid
                            setAgGridAPI = {this.setAgGridAPI}
                            isHideDateInputs={true}
                            domLayout="normal"

                            isHideSaveGridLayout={true}

                            rowData={gridData}
                            //GridRowsToUpdate={GridRowsToUpdate}

                            className="ag-theme-alpine"
                            rowSelection="multiple"
                            gridIdentityColumn="dealPipeSummaryId"

                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}

                            guikey = "dealPipelineDetails"
                            sortColumn = {true}
                            gridLinkColumn="Project Name"
                            //gridApproveColumn = "Changes"
                            handleRowSelected={this.handleRowSelected}

                            singleClickEdit = {true}    
                            isShowGrid = {showGrid}
                            cellValueChanged = {this.cellValueChanged}
                        />
                    </div>:<div><b>Deal Pipeline - No rows to display</b></div>
                }
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingDealDetails) !== JSON.stringify(newDealDetails)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={fromDate}
                    toDate={toDate}
                    fundList={fundList}
                    fund={fund}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default DealPipelineDashboard;