import React, { Component } from 'react';
// import history from '../../history/history';
import config from './../../config';
import LOGO from '../../images/TOR_LOGO_430_2965.png';
import { Session } from '../../helper/session';
import { userBALService } from '../../bal/user.bal';
import { message } from 'antd';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.timeout = 250;
    this.mounted = false;
    this.cssShowError = 'alert alert-danger alert-dismissible fade show';
    this.cssHideError = 'alert alert-danger alert-dismissible fade';
    this.state = {
      isOtpValidated: false,
      isOtpGenerated: false,
      otp: '',
      username: '',
      password: '',
      isPasswordChanged: false,
      error_Message: '',
      token: config.token,
      showHide: 'alert alert-danger alert-dismissible fade',
      error_username: null,
      error_password: null,      
      Data: {
        __MESSAGE__: 'forgotPassword',
        key: 'USER_AUTHENTICATION',
        username: '',
        password: '',
        GUID: ''
      },
      userInfo: null,
      display: "none",
      ws: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleSubmitOtp = this.handleSubmitOtp.bind(this);
    this.handleGenerateOtp = this.handleGenerateOtp.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    
  }
  handleLogin(e) {
    this.props.history.replace('/login');
  }

  handleGenerateOtp(e) {
    e.preventDefault();
    const {username} = this.state;
    if(username) {
      userBALService.generateOtpForgotPassword(this.state.username).then(otp => {
        if (otp !== "SUCCESS") {
          this.setState({ error_Message: `Error generating OTP : ${otp}`, showHide: this.cssShowError });
        } else {
          this.setState({ error_Message: '', showHide: this.cssHideError, isOtpGenerated: true });
        }
      },
        error => {
          this.setState({ error_Message: `Error generating OTP : ${error.toString()}`, showHide: this.cssShowError });
        }
      );    
    } else {
      this.setState({ error_Message: 'Enter the Windows Username', showHide: this.cssShowError });
    }
  }

  componentWillUnmount() {
  }

  connectServer = () => {
  };

  check = () => {
  };

  handleClose() {
    if (this.mounted) {
      this.setState({ showHide: this.cssHideError });
    }
  }

  handleSubmitOtp(e) {
    e.preventDefault();
    const { otp} = this.state;
    if(!isNaN(otp)) {
      userBALService.validateOtpForgotPassword(this.state.username, this.state.otp).then(otp => {
        if (otp === "VALID") {
          this.setState({ error_Message: '', showHide: this.cssHideError, isOtpValidated: true });
        } else {
          this.setState({ error_Message: `Error - ${otp}`, showHide: this.cssShowError });        
        }

      },
        error => {
          this.setState({ error_Message: `Error - ${error.toString()}`, showHide: this.cssShowError });
        }
      );
    } else {
      this.setState({ error_Message: 'Enter the numeric Otp', showHide: this.cssShowError });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let { error_newConfirmPassword, error_newPassword, username, newPassword, newConfirmPassword } = this.state;
    let error_Message;
    e.preventDefault();

    if (newPassword && newConfirmPassword) {
      if (newPassword.length < 8) {
        error_Message = "Enter atleast 8 character in New password!";
        error_newPassword = null;
        error_newConfirmPassword = null;
      }
      else if (newPassword === newConfirmPassword) {
        this.changePassword(username, newPassword);
      }
      else {
        error_Message = "New Password and Confirm Password doesn't match!";
        error_newPassword = null;
        error_newConfirmPassword = null;
      }
    }
    else {
      error_Message = "Enter New Password and Confirm Password!";
      error_newPassword = true;
      error_newConfirmPassword = true;
    } 

    if (error_Message) {
      this.setState({ error_Message: error_Message, showHide: this.cssShowError, error_newPassword: error_newPassword, error_newConfirmPassword: error_newConfirmPassword});
    }
  }

  changePassword(username, newPassword) {
    userBALService.changePasswordForgot(username, newPassword).then(_user => {
      this.resetState();
      localStorage.setItem('loginStatus', 'login');
      this.setState({ userInfo: _user, error_Message: "Password Updated successfully!", isPasswordChanged: true, showHide: this.cssShowError });
      Session.setItem(config.token, JSON.stringify(_user));

      const { location } = this.props;
      const { state } = location;

      /* Here place request login api   // go to state.from if set before */

      if (state && state.from) {
        this.props.history.replace(state.from);
      }
      else { // else go to home
        //this.props.history.replace('/home');
      }
    },
      error => {
        this.setState({ error_Message: `Error changing password - ${error.toString()}`, showHide: this.cssShowError });
        console.log(error.toString());
      }
    );
  }

  error(_error) {
    message.error(_error);
  };

  logout() {
  }

  componentDidMount() {
    this.mounted = true;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      ['error_' + name]: value
    });
  }

  resetState() {
    if (this.mounted) {
      this.setState({
        username: '',
        password: '',
        error_username: null,
        error_password: null
      });
    }
  }


  render() {
    return this.showPage();
  }

  showPage() {
    const {  username, error_username, isOtpGenerated,  isOtpValidated, isPasswordChanged, newPassword, newConfirmPassword, error_otp, error_newConfirmPassword, error_newPassword, error_Message, otp } = this.state;
    return (
      <div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 bColor">
            <div className='login-left'>
            <div className={this.state.showHide} role="alert">
                {error_Message}
                <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleClose}>
                  <span aria-hidden="true">&times;</span> 
                </span>
            </div>
              { !isOtpGenerated ? 
                <form name="form" onSubmit= {this.handleGenerateOtp}>
                 <div className="spacer">
                  <input type="text"
                    placeholder="Windows Username"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                    autoComplete="off"
                    className={'form-control ' + (error_username ? 'is-valid' : 'is-invalid')}
                  />
                </div>
                <div className="spacer">
                  <input className='login-button' type="submit" value="Generate Otp" onClick={this.handleGenerateOtp} />
                </div>
                 </form> :
                !isOtpValidated ?
                  <form name="form" onSubmit= {this.handleSubmitOtp}>
                  <div className="spacer">
                    <div className="spacer">
                      Enter OTP sent to your email
                    <input type="text"
                        placeholder="Otp"
                        name="otp"
                        value={otp}
                        onChange={this.handleChange}
                        className={'form-control ' + (error_otp ? 'is-valid' : 'is-invalid')}
                      />
                    </div>
                    <div className="spacer">
                      <input className='login-button' type="submit" value="Validate Otp" onClick={this.handleSubmitOtp} />
                    </div>
                  </div>
                </form>
                : !isPasswordChanged ?
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className="spacer">
                      <span className='login-lable'><h3>Change Password</h3></span>
                      <div className="spacer">
                        New Password *
                      <input type="password"
                          placeholder="New Password"
                          name="newPassword"
                          value={newPassword}
                          onChange={this.handleChange}
                          className={'form-control ' + (error_newPassword ? 'is-valid' : 'is-invalid')}
                        />
                      </div>
                      <div className="spacer">
                        Confirm Password *
                    <input type="password"
                          placeholder="Confirm Password"
                          name="newConfirmPassword"
                          value={newConfirmPassword}
                          onChange={this.handleChange}
                          className={'form-control ' + (error_newConfirmPassword ? 'is-valid' : 'is-invalid')}
                        />
                      </div>
                      <div className="spacer">
                        <input className='login-button' type="submit" value="Change Password" onClick={this.handleSubmit} />
                      </div>
                    </div>
                  </form>
                  : 
                  <div style={ {width:'100%' , float:'right'} }>
                    {//<a id="login" href="#" title="Click to go to login page" onClick={this.handleLogin}>Login</a>
                    }
                  <button
                    type="button"
                    className="link-button"
                    onClick={this.handleLogin}>
                      Login
                  </button>
                  </div>
              }
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div className="login-right">
              <img src={LOGO} alt="Go"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ForgotPassword;
