import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Date', 'col': 'reportDate','ag-grid': true, maxWidth: 200, editable: true, cellEditor: 'datePicker' },
            {displayIndex: 2, 'display': 'Notes', 'col': 'notes', 'ag-grid': true, autoHeight:true, editable: true , cellEditor: 'agLargeTextCellEditor', cellEditorParams: {maxLength: 5000000, width:500 }},
            {displayIndex: 3, 'display': 'Is Reviewed', 'col': 'isReviewed', 'ag-grid': true, width : 120, type: 'rightAligned',  cellClass: 'rag-green', cellRenderer: "checkboxRenderer" }, 
            {displayIndex: 4, 'display': 'Is Processed', 'col': 'isProcessed', 'ag-grid': true, maxWidth: 150, editable: true },
            {displayIndex: 5, 'display': 'Entered By', 'col': 'enteredBy', 'ag-grid': true, width: 150 },
            {displayIndex: 6, 'display': 'Notes Type', 'col': 'notesType', 'ag-grid': true, width: 150 } 
            ]};
}

export const assetCommentaryReviewBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'deal',
        minWidth: 100,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/emailNotes/getAll`, requestOptions).then(Utils.handleResponse);
}

function save(rows) {
    rows = rows.map(row => {
        return Utils.removeBlanksNotZero(row);
    });
    return fetch(`${config.apiDDSUrl}/addEmailNotes`, { body: JSON.stringify(rows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
