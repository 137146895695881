import React, { Component, createRef } from 'react'; 

export default class TextAreaEditor extends Component {
    constructor(props) {
        super(props);
 
        this.inputRef = createRef();
 
        this.state = {
            value: props.value
        };
    }
 
    componentDidMount() {
         this.inputRef.current.focus();
    }
 
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
        // this simple editor doubles any value entered into the input
        return this.state.value;
    }
 
    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored. 

    render() {
        return (
            <div> 
            <textarea
                ref={this.inputRef}
                rows="5"
                placeholder=""
                name={this.props.fieldName}
                onChange={event => this.setState({value: event.target.value})}
                value={this.state.value}
                autoComplete="off"
                className={'form-control input'}
            /></div>
        );
    }
 }