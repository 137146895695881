import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import * as moment from 'moment';
import { Session } from '../helper/session';


const {config} = require('../config');

const dateFormatter = (params) => {

  return moment(params.value).utcOffset(0, false).format(params.dateFormat ?? 'YYYY-MM-DD');

  //return moment(params.value)?.format(params.dateFormat ?? 'YYYY-MM-DD');
}
const getColumnList = async (params) => {
    const user = JSON.parse(Session.getItem(config.token));        
    const approverRole = user.userRoles.filter(function (item) { return item === "CovenantApprover" });
    const isApprover = approverRole.length > 0;

    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Deal', 'col': 'deal','ag-grid': true, showDisabledCheckboxes: true, minWidth: 200, cellRenderer: 'agGroupCellRenderer', sortable: true, filter: 'agSetColumnFilter', headerCheckboxSelection: isApprover, headerCheckboxSelectionFilteredOnly: isApprover, checkboxSelection: isApprover },
            {displayIndex: 2, 'display': 'Loan', 'col': 'loan', 'ag-grid': true, minWidth: 450, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 2, 'display': 'Type', 'col': 'type', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 3, 'display': 'Category', 'col': 'category', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 4, 'display': 'Frequency', 'col': 'frequency', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 5, 'display': 'Start Date', 'col': 'startDate', 'ag-grid': true, minWidth: 110, autoHeight:true, cellRenderer: dateFormatter, sortable: true, filter: 'agSetColumnFilter'},
            {displayIndex: 6, 'display': 'End Date', 'col': 'endDate', 'ag-grid': true, minWidth: 110, autoHeight:true, sortable: true, cellRenderer: dateFormatter, filter: 'agSetColumnFilter'},
            {displayIndex: 7, 'display': 'Days Due', 'col': 'daysDue', 'ag-grid': true, minWidth: 110, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 8, 'display': 'comment', 'col': 'comment', 'ag-grid': true, minWidth: 800, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 9, 'display': 'Last Updated', 'col': 'updatedDate', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 10, 'display': 'Added By', 'col': 'addedBy', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 11, 'display': 'Is Approved', 'col': 'approved', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 12, 'display': 'Approved By', 'col': 'Approval Date', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 13, 'display': 'Approved On', 'col': 'Approval Date', 'ag-grid': true, minWidth: 200, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            ]
    };
}

const getDetailCellRendererParams = (params) => {
    return  {
       detailGridOptions: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        domLayout: "normal",
        //pagination: true,
        //paginationAutoPageSize: true,
        columnDefs: [
            { headerName: 'Until', field: 'endDate', maxWidth: 120, cellRenderer: dateFormatter, cellRendererParams: { dateFormat: 'YYYY-MM-DD' } },
            { headerName: 'Operator',  field: 'operator', maxWidth: 120 , wrapText: true, autoHeight: true},
            { headerName: 'Value',  field: 'value1', maxWidth: 120 , wrapText: true, autoHeight: true},
            { headerName: '',  field: '', minWidth: 50 , wrapText: true, autoHeight: true},
        ],
        //rowHeight: "auto",
        getRowHeight: params => {
          return params.data.rowHeight ?? "auto";
        },
         defaultColDef: {
          sortable: true,
          flex: 1,
        },         
    },
    
    // get the rows for each Detail Grid
    getDetailRowData: params => {
        params.successCallback(params.data.targets);
    }
    } 
  }
export const covenantMasterBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save,
    getAllCovenantFrequency,
    getAllCovenantCategory,
    getAllCovenantStatus,
    getAllCovenantType,
    getDetailCellRendererParams,
    approve
};

function getAutoGroupColumnDef(params) {
    return {  
      };
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantMasterView`, requestOptions).then(Utils.handleResponse);
}

function getAllCovenantFrequency(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantFrequency`, requestOptions).then(Utils.handleResponse);
}

function getAllCovenantCategory(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantCategory`, requestOptions).then(Utils.handleResponse);
}

function getAllCovenantStatus(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantStatus`, requestOptions).then(Utils.handleResponse);
}

function getAllCovenantType(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantType`, requestOptions).then(Utils.handleResponse);
}

function save(covenantMaster) {
    covenantMaster =   Utils.removeBlanksNotZero(covenantMaster); 
    return fetch(`${config.apiDDSUrl}/addCovenantMaster`, { body: JSON.stringify(covenantMaster), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
} 

function approve(covenantMasters) {
    const promises = covenantMasters.map((covenantMaster) => { 
       var a = fetch(`${config.apiDDSUrl}/addApproval/id=${covenantMaster.id}/type=CovenantMaster/action=approved`, { body: JSON.stringify(covenantMaster), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return handleResponseApprove(data, covenantMaster) });
       return a;
    });
    return promises;
}

function handleResponseApprove(response, covenantMaster) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return {error : `${covenantMaster.covenantMasterId} : ${error}`};
        }
        return data;
    });
}