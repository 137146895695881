import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const SideDrawer = (props) => {
    const classes = useStyles();

    const toggleDrawer = () => (event) => {
        props.toggleDrawer();
    };

    const toggleTab = (val) => (event) => {
        toggleDrawer();
        props.toggleTabIndex(val);
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <List>
                <ListItem disabled>
                    <ListItemText primary={"Portfolio"} />
                </ListItem>
                <Divider />
                {
                    [{ id: 1, text: 'Overview' }, { id: 4, text: 'Grid' }].map((text, index) => (
                        <ListItem button key={text.id} onClick={toggleTab(text.id)}>
                            <ListItemText primary={text.text} style={props.tabindex === text.id ? { textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px" } : { paddingLeft: "10px" }} />
                        </ListItem>
                    ))
                }
                {
                    props.userRoles.includes("Analyst")
                        ?
                        null
                        :
                        [{ id: 5, text: 'Stress' }, { id: 2, text: 'Hedges' }].map((text, index) => (
                            <ListItem button key={text.id} onClick={toggleTab(text.id)}>
                                <ListItemText primary={text.text} style={props.tabindex === text.id ? { textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px" } : { paddingLeft: "10px" }} />
                            </ListItem>
                        ))
                }
                {
                    // [{ id: 3, text: 'FX' }, { id: 12, text: 'Cash/ Margin' }].map((text, index) => (
                    [{ id: 3, text: 'FX' }].map((text, index) => (
                        <ListItem button key={text.id} onClick={toggleTab(text.id)}>
                            <ListItemText primary={text.text} style={props.tabindex === text.id ? { textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px" } : { paddingLeft: "10px" }} />
                        </ListItem>
                    ))
                }
            </List>


            <List>
                <ListItem disabled>
                    <ListItemText primary={"Performance"} />
                </ListItem>
                <Divider />
                {
                    [{ id: 6, text: 'Overview' }, { id: 7, text: 'Grid' }, { id: 8, text: 'Projection' }].map((text, index) => (
                        <ListItem button key={text.id} onClick={toggleTab(text.id)}>
                            <ListItemText primary={text.text} style={props.tabindex === text.id ? { textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px" } : { paddingLeft: "10px" }} />
                        </ListItem>
                    ))
                }
            </List>

            <List>
                <ListItem disabled>
                    <ListItemText primary={"Exposure"} />
                </ListItem>
                <Divider />
                {
                    [{ id: 9, text: 'Credit Metrics' }, { id: 10, text: 'Commodity Risks' }, { id: 11, text: 'Real Estate Risks' }].map((text, index) => (
                        <ListItem button key={text.id} onClick={toggleTab(text.id)}>
                            <ListItemText primary={text.text} style={props.tabindex === text.id ? { textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px" } : { paddingLeft: "10px" }} />
                        </ListItem>
                    ))
                }
            </List>

        </div>
    );

    return (
        <div style={{ height: "100%", width: '100%' }}>
            <React.Fragment key={'left'}>
                <SwipeableDrawer
                    anchor={'left'}
                    open={props.show}
                    onClose={toggleDrawer()}
                    onOpen={toggleDrawer()}
                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

export default SideDrawer;