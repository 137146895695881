import React, { Component } from 'react';
import { torGridBALService } from '../../bal/torGridBALService.bal';
import TorGrid from './../torgrid/torgrid';

class DealMarks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            gridData: [],
            filterData: [],
            dataList: {}
        }

        this.filterData = this.filterData.bind(this);
        this.getData = this.getData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    filterData(colName, value) {
        var data = this.state.gridData;
        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                filterData: arr
            });
        } else {
            this.setState({
                filterData: this.state.gridData
            });
        }
    }

    getData() {
        const { Deal, AsOfDate } = this.props;
        torGridBALService.getRowData("hedgeReport", { query: AsOfDate ? `Exec [sp_get_mark_history] ${Deal}` : `Exec [sp_get_mark_history] '${Deal}', '${AsOfDate}'` }).then(data => {
            var dataList = this.state.dataList;
            dataList[Deal] = data;
            this.setState({isLoading: false});
            this.setState({ 
                gridData: data, 
                filterData: data, 
                dataList: dataList 
            });
        }, error => {
            this.setState({isLoading: false});
            this.setState({ gridData: [], filterData: [] });
        });
    }

    componentDidMount() {
        if(this.props.Deal) {
            this.setState({isLoading: true, gridData: [], filterData: []});
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Deal !== this.props.Deal || prevProps.AsOfDate !== this.props.AsOfDate) {
            var data = this.state.dataList[this.props.Deal];
            if(data) {
                this.setState({ 
                    gridData: data, 
                    filterData: data
                });
            } else {
                this.setState({isLoading: true, gridData: [], filterData: []})
                this.getData();
            }
        }

        if (prevProps.fund !== this.props.fund) {
            this.filterData('Fund', this.props.fund);
        }
    }


    getTemplate() {
        if(this.state.isLoading === true) {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        } else {
            if(this.props.Deal && this.state.filterData.length > 0) {
                return (
                    <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: '15px' }}>
                        <div style={{ gridTemplateRows: '1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                            <div style={{ height: "100%" }}>
                                <TorGrid
                                    guikey="dealMarks"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.state.filterData}
                                    gridIdentityColumn="position_id"
                                    // groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    suppressExpandablePivotGroups={true}
                                    className="ag-theme-alpine"
                                    enableFilter={false}
                                    groupDefaultExpanded={2}

                                    groupColumns={["Fund", "Description"]}
                                />
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }
    }
}
export default DealMarks;