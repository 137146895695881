import React, { Component } from 'react';
import ChartDisplay from "./components/chartDisplay.js"
import TorGrid from '../torgrid/torgrid';
import moment from 'moment';

class PortfolioPerformanceOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridData: []
        }

        this.aggregateData = this.aggregateData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    aggregateData() {
        var { gridData } = this.props;

        var cumSum = 1.0;
        var sum = 0.0;

        var jsObj = {
            date: null,
            sum: null,
            cumSum: null
        };

        var aggList = [];

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        for (var i = 0; i < gridData.length; i++) {
            var date = new Date(gridData[i]['Report Date']);
            date = monthNames[date.getMonth()];

            if (i === 0) {
                jsObj.date = date;
                sum += gridData[i]['ctr total'];
            } else if (date !== jsObj.date) {
                jsObj.sum = sum * 100;

                cumSum *= (1 + sum);

                jsObj.cumSum = (cumSum - 1) * 100;
                aggList.push(jsObj);

                jsObj = {
                    date: null,
                    sum: null,
                    cumSum: null
                };
                jsObj.date = date;

                sum = gridData[i]['ctr total'];

            } else {
                sum += gridData[i]['ctr total'];
            }
        }

        if (jsObj.date !== null) {
            jsObj.sum = sum * 100;

            cumSum *= (1 + sum);

            jsObj.cumSum = (cumSum - 1) * 100;
            aggList.push(jsObj);
        }

        return aggList;
    }


    getTemplate() {
        const {user} = this.props;
        if (this.props.gridData && this.props.gridData.length > 0) {
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: '15px' }}>
                    {
                        (user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester"))
                            ?
                            <div>
                            </div>
                            :
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceSummaryTop"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    query={`exec [sp_get_ctr_pnl] '${this.props.Fund}', '${moment(this.props.AsOfDate).format("YYYYMMDD")}'`}

                                    groupBy='Fund'

                                    gridIdentityColumn="position_id"
                                    className="topDisplayGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'
                                    gridTitle=''
                                />
                            </div>
                    }
                    {
                        (user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester"))
                            ?
                            <div>
                            </div>
                            :
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceDecomposition"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}

                                    gridIdentityColumn="pnl_id"
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupBy={this.props.GroupBy}
                                    domLayout="autoHeight"

                                    defaultColDef={
                                        {
                                            sortable: false
                                            , filter: false
                                            , floatingFilter: false
                                            , enableRowGroup: true
                                            , resizable: true
                                            , suppressSizeToFit: false
                                            , flex: 1
                                        }
                                    }
                                    sideBar='none'
                                    gridTitle=''
                                />
                            </div>
                    }
                    
                    {
                        (user?.userRoles?.includes("Analyst") || user?.userRoles?.includes("Tester"))
                            ?
                            <div>
                            </div>
                            :
                            <div style={{ paddingTop: '50px', marginBottom: '70px', paddingLeft: '30px', paddingRight: '30px', maxHeight: '300px' }}>
                                <ChartDisplay
                                    chartType="cartesian-pnl"
                                    xKey="date"
                                    yKeys={["sum"]}
                                    yNames={["CTR"]}
                                    yKeyLine="cumSum"
                                    data={this.aggregateData()}
                                />
                            </div>
                    }
                    

                    <div style={{ width: '100%', paddingBottom: '30px', paddingLeft: '50px', paddingRight: '50px' }}>
                        <div style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', display: 'grid', width: '100%' }}>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceTopMTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"


                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Top MTD'
                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceBottomMTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"


                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Bottom MTD'
                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceTopQTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Top QTD'

                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceBottomQTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Bottom QTD'

                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceTopYTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Top YTD'
                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                            <div>
                                <TorGrid
                                    guikey="portfolioPerformanceBottomYTD"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='pnl total deal'
                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardTopGrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }
                                    sideBar='none'
                                    gridTitle='Bottom YTD'
                                    pagination={true}
                                    gridIdentityColumn="pnl_id"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default PortfolioPerformanceOverview;