import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');


export const s3BALService = {
    uploadFile,
    downloadFile,
    deleteFile,
    getFiles
};

//https://morioh.com/p/4fc51303e865
function uploadFile(params) { 
    let formData = new FormData();
    formData.append('file', params.file);
    return fetch(`${config.apiDDSUrl}/uploadFile/path=${params.path ?? ''}`, { body: formData, method: 'PUT', headers: { ...userBALService.getAuthHeader() } }).then( data => { return Utils.handleResponse(data) });
}

function downloadFile(params) {
    return fetch(`${config.apiDDSUrl}/downloadFile`, { body: JSON.stringify({fileName: params}), method: 'PUT', responseType: 'arraybuffer', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json'} }).then( data => { return Utils.handleResponseFile(data) });
}

function deleteFile(params) {
    return fetch(`${config.apiDDSUrl}/deleteFile`, { body: JSON.stringify(params), method: 'DELETE', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json'} }).then( data => { return Utils.handleResponse(data) });
}

function getFiles(params) {
    return fetch(`${config.apiDDSUrl}/getS3Files`, { body: JSON.stringify(params), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json'} }).then( data => { return Utils.handleResponse(data) });
}
