/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import { dealBALService } from '../../bal/deal.bal';
import FormDisplay from './components/formDisplay.js';
import GICSPdf from '../../docs/112727-gics-mapbook_2018_v3_letter_digitalspreads.pdf';
import BICSPdf from '../../docs/2072704..pdf';

import ProgressBar from './components/progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faCheck, faSave } from '@fortawesome/free-solid-svg-icons';

// Our components
import './deals.css';

class Deal_Attribute_1 extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.state = {
            projectName: "",
            faceValue: 0,
            marketValue: 0,
            analyst1Weight: 0,
            analyst2Weight: 0,
            analyst3Weight: 0,
            analyst4Weight: 0,
            analystList: [],
            countryList: [],
            gicsSectorList: [],
            gicsIndustryGroupList: [],
            gicsIndustryList: [],
            gicsSubIndustryList: [],
            gicsNaceList: [],
            gicsActivityList: [],
            naceList: [],
            activityList: [],
            bicsSectorList: [],
            bicsIndustryGroupList: [],
            bicsIndustryList: [],
            dealAttributeList: [],
            percent: 0,
            dealAttribute: {
                deal_id: 0,
                country_of_risk_id: null,
                gics_sector_id: null,
                gics_industry_group_id: null,
                gics_industry_id: null,
                gics_sub_industry_id: null,
                bics_sector_id: null,
                bics_industry_group_id: null,
                bics_industry_id: null,
                snp_corporate_family_rating_id: null,
                moodys_corporate_family_rating_id: null,
                company_description: '',


                is_assetbased_cashflow: null,
                is_loan_underwritten: null,
                is_hard_asset_collateral: null,
                hard_asset_collateral_list: null,
                is_cov_lite: null,
                covenants_list: null,
                source_of_deal: null,
                has_financial_sponsor: null,
                financial_sponsor_list: null,
                has_leadership_role: null,
                primary_deal_purpose: null,
                secondary_deal_purpose: null,
                expected_exit_date: null,
                primary_exit_type_id: null,
                primary_exit_type: null,
                secondary_exit_type_id: null,
                secondary_exit_type: null,
                num_syndicate_leaders: null,


                is_assetbased_cashflow_comment: null,
                is_loan_underwritten_comment: null,
                is_hard_asset_collateral_comment: null,
                hard_asset_collateral_list_comment: null,
                is_cov_lite_comment: null,
                covenants_list_comment: null,
                source_of_deal_comment: null,
                has_financial_sponsor_comment: null,
                financial_sponsor_list_comment: null,
                has_leadership_role_comment: null,
                primary_deal_purpose_comment: null,
                secondary_deal_purpose_comment: null,
                expected_exit_date_comment: null,
                primary_exit_type_id_comment: null,
                primary_exit_type_comment: null,
                secondary_exit_type_id_comment: null,
                secondary_exit_type_comment: null,
                num_syndicate_leaders_comment: null,

                deal_information_notes: null
            }
        }
        dealBALService.getAnalysts().then(data => { this.setState({ analystList: data });}, error => { console.log(error); });
        dealBALService.getAllCountry().then(data => { this.setState({ countryList: data }); }, error => { console.log(error); });
        dealBALService.getGics().then(data => { this.setState({ gicsSectorList: data }); }, error => { console.log(error); });
        dealBALService.getNace().then(data => { this.setState({ naceList: data }); }, error => { console.log(error); });
        dealBALService.getNaceActivity().then(data => { this.setState({ activityList: data }); }, error => { console.log(error); });
        dealBALService.getBics().then(data => { this.setState({ bicsSectorList: data }); }, error => { console.log(error); });

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleDropDownChangeDeal = this.handleDropDownChangeDeal.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
    };

    handleChange(e) {
        this.props.handleChange(e);
    }

    handleDropDownChange(e, { name, value }) {
        this.props.handleDropDownChange(e, { name, value });

        if (name === "gics_sector_id") {
            this.setState({ gicsIndustryGroupList: this.state.gicsSectorList.filter(function (item) { return item.id === value })[0]?.industryGroups });
        } else if (name === "gics_industry_group_id") {
            this.setState({ gicsIndustryList: this.state.gicsIndustryGroupList.filter(function (item) { return item.id === value })[0]?.industries });
        } else if (name === "gics_industry_id" && this.state.gicsIndustryList !== undefined) {
            this.setState({ gicsSubIndustryList: this.state?.gicsIndustryList.filter(function (item) { return item.id === value })[0]?.subIndustries });
        } else if (name === "gics_sub_industry_id" && this.state.gicsSubIndustryList !== undefined) {
            const code = this.state?.gicsSubIndustryList.filter(function (item) { return item.id === value });
            console.log("Code", code, value, this.state?.gicsSubIndustryList);
            this.setState({ gicsNaceList: this.state?.naceList.filter(function (item) { return item.code === code }) });
        } else if (name === "bics_sector_id" && (value !== "" || value != null)) {
            this.setState({ bicsIndustryGroupList: this.state.bicsSectorList.filter(function (item) { return item.id === value })[0]?.industryGroups });
        } else if (name === "bics_industry_group_id" && (value !== "" || value != null)) {
            this.setState({ bicsIndustryList: this.state.bicsIndustryGroupList.filter(function (item) { return item.id === value })[0]?.industries });
        }
    }

    handleChangeNumber(name, value) {
        this.props.handleChangeNumber(name, value);
    }

    handleDropDownChangeDeal(e, { name, value }) {
        this.setState({
            positionList: [],
            faceValue: 0,
            marketValue: 0
        })

        this.props.handleDropDownChangeDeal(e, { name, value });
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    getProgress() {
        const { dealAttribute, analyst1Name, projectName, analyst1Weight, fieldList } = this.props;
        let filterData, permission, required;
        var fillCount = 0;
        var count = 0;

        var columnList = ["deal_id",
            "projectName",
            "country_of_risk_id",
            "gics_sector_id",
            "gics_industry_group_id",
            "gics_industry_id",
            "gics_sub_industry_id",
            "bics_sector_id",
            "bics_industry_group_id",
            "bics_industry_id",
            "snp_corporate_family_rating_id",
            "moodys_corporate_family_rating_id",
            "portfolio",
            "tacf_sub_strategy",
            "tacof_sub_strategy",
            "market_risk_sensitivity",
            "equity_upside",
            "equity_upside_form",
            "analyst1Name",
            "analyst1Weight",
            "company_description"
        ]

        for (const key of columnList) {
            filterData = fieldList.filter(function (item) { return item.field_name === key }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (key === "analyst1Weight" && permission !== 2) {
                required = false;
            }

            if (required && permission === 2) {
                if (key === "equity_upside_form" && dealAttribute["equity_upside"] === true) {
                    count += 1;
                    if (dealAttribute[key]) {
                        fillCount += 1;
                    }
                } else if(key !== "equity_upside_form") {
                    count += 1;
                    if (key === "tacf_sub_strategy" || key === "tacof_sub_strategy") {
                        if (dealAttribute["tacf_sub_strategy"] || dealAttribute["tacof_sub_strategy"]) {
                            fillCount += 1;
                        }
                    } else if (key === "projectName") {
                        if (projectName) {
                            fillCount += 1;
                        }
                    } else if (key === "analyst1Name") {
                        if (analyst1Name) {
                            fillCount += 1;
                        }
                    } else if (key === "analyst1Weight") {
                        if (analyst1Weight || analyst1Weight === 0) {
                            fillCount += 1;
                        }
                    } else {
                        if (dealAttribute[key] || dealAttribute[key] === false || dealAttribute[key] === 0) {
                            fillCount += 1;
                        }
                    }
                }
            }
        }

        this.setState({ percent: this.roundToTwo(fillCount / count * 100) });
    }

    componentDidMount() {
        this.getProgress();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dealAttribute !== this.props.dealAttribute || prevProps.projectName !== this.props.projectName
            || prevProps.analyst1Name !== this.props.analyst1Name 
            || (prevProps.analyst1Weight !== this.props.analyst1Weight && Number.isNaN(this.props.analyst1Weight) === false)) {
            this.getProgress();
        }
    }

    getSettingByName(name) {
        const { settingList } = this.props;
        let setting =  settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    render() {
        const { dealAttribute, projectName } = this.props;
        const { analyst1Name, analyst2Name, analyst3Name, analyst4Name, analyst1Weight, analyst2Weight, analyst3Weight, analyst4Weight, analystDate } = this.props;
        var { faceValue, marketValue } = this.props;
        faceValue = faceValue !== 0 ? '$' + Number(parseInt(faceValue, 10)).toLocaleString('en') : 0;
        marketValue = marketValue !== 0 ? '$' + Number(parseInt(marketValue, 10)).toLocaleString('en') : 0;
        const dealList = this.props.dealList.filter(function(item) {
            return (item ? true : false);
        }).map(item => { return { key: item.deal, value: item.dealID, text: item.deal } });
        const analystList = this.state.analystList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const countryList = this.state.countryList.map(item => { return { key: item.id, value: item.id, text: item.name } });
        const gicsSectorList = this.state.gicsSectorList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const gicsIndustryGroupList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const gicsIndustryList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.gics_industry_group_id
        })[0]?.industries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const gicsSubIndustryList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.gics_industry_group_id
        })[0]?.industries.filter(function (item) {
            return item.id === dealAttribute.gics_industry_id
        })[0]?.subIndustries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });

        const subindustryCode = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.gics_industry_group_id
        })[0]?.industries.filter(function (item) {
            return item.id === dealAttribute.gics_industry_id
        })[0]?.subIndustries.filter(function (item) {
            return item.id === dealAttribute.gics_sub_industry_id
        })[0]?.code;
        const gicsNaceList = this.state.naceList.filter(function (item) {
            return item.code === subindustryCode
        }).map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });

        const mitigationActivityList = this.state.activityList.filter(function(item) {
            return item.gics_nace_id === dealAttribute.gics_nace_id && item.mitigationActivity !== null
        }).map(item => {
            return {
                key: item.mitigationActivity, value: item.nace_climate_mitigation_id, text: item.mitigationActivity
            }
        });

        const adaptationActivityList = this.state.activityList.filter(function(item) {
            return item.gics_nace_id === dealAttribute.gics_nace_id && item.adaptationActivity !== null
        }).map(item => {
            return {
                key: item.adaptationActivity, value: item.nace_climate_adaptation_id, text: item.adaptationActivity
            }
        });

        const bicsSectorList = this.state.bicsSectorList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const bicsIndustryGroupList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const bicsIndustryList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.bics_industry_group_id
        })[0]?.industries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const bicsSubIndustryList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.bics_industry_group_id
        })[0]?.industries.filter(function (item) {
            return item.id === dealAttribute.bics_industry_id
        })[0]?.subIndustries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });

        const snPRatingList = this.getSettingByName("S&P Corporate Family Rating").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const moodysRatingList = this.getSettingByName("Moody's Corporate Family Rating").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const tacfSubStrategyList = this.getSettingByName("TACF Sub-Strategy at Inception").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const tacofSubStrategyList = this.getSettingByName("TACOF Sub-Strategy at Inception").map(item => { return { key: item.value, value: item.id, text: item.value } });
        const marketRiskList = this.getSettingByName("Market Risk Sensitivity").map(item => { return { key: item.value, value: item.id, text: item.value } });
        return (
            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%' }}>
                <div style={{ height: '100%' }}>
                    <ProgressBar percent={this.state.percent} />
                </div>
                <div style={{ overflow: 'auto' }}>
                    <div className="dealForm">
                        <div className="row">
                            <div className="column left">
                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Deal Name"
                                    dataList={dealList}
                                    fieldName="deal_id"
                                    dataField={dealAttribute?.deal_id}
                                    handleDropDownChange={this.handleDropDownChangeDeal}
                                    type="dropdown"
                                    clearable={false}
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="IR Code Name/Project Name"
                                    fieldName="projectName"
                                    dataField={projectName}
                                    handleChange={this.handleChange}
                                    type="textbox"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="GICS Sector"
                                    dataList={gicsSectorList}
                                    fieldName="gics_sector_id"
                                    dataField={dealAttribute?.gics_sector_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    tooltip={GICSPdf}
                                    type="dropdown-tooltip"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="GICS Industry Group"
                                    dataList={gicsIndustryGroupList}
                                    fieldName="gics_industry_group_id"
                                    dataField={dealAttribute?.gics_industry_group_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="GICS Industry"
                                    dataList={gicsIndustryList}
                                    fieldName="gics_industry_id"
                                    dataField={dealAttribute?.gics_industry_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="GICS Subindustry"
                                    dataList={gicsSubIndustryList}
                                    fieldName="gics_sub_industry_id"
                                    dataField={dealAttribute?.gics_sub_industry_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="NACE"
                                    dataList={gicsNaceList}
                                    fieldName="gics_nace_id"
                                    dataField={dealAttribute?.gics_nace_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Climate Mitigation Activity"
                                    dataList={mitigationActivityList}
                                    fieldName="eligible_climate_mitigation_activity"
                                    dataField={dealAttribute?.eligible_climate_mitigation_activity}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Climate Adaptation Activity"
                                    dataList={adaptationActivityList}
                                    fieldName="eligible_climate_adaptation_activity"
                                    dataField={dealAttribute?.eligible_climate_adaptation_activity}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="BICS Sector"
                                    dataList={bicsSectorList}
                                    fieldName="bics_sector_id"
                                    dataField={dealAttribute?.bics_sector_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    tooltip={BICSPdf}
                                    type="dropdown-tooltip"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="BICS Industry Group"
                                    dataList={bicsIndustryGroupList}
                                    fieldName="bics_industry_group_id"
                                    dataField={dealAttribute?.bics_industry_group_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="BICS Industry"
                                    dataList={bicsIndustryList}
                                    fieldName="bics_industry_id"
                                    dataField={dealAttribute?.bics_industry_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="BICS Subindustry"
                                    dataList={bicsSubIndustryList}
                                    fieldName="bics_sub_industry_id"
                                    dataField={dealAttribute?.bics_sub_industry_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Country of Risk"
                                    dataList={countryList}
                                    fieldName="country_of_risk_id"
                                    dataField={dealAttribute?.country_of_risk_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="TACF Sub-Strategy"
                                    dataList={tacfSubStrategyList}
                                    fieldName="tacf_sub_strategy"
                                    dataField={dealAttribute?.tacf_sub_strategy}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="TACOF Sub-Strategy"
                                    dataList={tacofSubStrategyList}
                                    fieldName="tacof_sub_strategy"
                                    dataField={dealAttribute?.tacof_sub_strategy}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="S&P Corporate Family Rating"
                                    dataList={snPRatingList}
                                    fieldName="snp_corporate_family_rating_id"
                                    dataField={dealAttribute?.snp_corporate_family_rating_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Moody's Corporate Family Rating"
                                    dataList={moodysRatingList}
                                    fieldName="moodys_corporate_family_rating_id"
                                    dataField={dealAttribute?.moodys_corporate_family_rating_id}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />
                            </div>

                            <div className="column right">

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Public vs Private"
                                    fieldName="portfolio"
                                    dataField={dealAttribute?.portfolio}
                                    handleChange={this.handleChange}
                                    type="checkbox-pp"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Market Risk Sensitivity"
                                    dataList={marketRiskList}
                                    fieldName="market_risk_sensitivity"
                                    dataField={dealAttribute?.market_risk_sensitivity}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Equity Upside?"
                                    fieldName="equity_upside"
                                    dataField={dealAttribute?.equity_upside}
                                    handleChangeDate={this.handleChangeNumber}
                                    type="checkbox"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Form of Equity Upside"
                                    fieldName="equity_upside_form"
                                    dataField={dealAttribute?.equity_upside_form}
                                    handleChange={this.handleChange}
                                    type="textbox"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Did Tor participate in a New Issue?"
                                    fieldName="is_new_issue"
                                    dataField={dealAttribute?.is_new_issue}
                                    handleChangeDate={this.handleChangeNumber}
                                    type="checkbox"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst Weighting Date"
                                    fieldName="analystDate"
                                    dataField={analystDate}
                                    handleChangeDate={this.handleChangeNumber}
                                    type="datepick"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst Name"
                                    fieldName="analyst1Name"
                                    dataField={analyst1Name}
                                    dataList={analystList}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst %"
                                    fieldName="analyst1Weight"
                                    dataField={analyst1Weight ?? ''}
                                    handleChangeDate={this.handleChangeNumber}
                                    textType="number"
                                    type="textbox"
                                    suffix = '%'
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst Name (2)"
                                    fieldName="analyst2Name"
                                    dataField={analyst2Name}
                                    dataList={analystList}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst % (2)"
                                    fieldName="analyst2Weight"
                                    dataField={analyst2Weight ?? ''}
                                    handleChangeDate={this.handleChangeNumber}
                                    textType="number"
                                    type="textbox"
                                    suffix = '%'
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst Name (3)"
                                    fieldName="analyst3Name"
                                    dataField={analyst3Name}
                                    dataList={analystList}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst % (3)"
                                    fieldName="analyst3Weight"
                                    dataField={analyst3Weight ?? ''}
                                    handleChangeDate={this.handleChangeNumber}
                                    textType="number"
                                    type="textbox"
                                    suffix = '%'
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst Name (4)"
                                    fieldName="analyst4Name"
                                    dataField={analyst4Name}
                                    dataList={analystList}
                                    handleDropDownChange={this.handleDropDownChange}
                                    type="dropdown"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Analyst % (4)"
                                    fieldName="analyst4Weight"
                                    dataField={analyst4Weight ?? ''}
                                    handleChangeDate={this.handleChangeNumber}
                                    textType="number"
                                    type="textbox"
                                    suffix = '%'
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Upfront Fees (%)"
                                    fieldName="upfront_fee"
                                    dataField={dealAttribute.upfront_fee}
                                    handleChangeDate={this.handleChangeNumber}
                                    textType="number"
                                    type="textbox"
                                    suffix = '%'
                                />


                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Face Value (USD)"
                                    fieldName="faceValue"
                                    dataField={faceValue === 0 ? '' : faceValue}
                                    type="textbox-blue"
                                />

                                <FormDisplay
                                    fieldList={this.props.fieldList}
                                    formType="formDisp"
                                    heading="Market Value (USD)"
                                    fieldName="marketValue"
                                    dataField={marketValue === 0 ? '' : marketValue}
                                    type="textbox-blue"
                                />
                            </div>
                        </div>

                        <FormDisplay
                            fieldList={this.props.fieldList}
                            formType="commentDisp"
                            heading="Please provide a short Company Description"
                            fieldName="company_description"
                            dataField={dealAttribute?.company_description}
                            handleChange={this.handleChange}
                            type="notes"
                        />
                    </div>
                </div>
                <div style = {{height: 'auto'}}>
                    <div style={{ paddingBottom: 15, height: '10px'}}>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(2)}>
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                        </div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.saveDB()}>
                            <FontAwesomeIcon icon={faSave} />
                        </div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px" }} onClick={() => this.props.approveDB()}>
                            <FontAwesomeIcon style = {{cursor: "pointer"}} icon={faCheck} tooltip = "true" title = "Submit for Approval" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Deal_Attribute_1;