/**<import>****************************************************************************************************/
import moment from 'moment'; 
import "react-datepicker/dist/react-datepicker.css"; 
import React, { Component } from 'react';
import { Redirect } from "react-router-dom"; 
import { torGridBALService } from '../../bal/torGridBALService.bal'; 
import { Prompt } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class HousingSummary extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === "markingtool" });

        this.state = { 
            rowData: [],
            isShowLoad: false, 
            isShowGrid: false, 
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            open:false,
            fundList : [],
            fund : null,
            forDate : moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        //getFormatted = getFormatted(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this); 
        this.refresh();

    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    refresh(e, modalVisible = false) {
        this.setState({ 
            isShowLoad : true, 
            isShowGrid : false,
            showMessage: false, 
            errorList: [], 
            messageHeader : null,
            modalVisible: false,
         });

        torGridBALService.getRowData('housing', { query: "select * From vw_housing" }).then(data => {
            this.setState({
                gridData: data,
                isShowLoad:false,
                isShowGrid: data?.length > 0,
                ...(data?.length <= 0) && {
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'No bonds exists for given date/fund.'
                },
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null
                }
            });
        }, error => {
            this.setState({ 
                isShowGrid: false,
                isShowLoad : false,
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the grid data :",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); 
        });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({ modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false});
        } else {
            this.setState({ modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ [name]: value });
    }
    
    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    getPage() {
        const { newDeal, existingDeal, modalHeading, isShowLoad, isShowGrid, gridData, showMessage, messageHeader, errorList } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    showMessage ?
                        <ToastMessage
                            header={messageHeader}
                            errorList={errorList}
                            closeMessage={this.closeMessage}
                        />:<div></div>
                }  
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                    <div>
                        <div style={{ float: "Left", padding: "5px 15px 0px 15px" }} ><h4><u>Housing Summary</u></h4></div>
                        <div style={{ float: "right", padding: "15px 15px", cursor: "pointer" }} onClick={this.refresh}>                            
                            <button key='removeButton' className="blueB-no-margin">Refresh</button>
                        </div>
                    </div>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            setAgGridAPI = {this.setAgGridAPI}
                            isHideDateInputs={true}
                            domLayout="normal"

                            isHideSaveGridLayout={true}

                            rowData={gridData} 

                            className="ag-theme-alpine"
                            rowSelection="multiple"
                            gridIdentityColumn="id"

                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}

                            guikey = "housingSummary"
                            sortColumn = {true} 

                            isShowLoad = {isShowLoad}
                            isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                        />
                    </div>
                </div> 
                <Prompt
                    when={this.state.isShowLoad === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                    message={location => `Do you want to discard changes?`}
                />
                <div>
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                                :
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default HousingSummary;