import { userBALService } from './user.bal';
import { Utils } from '../helper/utils';
const {config} = require('../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Id', 'col': 'user_log_id', 'ag-grid': true, width : 120},
            {'display': 'Date', 'col': 'date_time', 'agg': 'uniq', 'format': 'dateFormat', 'ag-grid': true, width : 250},
            {'display': 'User Id', 'col': 'user_id', 'ag-grid': true, width : 120},
            {'display': 'Path', 'col': 'path', 'ag-grid': true, width : 250},
            {'display': 'IP Address', 'col': 'ip_address', 'ag-grid': true, width : 180},
            {'display': 'Full name', 'col': 'full_name', 'ag-grid': true, width : 250},
        ]};
};

export const userLogBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'UserLog'
        ,field: 'description'
        ,width: 120
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}