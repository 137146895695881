/**<import>****************************************************************************************************/
import React, { Component } from 'react';
import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import _ from 'underscore';
import moment from 'moment';


class ChartDisplay extends Component {
  constructor(props) {
    super(props);
    this.getPieData = this.getPieData.bind(this);
    this.getStackedBarData = this.getStackedBarData.bind(this);
    this.groupBy = this.groupBy.bind(this);
    this.groupByInner = this.groupByInner.bind(this);
    this.getGroupedBarData2 = this.getGroupedBarData2.bind(this);
  };

  groupByInner = (array, key, value) => {
    return array.reduce((result, currentValue) => {
      result[currentValue.P[0][key]] = (result[currentValue.P[0][key]] || 0) + currentValue[value]
      return result;
    }, {});
  };

  groupBy = (array, key, value) => {
    if(array) {
      return array.reduce((result, currentValue) => {
        result[currentValue[key]] = (result[currentValue[key]] || 0) + currentValue[value]
        return result;
      }, {});
    }
    else {
      return null;
    }
  };

  getPieData = (data, GroupBy, sumCol) => {
    var grouped = this.groupBy(data, GroupBy, sumCol);
    var chartList = [];
    var labels = [];
    var sum = 0;

    for (var key in grouped) {
      if (grouped[key] !== 0) {
        var chart = {
          [`${GroupBy}`]: key,
          [`${sumCol}`]: grouped[key]
        }

        sum = sum + grouped[key];

        if (labels.includes(key) === false) {
          labels.push(key);
        }

        chartList.push(chart);
      }
    }

    labels.sort();

    return [chartList, labels, sum];
  }

  getStackedBarData = (data, GroupBy, xKey, sumCol) => {
    var grouped = this.groupBy(data, xKey, sumCol);

    var chartMaturityList = [];
    var maturityLabel = [];
    var sumList = [];

    for (let key in grouped) {
      if (grouped[key] !== 0) {
        var arr = data.filter(function (item) {
          return item[xKey] === key || item[xKey] === parseInt(key)
        });

        var sub_grouped = this.groupBy(arr, GroupBy, sumCol);

        var chart = {
          [`${xKey}`]: key
        }

        sumList.push({ [`${xKey}`]: key, sumVal: grouped[key]});

        for (var key2 in sub_grouped) {
          if (sub_grouped[key2] !== 0) {
            chart[`${key2} ${sumCol}`] = sub_grouped[key2];

            if (maturityLabel.includes(`${key2} ${sumCol}`) === false) {
              maturityLabel.push(`${key2} ${sumCol}`)
            }
          } else if(sub_grouped[key2] === 0 && key2 !== "Uncategorized") {
            chart[`${key2} ${sumCol}`] = 0;

            if (maturityLabel.includes(`${key2} ${sumCol}`) === false) {
              maturityLabel.push(`${key2} ${sumCol}`)
            }
          }
        }

        chartMaturityList.push(chart);
      }
    }

    maturityLabel.sort();

    return [chartMaturityList, maturityLabel, sumList];
  }

  getGroupedBarDataAlt = (data, xKey, sumCol, funds) => {
    var reportDate = _.keys(_.countBy(data, function(data) { return data[xKey]; }));
    var chartMaturityList = [];

    for(let i = 0; i < reportDate.length; i++){
      var chart = {
        [`${xKey}`]: reportDate[i]
      }

      var filteredData = data.filter(row => row[xKey] === reportDate[i]);

      for(let j = 0; j < funds.length; j++){
        var filteredDataFund = filteredData.filter(row => row['fund'] === funds[j]);

        const num = filteredDataFund.reduce( (numerator, row) => numerator + row[sumCol[0]], 0);
        const den = filteredDataFund.reduce( (numerator, row) => numerator + row[sumCol[1]], 0);

        chart[`${funds[j]}`] = ((den ?? 0) > 0 ? num / den : 0);
      }

      chartMaturityList.push(chart);
    }

    return [chartMaturityList, funds];
  }

  getGroupedBarData2 = (data, xKey, sumCol, funds) => {
    var reportDate = _.keys(_.countBy(data, function(data) { return data[xKey]; }));
    var chartMaturityList = [];

    for(let i = 0; i < reportDate.length; i++){
      var chart = {
        [`${xKey}`]: reportDate[i]
      }

      var filteredData = data.filter(row => row[xKey] === reportDate[i]);

      for(let j = 0; j < funds.length; j++){
        var filteredDataFund = filteredData.filter(row => row['fund'] === funds[j]);

        const num = filteredDataFund.reduce( (numerator, row) => numerator + row[sumCol], 0);

        chart[`${funds[j]}`] = num;
      }

      chartMaturityList.push(chart);
    }

    return [chartMaturityList, funds];
  }

  getGroupedBarData = (data, xKey, sumCol) => {
    var reportDate = _.keys(_.countBy(data, function(data) { return data[xKey]; }));
    var chartMaturityList = [];

    for(let i = 0; i < reportDate.length; i++){
      var chart = {
        [`${xKey}`]: reportDate[i]
      }
      for(let j = 0; j < sumCol.length; j++){
        var filteredData = data.filter(row => row[xKey] === reportDate[i]);
        const value = filteredData.reduce( (numerator, row) => numerator + row[sumCol[j]], 0);
        chart[`${sumCol[j]}`] = value;
      }

      chartMaturityList.push(chart);
    }

    return [chartMaturityList, sumCol];
  }



  render() {
    var dataList, angle, options, lData, labels;
    if (this.props.chartType === 'pie') {
      lData = this.getPieData(this.props.dataList, this.props.labelKey, this.props.angleKey);
      dataList = lData[0];
      dataList.sort((a, b) => (a[this.props.angleKey] < b[this.props.angleKey]) ? 1 : -1)

      labels = lData[1];
      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'pie',
            angleKey: this.props.angleKey,
            labelKey: this.props.labelKey,
            tooltip: {
              renderer: function (params) {
                return {
                  content: params.datum[params.labelKey] + ":" + 
                    params.angleValue.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + 
                    " (" + (params.angleValue / lData[2] * 100).toFixed(2) + "%) " ,
                };
              },
            },
          }
        ],
        legend: { enabled: false, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'bar-stacked') {
      lData = this.getStackedBarData(this.props.dataList, this.props.GroupBy, this.props.xKey, this.props.yKeys);
      dataList = lData[0];
      dataList.sort((a, b) => (a[this.props.xKey] > b[this.props.xKey]) ? 1 : -1)

      labels = lData[1];
      angle = 360;
      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      } 

      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'column',
            xKey: this.props.xKey,
            yKeys: labels,
            yNames: labels,
            tooltip: {
              renderer: function (params) {
                return {
                  content: params.xValue + ' : ' + params.yValue.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                };
              },
            },
          }
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              rotation: angle
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'bar-grouped') {
      lData = this.getGroupedBarData(this.props.dataList, this.props.xKey, this.props.yKeys);
      dataList = lData[0];
      dataList.sort((a, b) => (a[this.props.xKey] > b[this.props.xKey]) ? 1 : -1)
      labels = lData[1];
      angle = 360;
      if (this.props.rotationAngle){
        angle = this.props.rotationAngle;
      }

      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'column',
            xKey: this.props.xKey,
            yKeys: labels,
            yNames: labels,
            tooltip: {
              renderer: function (params) {
                return {
                  content: params.xValue + ' : ' + params.yValue.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                };
              },
            },
            grouped: true
          }
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              rotation: angle
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: true, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'line-grouped') {
      lData = this.getGroupedBarData(this.props.dataList, this.props.xKey, this.props.yKeys);
      dataList = lData[0];
      labels = lData[1];
      angle = 360;

      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (var i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            xKey: this.props.xKey,
            yKey: labels,
            yName: labels,
            tooltip: {
              renderer: function (params) {
                return {
                  content: moment(params.xValue).format("YYYY-MM-DD") + ' : ' + params.yValue.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                };
              },
            },
            grouped: true
          }
        ],
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: {count: agCharts.time.month},
            label: {
              format: '%b %Y',
              rotation: angle
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: true, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'line-grouped-alt') {
      const fundList = this.props.fund === 'All'? ['TOR', 'TIFO', 'TACO', 'TACO II', 'TACO III'] : [this.props.fund]
      lData = this.getGroupedBarDataAlt(this.props.dataList, this.props.xKey, this.props.sumKeys, fundList);
      dataList = lData[0];
      labels = lData[1];
      angle = 360;

      var seriesList = [];

      for(let i = 0; i < fundList.length; i++) {
        seriesList.push({
          xKey: this.props.xKey,
          yKey: fundList[i],
          yName: fundList[i],
          tooltip: {
            renderer: function (params) {
              return {
                content: moment(params.xValue).format("YYYY-MM-DD") + ' : ' + params.yValue.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
              };
            },
          },
          grouped: true
        })
      }

      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (let i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      options = {
        theme: lineTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: seriesList,
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: {count: 10},
            label: {
              format: '%b %Y',
              rotation: angle
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
          },
        ],
        legend: { enabled: true, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'line-grouped-alt2') {
      const fundList = ['TOR', 'TIFO', 'TACO', 'TACO II', 'TACO III']
      lData = this.getGroupedBarData2(this.props.dataList, this.props.xKey, this.props.sumKeys, fundList);
      dataList = lData[0];
      labels = lData[1];
      angle = 360;

      seriesList = [];

      for(let i = 0; i < fundList.length; i++) {
        seriesList.push({
          xKey: this.props.xKey,
          yKey: fundList[i],
          yName: fundList[i],
          marker: {
            enabled: false
          },
          tooltip: {
            renderer: function (params) {
              return {
                content: moment(params.xValue).format("YYYY-MM-DD") + ' : ' + params.yValue.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
              };
            },
          },
          grouped: true
        })
      }

      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (let i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      options = {
        theme: lineTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: seriesList,
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: {count: 5},
            label: {
              format: '%d %b %Y',
              rotation: angle
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: true, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'area') {
      lData = this.getStackedBarData(this.props.dataList, this.props.GroupBy, this.props.xKey, this.props.yKeys);
      dataList = lData[0];

      angle = 360;
      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (let i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      labels = lData[1];
      options = {
        theme: areaTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'area',
            xKey: this.props.xKey,
            yKeys: labels,
            yNames: labels
          }
        ],
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: { count: agCharts.time.month },
            label: { format: '%b %Y', rotation: angle }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value + '%';
              },
            },
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'area-mln') {
      lData = this.getStackedBarData(this.props.dataList, this.props.GroupBy, this.props.xKey, this.props.yKeys);
      dataList = lData[0];

      angle = 360;
      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (let i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      labels = lData[1];
      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'area',
            xKey: this.props.xKey,
            yKeys: labels,
            yNames: labels,
            fillOpacity: 1,
            tooltip: {
              renderer: function (params) {
                return {
                  content: moment(params.xValue).format('YYYY-MM-DD') + ' : ' + params.yValue.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                };
              },
            },
          }
        ],
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: { count: 10 },
            label: { format: '%b %Y', rotation: angle }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === 'area-normalized') {
      lData = this.getStackedBarData(this.props.dataList, this.props.GroupBy, this.props.xKey, this.props.yKeys);
      dataList = lData[0];

      angle = 360;
      if (this.props.rotationAngle) {
        angle = this.props.rotationAngle;
      }

      for (let i = 0; i < dataList.length; i++) {
        dataList[i][this.props.xKey] = new Date(dataList[i][this.props.xKey]);
      }

      const {xKey} = this.props;

      labels = lData[1];
      options = {
        theme: myTheme,
        data: dataList,
        title: {
          text: this.props.chartTitle
        },
        series: [
          {
            type: 'area',
            xKey: xKey,
            yKeys: labels,
            yNames: labels,
            normalizedTo: 100,
            fillOpacity: 1,
            tooltip: {
              renderer: function (params) {
                let sumVal = lData[2].filter(function (item) {
                    return item[xKey] === moment(params.xValue).format('YYYY-MM-DD')
                })[0].sumVal;
                return {
                  content: moment(params.xValue).format('YYYY-MM-DD') + ' : ' + (params.yValue / sumVal * 100).toFixed(2) + "%",
                };
              },
            },
          }
        ],
        axes: [
          {
            type: 'time',
            nice: false,
            position: 'bottom',
            tick: { count: 10 },
            label: { format: '%b %Y', rotation: angle }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value + '%';
              },
            },
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      }

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === "cartesian") {
      options = {
        type: 'cartesian',
        theme: areaTheme,
        autoSize: true,
        title: {
          enabled: true,
          text: 'Historical Cashflows',
        },
        data: this.props.data,
        series: [
          {
            type: 'column',
            xKey: this.props.xKey,
            yKeys: this.props.yKeys,
            yNames: this.props.yNames
          },
          {
            type: 'line',
            xKey: this.props.xKey,
            yKey: this.props.yKeyLine,
            yName: 'Cumulative Cashflow',
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            tick: { count: 5 },
            label: {
              rotation: 335
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 },
            label: {
              formatter: function (params) {
                return params.value / 1e6 + 'M';
              },
            },
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      };

      return <AgChartsReact options={options} />;
    } else if (this.props.chartType === "cartesian-pnl") {
      options = {
        type: 'cartesian',
        theme: areaTheme,
        autoSize: true,
        title: {
          enabled: true,
          text: 'Historical CTR',
        },
        data: this.props.data,
        series: [
          {
            type: 'column',
            xKey: this.props.xKey,
            yKeys: this.props.yKeys,
            yNames: this.props.yNames
          },
          {
            type: 'line',
            xKey: this.props.xKey,
            yKey: this.props.yKeyLine,
            yName: 'Cumulative CTR',
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            tick: { count: 5 },
            label: {
              rotation: 335
            }
          },
          {
            type: 'number',
            position: 'left',
            tick: { count: 5 }
          },
        ],
        legend: { enabled: false, position: 'bottom' }
      };

      return <AgChartsReact options={options} />;
    } else {
      return null;
    }
  }
}

var myTheme = {
  baseTheme: 'ag-vivid',
  palette: {
    fills: [
      '#003396',
      '#5A89E6',
      '#77a2f7',
      '#91B7FF',
      '#73B9EE',
      '#A8C6FF',
      '#A5B7D9',
      '#B7E9F7',
      '#DBF3FA',
      '#AAB6D6',
      '#8FA3D9',
      '#7489CE',
      '#6275CC',
      '#5266C0',
      '#1647A6',
      '#285CBF',
      '#3D70D4',
      '#4750B8'
    ],
    strokes: ['#09253D']
  },
  defaults: {
    cartesian: {
      title: { fontSize: 24 },
      series: {
        column: {
          label: {
            enabled: true,
            color: 'black',
          },
        },
      },
    },
  },
};

var areaTheme = {
  baseTheme: 'ag-vivid',
  palette: {
    fills: [

      '#011f4b',
      '#5A89E6', 
      '#005b96', 
      '#6497b1', 
      '#b3cde0'

      // '#003396',
      // '#1647A6',
      // '#285CBF',
      // '#3D70D4',
      // '#5A89E6',
      // '#77a2f7',
    ],
    strokes: ['#09253D']
  },
  defaults: {
    cartesian: {
      title: { fontSize: 24 },
      series: {
        column: {
          label: {
            enabled: true,
            color: 'black',
          },
        },
      },
    },
  },
};

var lineTheme = {
  baseTheme: 'ag-vivid',
  palette: {
    fills: [
      '#007E86',
      '#00AC88',
      '#907245', 
      '#DFA01E',
      '#de6e56'
    ],
    strokes: ['#09253D']
  },
  defaults: {
    cartesian: {
      title: { fontSize: 24 },
      series: {
        column: {
          label: {
            enabled: true,
            color: 'black',
          },
        },
      },
    },
  },
};

export default ChartDisplay;