import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import { covenantMasterBALService } from './covenantMaster.bal';
import * as moment from 'moment';
const {config} = require('../config');
class CustomSelectCellRenderer {
    eGui;
  
    init(params) {
      this.eGui = document.createElement('div');
      const value = params?.value?.name;
      this.eGui.innerHTML = `<span style="overflow: hidden; text-overflow: ellipsis">${value ?? ""}</span>`;
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params) {
      return false;
    }
}


const dateFormatter = (params) => {

  return moment(params.value).utcOffset(0, false).format(params.dateFormat ?? 'YYYY-MM-DD');

  //return moment(params.value)?.format(params.dateFormat ?? 'YYYY-MM-DD');
}

const getColumnList = async (params) => { 
    var covenantStatusValueList= [];
    const result1 = covenantMasterBALService.getAllCovenantStatus().then(data=> { 

        covenantStatusValueList = data.filter(function (item) {
            return (item ? true : false);
        }).map(item => { return { name:item.name, code : item.id } }); 
        
    }
    );

    await Promise.all([result1]);

    return  {
        'columnSpecs': [
          {displayIndex: 1, 'display': 'Deal', 'col': 'deal','ag-grid': true,  minWidth: 200 , cellRenderer: 'agGroupCellRenderer', filter: 'agSetColumnFilter' },
          {displayIndex: 2, 'display': 'Loan', 'col': 'loan', 'ag-grid': true, minWidth: 300, autoHeight:true, sortable: true, filter: 'agSetColumnFilter' },
            {displayIndex: 2, 'display': 'Type', 'col': 'type', 'ag-grid': true, minWidth: 180, autoHeight:true, filter: 'agSetColumnFilter' },
            {displayIndex: 3, 'display': 'Category', 'col': 'category', 'ag-grid': true, minWidth: 180, autoHeight:true, filter: 'agSetColumnFilter' },
            {displayIndex: 4, 'display': 'Frequency', 'col': 'frequency', 'ag-grid': true, minWidth: 100, autoHeight:true, filter: 'agSetColumnFilter' },
            {displayIndex: 5, 'display': 'Operator', 'col': 'operator', 'ag-grid': true, minWidth: 100, maxWidth: 100, autoHeight:true, filter: 'agSetColumnFilter' },
            {displayIndex: 6, 'display': 'Target', 'col': 'targetValue', 'ag-grid': true, minWidth: 100, maxWidth: 100, autoHeight:true, filter: 'agSetColumnFilter' },
            {displayIndex: 7, 'display': 'For Date', 'col': 'forDate', 'ag-grid': true, 'agg': 'uniq', minWidth: 110, autoHeight:true,
              cellRenderer: dateFormatter,
              filter: 'agSetColumnFilter',
              filterParams: {
                  comparator: (a, b) => {
                      const valA = a;
                      const valB = b;
                      if (valA === valB) return 0;
                      return valA > valB ? 1 : -1;
                  }
              }
            },
            {displayIndex: 8, 'display': 'Due Date', 'col': 'dueDate', 'ag-grid': true, 'agg': 'uniq', minWidth: 110, autoHeight:true,
              cellRenderer: dateFormatter,
              filter: 'agSetColumnFilter',
              filterParams: {
                  comparator: (a, b) => {
                      const valA = a;
                      const valB = b;
                      if (valA === valB) return 0;
                      return valA > valB ? 1 : -1;
                  }
              }
            },
            {displayIndex: 9, 'display': 'Value', 'col': 'valueNew1', 'ag-grid': true, minWidth: 100, maxWidth: 150, autoHeight:true, editable: true , filter: 'agSetColumnFilter'},
            {displayIndex: 10, 'display': 'Status', 'col': 'statusObject', 'ag-grid': true, minWidth: 100, maxWidth: 100, editable: true, filter: 'agSetColumnFilter', 
              cellEditor:'agRichSelectCellEditor', autoHeight:true,
              cellRenderer: CustomSelectCellRenderer,
              cellEditorParams: {  cellRenderer: CustomSelectCellRenderer, values: covenantStatusValueList },
              keyCreator: (params) => { return params.value.name; },
            }, 
            {displayIndex: 11, 'display': 'Attachments', 'col': 'attachments', 'ag-grid': true, minWidth: 500, autoHeight:true, editable: true,
                suppressKeyboardEvent: (params) => {
                return false;
                },
                cellEditor: 'fileAttachmentEditor', cellEditorPopup: true, cellEditorParams: {
                    maxLength: '5300', // override the editor defaults
                    cols: '2000',
                    rows: '100',
                },
                cellRenderer: function(params) {
                  return "";
                  //return params?.value ? params.value?.files.length : "" ;
                }
            },
            {displayIndex: 12, 'display': 'Comment', 'col': 'commentNew', 'ag-grid': true, minWidth: 1500, autoHeight:true, editable: true, filter: 'agSetColumnFilter',
                suppressKeyboardEvent: (params) => {
                return suppressEnter(params) || suppressNavigation(params);
                },
                cellEditor: 'richTextEditor', cellEditorPopup: true, cellEditorParams: {
                    maxLength: '5300', // override the editor defaults
                    cols: '2000',
                    rows: '100',
                },
                cellRenderer: function(params) {
                  return params.value ? params.value : '';
                }
            },
            {displayIndex: 13, 'display': 'Id', 'col': 'id', 'ag-grid': true, hide: true,  minWidth: 200 ,  filter: 'agSetColumnFilter' },

          ]};
}

const getDetailCellRendererParams = (params) => {
  return  {
     detailGridOptions: {
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableRangeSelection: true,
      domLayout: "normal",
      //pagination: true,
      //paginationAutoPageSize: true,
      columnDefs: [
          { field: 'addedBy', minWidth: 150, maxWidth:300, wrapText: true, autoHeight: true },
          { field: 'on', minWidth: 150, maxWidth:180, wrapText: true, autoHeight: true, cellRenderer: dateFormatter, cellRendererParams: { dateFormat: 'YYYY-MM-DD HH:mm:ss' } },
          { field: 'comment', minWidth: 800, autoHeight: true,
            cellRenderer: function(params) {
              return params.value ? params.value : '';
            }        
          },
          { field: '', minWidth: 50, autoHeight: true },
      ],
      //rowHeight: "auto",
      getRowHeight: params => {
        return params.data.rowHeight ?? "auto";
      },
       defaultColDef: {
        sortable: true,
        flex: 1,
      },         
  },
  
  // get the rows for each Detail Grid
  getDetailRowData: params => {
      params.successCallback(params.data.comments);
  }
  } 
}

export const covenantInstanceBALService = {
  getColumnList,
  getRowData,
  getAutoGroupColumnDef,
  save,
  getAll,
  saveComment,
  getDetailCellRendererParams,
  getAllComments,
  getRowDataBy
};
function suppressEnter(params) {
  var KEY_ENTER = 'Enter';

  var event = params.event;
  var key = event.key;
  var suppress = key === KEY_ENTER;

  return suppress;
}

function suppressNavigation(params) {
  var KEY_A = 'A';
  var KEY_C = 'C';
  var KEY_V = 'V';
  var KEY_D = 'D';

  var KEY_PAGE_UP = 'PageUp';
  var KEY_PAGE_DOWN = 'PageDown';
  var KEY_TAB = 'Tab';
  var KEY_LEFT = 'ArrowLeft';
  var KEY_UP = 'ArrowUp';
  var KEY_RIGHT = 'ArrowRight';
  var KEY_DOWN = 'ArrowDown';
  var KEY_F2 = 'F2';
  var KEY_BACKSPACE = 'Backspace';
  var KEY_ESCAPE = 'Escape';
  var KEY_SPACE = ' ';
  var KEY_DELETE = 'Delete';
  var KEY_PAGE_HOME = 'Home';
  var KEY_PAGE_END = 'End';

  var event = params.event;
  var key = event.key;

  var keysToSuppress = [
    KEY_PAGE_UP,
    KEY_PAGE_DOWN,
    KEY_TAB,
    KEY_F2,
    KEY_ESCAPE,
  ];

  var editingKeys = [
    KEY_LEFT,
    KEY_RIGHT,
    KEY_UP,
    KEY_DOWN,
    KEY_BACKSPACE,
    KEY_DELETE,
    KEY_SPACE,
    KEY_PAGE_HOME,
    KEY_PAGE_END,
  ];

  if (event.ctrlKey || event.metaKey) {
    keysToSuppress.push(KEY_A);
    keysToSuppress.push(KEY_V);
    keysToSuppress.push(KEY_C);
    keysToSuppress.push(KEY_D);
  }

  if (!params.editing) {
    keysToSuppress = keysToSuppress.concat(editingKeys);
  }

  if (
    params.column.getId() === 'country' &&
    (key === KEY_UP || key === KEY_DOWN)
  ) {
    return false;
  }

  var suppress = keysToSuppress.some(function (suppressedKey) {
    return suppressedKey === key || key.toUpperCase() === suppressedKey;
  });

  return suppress;
}

function getAutoGroupColumnDef(params) {
    return {  
        pinned: 'left',
        resizable: true
      };
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllPendingCovenantInstanceView`, requestOptions).then(Utils.handleResponse);
}

function getRowDataBy(selectedOption) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };

    return fetch(`${config.apiDDSUrl}/get${selectedOption}CovenantInstanceView`, requestOptions).then(Utils.handleResponse);
}

function getAll(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantInstanceView`, requestOptions).then(Utils.handleResponse);
} 

function getAllComments(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantInstanceCommentView`, requestOptions).then(Utils.handleResponse);
} 

function save(covenantInstanceRows) {
  covenantInstanceRows = covenantInstanceRows.map(covenantInstanceRow => {
      return Utils.removeBlanksNotZero(covenantInstanceRow);
  });
  return fetch(`${config.apiDDSUrl}/updateCovenantInstanceRows/`, { body: JSON.stringify(covenantInstanceRows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}

function saveComment(covenantInstanceCommentRows) {
  covenantInstanceCommentRows = covenantInstanceCommentRows.map(covenantInstanceCommentRow => {
      return Utils.removeBlanksNotZero(covenantInstanceCommentRow);
  });
  return fetch(`${config.apiDDSUrl}/addCovenantInstanceCommentRows/`, { body: JSON.stringify(covenantInstanceCommentRows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
