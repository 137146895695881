import React, { Component } from 'react';
import moment from 'moment';
import ChartDisplay from "../portfolioDashboard/components/chartDisplay.js"

import { dealDashboardBALService } from '../../bal/Deal-Dashboard/dealDashboard.bal';
import TorGrid from './../torgrid/torgrid';


class HistoricalCashflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            gridData: [],
            irrData: [],
            irrFilterData: [],
            filterData: []
        }

        this.isZero = this.isZero.bind(this);
        this.getDate = this.getDate.bind(this);
        this.agValueFormatter = this.agValueFormatter.bind(this);
        this.getValueFormatted = this.getValueFormatted.bind(this);
        this.getData = this.getData.bind(this);
        this.aggregateData = this.aggregateData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    isZero(value) {
        return value < 0.0001 && value > -0.0001
    }

    getDate(params) {
        var val = Date(params.value);
        return val;
    }

    agValueFormatter(value, format) {
        var agformat = format;
        if (value && value !== '' && value !== 0) {
            switch (agformat) {
                case 'millionsFormat':
                    return this.getValueFormatted(value / 1e6, 1);
                case 'millionsFormat2':
                    return this.getValueFormatted(value / 1e6, 2);
                case 'percentFormat':
                    return this.getValueFormatted(value * 100);
                case 'percentFormat1':
                    return this.getValueFormatted(value * 100, 1);
                case 'decimalFormat0':
                    return this.getValueFormatted(value, 0);
                case 'decimalFormat2':
                    return this.getValueFormatted(value, 2);
                case '{0:.2f}x':
                    return this.getValueFormatted(value);
                case 'leverageFormat':
                    const displayvalue = this.getValueFormatted(value, 2);
                    return `${displayvalue}${displayvalue && displayvalue.toString() !== '' ? 'x' : ''}`;
                case 'dateFormat':
                    return this.getDate(value);
                default:
                    return value;
            }
        }
        else if (value && value !== '' && agformat === 'decimalFormatShowZero2')
            return this.getValueFormatted(value, 2);
        return '';
    }

    getValueFormatted(param, digit = 2) {
        var result = param;
        var val = 0.0;

        if (!isNaN(result)) {
            try {
                val = parseFloat(result).toFixed(digit);
                if (this.isZero(val)) return '';

                if (!isNaN(val))
                    result = val.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            } catch (error) {
                val = result;
            }
        }
        return result;
    }

    filterData(colName, value) {
        var data = this.state.gridData;
        var data2 = this.state.irrData;

        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            var arr2 = data2.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                filterData: arr,
                irrFilterData: arr2
            });
        } else {
            this.setState({
                filterData: this.state.gridData,
                irrFilterData: this.state.irrData
            });
        }

        this.aggregateData();
    }

    aggregateData() {
        var { filterData } = this.state;

        var cumSum = 0.0;
        var sum = 0.0;

        var jsObj = {
            date: null,
            sum: null,
            cumSum: null
        };

        var aggList = [];

        for (var i = 0; i < filterData.length; i++) {
            var date = new Date(filterData[i].tdate);
            date = new Date(date.getFullYear(), date.getMonth(), 1);
            date = moment(date).format("YYYY/MM/DD");

            if (i === 0) {
                jsObj.date = date;
                sum += filterData[i]['netBook'];
                cumSum += filterData[i]['netBook'];
            } else if (date !== jsObj.date) {
                jsObj.sum = sum;
                jsObj.cumSum = cumSum;
                aggList.push(jsObj);

                jsObj = {
                    date: null,
                    sum: null,
                    cumSum: null
                };
                jsObj.date = date;

                sum = filterData[i]['netBook'];
                cumSum += filterData[i]['netBook'];

            } else {
                sum += filterData[i]['netBook'];
                cumSum += filterData[i]['netBook'];
            }
        }

        if (jsObj.date !== null) {
            jsObj.sum = sum;
            jsObj.cumSum = cumSum;
            aggList.push(jsObj);
        }

        return aggList;
    }

    getData() {
        const { Deal, AsOfDate } = this.props;

        dealDashboardBALService.getCashflowData({ Deal: Deal, Quarter: AsOfDate }).then(data => {
            this.setState({ gridData: data.cashflows });
            this.setState({ irrData: data.irr });
            this.setState({ isLoading: false });
            this.filterData('fund', this.props.fund);
        }, error => {
            this.setState({ isLoading: false });
            console.log(error);
        });
    }

    componentDidMount() {
        if (this.props.Deal && this.props.AsOfDate) {
            this.setState({ isLoading: true, gridData: [], filterData: [] });
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Deal !== this.props.Deal || prevProps.AsOfDate !== this.props.AsOfDate) {
            if (this.props.Deal && this.props.AsOfDate) {
                this.setState({ isLoading: true, gridData: [], filterData: [] })
                this.getData();
            }
        }

        if (prevProps.fund !== this.props.fund) {
            this.filterData('fund', this.props.fund);
        }
    }


    getTemplate() {
        const {AsOfDate} = this.props;
        let quarterDate = '';
        if(AsOfDate) {
            quarterDate = new Date(AsOfDate.substring(2), (parseInt(AsOfDate.substring(1, 2)) * 3).toString(), '01');
            quarterDate = new Date(quarterDate - 1);

            quarterDate = moment(quarterDate).format("YYYY-MM-DD");
        }

        if (this.state.isLoading === true) {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        } else {
            if (this.props.Deal && this.state.filterData.length > 0) {
                return (
                    <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: '15px' }}>
                        <div style={{marginTop: '20px', marginLeft: '20px'}}>
                            <h4>{`Realized Performance though ${quarterDate}`}</h4>
                        </div>
                        <div style={{ margin: 'auto' }}>
                            <TorGrid
                                guikey="historicalCashflowTop"
                                isHideDateInputs={true}
                                isHideSaveGridLayout={true}

                                className="topDisplayGrid"
                                rowGroupPanelShow="none"
                                enableFilter={false}
                                domLayout="autoHeight"
                                rowData={this.state.irrFilterData}

                                defaultColDef={this.props.defaultColDef ??
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                                }
                                sideBar='none'
                            />
                        </div>
                        <div style={{ marginTop: '25px', height: 'auto' }}>
                            <div style={{ gridTemplateColumns: '1fr 1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                                <div style={{ height: "100%" }}>
                                    <TorGrid
                                        guikey="hCashflow"
                                        isHideDateInputs={true}
                                        isHideSaveGridLayout={true}

                                        rowData={this.state.filterData}
                                        gridIdentityColumn="position_id"
                                        groupIncludeFooter={true}
                                        groupIncludeTotalFooter={true}
                                        suppressExpandablePivotGroups={true}
                                        className="ag-theme-alpine"
                                        enableFilter={false}
                                        domLayout="autoHeight"
                                        groupDefaultExpanded={-1}
                                    />
                                </div>
                                <div style={{ maxHeight: "650px" }}>
                                    <ChartDisplay
                                        chartType="cartesian"
                                        xKey="date"
                                        yKeys={["sum"]}
                                        yNames={["Cashflows"]}
                                        yKeyLine="cumSum"
                                        data={this.aggregateData()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }

    }
}
export default HistoricalCashflow;