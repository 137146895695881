import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'DealName', 'col': 'dealName','ag-grid': true, hide:true, rowGroup : true, rowGroupIndex: 1, width: 200 },
            {displayIndex: 2, 'display': 'Group1', 'col': 'group1', 'ag-grid': true, hide:true, rowGroup : true,  rowGroupIndex: 2, width: 200, autoHeight:true },
            {displayIndex: 3, 'display': 'Group2', 'col': 'group2', 'ag-grid': true,hide: true, rowGroup : true,  rowGroupIndex: 3, width: 400, autoHeight:true },
            {displayIndex: 4, 'display': 'Question', 'col': 'question', 'ag-grid': true, width: 1000, autoHeight:true },
            {displayIndex: 5, 'display': 'Answer', 'col': 'valueString', 'ag-grid': true, autoHeight:true },
            ]};
}

export const dealLegalDetailsBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'deal',
        width: 600,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/dealDetails/getAll`, requestOptions).then(Utils.handleResponse);
}

function save(rows) {
    rows = rows.map(row => {
        return Utils.removeBlanksNotZero(row);
    });
    return fetch(`${config.apiDDSUrl}/addDealDetails`, { body: JSON.stringify(rows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
