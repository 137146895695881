import React, { Component } from 'react';
import ChartDisplay from "./components/chartDisplay.js"
import moment from 'moment';

import TorGrid from './../torgrid/torgrid';

class PortfolioOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: []
        }

        this.filterChartData = this.filterChartData.bind(this);

        this.isZero = this.isZero.bind(this);
        this.getDate = this.getDate.bind(this);
        this.agValueFormatter = this.agValueFormatter.bind(this);
        this.getValueFormatted = this.getValueFormatted.bind(this);
        this.getData = this.getData.bind(this);
    }


    render() {
        return this.getTemplate();
    }

    isZero(value) {
        return value < 0.0001 && value > -0.0001
    }

    getDate(params) {
        var val = Date(params.value);
        return val;
    }

    agValueFormatter(value, format) {
        var agformat = format;
        if (value && value !== '' && value !== 0) {
            switch (agformat) {
                case 'millionsFormat':
                    return this.getValueFormatted(value / 1e6, 1);
                case 'millionsFormat2':
                    return this.getValueFormatted(value / 1e6, 2);
                case 'percentFormat':
                    return this.getValueFormatted(value * 100);
                case 'percentFormat1':
                    return this.getValueFormatted(value * 100, 1);
                case 'decimalFormat0':
                    return this.getValueFormatted(value, 0);
                case 'decimalFormat2':
                    return this.getValueFormatted(value, 2);
                case '{0:.2f}x':
                    return this.getValueFormatted(value);
                case 'leverageFormat':
                    const displayvalue = this.getValueFormatted(value, 2);
                    return `${displayvalue}${displayvalue && displayvalue.toString() !== '' ? 'x' : ''}`;
                case 'dateFormat':
                    return this.getDate(value);
                default:
                    return value;
            }
        }
        else if (value && value !== '' && agformat === 'decimalFormatShowZero2')
            return this.getValueFormatted(value, 2);
        return '';
    }

    getValueFormatted(param, digit = 2) {
        var result = param;
        var val = 0.0;

        if (!isNaN(result)) {
            try {
                val = parseFloat(result).toFixed(digit);
                if (this.isZero(val)) return '';

                if (!isNaN(val))
                    result = val.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            } catch (error) {
                val = result;
            }
        }
        return result;
    }

    getData() {
        var grossMV = 0.0;
        var capital = 0.0;
        var cYield = 0.0;

        var data = this.props.gridData

        for (var i = 0; i < data.length; i++) {
            grossMV += data[i]['asset mv'];
            capital += data[i]['capital total'];
            cYield += (data[i]['gross annual total coupon'] + data[i]['gross annual fx carry'] + data[i]['annual cost of capital'])
        }

        return [grossMV, capital, grossMV / capital, cYield / capital];
    }

    filterChartData(colName, value, freq) {
        var data = this.props.chartData;
        var AsOfDate = moment(this.props.AsOfDate).format("YYYYMMDD");

        data = data.filter(function (item) {
            return item['for frequency'] === freq
        });

        data = data.filter(function (item) {
            return item['report date']  <= AsOfDate
        });

        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                chartData: arr
            });
        } else {
            this.setState({
                chartData: data
            });
        }
    }

    componentDidMount() {
        if (this.props.fund && this.props.chartData) {
            this.setState({
                chartData: []
            }, () => {
                this.filterChartData('fund', this.props.fund, this.props.Frequency)
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fund !== this.props.fund || prevProps.AsOfDate !== this.props.AsOfDate || prevProps.chartData !== this.props.chartData) {
            this.filterChartData('fund', this.props.fund, this.props.Frequency)
        }
    }


    getTemplate() {
        if (this.props.GroupBy && this.props.gridData.length > 0) {
            var l = this.getData();
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px', paddingBottom: '15px'}}>
                    <div>
                        <div className="flexDisplay" style = {{marginBottom: '25px'}}>
                            <div className="flexAuto centerElem">
                                <h3 style = {{color: "gray",margin: "0"}}>Gross MV</h3>
                                <h3 style = {{margin: "0"}}>{`$${this.agValueFormatter(l[0], 'millionsFormat')}M`}</h3>
                            </div>

                            <div className="flexAuto centerElem">
                                <h3 style = {{color: "gray", margin: "0"}}>Capital</h3>
                                <h3 style = {{margin: "0"}}>{`$${this.agValueFormatter(l[1], 'millionsFormat')}M`}</h3>
                            </div>

                            <div className="flexAuto centerElem">
                                <h3 style = {{color: "gray", margin: "0"}}>Leverage</h3>
                                <h3 style = {{margin: "0"}}>{`${this.agValueFormatter(l[2], 'leverageFormat')}`}</h3>
                            </div>

                            <div className="flexAuto centerElem">
                                <h3 style = {{color: "gray", margin: "0"}}>Current Yield</h3>
                                <h3 style = {{margin: "0"}}>{`${this.agValueFormatter(l[3], 'percentFormat1')}%`}</h3>
                            </div>
                        </div>

                        <h3 style={{textAlign:'center'}}>{`Gross Asset MV by ${this.props.GroupByName}`}</h3>

                        <div className="flexDisplay" style = {{marginBottom: '25px'}}>
                            <div style = {{width: '33%'}}>
                                <ChartDisplay
                                    chartTitle={''}
                                    chartType='pie'
                                    labelKey={this.props.GroupBy ? this.props.GroupBy : ''}
                                    angleKey="asset mv"
                                    dataList={this.props.gridData}
                                />
                            </div>
                            <div style = {{width: '33%'}}>
                                <ChartDisplay
                                    chartTitle={''}
                                    chartType='area-mln'
                                    GroupBy={this.props.GroupBy ? this.props.GroupBy : ''}
                                    xKey="format report date"
                                    yKeys="asset mv"
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style = {{width: '34%'}}>
                                <ChartDisplay
                                    chartTitle={''}
                                    chartType='area-normalized'
                                    GroupBy={this.props.GroupBy ? this.props.GroupBy : ''}
                                    xKey="format report date"
                                    yKeys="asset mv"
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                        </div>
                        <div className="flexDisplay" style = {{marginBottom: '25px'}}>
                            <div style = {{width: '33%'}}>
                                <ChartDisplay
                                    chartTitle="Historical Leverage"
                                    chartType='line-grouped-alt'
                                    xKey='format report date'
                                    fund={this.props.fund}
                                    sumKeys={['asset mv', 'mv total']}
                                    yName='Leverage'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style = {{width: '33%'}}>
                                <ChartDisplay
                                    chartTitle="Asset MV by Maturity"
                                    chartType='bar-stacked'
                                    GroupBy={this.props.GroupBy ? this.props.GroupBy : ''}
                                    xKey="maturity bucket"
                                    yKeys="asset mv"
                                    dataList={this.props.gridData}
                                />
                            </div>
                            <div style = {{width: '34%'}}>
                                <ChartDisplay
                                    chartTitle="Asset MV by Vintage"
                                    chartType='bar-stacked'
                                    GroupBy={this.props.GroupBy ? this.props.GroupBy : ''}
                                    xKey="vintage year"
                                    yKeys="asset mv"
                                    dataList={this.props.gridData}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div style={{ height: '100%', width: '100%', marginBottom: '15px' }}>
                                <TorGrid
                                    guikey="portfolioOverviewSummary"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}

                                    gridIdentityColumn="position_id"
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}
                                    groupColumns={[this.props.GroupBy, 'deal']}
                                    domLayout="autoHeight"

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , flex: 1
                                    }
                                    }

                                    handleRowSelected={this.handleRowSelected}
                                    sideBar='none'
                                />
                            </div>
                            <div style={{ height: '100%', width: '100%', marginBottom: "20px" }}>
                                <div style={{ gridTemplateColumns: '1fr 1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                                    <div style={{height: '70', margin: 'auto' }} >
                                        <TorGrid
                                            guikey="portfolioOverviewTopExposure"
                                            isHideDateInputs={true}
                                            isHideSaveGridLayout={true}

                                            AsOfDate='20200930'
                                            Frequency='m'
                                            gridIdentityColumn="position_id"

                                            rowData={this.props.gridData}
                                            groupBy='Deal'
                                            ignoreBlanks='asset mv'

                                            domLayout="autoHeight"


                                            suppressExpandablePivotGroups={true}
                                            className="dashboardTopGrid"
                                            rowGroupPanelShow="none"
                                            enableFilter={false}

                                            defaultColDef={this.props.defaultColDef ??
                                            {
                                                sortable: false
                                                , filter: false
                                                , floatingFilter: false
                                                , enableRowGroup: true
                                                , resizable: true
                                                , suppressSizeToFit: false
                                                , flex: 1
                                            }
                                            }

                                            handleRowSelected={this.handleRowSelected}
                                            sideBar='none'
                                            gridTitle='Top Gross Exposures'
                                            pagination={true}
                                        />
                                    </div>
                                    <div style={{height: '70', margin: 'auto' }}>
                                        <TorGrid
                                            guikey="portfolioOverviewTopTRS"
                                            isHideDateInputs={true}
                                            isHideSaveGridLayout={true}

                                            rowData={this.props.gridData}
                                            groupBy='Deal'
                                            ignoreBlanks='deal trs mv'

                                            AsOfDate='20200930'
                                            Frequency='m'
                                            gridIdentityColumn="position_id"
                                            domLayout="autoHeight"

                                            suppressExpandablePivotGroups={true}
                                            className="dashboardTopGrid"
                                            rowGroupPanelShow="none"
                                            enableFilter={false}

                                            defaultColDef={this.props.defaultColDef ??
                                            {
                                                sortable: false
                                                , filter: false
                                                , floatingFilter: false
                                                , enableRowGroup: true
                                                , resizable: true
                                                , suppressSizeToFit: false
                                                , flex: 1
                                            }
                                            }

                                            handleRowSelected={this.handleRowSelected}
                                            sideBar='none'
                                            gridTitle='Top TRS Exposures'

                                            pagination={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default PortfolioOverview;