import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, width: 100, hide: true},
            {'display': 'Quarter', 'col': 'Quarter', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Has borrower missed Payments?', 'col': 'Has borrower missed Payments?', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Has borrower breached Covenants?', 'col': 'Has borrower breached Covenants?', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'As Of Date', 'col': 'As Of Date', 'agg': 'uniq', 'format': 'dateFormat', 'ag-grid': true, width: 150},
            {'display': 'Total Ranking Drawn Debt', 'col': 'Total Ranking Drawn Debt', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Total Drawn Debt through Tranche', 'col': 'Total Drawn Debt through Tranche', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Total Debt', 'col': 'Total Debt', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Cash Balance', 'col': 'Cash Balance', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Gross Debt', 'col': 'Gross Debt', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Equity Book Value', 'col': 'Equity Book Value', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Equity Market Value', 'col': 'Equity Market Value', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Equity Value DCF', 'col': 'Equity Value DCF', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Enterprise Value', 'col': 'Enterprise Value', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Debt Equity Ratio', 'col': 'Debt Equity Ratio', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Unadjusted LTM EBITDA', 'col': 'Unadjusted LTM EBITDA', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Adjusted LTM EBITDA', 'col': 'Adjusted LTM EBITDA', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Appraised Value', 'col': 'Appraised Value', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Purchased Price', 'col': 'Purchased Price', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Occupancy', 'col': 'Occupancy', 'agg': 'uniq', 'format': 'percentFormat2P', 'ag-grid': true, width: 150},
            {'display': 'Cost to Complete', 'col': 'Cost to Complete', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Real Estate Status', 'col': 'Real Estate Status', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'LTM Interest Expense', 'col': 'LTM Interest Expense', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue', 'col': 'LTM Revenue', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue Oceania', 'col': 'LTM Revenue Oceania', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue Africa', 'col': 'LTM Revenue Africa', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue Asia', 'col': 'LTM Revenue Asia', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue Europe', 'col': 'LTM Revenue Europe', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue North America', 'col': 'LTM Revenue North America', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Revenue South America', 'col': 'LTM Revenue South America', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Operation Cashflow', 'col': 'LTM Operation Cashflow', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'LTM Capital Expenditure', 'col': 'LTM Capital Expenditure', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Total Leverage Multiple', 'col': 'Total Leverage Multiple', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Tor Leverage Attachment', 'col': 'Tor Leverage Attachment', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Tor Leverage Detachment', 'col': 'Tor Leverage Detachment', 'agg': 'uniq', 'format': 'millionsFormat2M', 'ag-grid': true, width: 150},
            {'display': 'Total LTV Percentage', 'col': 'Total LTV Percentage', 'agg': 'uniq', 'format': 'percentFormat2P', 'ag-grid': true, width: 150},
            {'display': 'Tor LTV Attachment', 'col': 'Tor LTV Attachment', 'agg': 'uniq', 'format': 'percentFormat2P', 'ag-grid': true, width: 150},
            {'display': 'Tor LTV Detachment', 'col': 'Tor LTV Detachment', 'agg': 'uniq', 'format': 'percentFormat2P', 'ag-grid': true, width: 150},
            {'display': 'Environment ESG Rating', 'col': 'Environment ESG Rating', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Social ESG Rating', 'col': 'Social ESG Rating', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Governance ESG Rating', 'col': 'Governance ESG Rating', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'ESG Rating', 'col': 'ESG Rating', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'is ESG in IC Memo', 'col': 'is ESG in IC Memo', 'agg': 'uniq', 'ag-grid': true, width: 150},
        ]};
}

export const creditMetricBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' , width: 100}, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}