import React, { Component } from 'react';
// import history from '../../history/history';
import config from './../../config';
import LOGO from '../../images/TOR_LOGO_430_2965.png';
import { Session } from '../../helper/session';
import { userBALService } from '../../bal/user.bal';
import { message } from 'antd';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.timeout = 250;
    this.mounted = false;
    this.state = {
      isOtpValidated: false,
      otp: '',
      username: '',
      password: '',
      isPasswordChanged: false,
      error_Message: '',
      token: config.token,
      showHide: 'alert alert-danger alert-dismissible fade',
      error_username: null,
      error_password: null,
      Data: {
        __MESSAGE__: 'changePassword',
        key: 'USER_AUTHENTICATION',
        username: '',
        password: '',
        GUID: ''
      },
      userInfo: null,
      display: "none",
      ws: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleSubmitOtp = this.handleSubmitOtp.bind(this);

    userBALService.generateOtp(this.state.otp).then(otp => {
      if (otp !== "SUCCESS") {
        this.setState({ error_Message: `Error generating OTP : ${otp}` });
        this.handleClose();
      }
    },
      error => {
        this.setState({ error_Message: `Error generating OTP : ${error.toString()}` });
        this.handleClose();
      }
    );
  }

  componentWillUnmount() {
  }

  connectServer = () => {
  };

  check = () => {
  };

  handleClose() {
    var _showHide = this.state.showHide === 'alert alert-danger alert-dismissible fade show' ? 'alert alert-danger alert-dismissible fade' : 'alert alert-danger alert-dismissible fade show';
    if (this.mounted) {
      this.setState({ showHide: _showHide });
    }
  }

  handleSubmitOtp(e) {
    e.preventDefault();
    userBALService.validateOtp(this.state.otp).then(otp => {
      if (otp?.message === "VALID") {
        this.setState({ isOtpValidated: true });
      } else {
        this.setState({ error_Message: `Error - ${otp?.message}` });
        this.handleClose();
      }

    },
      error => {
        this.setState({ error_Message: `Error - ${error.toString()}` });
        this.handleClose();
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    let { error_oldPassword, error_newConfirmPassword, error_newPassword } = this.state;
    let error_Message;
    const { oldPassword, newPassword, newConfirmPassword } = this.state;

    if (oldPassword) {
      if (newPassword && newConfirmPassword) {
        if (newPassword.length < 8) {
          error_Message = "Enter atleast 8 character in New password!";
          error_newPassword = null;
          error_newConfirmPassword = null;
        }
        else if (oldPassword === newPassword) {
          error_Message = "New Password cannot be same as Current password!";
          error_oldPassword = null;
          error_newPassword = null;
          error_newConfirmPassword = null;
        }
        else if (newPassword === newConfirmPassword) {
          this.changePassword(oldPassword, newPassword);
        }
        else {
          error_Message = "New Password and Confirm Password doesn't match!";
          error_newPassword = null;
          error_newConfirmPassword = null;
        }
      }
      else {
        error_Message = "Enter New Password and Confirm Password!";
        error_oldPassword = true;
      }
    }
    else {
      error_Message = "Please enter the Current Password!";
      error_oldPassword = true;
    }

    if (error_Message) {
      this.setState({ error_Message: error_Message, error_newPassword: error_newPassword, error_newConfirmPassword: error_newConfirmPassword, error_oldPassword: error_oldPassword });
      this.handleClose();
    }
  }

  changePassword(oldPassword, newPassword) {
    userBALService.changePassword(oldPassword, newPassword).then(_user => {
      this.resetState();
      localStorage.setItem('loginStatus', 'login');
      this.setState({ userInfo: _user, error_Message: "Password Updated successfully!", isPasswordChanged: true });
      Session.setItem(config.token, JSON.stringify(_user));

      const { location } = this.props;
      const { state } = location;

      /* Here place request login api   // go to state.from if set before */

      if (state && state.from) {
        this.props.history.replace(state.from);
      }
      else { // else go to home
        //this.props.history.replace('/home');
      }
    },
      error => {
        this.setState({ error_Message: `Error changing password - ${error.toString()}` });
        console.log(error.toString());
        this.handleClose();
      }
    );
  }

  error(_error) {
    message.error(_error);
  };

  logout() {
  }

  componentDidMount() {
    this.mounted = true;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      ['error_' + name]: value
    });
  }

  resetState() {
    if (this.mounted) {
      this.setState({
        username: '',
        password: '',
        error_username: null,
        error_password: null
      });
    }
  }


  render() {
    return this.showPage();
  }

  showPage() {
    const { oldPassword, isOtpValidated, isPasswordChanged, newPassword, newConfirmPassword, error_otp, error_newConfirmPassword, error_oldPassword, error_newPassword, error_Message, otp } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 bColor">
            <div className='login-left'>
              {!isOtpValidated ?
                <form name="form" onSubmit= {this.handleSubmitOtp}>
                  <div className={this.state.showHide} role="alert">
                    {error_Message}
                    <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleClose}>
                      <span aria-hidden="true">&times;</span>
                    </span>
                  </div>
                  <div className="spacer">
                    <div className="spacer">
                      Enter OTP sent to your email
                    <input type="text"
                        placeholder="Otp"
                        name="otp"
                        value={otp}
                        onChange={this.handleChange}
                        className={'form-control ' + (error_otp ? 'is-valid' : 'is-invalid')}
                      />
                    </div>
                    <div className="spacer">
                      <input className='login-button' type="submit" value="Validate Otp" onClick={this.handleSubmitOtp} />
                    </div>
                  </div>
                </form>
                : !isPasswordChanged ?
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className={this.state.showHide} role="alert">
                      {error_Message}
                      <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <div className="spacer">
                      <span className='login-lable'><h3>Change Password</h3></span>
                      <div className="spacer">
                        Current Password *
                    <input type="password"
                          placeholder="Old Password"
                          name="oldPassword"
                          value={oldPassword}
                          onChange={this.handleChange}
                          className={'form-control ' + (error_oldPassword ? 'is-valid' : 'is-invalid')}
                        />
                      </div>
                      <div className="spacer">
                        New Password *
                    <input type="password"
                          placeholder="New Password"
                          name="newPassword"
                          value={newPassword}
                          onChange={this.handleChange}
                          className={'form-control ' + (error_newPassword ? 'is-valid' : 'is-invalid')}
                        />
                      </div>
                      <div className="spacer">
                        Confirm Password *
                    <input type="password"
                          placeholder="Confirm Password"
                          name="newConfirmPassword"
                          value={newConfirmPassword}
                          onChange={this.handleChange}
                          className={'form-control ' + (error_newConfirmPassword ? 'is-valid' : 'is-invalid')}
                        />
                      </div>
                      <div className="spacer">
                        <input className='login-button' type="submit" value="Change Password" onClick={this.handleSubmit} />
                      </div>
                    </div>
                  </form>
                  : null
              }
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div className="login-right">
              <img src={LOGO} alt="Go"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
