import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
import moment from 'moment';
const {config} = require('../config');

const getColumnList = async (params) => {
    function dateFormatter(params) {
        return moment(params.value).format('MM/DD/YYYY');
      }
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Until(mm/dd/yyyy)', 'col': 'endDate', 'ag-grid': true, maxWidth: 200, editable: true, cellEditor: 'datePicker',
                valueFormatter: dateFormatter, filter: 'agSetColumnFilter'
            },
            {displayIndex: 2, 'display': 'Operator', 'col': 'operator', 'ag-grid': true, width: 200, autoHeight:true, editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['=', '>', '>=', '<', '<='],
                    valueListGap: 0
                }, filter: 'agSetColumnFilter'
            },
            {displayIndex: 3, 'display': 'Target', 'col': 'value1', 'ag-grid': true, width: 400, autoHeight:true, editable: true, filter: 'agSetColumnFilter' },            
            {displayIndex: 4, 'display': 'Remove', 'col': 'amount', 'ag-grid': true, width: 40, editable: false, cellRenderer: 'removeButton', filter: 'agSetColumnFilter'} 
            ]};
}

export const covenantTargetBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save,
    getAllCovenantTargetOperator
};

function getAutoGroupColumnDef(params) {
    return { 
        width: 600,
        pinned: 'left',
      };
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantTarget`, requestOptions).then(Utils.handleResponse);
}

function getAllCovenantTargetOperator(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/getAllCovenantTargetOperator`, requestOptions).then(Utils.handleResponse);
}

function save(covenantTargetRows) {
    covenantTargetRows = covenantTargetRows.map(covenantTargetRow => {
        return Utils.removeBlanksNotZero(covenantTargetRow);
    }); 
    covenantTargetRows = covenantTargetRows.map(covenantTargetRow => {
        return Utils.removeNegative(covenantTargetRow, 'id');
    }); 
    return fetch(`${config.apiDDSUrl}/addCovenantTargetRows`, { body: JSON.stringify(covenantTargetRows), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
