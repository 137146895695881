import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Modal from 'react-awesome-modal';
import DatePicker from 'react-datepicker';
import TorGrid from '../torgrid/torgrid';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';

class ModalForm extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        this.state = {
            rowData: []
        }
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    getPage() {
        return (
            <div>
                <section>
                    {
                        this.props.modalType === 'close'
                            ?
                            <Modal visible={this.props.modalVisible} effect="fadeInUp" onClickAway={this.props.onClickAway}>
                                <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                                    <h1>{this.props.modalHeading}</h1>
                                    <button className="graybtnDes mt100" onClick={this.props.onClickClose}>Close</button>
                                </div>
                            </Modal>
                            :
                            this.props.modalType === 'saveChanges'
                                ?
                                <Modal visible={this.props.modalVisible} effect="fadeInUp" onClickAway={this.props.onClickAway}>
                                    <div className="p20 centerElem" style={{ width: "420px", height: "270px" }}>
                                        <h1>{this.props.modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.props.onClickYes}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={this.props.onClickAway}>Cancel</button>
                                    </div>
                                </Modal>
                                :
                                this.props.modalType === 'genReport'
                                    ?
                                    <Modal visible={this.props.modalVisible} effect="fadeInUp" onClickAway={this.props.onClickAway}>
                                        <div className="p20 centerElem" style={{ width: "auto", height: "150px" }}>
                                            <h1>{this.props.modalHeading}</h1>
                                            <div style={{ marginBottom: "40px", marginTop: "10px" }}>
                                                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                                                    Fund:
                                                </div>
                                                <div style={{ float: "left", minWidth: "150px", paddingTop: "5px" }}>
                                                    <Dropdown
                                                        placeholder='Select Values'
                                                        name='fund'
                                                        fluid
                                                        search
                                                        selection
                                                        clearable
                                                        onChange={this.props.handleDropDownChange}
                                                        options={this.props.fundList}
                                                        value={this.props.fund}
                                                    />
                                                </div>
                                                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                                                    From Date:
                                                </div>
                                                <div style={{ float: "left", marginRight: "15px", marginTop: "12px" }}>
                                                    <DatePicker
                                                        name="fromDate"
                                                        selected={this.props.fromDate ? new Date(this.props.fromDate) : null}
                                                        onChange={(date) => {
                                                            this.props.handleChange('fromDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                                        }}
                                                        filterDate={(date) => {
                                                            const day = date.getDay();
                                                            return day !== 0 && day !== 6;
                                                        }}
                                                        maxDate={this.props.maxDate}
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                                                </div>
                                                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                                                    To Date:
                                                </div>
                                                <div style={{ float: "left", marginRight: "15px", marginTop: "12px" }}>
                                                    <DatePicker
                                                        name="toDate"
                                                        selected={this.props.toDate ? new Date(this.props.toDate) : null}
                                                        onChange={(date) => {
                                                            this.props.handleChange('toDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                                        }}
                                                        filterDate={(date) => {
                                                            const day = date.getDay();
                                                            return day !== 0 && day !== 6;
                                                        }}
                                                        maxDate={this.props.maxDate}
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                                                </div>
                                                <div style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }} onClick={this.props.generateReport}>
                                                    <span className="nav-link login">Generate</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                    :
                                    this.props.modalType === 'datagrid'
                                        ?
                                        <Modal visible={this.props.modalVisible} effect="fadeInUp" onClickAway={this.props.onClickAway}>
                                            <div className="p20 centerElem" style={{ width: "400px", height: "auto" }}>
                                                <h1>{this.props.modalHeading}</h1>
                                                <TorGrid
                                                    setAgGridAPI={this.props.setAgGridAPI}
                                                    domLayout="autoHeight"
                                                    enableFilter={false}

                                                    isHideDateInputs={true}
                                                    isHideSaveGridLayout={true}
                                                    rowData={this.props.gridReminderData}
                                                    rowGroupPanelShow="none"
                                                    sideBar='none'
                                                    gridTitle=''

                                                    className="dashboardgridNoTotal"
                                                    rowSelection="multiple"
                                                    groupDefaultExpanded={-1}
                                                    groupIncludeTotalFooter={false}

                                                    defaultColDef={this.props.defaultColDef ??
                                                    {
                                                        sortable: false
                                                        , filter: false
                                                        , floatingFilter: false
                                                        , enableRowGroup: true
                                                        , resizable: true
                                                        , suppressSizeToFit: false
                                                        , flex: 1
                                                    }
                                                    }
                                                    guikey="approvalReminder"
                                                    gridIdentityColumn="id"
                                                />
                                                <button className="btnDes mt100" onClick={this.props.handleSendReminder}>Yes</button>
                                                <button className="graybtnDes mt100 ml20" onClick={this.props.onClickAway}>Cancel</button>
                                            </div>
                                        </Modal> :
                                        <Modal visible={this.props.modalVisible} effect="fadeInUp" onClickAway={this.props.onClickAway}>
                                            <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                                                <h1>{this.props.modalHeading}</h1>
                                                <button className="btnDes mt100" onClick={this.props.onClickYes}>Yes</button>
                                                <button className="graybtnDes mt100 ml20" onClick={this.props.onClickAway}>No</button>
                                                <button className="graybtnDes mt100 ml20" onClick={this.props.onClickAway}>Cancel</button>
                                            </div>
                                        </Modal>
                    }
                </section>
            </div>
        );
    }
}

export default ModalForm;