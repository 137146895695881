import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'format': 'fundFormat', 'ag-grid': true, 'hide': true, width: 100},
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'decimalFormat2', 'ag-grid': true, width: 150},
            {'display': '!{col001}', 'col': '!{col001}', 'col2': '!{col001}', 'agg': 'uniq',  'ag-grid': true, width: 200},
            {'display': 'Direction', 'col': 'Direction', 'agg': 'uniq', 'ag-grid': true, width: 120},
            {'display': 'Description', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width: 250},
            {'display': 'Is Development Asset', 'col': 'Is Development Asset', 'agg': 'uniq', 'ag-grid': true, width: 200},
            {'display': 'Location', 'col': 'Location', 'agg': 'uniq', 'ag-grid': true, width: 250},
            {'display': '% of Deal Asset', 'col': '% of Deal Asset', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 160},
        ]};
}

const getColumnListToggle = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'format': 'fundFormat', 'ag-grid': true, 'hide': true, width: 100},
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, width: 150},
            {'display': 'Asset MV', 'col': 'asset mv', 'agg': 'sum', 'format': 'decimalFormat2', 'ag-grid': true, width: 150},
            {'display': '!{col001}', 'col': '!{col001}', 'col2': '!{col001}', 'agg': 'uniq',  'ag-grid': true, width: 200},
            {'display': 'Description', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width: 250},
            {'display': 'Is Development Asset', 'col': 'Is Development Asset', 'agg': 'uniq', 'ag-grid': true, width: 200},
            {'display': 'Location', 'col': 'Location', 'agg': 'uniq', 'ag-grid': true, width: 250},
            {'display': '% of Deal Asset', 'col': '% of Deal Asset', 'agg': 'uniq', 'format': 'decimalFormat2', 'ag-grid': true, width: 160},
        ]};
}

export const exposureRiskBalService = {
     getPositions
    ,getColumnList
    ,getColumnListToggle
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' , width: 100}, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}