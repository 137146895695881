import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');

const True = true;
const False = false;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Public Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'Country', 'col': 'Country', 'agg': 'uniq', 'ag-grid': true, minWidth: 120, hide : true, sort: 'asc'},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, hide : true, sort: 'asc'},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, minWidth: 90, maxWidth: 95, hide : true, sort: 'asc'},

            {'display': 'MTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
        
            {'display': 'QTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
        
            {'display': 'YTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2', 'ag-grid': true, width: 100},
        ]};
}

export const portfolioPerformanceDecompositionBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'deal'
        ,pinned: 'left'
        ,resizable: false
        ,hide: false
        ,width: 100
        ,cellRendererParams:  { suppressCount: true } 
       };    
}

function getRowData(params) {
    return getPositions(params);
}
function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}