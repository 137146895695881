
const cellClassRules = {
    "cell-red": params => params.data && params.data['cellcss'] === 'cell-red',
    "cell-green": params => params.data && params.data['cellcss'] === 'cell-green',
    "cell-blue": params => params.data && params.data['cellcss'] === 'cell-blue',
    "cell-normal": params => params.data === undefined || params.data['cellcss'] === 'cell-normal'
  };
  
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 0, 'display': 'Id', 'col': 'position_id', 'agg': 'uniq', 'ag-grid': true, hide:true,  },
            {displayIndex: 1, 'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width : 150, hide:true },
            {displayIndex: 2, 'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, width : 200, hide:true},
            {displayIndex: 3, 'display': 'Description', 'col': 'Description', 'agg': 'uniq', 'ag-grid': true, width : 150 }, 
            {displayIndex: 4, 'display': 'Product Type', 'col': 'Product Type', 'agg': 'uniq', 'ag-grid': true, width : 150 },

            {displayIndex: 6, 'display': 'Position.Quantity', 'col': 'Current Quantity', 'agg': 'uniq', 'ag-grid': true, 'format': 'millionsFormat2M', width : 110, type: 'rightAligned'}, 
            {displayIndex: 7, 'display': 'Position.$FV', 'col': 'Entry Cost', 'agg': 'sum', 'ag-grid': true, width : 110, 'format': 'millionsFormat2M' }, 
            {displayIndex: 8, 'display': 'Position.Curr', 'col': 'Curr', 'agg': 'uniq', 'ag-grid': true, 'format': 'decimal2', width : 80, type: 'rightAligned'}, 
            {displayIndex: 9, 'display': 'Position.$MV', 'col': 'MV', 'agg': 'sum', 'ag-grid': true, width : 110, 'format': 'millionsFormat2M' }, 

            {displayIndex: 10, 'display': 'Price.TOR Mark', 'col': 'Prev Price', 'agg': 'uniq', 'ag-grid': true, width : 110, 'format': 'decimalFormat2' }, 
            {displayIndex: 11, 'display': 'Price.Mark Change', 'col': 'change', 'format': 'decimalFormat2', 'ag-grid': true, width : 120 
                , valueGetter : params => {
                    if(params.data) {
                        let col1 = Object.keys(params.data).filter(p => p.startsWith("Current Price"));
                        let col2 = Object.keys(params.data).filter(p => p.startsWith("Prev Price"));
                        if(col1 && col2 && params.data[col1] && params.data[col2] && params.data[col1] !== 0 && params.data[col2] !== 0 && params.data[col1] !== params.data[col2]) {
                            return (params.data[col1] - params.data[col2]).toFixed(2);
                        } else if(col1 && col2 && (params.data[col1] === 0 || params.data[col2] === 0)) {
                            return (params.data[col1] - params.data[col2]).toFixed(2);
                        }
                    }
                    return (0).toFixed(2);
                }
            },
            {displayIndex: 12, 'display': 'Price.BBG Mark', 'col': 'Current Price', 'agg': 'uniq', 'ag-grid': true, width : 110, 'format': 'decimalFormat2', cellClassRules: cellClassRules},

            {displayIndex: 13, 'display': 'Daily P&L.Total', 'col': 'DOD Total', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' },
            {displayIndex: 14, 'display': 'Daily P&L.Price', 'col': 'DOD Price', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 
            {displayIndex: 15, 'display': 'Daily P&L.FX', 'col': 'DOD FX', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 
            {displayIndex: 16, 'display': 'Daily P&L.Interest', 'col': 'DOD Interest', 'agg': 'sum', 'ag-grid': true, width : 130, 'format': 'decimalFormat0' }, 
            {displayIndex: 17, 'display': 'Daily P&L.Other', 'col': 'DOD Other', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' },
            {displayIndex: 18, 'display': 'Daily P&L.BBG', 'col': 'DOD Position P&L', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 

            {displayIndex: 19, 'display': 'MTD P&L.Total', 'col': 'MTD Total', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' },
            {displayIndex: 20, 'display': 'MTD P&L.Price', 'col': 'MTD Price', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 
            {displayIndex: 21, 'display': 'MTD P&L.FX', 'col': 'MTD FX', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 
            {displayIndex: 22, 'display': 'MTD P&L.Interest', 'col': 'MTD Interest', 'agg': 'sum', 'ag-grid': true, width : 130, 'format': 'decimalFormat0' }, 
            {displayIndex: 23, 'display': 'MTD P&L.Other', 'col': 'MTD Other', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' },
            {displayIndex: 24, 'display': 'MTD P&L.BBG', 'col': 'Position P&L', 'agg': 'sum', 'ag-grid': true, width : 120, 'format': 'decimalFormat0' }, 

            {displayIndex: 30, 'display': 'Classification.Country', 'col': 'Country', 'agg': 'uniq', 'ag-grid': true, width : 160 },
            {displayIndex: 31, 'display': 'Classification.Credit Sub-Strategy', 'col': 'Credit Sub-Strategy', 'agg': 'uniq', 'ag-grid': true, width : 180 },
            {displayIndex: 32, 'display': 'Classification.Sector', 'col': 'Sector', 'agg': 'uniq', 'ag-grid': true, width : 150 },
            {displayIndex: 33, 'display': 'Classification.Book', 'col': 'Book', 'agg': 'uniq', 'ag-grid': true, hide:true, width : 90 },
            {displayIndex: 34, 'display': 'cellcss', 'col': 'cellcss', 'ag-grid': true, hide:true, width : 150 },
            // {displayIndex: 31, 'display': 'Trade P&L', 'col': 'Trade P&L', 'agg': 'sum', 'ag-grid': true, width : 150, 'format': 'decimalFormat2' }, 
    ]};
}

export const pnlBALService = {
    getRowData,
    getColumnList,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: '',
        resizable: true,
        width: 200,
        pinned: 'left'
    };
}

function getRowData(params) { 

}

function save(instrumentMarks, isSendForApproval, date, fund) { 
    
}