import React, { Component } from 'react';

import TorGrid from '../../torgrid/torgrid';
import ChartDisplay from "../../portfolioDashboard/components/chartDisplay.js"
import moment from 'moment';

class DealRisk extends Component {

  constructor(props) {
    super(props);

    this.state = {
      chartData: []
    }

  }

  render() {
    return this.getTemplate();
  }

  componentDidMount() {
    const { AsOfDate, chartData } = this.props;

    let chartDataFiltered = [];

    if (AsOfDate) {
      chartDataFiltered = chartData.filter(row => new Date(row['report date']) <= new Date(AsOfDate));
    }

    this.setState({
      chartData: chartDataFiltered
    });
  }

  componentDidUpdate(prevProps) {
    const { Deal, Fund, AsOfDate, chartData } = this.props;

    if (prevProps.Deal !== Deal || prevProps.AsOfDate !== AsOfDate || prevProps.chartData !== chartData) {
      let chartDataFiltered = [];

      if (AsOfDate) {
        chartDataFiltered = chartData.filter(row =>  new Date(row['report date']) <= new Date(AsOfDate));
      }

      this.setState({
        chartData: chartDataFiltered
      });
    } else if (prevProps.Fund !== Fund) {
      let chartDataFiltered = [];

      if (AsOfDate) {
        chartDataFiltered = chartData.filter(row =>  new Date(row['report date']) <= new Date(AsOfDate));
      }

      if (Fund && Fund !== 'All') {
        chartDataFiltered = chartDataFiltered.filter(row => row['fund'] === Fund);
      }

      this.setState({
        chartData: chartDataFiltered
      });
    }
  }

  getTemplate() {
    var gridDataFiltered = this.props.gridData
    if (this.props.AsOfDate) {
      gridDataFiltered = gridDataFiltered.filter(row =>  row['report date'] === moment(this.props.AsOfDate).format("YYYYMMDD"));
    }
    
    if(this.props.chartData && this.props.chartData.length > 0) {
      return (
        <div style={{ height: '100%', width: '100%', gridTemplateRows: "auto 1fr" }}>
          <div>
            <TorGrid
              guikey="dealRisk"
              isHideDateInputs={true}
              isHideSaveGridLayout={true}

              rowData={gridDataFiltered}
              groupBy='deal'

              gridIdentityColumn="position_id"
              className="topDisplayGrid"
              rowGroupPanelShow="none"
              enableFilter={false}
              domLayout="autoHeight"

              defaultColDef={this.props.defaultColDef ??
              {
                sortable: false
                , filter: false
                , floatingFilter: false
                , enableRowGroup: true
                , resizable: true
                , suppressSizeToFit: false
                , flex: 1
              }
              }
              handleRowSelected={this.handleRowSelected}
              sideBar='none'
              gridTitle=''
            />
          </div>
          <div style={{ gridTemplateRows: "1fr 1fr", display: "grid" }}>
            <div style={{ gridTemplateColumns: "1fr 1fr", display: "grid" }}>
              <div>
                <ChartDisplay
                  chartTitle="Stress Test"
                  chartType='line-grouped'
                  xKey='report date'
                  yKeys={['asset stress pnl']}
                  yName='Stress Test'
                  dataList={this.state.chartData}
                  rotationAngle={335}
                />
              </div>
              <div>
                <ChartDisplay
                  chartTitle="IR +100bp"
                  chartType='line-grouped'
                  xKey='report date'
                  yKeys={['ir 100bp']}
                  yName='IR +100bp'
                  dataList={this.state.chartData}
                  rotationAngle={335}
                />
              </div>
            </div>
            <div style={{ gridTemplateColumns: "1fr 1fr", display: "grid" }}>
              <div>
                <ChartDisplay
                  chartTitle="CD Spread +100bp"
                  chartType='line-grouped'
                  xKey='report date'
                  yKeys={['cs 100bp']}
                  yName='CD Spred +100bp'
                  dataList={this.state.chartData}
                  rotationAngle={335}
                />
              </div>
              <div>
                <ChartDisplay
                  chartTitle="CD Spread +100%"
                  chartType='line-grouped'
                  xKey='report date'
                  yKeys={['cswpct_100']}
                  yName='CD Spread +100%'
                  dataList={this.state.chartData}
                  rotationAngle={335}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default DealRisk;