import { Utils } from '../../helper/utils';
import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { covenantMasterBALService } from '../../bal/covenantMaster.bal';
import { covenantTargetBALService } from '../../bal/covenantTarget.bal';
import history from '../../history/history';
import TorGrid from '../torgrid/torgrid';
import config from './../../config';
import { Session } from '../../helper/session';

class CovenantDashboard extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);
        const user = JSON.parse(Session.getItem(config.token));        
        const approverRole = user.userRoles.filter(function (item) { return item === "CovenantApprover" });

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            user: user,
            isApprover: approverRole.length > 0,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            isShowLoad: false,
            isShowGrid: true,
            includeDate: true, 
            toggleRefresh: true,
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ], 
            covenantFrequencyList: null,
            covenantCategoryList: null,
            covenantTypeList: null,
            newCovenant : {
                id:null,
                dealId:null,
                covenantTypeId : null,
                covenantFrequencyId : null, 
                covenantCategoryId : null,
                startDate : null, 
                endDate : null, 
                daysDue : 0
            },
        };
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.refresh = this.refresh.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.getFormatted = this.getFormatted.bind(this);
        this.updateGridData = this.updateGridData.bind(this);
        this.addNew = this.addNew.bind(this);
        this.approveCovenants = this.approveCovenants.bind(this);
        this.refresh(null);
    }

    addNew() {
        history.push('/covenantNew');
        window.location.pathname = '/covenantNew';
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(0) : value;
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }
    
    save (rowData) {
        rowData = rowData.map(p=>p.data);
        console.log(rowData);
        
        const modifiedRows = rowData.filter(p=>((!Utils.isBlank(p.value1) && Utils.isBlank(p.valueNew1)) || (Utils.isBlank(p.value1) && !Utils.isBlank(p.valueNew1)) || p.value1 !== p.valueNew1 || p.statusObject?.code !== p.covenantStatusId)).map (p =>   {
                    return {
                    ...{id : p.id},
                    ...(p.value1 !== p.valueNew1) && {value: p.valueNew1},                    
                    ...(p.statusObject?.code !== p.covenantStatusId) && {covenantStatusId: p.statusObject?.code},                    
                    } 
                }
            );

        const commentRows = rowData.filter(p=>!Utils.isBlank(p.commentNew)).map(
            p=> {
                return {
                    id : p.id,
                    comment : p.commentNew
                }
            }
        ); 
        console.log(modifiedRows);
        console.log(commentRows);
    } 

    updateGridData(data, targets) {
        let showGrid = false;
        if(data && data.length > 0) {
            data = data.map(row => { return {
                    ...row, 
                    existingValue: row?.value,
                    statusObject : { name: row.status, code : row.covenantStatusId },
                    valueNew1 : row.value1,
                    commentNew : row.comment,
                    targets : targets?.filter(p => p.covenantMasterId === row.id)
                }
            });
            showGrid = true;
        } 

        this.setState({
            gridData: data,
            targets : targets,
            showGrid: showGrid
        }, () => {
            console.log(data);
        });        
    }

    refresh(deal) {
        covenantTargetBALService.getRowData().then(targets => {

            covenantMasterBALService.getRowData().then(data => {
                this.updateGridData(data, targets);
            }, error => {
                this.setState({ 
                    showMessage: true, 
                    errorList: [error], 
                    messageHeader : `Error while retrieving...`,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Error while retrieving data!'
                }); 
                console.log(error);
            }); 
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving data!'
            }); 
            console.log(error);
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }
    
    handleDropDownChange(e, { name, value }) {
        this.setState({ newCovenant: { ...this.state.newCovenant, [name]: value } });
    }

    handleChangeNew(name, value) {
        this.setState({
            newCovenant: {
                ...this.state.newCovenant,
                [name]: value 
            }
        });
    }

    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    approveCovenants = async () => {
        const selectedRows = this.gridApi?.getSelectedNodes();
        if(selectedRows.length > 0) {
            const covenants = selectedRows.map(covenantMaster => {return {id: covenantMaster.data['id']} });
            console.log(covenants);

            var promises = covenantMasterBALService.approve(covenants);
            Promise.all(promises).then(data => {
                    //var d = JSON.parse(data);
                    var errorList = data.filter(element => element.error).map(element => { return element.error });
                    if(errorList && errorList.length > 0) {
                        this.setState({ showMessage: true, errorList : errorList, errorHeader : "Error while approving the Covenant:", toggleRefresh : !this.state.toggleRefresh });
                    } else {
                        this.setState({
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: '',
                            modalHeading: 'Approved Successfully!'
                        });
                    }
                }
            );

            this.refresh(null);
        }
        else {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: 'First select covenant master(s) to approve'
            });
        }
    }

    setAgGridAPI(api) {
        this.gridApi = api;
    }

    getPage() {
        const { includeDate, gridData, isShowLoad, toggleRefresh, isShowGrid, isApprover, newCovenant, existingCovenant, modalType, modalVisible, modalHeading, gridReminderData } = this.state;
        const { startDate, endDate } = newCovenant
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', borderTop: "solid 1px white", position: 'relative' }}>
            <div style ={{ margin: '10px 10px 10px 10px' }}>

                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }  
                </div>
                <div  style ={{ margin: '10px 10px 10px 10px' }}  >
                    <button key='addButton' className="blueB-no-margin" onClick={this.addNew}>Add New Row</button>
                </div>
                <div style ={{ margin: 'auto', width: '100%', height: '100%' }} >
                        <TorGrid
                            gridTitle=''
                            sideBar='none'
                            gridIdentityColumn="id"
                            rowSelection="multiple"
                            identitySeed={-9999999999999}
                            //showAdd={true}   
                            rowData={gridData}
                            setAgGridAPI = {this.setAgGridAPI}
                            groupIncludeTotalFooter = {false}
                            guikey = "covenantMaster"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}
                            includeDate = {includeDate}
                            suppressExpandablePivotGroups={true}
                            className="ag-theme-alpine"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="normal"
                            groupIncludeFooter={true}
                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: true
                                    , flex: 1
                                }
                            }
                            dateFormat = 'mm/dd/yy'
                            singleClickEdit = {true}
                            isShowLoad = {isShowLoad}
                            isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                            GridStyle= {{ margin: 'auto', width: '100%', height: '100%' }}
                            //save = {this.save}
                            //showSave = {true}
                            isApprover = {isApprover}
                            approve = {this.approveCovenants}
                            toggleRefresh= {toggleRefresh}
                            masterDetail= {true}
                            groupDefaultExpanded = {0}
                            isRowSelectable={ (node) => {
                                return isApprover && (node.data? node.data['approved'] === false : false);
                            } }
                        />
                </div>  
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingCovenant) !== JSON.stringify(newCovenant)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={startDate}
                    toDate={endDate}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default CovenantDashboard;