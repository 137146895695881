import React, { Component } from 'react';
import TorGrid from './../torgrid/torgrid';
import ChartDisplay from "./components/chartDisplay.js"
import { torGridBALService } from '../../bal/torGridBALService.bal';

class PortfolioProjection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            gridData: [],
            filterData: []
        }

        this.getData = this.getData.bind(this);
    }

    render() {
        return this.getTemplate();
    }

    filterData(colName, value) {
        var data = this.state.gridData;
        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                filterData: arr
            });
        } else {
            this.setState({
                filterData: this.state.gridData
            });
        }
    }

    getData = async () => {
        const { projectionDeal } = this.props;
        await torGridBALService.getRowData("pCashflow", { query: `Exec [sp_projected_cashflow] '${projectionDeal}'` }).then(data => {
            this.setState({ gridData: data });
            this.setState({ isLoading: false });
            this.filterData('Fund', this.props.projectionFund);
        }, error => {
            this.setState({ isLoading: false });
            this.setState({ gridData: [], filterData: [] });
        });
    }

    componentDidMount() {
        if (this.props.projectionDeal) {
            this.setState({ isLoading: true, gridData: [], filterData: [] });
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.projectionDeal ?? '') !== (this.props.projectionDeal ?? '')) {
            this.setState({ isLoading: true, gridData: [], filterData: [] })
            this.getData();
        }

        if (prevProps.projectionFund !== this.props.projectionFund) {
            this.filterData('Fund', this.props.projectionFund);
        }
    }

    getTemplate() {
        if (this.props.Fund) {
            return (
                <div style={this.props.isCollapsed ? { gridTemplateColumns: '1fr', display: 'grid', height: '100%', width: '100%' } : { gridTemplateColumns: '1fr 1fr', display: 'grid', height: '100%', width: '100%' }}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            setAgGridAPI={this.props.setAgGridAPI}
                            guikey="portfolioProjection"
                            rowData={this.props.gridData}
                            isHideDateInputs={true}
                            gridIdentityColumn="projectionId"
                            groupBy="Fund"
                            className="ag-theme-alpine"
                            groupDefaultExpanded={-1}
                            rowSelection={'multiple'}
                            isApprover = {this.props.isCollapsed}
                            approve = {this.props.approve}
                            isPanelCollapsed = {this.props.isCollapsed}
                            togglePanel = {this.props.togglePanelCollapse}
                            // isRowSelectable={(node) => { return true && (node.data ? node.data['Approval Status'] !== "Approved" : false) }}
                            rowSelected={this.props.rowSelected}
                            groupSelectsChildren={true}
                        />
                    </div>
                    {
                        !this.props.isCollapsed &&
                        <div style={{ height: '100%', width: '100%' }}>
                            {
                                this.state.filterData.length > 0
                                    ?
                                    <div style={{ gridTemplateRows: 'auto auto 1fr', height: '100%', width: '100%', display: 'grid' }}>
                                        <div>
                                            <div style={{ float: "right", marginTop: "5px" }}>
                                                <button key='approveButton' className="blueB-no-margin" onClick={this.props.approve}>Approve</button>
                                            </div>
                                            <div style={{ float: "right", marginTop: "5px" }}>
                                                <button key='approveButton' className="blueB-no-margin" onClick={this.props.togglePanelCollapse}>Show Charts</button>
                                            </div>
                                        </div>
                                        <div style={{ height: '100%', width: '100%' }}>
                                            <ChartDisplay
                                                chartType="bar-stacked"
                                                chartTitle={`${this.props.projectionDeal} Projected CF (USD)`}
                                                xKey="Cashflow Date"
                                                GroupBy="Fund"
                                                yKeys="Cashflow Book Amount"
                                                dataList={this.state.filterData}
                                                rotationAngle={335}
                                            />
                                        </div>
                                        <div style={{ height: '100%', width: '100%' }}>
                                            <TorGrid
                                                guikey="pCashflow"
                                                isHideDateInputs={true}
                                                isHideSaveGridLayout={true}

                                                rowData={this.state.filterData}
                                                gridIdentityColumn="position_id"
                                                groupIncludeFooter={true}
                                                groupIncludeTotalFooter={true}
                                                suppressExpandablePivotGroups={true}
                                                className="ag-theme-alpine"
                                                enableFilter={false}
                                                groupDefaultExpanded={-1}

                                                defaultColDef={this.props.defaultColDef ??
                                                {
                                                    sortable: false
                                                    , filter: false
                                                    , floatingFilter: false
                                                    , enableRowGroup: true
                                                    , resizable: true
                                                    , suppressSizeToFit: false
                                                }
                                                }

                                                handleRowSelected={this.handleRowSelected}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div style={{ float: "right", marginTop: "5px" }}>
                                            <button key='approveButton' className="blueB-no-margin" onClick={this.props.approve}>Approve</button>
                                        </div>
                                        <div style={{ float: "right", marginTop: "5px" }}>
                                            <button key='approveButton' className="blueB-no-margin" onClick={this.props.togglePanelCollapse}>Show Charts</button>
                                        </div>
                                    </div>
                            }
                        </div>
                    }

                </div>
            );
        } else {
            return null;
        }
    }
}
export default PortfolioProjection;