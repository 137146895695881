import React, { Component } from 'react';

import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

import { portfolioProjectionBalService } from '../../bal/Portfolio-Dashboard/portfolioProjection.bal'
import { userBALService } from '../../bal/user.bal';
import { torGridBALService } from '../../bal/torGridBALService.bal';
import CashMargin from './cashMargin'
import ToastMessage from "./../deals/components/toastMessage";

import './liquidityDashboard.css';

class LiquidityDashboard extends Component {
    constructor(props) {
        super(props);

        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        this.state = {
            GridId: "positions1" ?? this.constructor.name + "_default",
            drawerOpen: false,
            GridTitle: 'Cash / Margin Dashboard',
            display: "none",
            thetaChartData: [],
            tabindex: 4,
            chartMaturityData: [],
            chartVintageData: [],
            maturityLabel: [],
            vintageLabel: [],
            AsOfDate: null,
            Frequency: 'm',
            gridData: [],
            fxGridData: [],
            pnlGridData: [],
            projectionGridData: [],
            hedgeData: [],
            filterData: [],
            stressChartData: [],
            portfolioChartData: [],
            MessageBoxBackground: "Red",
            isOpenLoad: false,
            dateList: [],
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            isCollapsed: true
        }

        this.handleCloseErrorBox = this.handleCloseErrorBox.bind(this);
        this.handleChangeAsOfDate = this.handleChangeAsOfDate.bind(this);
        this.filterData = this.filterData.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
        this.closeErrorMessage = this.closeErrorMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.togglePanelCollapse = this.togglePanelCollapse.bind(this);
    }

    componentDidMount = async () => {
        this.mounted = true;
        await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 4' }).then(data => {
            this.setState({ dateList: data });
            this.setState({ AsOfDate: new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD"))});
        });
    }


    render() {
        return this.getTemplate();
    }

    handleChangeAsOfDate = (date) => {
        const { dateList } = this.state;
        if (date) {
            const frequencyList = dateList.filter(function (item) {
                return item['report date'] === parseInt(moment(date).format("YYYYMMDD"))
            });

            if (frequencyList?.length > 0 && frequencyList[0]) {
                this.setState({
                    AsOfDate: moment(date).format("YYYY-MM-DD")
                }, async () => {
                    await this.filterDate(this.state.dateList, parseInt(moment(date).format("YYYYMMDD")), moment(date).format("YYYY-MM-DD"));
                });
            } else {
                this.setState({
                    AsOfDate: moment(date).format("YYYY-MM-DD")
                });
            }

        } else {
            this.setState({
                AsOfDate: null
            })
        }

    };

    handleCloseErrorBox = () => {
        this.setState({ display: "none" });
    }

    filterData(colName, value) {
        var data = this.state.gridData;

        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                filterData: arr
            });
        } else {
            this.setState({
                filterData: this.state.gridData
            });
        }
    }

    filterDate(data, date, formatDate) {
        const frequencyList = data.filter(function (item) {
            return item['report date'] === date
        });

        if (frequencyList?.length > 0 && frequencyList[0] && frequencyList[0]['for frequency'])
            this.setState({ AsOfDate: moment(formatDate).format("YYYY-MM-DD"), Frequency: frequencyList[0]['for frequency'] });
        else
            this.setState({ AsOfDate: moment(formatDate).format("YYYY-MM-DD"), Frequency: null });
    }

    drawerToggleClickHandler = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
            this.setState({ isOpenGrid: true, modalVisible: false });
        } else if (this.state.modalHandler === 'save') {
            this.saveDB();
            this.setState({ isOpenGrid: true, modalVisible: false });
        } else {
            this.setState({ isOpenGrid: true, modalVisible: false });
        }
    }

    closeErrorMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    closeMessage() {
        this.setState({ showMessage: false, messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
    }

    approvalRejection = (action) => {
        var selectRows = this.gridApi?.getSelectedNodes();

        const user = userBALService.getUserInfo();
        selectRows = selectRows.filter(deal => {
            return deal.data['Analyst 1'] === user?.user?.name
        });

        var selectedRows = selectRows.filter(deal => {
            return deal.data['Approval Status'] !== 'Approved'
        });

        if (selectedRows?.length > 0) {
            const proposals = selectedRows.map(deal => { return { id: deal.data['projectionId'], action: action } });

            var promises = portfolioProjectionBalService.approveGrid(proposals);
            Promise.all(promises).then(data => {
                //var d = JSON.parse(data);
                var errorList = data.filter(element => element.error).map(element => { return element.error });
                if (errorList && errorList.length > 0)
                    this.setState({
                        showMessage: true,
                        errorList: errorList,
                        messageHeader: `Error while ${action.toLowerCase().startsWith('approve') ? 'approving' : action.toLowerCase().startsWith('reject') ? 'rejecting' : action} the request :`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: `${action} failed, please contact support!`
                    });
                else {
                    this.setState({
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: `${action} sucessfully!`
                    });
                }
            }
            );
        } else if (selectRows?.length > 0) {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: `Proposal(s) have already been ${action?.toLowerCase().startsWith('approve') ? 'Approved' : action?.toLowerCase().startsWith('reject') ? 'Rejected' : action}`
            })
        } else {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: '',
                modalHeading: `First select proposal(s) to ${action?.toLowerCase().startsWith('approve') ? 'Approve' : action?.toLowerCase().startsWith('reject') ? 'Reject' : action}`
            })
        }
    }

    approve() {
        this.approvalRejection('Approved');
    }

    reject() {
        this.approvalRejection('Rejected');
    }

    togglePanelCollapse() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }

    getTemplate() {
        const { GridTitle, AsOfDate, dateList, isOpenLoad, modalHeading } = this.state;
        const includeDate = dateList.map(item => {
            return new Date(moment(item['report date'], "YYYYMMDD").format("YYYY-MM-DD"))
        });
        
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', position: 'relative', resizable: true }}>
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeErrorMessage}
                        /> : <div></div>
                }
                <div className="content-container" style={{ height: "100%", width: '100%', border: "solid 1px #babfc7", padding: "0px 0px 0px 0px" }}>
                    <div style={{ float: "left", padding: "14px 6px 10px 16px", cursor: "pointer" }} onClick={this.drawerToggleClickHandler} >
                        <FontAwesomeIcon icon={faBackward} />
                    </div>
                    <div style={{ display: (GridTitle && GridTitle !== '') ? "inline" : "none", float: "left", height: "50px", borderRight: "solid 1px #babfc7", position: "relative", padding: "14px 10px 10px 10px", cursor: "pointer" }} onClick={this.drawerToggleClickHandler}>
                        <b>{GridTitle}</b>
                    </div>

                    <Collapse isOpen={true} style={{ float: "right", height: "auto" }}>
                        <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                            As Of Date:
                        </div>
                        <div style={{ float: "right", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name="AsOfDate"
                                selected={AsOfDate ? new Date(AsOfDate) : null}
                                includeDates={includeDate}
                                onChange={this.handleChangeAsOfDate}
                                autoComplete="off"
                                showYearDropdown
                                showMonthDropdown
                            />
                        </div>
                    </Collapse>
                </div>
                {
                    isOpenLoad === false
                        ?
                        <div className="scrollForm">
                            <CashMargin
                                AsOfDate={moment(AsOfDate).format('YYYYMMDD')}
                            />
                        </div>
                        :
                        <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                            <img src={require('../../images/Loading.gif')} alt="Loading..." />
                        </div>
                }
                <div>
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style={{ width: "420px", height: "270px" }}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                                    :
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                                            <h1>{modalHeading}</h1>
                                            <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                        </div>
                                    </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}
export default LiquidityDashboard;