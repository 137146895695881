import { userBALService } from './../user.bal';
import { Utils } from '../../helper/utils';
import Moment from 'moment';
const {config} = require('../../config');
const True = true;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Description', 'col': 'description', 'agg': 'uniq', 'ag-grid': True, minWidth: 120, maxWidth: 120},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, minWidth: 100, maxWidth: 100},
            {'display': 'Quantity', 'col': 'asset quantity', 'agg': 'sum', 'format': 'millionsFormat2', 'ag-grid': True, minWidth: 60, maxWidth: 65},
            {'display': '$FV.Total', 'col': 'asset quantity usd', 'fltr': {'capital type': ['Asset', 'Deal TRS']}, 'agg': 'sumif', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 60, maxWidth: 65},
            {'display': 'CCY', 'col': 'curr', 'agg': 'uniq', 'ag-grid': True, minWidth: 40, maxWidth: 50},
            {'display': 'Mark', 'cols': ['ending local price', 'credit mv clean'], 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth: 55, maxWidth: 60},

            {'display': 'Capital.Asset', 'col': 'capital asset', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 45, maxWidth: 50},
            {'display': 'Capital.TRS', 'col': 'capital financing', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 45, maxWidth: 50},
            {'display': 'Capital.TRS Buffer', 'col': 'capital trs buffer', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 70, maxWidth: 70},
            {'display': 'Capital.FX Margin', 'col': 'capital fx margin', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 70, maxWidth: 70},
            {'display': 'Capital.FX Buffer', 'col': 'capital fx buffer', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 65, maxWidth: 70},
            {'display': 'Capital.Parked Capital', 'col': 'capital pb parked', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 65, maxWidth: 70},
            {'display': 'Capital.PB Lev', 'col': 'capital pb cash', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 65, maxWidth: 70},
            {'display': 'Capital.Unalloc', 'col': 'capital unallocated', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 55, maxWidth: 65},
            {'display': 'Capital.Total', 'col': 'capital total', 'agg': 'sum', 'format': 'millionsFormat', 'ag-grid': True, minWidth: 40, maxWidth: 45},

            {'display': 'Asset Coupon.Cash', 'cols': ['cash rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth: 35, maxWidth: 40},
            {'display': 'Asset Coupon.PIK', 'cols': ['pik rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth: 35, maxWidth: 40},
            {'display': 'Asset Coupon.Other', 'cols': ['other rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth: 35, maxWidth: 40},
            {'display': 'Asset Coupon.All In', 'cols': ['all in rate', 'credit mv clean'], 'stopif': 'deal', 'agg': 'wAvg', 'format': 'decimalFormat2', 'ag-grid': True, minWidth: 35, maxWidth: 40},

            {'display': 'Gross Levered Yield.Cash', 'numcols': ['gross annual cash coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth: 40, maxWidth: 45},
            {'display': 'Gross Levered Yield.PIK', 'numcols': ['gross annual pik coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth: 40, maxWidth: 45},
            {'display': 'Gross Levered Yield.Other', 'numcols': ['gross annual other coupon'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth: 40, maxWidth: 45},
            {'display': 'Gross Levered Yield.FX', 'numcols': ['gross annual fx carry'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth: 35, maxWidth: 40},
            {'display': 'Gross Levered Yield.Funding', 'numcols': ['annual funding cost'], 'denomcols': ['capital total'], 'agg': 'aggDiv', 'format': 'percentFormat1', 'ag-grid': True, minWidth: 55, maxWidth: 55},
            {'display': 'Deal', 'col': 'deal', 'agg': 'uniq', 'ag-grid': True, hide : True, minWidth: 60, maxWidth: 80},
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'ag-grid': True, hide : True, 'format': 'fundFormat', 'rowGroup': true, minWidth: 60, maxWidth: 80},
        ]};
}

export const dealDashboardBALService = {
     getPositionsData
    ,getColumnList
    ,getRowData
    ,getMVHistory
    ,getDealQuarterlyDates
    ,getCashflowQuarterlyDates
    ,getProjectedCashflowQuarterlyDates
    ,getAutoGroupColumnDef
    ,getCreditMetrics
    ,getCashflowData
    ,getAllCashflowDeals
    ,getPortfolioChartData
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Fund'
        ,field: 'description'
        ,minWidth: 100
        ,maxWidth: 100
        ,pinned: 'left'
        ,resizable: true
      };    
}

function getRowData(params) {
    return getPositionsData(params);
}

function getPositionsData(params) {
    var {AsOfDate, Frequency, Deal, Fund} = params;

    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    Deal = Deal ?? 'ALL';
    Fund = Fund ?? 'ALL';
    
    var body = {
        "frequency": Frequency
        ,"asofdate": asdate
        ,"deal": Deal
        ,"fund": Fund
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };

    return fetch(`${config.apiDDSUrl}/dealdata`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}

function getDealQuarterlyDates(params) {
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } };
    return fetch(`${config.apiDDSUrl}/dealquarterlydates`, requestOptions).then(handleResponseArray).then(dates => { return dates; });
}


function getAllCashflowDeals() {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/cashflowdeals`, requestOptions).then(Utils.handleResponse).then(deal => { return deal; });
}

function getCashflowData(params) {
    var {Deal, Quarter} = params;

    let AsOfDate = '';
    AsOfDate = new Date(Quarter.substring(2), (parseInt(Quarter.substring(1, 2)) * 3).toString(), '01');
    AsOfDate = new Date(AsOfDate - 1);

    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    
    var body = {
        "Quarter": Quarter
        ,"ReportDate": asdate
        ,"Deal": Deal
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/deal/cashflow`, requestOptions).then(Utils.handleResponse).then(data => { return data; });
}

function getCashflowQuarterlyDates(params) {
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } };
    return fetch(`${config.apiDDSUrl}/cashflowquarterlydates`, requestOptions).then(Utils.handleResponse).then(dates => { return dates; });
}

function getPortfolioChartData(body) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/positionChartData`, requestOptions).then(Utils.handleResponse).then(data => { return data; });
}

function getProjectedCashflowQuarterlyDates(params) {
    const requestOptions = { method: 'GET', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } };
    return fetch(`${config.apiDDSUrl}/projectedcashflowquarterlydates`, requestOptions).then(Utils.handleResponse).then(dates => { return dates; });
}

function getMVHistory(params) {
    var { Deal, Fund} = params; 
    Deal = Deal ?? 'ALL';
    Fund = Fund ?? 'ALL';
    
    var body = { 
        "deal": Deal
        ,"fund": Fund
    }

    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/dealmvhistory`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}

function getCreditMetrics(params) {
    var { Deal, Quarter } = params; 
    Deal = Deal ?? 'ALL';
    Quarter = Quarter ?? 'ALL';
     
    var body = { 
        "deal": Deal
        ,"quarter": Quarter
    }
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(body) };
    return fetch(`${config.apiDDSUrl}/dealcreditmetrics`, requestOptions).then(handleResponseArray).then(positions => { return positions; });
}

function handleResponseArray(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }

        if(str && str !== '')
            return JSON.parse(str);
        return [];
    });
}