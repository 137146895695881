import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Underlying', 'col': 'Issuer', 'agg': 'uniq', 'ag-grid': true, width : 200},
            {'display': 'CDS Spread.Mark', 'col': 'Mark', 'agg': 'sum', 'format': 'decimalFormat1', 'ag-grid': true, width : 80},
            {'display': 'CDS Topsheet (%).10%', 'col': '10%', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (%).25%', 'col': '25%', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (%).50%', 'col': '50%', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (%).100%', 'col': '100%', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).-50', 'col': '-50', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).-25', 'col': '-25', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).25', 'col': '25', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).50', 'col': '50', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).75', 'col': '75', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).100', 'col': '100', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).150', 'col': '150', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).200', 'col': '200', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).250', 'col': '250', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).300', 'col': '300', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).350', 'col': '350', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).400', 'col': '400', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).450', 'col': '450', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'CDS Topsheet (bps).500', 'col': '500', 'agg': 'sum', 'format': 'millionsFormat0', 'ag-grid': true, width : 40},
            {'display': 'Underlying', 'col': 'Underlying', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Asset Class', 'col': 'AssetClass', 'agg': 'uniq', 'ag-grid': true, hide: true},
            {'display': 'Tag1', 'col': 'Tag1', 'agg': 'uniq', 'ag-grid': true, hide: true},
        ]};
}

export const cdsTopsheetBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'description'
        ,width: 250
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}