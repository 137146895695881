import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');

const True = true;
const False = false;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
        {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, minWidth : 90, maxWidth : 95, 'rowGroup': true, 'format': 'fundFormat', hide : true},

            {'display': 'MTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            
            {'display': 'QTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            
            {'display': 'YTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100', 'ag-grid': true, width: 100},
        ]};
}

export const dealPerformanceSummaryCTRBALService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'deal'
        ,pinned: 'left'
        ,resizable: false
        ,hide: false
        ,width: 70
        ,display: 'Fund'
        ,cellRendererParams:  { suppressCount: true } 
       };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}