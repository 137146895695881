import React, { Component } from 'react'
import { Message } from "semantic-ui-react";

export default class ToastMessage extends Component {
    render() {
        return (
            <Message
                error
                header={this.props.header}
                list={this.props.errorList}
                onDismiss={this.props.closeMessage}
            />
        )
    }
}

