import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { dealLegalDetailsBALService } from '../../bal/dealLegalDetails.bal';
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

class DealLegalDetails extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingDealDetails: [],
            newDealDetails: [],
            dealList: [],
            editorState: null,
            fordate: null,
            toDate: moment(today).format("YYYY-MM-DD"),
            fromDate: moment(yesterday).format("YYYY-MM-DD"),
            deal: null,
            fund: 'All',
            fundList: [
                { key: 0, text: "TACF", value: "TOR" },
                { key: 1, text: "TACO", value: "TACO" },
                { key: 2, text: "TACO II", value: "TACO II" },
                { key: 3, text: "TACO III", value: "TACO III" },
                { key: 4, text: "TIFO", value: "TIFO" },
                { key: 5, text: "ALL", value: "All" }
              ],
            values: null,
            selectedRows: null,
            showAll: false
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.refresh = this.refresh.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeCheckboxValues = this.handleChangeCheckboxValues.bind(this);
        this.handleChangeValues = this.handleChangeValues.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.populateDeals = this.populateDeals.bind(this);
        
        this.refresh(null);
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    save () {
        const { values, deal } = this.state; 
         let rows = Object.keys(values).map(function(key){  
            let keys = key.split("_");
            return { dealId : keys[0] , questionId: keys[1], value : values[key]};
        });

        rows = rows.filter(row => row.value !== row.existingValue)

        console.log(rows);
        dealLegalDetailsBALService.save(rows).then( 
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saved data successfully!',
                        open: false,
                        deal:null
                    }, ()=> {
                        this.refresh(deal);
                    });
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while saving marks:`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saving data failed!'
                    }); 
                    console.log(error);
            });
    }

    populateDeals() {
        const {showAll, gridData} = this.state;
        let deals = [...new Set(gridData.filter(function (item) {
            return (item && (showAll || !(item.id && item.id>0)) ? true : false);
        }).map(item => item.dealName))];

        let dealList = deals.map(item => { return { key: item, value: item, text: item } });

        this.setState({
            dealList: dealList
         }, () => {
            const {dealList, deal} = this.state;
            if(dealList.filter(item=>item.value === deal).length === 0 ) {
                 this.handleDropDownChange(null, {name: 'deal', value : null});
            }
         });
    }
    
    handleChangeCheckbox(e) {
        this.setState({ 
            [e.target.name]: e.target.value === "true"
        }, () => {
            this.populateDeals();
        });
    }

    refresh(deal) {
        dealLegalDetailsBALService.getRowData().then(data => {
            let showGrid = false;
            if(data && data.length > 0) {
                data = data.map(row => { return {
                        ...row, 
                        existingValue: row?.value
                    }
                });
                showGrid = true;
            } 
 
            this.setState({
                gridData: data,
                showGrid: showGrid
            }, () => {
                this.populateDeals();
                if(deal) {
                    this.handleChange('deal', deal);
                }
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving data!'
            }); 
            console.log(error);
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({
            values : null,
            existingValues : null,
            selectedRows: [],
        }, () => {
            const { gridData, showAll } = this.state;
            let rows = gridData.filter(item => item.dealName === value).sort((a, b) => a.questionId > b.questionId ? 1 : -1);

            let values = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));
            let existingValues = Object.assign({}, ...rows.map((x) => ({[x.idDealQuestion]: x.valueString})));

            this.setState({ 
                [name]: value,
                selectedRows: rows,
                values : values,
                existingValues : existingValues,
                showAll: showAll
            });
        });
    }
    
    handleChangeValues(e) { 
        const { name, value } = e.target; 
        this.setState({ 
            values: {
                ...this.state.values,
                [name]: value
            }
        });  
    }

    handleChangeCheckboxValues(e) {
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });
    } 

    getPage() {
        const { modalType, dealList, values, showAll, selectedRows, deal, fund, fundList, fromDate, toDate, modalVisible, modalHeading, gridReminderData, existingDealDetails, newDealDetails } = this.state;
        let showGroup = false;
        let previousGroup = ' ';
        let isFilled = false;
        let divs = selectedRows?.map(item => {
            isFilled = item.id;
            showGroup = previousGroup !== item.group2;
            previousGroup = item.group2;
            return <div style={{  padding: "14px 20px 5px 5px" }}>
                        {
                            showGroup ? <div><b>{item.group2}</b></div>:<div></div>
                        }
                        <div style={item.group2 !== " "? {paddingLeft:"50px"}:{}}><b>{item.question}</b></div> 
                        <div style={item.group2 !== " "? {paddingLeft:"50px"}:{}}>
                            {
                                item.isReadOnly ? <div><b>{item.valueString}</b></div> : 
                                item.dataType === "bigstring" || item.dataType === "string" ? 
                                    <input type="text"
                                        name={item.idDealQuestion}
                                        autoComplete="off"
                                        value={values[item.idDealQuestion]}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChangeValues} 
                                    />: 
                                item.dataType === "boolean" ? 
                                    <div className="col" onChange={this.handleChangeCheckboxValues}> 
                                        <input type="radio" id={`co1_${item.idDealQuestion}`} value={"Yes"} name={item.idDealQuestion} checked={values[item.idDealQuestion] === "Yes"} /><label for={`co1_${item.idDealQuestion}`} className="fsBig"><b>&nbsp;Yes</b></label>&nbsp;&nbsp;&nbsp;
                                        <input type="radio" id={`co2_${item.idDealQuestion}`} value={"No"} name={item.idDealQuestion} checked={values[item.idDealQuestion] === "No"} /><label for={`co2_${item.idDealQuestion}`} className="fsBig"><b>&nbsp;No</b></label>
                                    </div> :
                                item.dataType === "biginteger" || item.dataType === "integer" ? 
                                    <NumberFormat 
                                        name={item.idDealQuestion}
                                        value={values[item.idDealQuestion]}
                                        thousandSeparator={true}
                                        onValueChange={(values) => { this.handleChangeNew(item.idDealQuestion, values.floatValue); }}
                                        className={'form-control inputLightP'}
                                    />  :<div></div>
                            }
                        </div> 
                    </div>  
        });
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                } 
                <div>
                <div className="col" onChange={this.handleChangeCheckbox}> 
                    <input type="radio" id="showAll1" value={true} name="showAll" checked={showAll === true} /><label for='showAll1' className="fsBig"><b>&nbsp;All</b></label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" id="showAll2" value={false} name="showAll" checked={showAll === false} /><label for='showAll2' className="fsBig"><b>&nbsp;Only unfilled</b></label>
                </div>
                <div style={{ float: "left", width: "250px", paddingTop: "5px" }}>
                    <Dropdown
                        placeholder='Select Deal'
                        name='deal'
                        fluid
                        search
                        selection
                        clearable
                        onChange={this.handleDropDownChange}
                        options={dealList}
                        value={deal}
                    />
                </div>
                {
                    deal !==null && deal !=='' ?
                    <div style={{ float: "left",  padding: "15px 0px 0px 20px" }}>
                        Status: <b>
                        {
                            isFilled ? "Filled" : "Not Filled"
                        }</b>
                    </div>:<div></div>
                }
                {
                    deal !==null && deal !=='' ?
                    <div style={{ float: "right", padding: "5px 5px", cursor: "pointer" }} onClick={this.save}>                            
                        <button key='saveButton' className="blueB-no-margin">Save</button>
                    </div>:<div></div>
                }
                </div>
                <div style={{'overflow':'scroll'}}>
                    {divs}
                </div>
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingDealDetails) !== JSON.stringify(newDealDetails)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    fromDate={fromDate}
                    toDate={toDate}
                    fundList={fundList}
                    fund={fund}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default DealLegalDetails;