import React, { Component } from 'react';
import { dealBALService } from '../../bal/deal.bal';
import { Redirect } from "react-router-dom";
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; import FormDisplay from './components/formDisplay.js';
import GICSPdf from '../../docs/112727-gics-mapbook_2018_v3_letter_digitalspreads.pdf';
import BICSPdf from '../../docs/2072704..pdf';
import { Dropdown } from 'semantic-ui-react';
import ToastMessage from "./components/toastMessage";
import Modal from 'react-awesome-modal';


class DealSetup extends Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        var deal = '';
        var dealID = null;
        if (params && params.deal && params.dealID) {
            deal = params.deal;
            dealID = parseInt(params.dealID);
        }

        const analystDate = new Date('2013-01-01');

        this.state = {
            dealAttribute: {
                deal_id: dealID,
                country_of_risk_id: null,
                gics_sector_id: null,
                gics_industry_group_id: null,
                gics_industry_id: null,
                gics_sub_industry_id: null,
                bics_sector_id: null,
                bics_industry_group_id: null,
                bics_industry_id: null,
                bics_sub_industry_id: null,
                portfolio: null,
                market_risk_sensitivity: null,
                tacf_sub_strategy: null,
                tacof_sub_strategy: null,
                status: 'Approved'
            },
            trancheAttribute: {
                deal_id: null,
                investment_code: null,
                asset_type: null,
                credit_ranking: null,
                holdco_vs_opco: null,
            },
            showMessage: false,
            errorList: [],
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            trancheAttributeList: [],
            projectName: null,
            analystDate: analystDate,
            analyst1Weight: null,
            analyst1Name: null,
            analyst2Weight: null,
            analyst2Name: null,
            analyst3Weight: null,
            analyst3Name: null,
            dealName: deal,
            dealID: dealID,
            dealList: [],
            analystList: [],
            countryList: [],
            gicsSectorList: [],
            gicsIndustryGroupList: [],
            gicsIndustryList: [],
            gicsSubIndustryList: [],
            bicsSectorList: [],
            bicsIndustryGroupList: [],
            bicsIndustryList: [],
            bicsSubIndustryList: [],
            marketRiskList: [],
            tacfSubStrategyList: [],
            tacofSubStrategyList: [],
            assetClassList: [],
            assetRankingList: [],
            holdcoList: [],
            fieldList: [],
            positionList: [],
            display: true,
            isLoading: true
        }
        dealBALService.getFields().then(data => { this.setState({ fieldList: data }); }, error => { console.log(error); });

        dealBALService.checkDeal(dealID).then(data => {
            this.setState({ display: data.message });
        }, error => { console.log(error); })

        dealBALService.getAnalysts().then(data => { this.setState({ analystList: data }); }, error => { console.log(error); });
        dealBALService.getAllCountry().then(data => { this.setState({ countryList: data }); }, error => { console.log(error); });
        dealBALService.getGics().then(data => { this.setState({ gicsSectorList: data }); }, error => { console.log(error); });
        dealBALService.getBics().then(data => { this.setState({ bicsSectorList: data }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "Asset Type" }).then(data => { this.setState({ assetClassList: data.childSettings }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "Credit Ranking" }).then(data => { this.setState({ assetRankingList: data.childSettings }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "HoldCo vs OpCo" }).then(data => { this.setState({ holdcoList: data.childSettings }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "TACF Sub-Strategy at Inception" }).then(data => { this.setState({ tacfSubStrategyList: data.childSettings }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "TACOF Sub-Strategy at Inception" }).then(data => { this.setState({ tacofSubStrategyList: data.childSettings }); }, error => { console.log(error); });
        dealBALService.getSetting({ value: "Market risk Sensitivity" }).then(data => { this.setState({ marketRiskList: data.childSettings }); }, error => { console.log(error); });

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChangeTranche = this.handleDropDownChangeTranche.bind(this);
        this.approveDB = this.approveDB.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.round = this.round.bind(this);
    }

    componentDidMount = async () => {
        const { dealID } = this.state;
        await dealBALService.getAllDeals().then(data => {
            var project = data.filter(function (item) { return item.id === dealID }).map(item => { return item.project });
            if (project.length > 0) {
                this.setState({ projectName: project[0] });
            }
            this.setState({ dealList: data });
        }, error => { console.log(error); });

        await dealBALService.getAllPositions(this.state.dealID).then(data => {
            let trancheAttributeList = [];

            for (var i = 0; i < data.length; i++) {
                let trancheAttribute = {
                    deal_id: this.state.dealID,
                    investment_code: data[i].investmentCode,
                    asset_type: null
                }
                trancheAttributeList.push(trancheAttribute);
            }

            this.setState({
                positionList: data,
                trancheAttributeList: trancheAttributeList
            });
        }, error => {
            console.log(error);
        });

        await dealBALService.getAllAnalysts(this.state.dealID).then(data => {
            if (data.length >= 3) {
                this.setState({ analyst3Name: data[2].analyst.id, analyst3Weight: data[2].dealWeighting });
            } else {
                this.setState({ analyst3Name: null, analyst3Weight: null });
            }

            if (data.length >= 2) {
                this.setState({ analyst2Name: data[1].analyst.id, analyst2Weight: data[1].dealWeighting })
            } else {
                this.setState({ analyst2Name: null, analyst2Weight: null });
            }

            if (data.length >= 1) {
                this.setState({ analyst1Name: data[0].analyst.id, analyst1Weight: data[0].dealWeighting, analystDate: data[0].fromDate })
            } else {
                this.setState({ analyst1Name: null, analyst1Weight: null });
            }
        }, error => {
            console.log(error);
        });

        await dealBALService.getDealAttribute(this.state.dealID).then(data => {
            data['deal_id'] = data['deal']
            delete (data['deal']);
            this.setState({
                dealAttribute: data,
                dealAttributeDb: data
            });
        }, error => {
            console.log(error);
        });

        await dealBALService.getTrancheAttribute(this.state.dealID).then(data => {
            for (var i = 0; i < data.length; i++) {
                data[i]['deal_id'] = data[i]['deal']
                data[i]['investment_code'] = data[i]['investmentCode']
                delete (data[i]['deal']);
                delete (data[i]['investmentCode']);
            }
            this.setState({
                trancheAttributeList: data,
                trancheAttributeListDb: data,
                trancheAttribute: {
                    deal_id: null,
                    investment_code: null,
                    asset_type: null,
                    credit_ranking: null,
                    holdco_vs_opco: null,
                    has_defaulted: null,
                    default_date: null,
                    makewhole: null,
                    amortization: null
                }
            });
        }, error => {
            console.log(error);
        });

        this.setState({ isLoading: false })
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getTemplate();
    }

    closeModal() {
        this.setState({
            modalVisible: false
        });
    }

    handleDropDownChange(e, { name, value }) {
        if (name === "analyst1Name" || name === "analyst2Name" || name === "analyst3Name") {
            this.setState({ [name]: value });
        } else {
            this.setState({ dealAttribute: { ...this.state.dealAttribute, [name]: value } });
        }

        if (name === "gics_sector_id") {
            this.setState({ gicsIndustryGroupList: this.state.gicsSectorList.filter(function (item) { return item.id === value })[0]?.industryGroups });
        } else if (name === "gics_industry_group_id") {
            this.setState({ gicsIndustryList: this.state.gicsIndustryGroupList.filter(function (item) { return item.id === value })[0]?.industries });
        } else if (name === "gics_industry_id" && this.state.gicsIndustryList !== undefined) {
            this.setState({ gicsSubIndustryList: this.state?.gicsIndustryList.filter(function (item) { return item.id === value })[0]?.subIndustries });
        } else if (name === "bics_sector_id" && (value !== "" || value != null)) {
            this.setState({ bicsIndustryGroupList: this.state.bicsSectorList.filter(function (item) { return item.id === value })[0]?.industryGroups });
        } else if (name === "bics_industry_group_id" && (value !== "" || value != null)) {
            this.setState({ bicsIndustryList: this.state.bicsIndustryGroupList.filter(function (item) { return item.id === value })[0]?.industries });
        } else if (name === "bics_industry_id" && this.state.bicsIndustryList !== undefined) {
            this.setState({ bicsSubIndustryList: this.state?.bicsIndustryList.filter(function (item) { return item.id === value })[0]?.subIndustries });
        }

    }

    handleChangeDate(name, value) {
        if (name === "projectName" || name === "analyst1Weight" || name === "analyst2Weight" || name === "analyst3Weight" || name === "analystDate") {
            this.setState({
                [name]: value
            });
        } else {
            this.setState({
                dealAttribute: {
                    ...this.state.dealAttribute,
                    [name]: value
                }
            });
        }
    };

    handleChange(e) {
        const { name, value } = e.target;

        if (name === "projectName" || name === "analyst1Weight" || name === "analyst2Weight" || name === "analyst3Weight") {
            if (Number.isNaN(value)) {
                this.setState({
                    [name]: null
                })
            } else {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                dealAttribute: {
                    ...this.state.dealAttribute,
                    [name]: value
                }
            });
        }
    }

    handleDropDownChangeTranche(e, { id, name, value }) {
        if (this.state.trancheAttribute[name] === null && name !== "has_defaulted") {
            this.setState({ trancheAttribute: { ...this.state.trancheAttribute, [name]: value } });
            let trancheAttributeList = [...this.state.trancheAttributeList];

            for (var i = 0; i < trancheAttributeList.length; i++) {
                if (i === id || trancheAttributeList[i][name] === null || trancheAttributeList[i][name] === undefined) {
                    let trancheAttribute = {
                        ...trancheAttributeList[i],
                        [name]: value
                    }
                    trancheAttributeList[i] = trancheAttribute;
                }
            }
            this.setState({
                trancheAttributeList: trancheAttributeList,
            });

        } else {
            let trancheAttributeList = [...this.state.trancheAttributeList];
            let trancheAttribute = {
                ...trancheAttributeList[id],
                [name]: value
            }
            trancheAttributeList[id] = trancheAttribute;

            this.setState({
                trancheAttributeList: trancheAttributeList,
            });
        }
    }

    round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }

    approveDB = async () => {
        try {
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data save in progress !!'
            });
            var i, key, errorList = [];
            var { dealAttribute, projectName, trancheAttributeList } = this.state;
            const { analyst1Name, analyst1Weight, analyst2Name, analyst2Weight, analyst3Name, analyst3Weight, analystDate } = this.state;

            var sum = parseInt(Number.isNaN(analyst1Weight) ? 0 : analyst1Weight ?? 0) + parseInt(Number.isNaN(analyst2Weight) ? 0 : analyst2Weight ?? 0) + parseInt(Number.isNaN(analyst3Weight) ? 0 : analyst3Weight ?? 0);
            if (((sum <= 99.9 || sum > 100) && sum !== 0) || Number.isNaN(sum)) {
                errorList.push("Analyst Weights should sum to 100%");
            }

            if (((analyst1Name ?? '111') === (analyst2Name ?? '222') && analyst1Name !== "" && analyst2Name !== "")
                || ((analyst1Name ?? '111') === (analyst3Name ?? '333') && analyst1Name !== "" && analyst3Name !== "")
                || ((analyst2Name ?? '222') === (analyst3Name ?? '333') && analyst2Name !== "" && analyst3Name !== "")) {
                errorList.push("Please enter different analysts for position 1, 2, 3");
            }

            // if (projectName === "" || projectName === null) {
            //     errorList.push("Project name cannot be empty");
            // }

            var listVal = "", field = "";
            var columnList = [
                { label: "Deal", id: "deal_id" },
                { label: "Country of Risk", id: "country_of_risk_id" },
                { label: "GICS Sector", id: "gics_sector_id" },
                { label: "Gics Industry Group", id: "gics_industry_group_id" },
                { label: "GICS Industry", id: "gics_industry_id" },
                { label: "GICS Sub Industry", id: "gics_sub_industry_id" },
                { label: "BICS Sector", id: "bics_sector_id" },
                { label: "BICS Industry Group", id: "bics_industry_group_id" },
                { label: "BICS Industry", id: "bics_industry_id" },
                { label: "BICS Sub Industry", id: "bics_sub_industry_id" },
                { label: "Portfolio", id: "portfolio" },
                { label: "TACF Sub Strategy", id: "tacf_sub_strategy" },
                { label: "TACOF Sub Strategy", id: "tacof_sub_strategy" },
                { label: "Analyst Weighting Date", id: "analystDate" },
                { label: "Analyst 1 Name", id: "analyst1Name" },
                { label: "Analyst 1 Weight", id: "analyst1Weight" },
                { label: "Analyst 2 Name", id: "analyst2Name" },
                { label: "Analyst 3 Name", id: "analyst3Name" },
                { label: "Market Risk Sensitivity", id: "market_risk_sensitivity" },
            ]

            for (const key of columnList) {
                field = key.id;
                if (field === "tacf_sub_strategy" || field === "tacof_sub_strategy") {
                    if (dealAttribute["tacf_sub_strategy"] || dealAttribute["tacof_sub_strategy"]) {
                        // 
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "analystDate") {
                    if (analystDate) {
                        //
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "analyst1Name") {
                    if (analyst1Name) {
                        //
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "analyst1Weight") {
                    if (analyst1Weight || analyst1Weight === 0) {
                        //
                    } else {
                        listVal += key.label + ", "
                    }
                } else if (field === "analyst2Name") {
                    if (analyst2Weight && (analyst2Name === null || analyst2Name === undefined || analyst2Name === '')) {
                        listVal += key.label + ", "
                    }
                } else if (field === "analyst3Name") {
                    if (analyst3Weight && (analyst3Name === null || analyst3Name === undefined || analyst3Name === '')) {
                        listVal += key.label + ", "
                    }
                } else {
                    if (dealAttribute[field] || dealAttribute[field] === false || dealAttribute[field] === 0) {
                        // 
                    } else {
                        listVal += key.label + ", "
                    }
                }
            }

            if (listVal !== "") {
                listVal = "Deal Attribute: " + listVal.substring(0, listVal.length - 2);
                errorList.push(listVal);
            }

            listVal = "";
            var flag = false;

            for (i = 0; i < trancheAttributeList.length; i++) {
                field = "asset_type";
                if (trancheAttributeList[i][field] || trancheAttributeList[i][field] === false || trancheAttributeList[i][field] === 0) {
                    // 
                } else {
                    flag = true;
                }

            }

            if (flag) {
                listVal += "Asset Type"
            }

            if (listVal !== "") {
                listVal = "Tranche Attribute: " + listVal;
                errorList.push(listVal);
            }

            if (errorList.length === 0) {
                var body = {};
                body['project'] = projectName;

                await dealBALService.updateDeal(body, dealAttribute.deal_id).then(data => {

                }, error => {
                    errorList.push(error);
                });
            }


            if (errorList.length === 0) {
                var analystDeal = {};

                analystDeal['analystDate'] = this.state.analystDate;
                analystDeal['analyst1Name'] = this.state.analyst1Name;
                analystDeal['analyst1Weight'] = this.state.analyst1Weight;
                analystDeal['analyst2Name'] = this.state.analyst2Name;
                analystDeal['analyst2Weight'] = this.state.analyst2Weight;
                analystDeal['analyst3Name'] = this.state.analyst3Name;
                analystDeal['analyst3Weight'] = this.state.analyst3Weight;

                await dealBALService.insertDealAnalyst(analystDeal, dealAttribute.deal_id).then(data => {
                    
                }, error => {
                    errorList.push(error);
                });
            }

            if (errorList.length === 0) {
                for (key in dealAttribute) {
                    if (dealAttribute[key] === null || dealAttribute[key] === 0 || dealAttribute[key] === "" || Number.isNaN(dealAttribute[key])) {
                        delete dealAttribute[key];
                    }
                }

                dealAttribute.status = 'Approved';
                dealAttribute.approved_by = 43;


                await dealBALService.insertDealAttribute(dealAttribute).then(data => {
                    data['deal_id'] = data['deal']
                    delete (data['deal']);
                    this.setState({
                        dealAttribute: data,
                        dealAttributeDb: data
                    });
                }, error => {
                    errorList.push(error);
                });
            }


            if (errorList.length === 0) {
                for (i = 0; i < trancheAttributeList.length; i++) {
                    for (key in trancheAttributeList[i]) {
                        if (trancheAttributeList[i][key] === null || trancheAttributeList[i][key] === 0 || trancheAttributeList[i][key] === "") {
                            delete trancheAttributeList[i][key];
                        }
                    }
                    trancheAttributeList[i]['isChanged'] = true;

                    trancheAttributeList[i].status = 'Approved';
                    trancheAttributeList[i].approved_by = 43;

                }

                await dealBALService.insertTrancheAttribute(trancheAttributeList).then(data => {
                    data = data.data;
                    for (var i = 0; i < data.length; i++) {
                        data[i]['deal_id'] = data[i]['deal']
                        data[i]['investment_code'] = data[i]['investmentCode']
                        delete (data[i]['deal']);
                        delete (data[i]['investmentCode']);
                    }
                    this.setState({
                        trancheAttributeList: data,
                        trancheAttributeListDb: data,
                        trancheAttribute: {
                            deal_id: null,
                            investment_code: null,
                            asset_type: null,
                            credit_ranking: null,
                            holdco_vs_opco: null,
                            has_defaulted: null,
                            default_date: null,
                            makewhole: null,
                            amortization: null
                        }
                    });
                }, error => {
                    errorList.push(error);
                });

            }

            if (errorList.length === 0) {
                await dealBALService.getRowData().then(data => {
                    this.setState({ gridData: data });
                }, error => { console.log(error); });

                await dealBALService.getAllDeals().then(data => {
                    this.setState({ dealList: data });
                }, error => { console.log(error); });

                this.setState({
                    isDataChanged: false,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Data has been saved successfully'
                });

                await dealBALService.generateEmailConfirmation({ deal: this.state.dealName });

                setTimeout(function () {
                    window.location.href = '/dss';
                }, 3000);
            } else {
                console.log(errorList);
                this.setState({
                    showMessage: true,
                    errorList: errorList,
                    messageHeader: "Error while saving the data :",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'Please fill all fields before submitting.'
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Data could not be saved, please retry!'
            });
        }
    };

    closeMessage() {
        this.setState({ showMessage: false, messageHeader: "Following Fields have not been filled in: " });
    }

    getTemplate() {
        const { dealAttribute, fieldList, trancheAttributeList, positionList, dealName, projectName, modalHeading, display, isLoading } = this.state;
        const { analyst1Name, analyst2Name, analyst3Name, analyst1Weight, analyst2Weight, analyst3Weight, analystDate } = this.state;

        const analystList = this.state.analystList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const countryList = this.state.countryList.map(item => { return { key: item.id, value: item.id, text: item.name } });
        const gicsSectorList = this.state.gicsSectorList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const gicsIndustryGroupList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const gicsIndustryList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.gics_industry_group_id
        })[0]?.industries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const gicsSubIndustryList = this.state.gicsSectorList.filter(function (item) {
            return item.id === dealAttribute.gics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.gics_industry_group_id
        })[0]?.industries.filter(function (item) {
            return item.id === dealAttribute.gics_industry_id
        })[0]?.subIndustries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });

        const bicsSectorList = this.state.bicsSectorList.map(item => { return { key: item.name, value: item.id, text: item.name } });
        const bicsIndustryGroupList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const bicsIndustryList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.bics_industry_group_id
        })[0]?.industries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });
        const bicsSubIndustryList = this.state.bicsSectorList.filter(function (item) {
            return item.id === dealAttribute.bics_sector_id
        })[0]?.industryGroups.filter(function (item) {
            return item.id === dealAttribute.bics_industry_group_id
        })[0]?.industries.filter(function (item) {
            return item.id === dealAttribute.bics_industry_id
        })[0]?.subIndustries.map(item => {
            return {
                key: item.name, value: item.id, text: item.name
            }
        });

        const tacfSubStrategyList = this.state.tacfSubStrategyList.map(item => { return { key: item.value, value: item.id, text: item.value } });
        const tacofSubStrategyList = this.state.tacofSubStrategyList.map(item => { return { key: item.value, value: item.id, text: item.value } });
        const marketRiskList = this.state.marketRiskList.map(item => { return { key: item.value, value: item.id, text: item.value } });

        const assetClassList = this.state.assetClassList.map(item => { return { key: item.value, value: item.id, text: item.value } });
        const assetRankingList = this.state.assetRankingList.map(item => { return { key: item.value, value: item.id, text: item.value } });
        const holdcoList = this.state.holdcoList.map(item => { return { key: item.value, value: item.id, text: item.value } });

        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        if (!isLoading) {
            if (display) {
                return (
                    <div style={{ gridTemplateRows: '1fr auto', display: 'grid', height: '100%', width: '100%', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                        <div style={{ overflowY: 'auto', height: '100%' }}>
                            <div className="dealForm" style={{ height: '100%' }}>
                                {
                                    this.state.showMessage === false
                                        ?
                                        null
                                        :
                                        <ToastMessage
                                            header={this.state.messageHeader}
                                            errorList={this.state.errorList}
                                            closeMessage={this.closeMessage}
                                        />
                                }
                                <div className="row">
                                    <div className="column left">
                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Deal Name"
                                            fieldName="deal_id"
                                            dataField={dealName}
                                            type="textbox-blue"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="IR Code Name/Project Name"
                                            fieldName="projectName"
                                            dataField={projectName}
                                            handleChange={this.handleChange}
                                            type="textbox"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="GICS Sector"
                                            dataList={gicsSectorList}
                                            fieldName="gics_sector_id"
                                            dataField={dealAttribute?.gics_sector_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            tooltip={GICSPdf}
                                            type="dropdown-tooltip"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="GICS Industry Group"
                                            dataList={gicsIndustryGroupList}
                                            fieldName="gics_industry_group_id"
                                            dataField={dealAttribute?.gics_industry_group_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="GICS Industry"
                                            dataList={gicsIndustryList}
                                            fieldName="gics_industry_id"
                                            dataField={dealAttribute?.gics_industry_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="GICS Subindustry"
                                            dataList={gicsSubIndustryList}
                                            fieldName="gics_sub_industry_id"
                                            dataField={dealAttribute?.gics_sub_industry_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="BICS Sector"
                                            dataList={bicsSectorList}
                                            fieldName="bics_sector_id"
                                            dataField={dealAttribute?.bics_sector_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            tooltip={BICSPdf}
                                            type="dropdown-tooltip"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="BICS Industry Group"
                                            dataList={bicsIndustryGroupList}
                                            fieldName="bics_industry_group_id"
                                            dataField={dealAttribute?.bics_industry_group_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="BICS Industry"
                                            dataList={bicsIndustryList}
                                            fieldName="bics_industry_id"
                                            dataField={dealAttribute?.bics_industry_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="BICS Subindustry"
                                            dataList={bicsSubIndustryList}
                                            fieldName="bics_sub_industry_id"
                                            dataField={dealAttribute?.bics_sub_industry_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Country of Risk"
                                            dataList={countryList}
                                            fieldName="country_of_risk_id"
                                            dataField={dealAttribute?.country_of_risk_id}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />
                                    </div>

                                    <div className="column right">
                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="TACF Sub-Strategy"
                                            dataList={tacfSubStrategyList}
                                            fieldName="tacf_sub_strategy"
                                            dataField={dealAttribute?.tacf_sub_strategy}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="TACOF Sub-Strategy"
                                            dataList={tacofSubStrategyList}
                                            fieldName="tacof_sub_strategy"
                                            dataField={dealAttribute?.tacof_sub_strategy}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Public vs Private"
                                            fieldName="portfolio"
                                            dataField={dealAttribute?.portfolio}
                                            handleChange={this.handleChange}
                                            type="checkbox-pp"
                                        />
                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Market Risk Sensitivity"
                                            dataList={marketRiskList}
                                            fieldName="market_risk_sensitivity"
                                            dataField={dealAttribute?.market_risk_sensitivity}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst Weighting Date"
                                            fieldName="analystDate"
                                            dataField={analystDate}
                                            handleChangeDate={this.handleChangeDate}
                                            type="datepick"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst Name"
                                            fieldName="analyst1Name"
                                            dataField={analyst1Name}
                                            dataList={analystList}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst %"
                                            fieldName="analyst1Weight"
                                            dataField={analyst1Weight ?? ''}
                                            handleChangeDate={this.handleChangeDate}
                                            textType="number"
                                            type="textbox"
                                            suffix='%'
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst Name (2)"
                                            fieldName="analyst2Name"
                                            dataField={analyst2Name}
                                            dataList={analystList}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst % (2)"
                                            fieldName="analyst2Weight"
                                            dataField={analyst2Weight ?? ''}
                                            handleChangeDate={this.handleChangeDate}
                                            textType="number"
                                            type="textbox"
                                            suffix='%'
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst Name (3)"
                                            fieldName="analyst3Name"
                                            dataField={analyst3Name}
                                            dataList={analystList}
                                            handleDropDownChange={this.handleDropDownChange}
                                            type="dropdown"
                                        />

                                        <FormDisplay
                                            fieldList={fieldList}
                                            formType="formDisp"
                                            heading="Analyst % (3)"
                                            fieldName="analyst3Weight"
                                            dataField={analyst3Weight ?? ''}
                                            handleChangeDate={this.handleChangeDate}
                                            textType="number"
                                            type="textbox"
                                            suffix='%'
                                        />
                                    </div>
                                </div>

                                <div style={{ overflow: 'auto', minHeight: '450px' }}>
                                    <div className="dealForm">
                                        <div className="spacer table-outer">
                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td className="firstCell"><h3>Tranche Attributes</h3></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return (
                                                                    <td className="minW300" key={i}>
                                                                        {
                                                                            item.hasExpired === true
                                                                                ?
                                                                                <h3>Tranche {i + 1} (Expired)</h3>
                                                                                :
                                                                                <h3>Tranche {i + 1}</h3>
                                                                        }
                                                                    </td>
                                                                );
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Asset Type <span style={{ color: 'red' }}>*</span></label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}>
                                                                    <Dropdown
                                                                        placeholder='Select Asset Type'
                                                                        name="asset_type"
                                                                        id={i}
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        clearable
                                                                        onChange={this.handleDropDownChangeTranche}
                                                                        options={assetClassList}
                                                                        value={trancheAttributeList[i]?.asset_type === null ? '' : trancheAttributeList[i]?.asset_type}
                                                                        className={'form-control maxW400'}
                                                                        upward={false}
                                                                        scrolling
                                                                    />
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Credit Ranking <span style={{ color: 'red' }}>*</span></label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}>
                                                                    <Dropdown
                                                                        placeholder='Select Credit Ranking'
                                                                        name="credit_ranking"
                                                                        id={i}
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        clearable
                                                                        onChange={this.handleDropDownChangeTranche}
                                                                        options={assetRankingList}
                                                                        value={trancheAttributeList[i]?.credit_ranking === null ? '' : trancheAttributeList[i]?.credit_ranking}
                                                                        className={'form-control maxW400'}
                                                                        upward={false}
                                                                        scrolling
                                                                    />
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">HoldCo vs. OpCo <span style={{ color: 'red' }}>*</span></label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}>
                                                                    <Dropdown
                                                                        placeholder='Select HoldCo vs Opco'
                                                                        name="holdco_vs_opco"
                                                                        id={i}
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        clearable
                                                                        onChange={this.handleDropDownChangeTranche}
                                                                        options={holdcoList}
                                                                        value={trancheAttributeList[i]?.holdco_vs_opco === null ? '' : trancheAttributeList[i]?.holdco_vs_opco}
                                                                        className={'form-control maxW400'}
                                                                        upward={false}
                                                                        scrolling
                                                                    />
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Tranche Investment Code</label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}><input type="text" placeholder="Tranche Investment Code" name="trancheIC" value={item.investmentCode} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Tranche Description</label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}><input type="text" placeholder="Tranche Description" name="trancheDesc" value={item.trancheDescription} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Inception/ Trade Date</label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}><input type="text" placeholder="Inception/ Trade Date" name="inceptionDate" value={item.inceptionDate === null ? '' : item.inceptionDate.substring(0, 10)} autoComplete="off" className={'form-control inputDisabled fs14'} disabled /></td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Face Value (USD)</label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}>
                                                                    <input type="text"
                                                                        placeholder="Face Value (USD)"
                                                                        name="faceValue"
                                                                        value={item.faceValue !== 0 ? '$' + Number(parseInt(item.faceValue, 10)).toLocaleString('en') : 0}
                                                                        autoComplete="off"
                                                                        className={'form-control inputDisabled fs14'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <td className="firstCell"><label className="fsBig">Market Value (USD)</label></td>
                                                        {
                                                            positionList.map((item, i) => {
                                                                return <td className="minW300" key={i}>
                                                                    <input type="text"
                                                                        placeholder="Market Value (USD)"
                                                                        name="marketValue"
                                                                        value={item.bookValue !== 0 ? '$' + Number(parseInt(item.bookValue, 10)).toLocaleString('en') : 0}
                                                                        autoComplete="off"
                                                                        className={'form-control inputDisabled fs14'}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 'auto' }}>
                            <div style={{ height: '10px' }}>
                                <div style={{ float: "right" }} onClick={() => this.approveDB()}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit and Approve" />
                                </div>
                            </div>
                        </div>
                        <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                            <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                                <h1>{modalHeading}</h1>
                                <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                            </div>
                        </Modal>
                    </div>
                )
            } else {
                return (
                    <div>Please Fill from DSS - No permission !!</div>
                )
            }
        } else {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        }

    }
}

export default DealSetup;