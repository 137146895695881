import { userBALService } from '../user.bal';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Trade Date', 'col': 'tdate', 'agg': 'uniq', 'ag-grid': true, width : 120},
            {'display': 'Fund', 'col': 'fund', 'agg': 'uniq', 'ag-grid': true, width : 90, hide: true, 'format': 'fundFormat', 'rowGroup': true},
            {'display': 'Event Desc', 'col': 'eventDesc', 'agg': 'uniq', 'ag-grid': true, width : 200},
            {'display': 'Currency', 'col': 'currency', 'agg': 'uniq', 'ag-grid': true, width : 110},
            {'display': 'Local Amount', 'col': 'netLocal', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 140},
            {'display': 'USD Amount', 'col': 'netBook', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 140},
        ]};
}

export const hCashflowBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: ''
        ,field: 'Fund'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true }
        , flex: 1
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok || (data && data.error_message)) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //this.location.reload(true);
            }

            const error = (data && data.error_message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        var str = ""
        for(var i = 0 ; i < data.length; i++) {
            str = str + data[i];
        }
        return JSON.parse(str);
    });
}