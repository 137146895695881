import React, { Component } from 'react';
import * as XLSX from "xlsx";
class ExcelImport extends Component {
    constructor(props) {
      super(props);

      this.state = {
        handleUpload: this.props.handleUpload,
        buttonCaption: this.props.buttonCaption ?? "Upload",
        jsonData : null,
        file: null
      }
      
      this.handleConvert = this.handleConvert.bind(this);
      this.handleReset = this.handleReset.bind(this);
    }
    
    handleReset() {

       const {file} = this.state;
      if (file?.current) {
        file.current.value = "";
        file.current.type = "text";
        file.current.type = "file";
        this.setState({file : file});
      }
    }

    handleConvert() {
        const {file, handleUpload} = this.state;
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: "binary" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = XLSX.utils.sheet_to_json(worksheet);
              //const jsonData = JSON.stringify(json, null, 2);
              this.setState({ jsonData: json})
              handleUpload(json);
            };
            reader.readAsBinaryString(file);
            //reader.abort();
          }
    }

    render() {
        return this.showPage();
    }

    showPage() {
        const { buttonCaption, file} = this.state;
        return (
        <div>
            <input  
            type="file"
            accept=".xls,.xlsx"
            ref={file} 
            onChange={e => this.setState({file : e.target.files[0]  })}
        />
        <button onClick={this.handleConvert}>{buttonCaption}</button> 
        <button onClick={this.handleReset}>Reset</button>
        {/*<pre>{jsonData}</pre>        */}
        </div>
        );
    }
}
export default ExcelImport;
 