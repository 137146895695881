/**<import>****************************************************************************************************/
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { restrictedListBALService } from '../../bal/restrictedList.bal';
import { Prompt } from "react-router-dom";
import { Utils } from '../../helper/utils';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class RestrictedList extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        this.state = { 
            rowData: [],
            existingGridData: [],
            deleted : [],
            restrictionTypes: [],
            isShowLoad: false, 
            isShowGrid: false, 
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            user: user,
            num: -1,
            open:false,
            fundList : [],
            fund : null,
            forDate : moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        //getFormatted = getFormatted(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this); 
        this.update = this.update.bind(this); 
        this.onCellClicked = this.onCellClicked.bind(this);
        this.refresh(null);

    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open })
    }

    refresh(e, modalVisible = false) { 
        this.setState({ 
            gridData: null,
            existingGridData: null,
            isShowLoad : true, 
            isShowGrid : false,
            showMessage: false, 
            errorList: [], 
            messageHeader : null,
            modalVisible: false,
         });
         
         restrictedListBALService.getAllRestrictionType().then(
            data => {
               let restrictionTypes = data.filter(function (item) {
                   return (item ? true : false);
               }).map(item => { return { key: item.restrictionTypeId, value: item.restrictionTypeId, text: item.displayName } });
               if(restrictionTypes) {
                   this.setState({
                    restrictionTypes: restrictionTypes
                   });
               }
            }, error => {
           this.setState({ 
               isShowGrid: false,
               isShowLoad : false,
               showMessage: true, 
               errorList: [error], 
               messageHeader : "Error while getting the restriction type:",
               modalVisible: true,
               modalType: 'close',
               modalHandler: 'close',
               modalHeading: 'Data could not be retrieved, please contact support!'
           }); 
           console.log(error); 
       });

        restrictedListBALService.getAllExchangeMco().then(
            data => {
                let exchanges = data.filter(function (item) {
                    return (item ? true : false);
                }).map(item => { return { key: item.exchangeMcoId, value: item.exchangeMcoId, text: item.code } });
                if(exchanges) {
                    this.setState({
                        exchanges: exchanges
                    });
                }
                }, error => {
            this.setState({ 
                isShowGrid: false,
                isShowLoad : false,
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the exchanges:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); 
        });

        restrictedListBALService.getRowData('restrictedList', null).then(data => {
            this.setState({
                gridData: data,
                existingGridData: data,
                isShowLoad:false,
                isShowGrid: data?.length > 0,
                ...(data?.length <= 0) && {
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'No restriction names exists.'
                },
                ...!modalVisible && {
                    showMessage: false,
                    errorList: null,
                    messageHeader : null
                }
            });
        }, error => {
            this.setState({ 
                isShowGrid: false,
                isShowLoad : false,
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while getting the grid data :",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Data could not be retrieved, please contact support!'
            }); 
            console.log(error); 
        });
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({ modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false});
        } else {
            this.setState({ modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
        
    }

    onCellClicked(params) {        
      if (params.column.colId === "delete") {
        params.api.applyTransaction({
          remove: [params.node.data]
        });
        this.setState( {
                deleted : [...this.state.deleted, params.node.data]             
            });
      }
    }

    handleDropDownChange(e, { name, value }) {
        this.setState({ [name]: value });
    }
    
    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    update(restrictedListRows) {
        if(restrictedListRows && restrictedListRows.length>0) {
            restrictedListRows = restrictedListRows.map(restricted  => {
                return Utils.removeBlanksNotZero(restricted );
            });
            
            let errorList = [];
            restrictedListRows.forEach(restrictedName => {
                if(!(restrictedName.isin || restrictedName.cusip || restrictedName.sedol || (restrictedName.ticker && restrictedName.exchangeSymbol))) {
                    errorList = [...errorList, `CompanyName:${restrictedName.companyName}, Description:${restrictedName.description} - Either ISIN/Cusip/Sedol/Ticker&Exchange must be provided`]
                }
                if(!(restrictedName.companyName && restrictedName.restrictionType && restrictedName.description && restrictedName.addedDate && restrictedName.addedDate !== 'undefined')) {
                    errorList = [...errorList, `CompanyName:${restrictedName.companyName}, Description:${restrictedName.description}, Date put on list:${restrictedName.addedDate}, RestrictionType:${restrictedName.restrictionType} - All four columns are mandatory`]
                }
            });

            if(errorList.length > 0 ) {
                this.setState({ 
                    showMessage: true, 
                    errorList: errorList, 
                    messageHeader : `Error while saving restricted list:`,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Saving restricted list data failed!'
                }); 
                console.log(errorList);
            } else {
                restrictedListBALService.save(restrictedListRows).then(
                    data => {
                            this.setState({
                                showMessage: false, 
                                errorList: null, 
                                messageHeader : "",
                                modalVisible: true,
                                modalType: 'close',
                                modalHandler: 'close',
                                modalHeading: 'Saved restriction list successfully!',
                                open: false ,
                                gridData: data
                            });
                        }
                        , error => {
                            this.setState({ 
                                showMessage: true, 
                                errorList: [error], 
                                messageHeader : `Error while saving restricted list:`,
                                modalVisible: true,
                                modalType: 'close',
                                modalHandler: 'close',
                                modalHeading: 'Saving restricted list data failed!'
                            }); 
                            console.log(error);
                    });
            }
        }
    }

    save = async (rowData) => {
        let restrictedListRows = rowData.map(p=>p.data);
        //restrictedListRows = restrictedListRows.map(p=> {return {...p, fyStart:this.state.fy}});
        let {deleted} = this.state;
        console.log(restrictedListRows);

        if(deleted && deleted.length > 0) {
            let idsToDelete = this.state.deleted.map(p=>p.restrictedListId);

            restrictedListBALService.remove(idsToDelete).then(
                data => {
                        this.setState({
                            showMessage: false, 
                            errorList: null, 
                            messageHeader : "",
                            modalVisible: false,
                            modalType: 'close',
                            modalHandler: 'close',
                            modalHeading: '',
                            open: false ,
                        });
                        this.update(restrictedListRows);
                    }
                    , error => {
                        this.setState({ 
                            showMessage: true, 
                            errorList: [error], 
                            messageHeader : `Error while removing restricted names:`,
                            modalVisible: true,
                            modalType: 'close',
                            modalHandler: 'close',
                            modalHeading: 'Removing restricted names failed!'
                        }); 
                        console.log(error);
                        return;
                });
        } else {
            this.update(restrictedListRows);
        }
    }

    getPage() {
        const { newDeal, existingDeal, modalHeading, isShowLoad, gridData, showMessage, messageHeader, errorList } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    showMessage ?
                        <ToastMessage
                            header={messageHeader}
                            errorList={errorList}
                            closeMessage={this.closeMessage}
                        />:<div></div>
                }  
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                    <div>
                        <div style={{ float: "left", padding: "5px 15px 0px 15px" }} ><h4><u>Restricted List</u></h4></div>
                        <div style={{ float: "right", padding: "15px 15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                    </div>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            isHideDateInputs={true}
                            domLayout="normal"

                            gridIdentityColumn="restrictedListId"
                            identitySeed={1000000000}
                            showAdd={true}
                            setAgGridAPI = {this.setAgGridAPI} 
                            rowGroupPanelShow="none"
                            onCellClicked={this.onCellClicked}

                            isHideSaveGridLayout={true}

                            rowData={gridData} 

                            className="ag-theme-alpine"
                            rowSelection="multiple"

                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}
                             
                            guikey = "restrictedList"
                            sortColumn = {true} 
                            singleClickEdit = {true}
                            save = {this.save}
                            showSave = {true}

                            isShowLoad = {isShowLoad}
                            //isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                        />
                    </div>
                </div> 
                <Prompt
                    when={this.state.isShowLoad === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                    message={location => `Do you want to discard changes?`}
                />
                <div>
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                                :
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default RestrictedList;