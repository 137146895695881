/**<import>****************************************************************************************************/
import React, { Component } from 'react';

import { Dropdown } from 'semantic-ui-react'

// Our components
import './deals.css';
import FormDisplay from './components/formDisplay.js';
import DealReturns from './components/deal_returns.js';
import DealMetrics from './components/deal_metrics.js';

import ProgressBar from './components/progressbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faArrowCircleLeft, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';

class CreditMetrics extends Component {
    constructor(props) {
        super(props);
        this.timeout = 250;
        this.state = {
            quarterList: [],
            percent: 0
        }
        this.handleChangeMetrics = this.handleChangeMetrics.bind(this);
        this.handleDropDownChangeMetrics = this.handleDropDownChangeMetrics.bind(this);
        this.handleChangeReturns = this.handleChangeReturns.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
    };

    handleChangeMetrics(e) {
        this.props.handleChangeMetrics(e);
    }

    handleDropDownChangeMetrics(e, { name, value }) {
        this.props.handleDropDownChangeMetrics(e, { name, value });
    }

    handleChangeReturns(id, name, value) {
        this.props.handleChangeReturns(id, name, value);
    }

    handleChangeDate(name, value) {
        this.props.handleChangeDateMetrics(name, value);
    }

    handleChangeCurrency(name, currency, rate) {
        this.props.handleChangeCurrency(name, currency, rate);
    }

    componentDidMount() {
        var quarterList = [];
        var quarterUd = {
            key: 0,
            value: "Underwriting",
            text: "Underwriting"
        }
        quarterList.push(quarterUd);

        for (var i = new Date().getFullYear(); i >= 2013; i--) {
            var m = 4;
            if (i === new Date().getFullYear()) {
                m = new Date().getMonth() / 3;
                m = Number(parseInt(m, 10))
            }
            for (var j = m; j >= 1; j--) {
                var s = "q" + j.toString() + i.toString();
                var v = "Q" + j.toString() + '-' + i.toString();
                var quarter = {
                    key: (i - 2013) * 4 + j,
                    value: s,
                    text: v
                }
                quarterList.push(quarter);
            }
        }
        this.setState({ quarterList: quarterList });

        this.getProgress();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dealMetrics !== this.props.dealMetrics || prevProps.dealReturnList !== this.props.dealReturnList) {
            this.getProgress();
        }
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    getProgress() {
        const { dealMetrics, dealReturnList, fieldList } = this.props;
        let filterData, permission, required;
        var fillCount = 0;
        var count = 0;

        var columnList = [
            "total_debt_entry",
            "cash_balance_entry",
            "currency",
            "rate",
            // "equity_book_value",
            // "equity_market_value",
            // "equity_value_dcf",
            "unadjusted_ltm_ebitda",
            "adjusted_ltm_ebitda",
            "appraised_value",
            "implied_ownership",
            "purchased_price",
            "ltm_interest_expense",
            "ltm_revenue",
            "ltm_revenue_oceania",
            "ltm_revenue_africa",
            "ltm_revenue_asia",
            "ltm_revenue_europe",
            "ltm_revenue_north_america",
            "ltm_revenue_south_america",
            // "ltm_operation_cashflow",
            "ltm_capital_expenditure",
            "esg_rating_value",
            "enterprise_value",
            "esg_environment_rating_value",
            "esg_social_rating_value",
            "esg_governance_rating_value",
            "deal_metrics_notes"
        ]

        columnList.push(...["total_ranking_drawn_debt", "total_drawn_debt_tranche", "has_borrower_missed_payment", "has_borrower_breach_covenant", "as_of_date"]);

        if(dealMetrics?.quarter === "Underwriting") {
            columnList.push(...[ "is_ESG_in_Memo"]);
        }

        for (const key of columnList) {
            filterData = fieldList.filter(function (item) { return item.field_name === key }).map(item => { return item });
            permission = filterData[0]?.permission;
            required = true;

            if (required && permission === 2) {
                count += 1;
                if (dealMetrics[key] || dealMetrics[key] === false || dealMetrics[key] === 0) {
                    fillCount += 1;
                }
            }
        }

        if ((dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) ||
            (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) ||
            (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0)
        ) {
            count += 3;

            if (dealMetrics["total_leverage_multiple"] || dealMetrics["total_leverage_multiple"] === false || dealMetrics["total_leverage_multiple"] === 0) {
                fillCount += 1;
            }

            if (dealMetrics["tor_leverage_attachment"] || dealMetrics["tor_leverage_attachment"] === false || dealMetrics["tor_leverage_attachment"] === 0) {
                fillCount += 1;
            }

            if (dealMetrics["tor_leverage_detachment"] || dealMetrics["tor_leverage_detachment"] === false || dealMetrics["tor_leverage_detachment"] === 0) {
                fillCount += 1;
            }
        } else {
            count += 3;

            if (dealMetrics["total_ltv_percentage"] || dealMetrics["total_ltv_percentage"] === false || dealMetrics["total_ltv_percentage"] === 0) {
                fillCount += 1;
            }

            if (dealMetrics["tor_ltv_detachment"] || dealMetrics["tor_ltv_detachment"] === false || dealMetrics["tor_ltv_detachment"] === 0) {
                fillCount += 1;
            }

            if (dealMetrics["tor_ltv_attachment"] || dealMetrics["tor_ltv_attachment"] === false || dealMetrics["tor_ltv_attachment"] === 0) {
                fillCount += 1;
            }
        }

        columnList = ["unlevered_irr_percentage",
            "levered_irr_percentage",
            "unlevered_moic",
            "levered_moic",
            "return_scenario_probability"
        ]

        if(dealMetrics?.quarter === "Underwriting") {
            if (dealReturnList.length > 0) {
                for (const key of columnList) {
                    filterData = fieldList.filter(function (item) { return item.field_name === key }).map(item => { return item });
                    permission = filterData[0]?.permission;
                    required = true;
    
                    if (required && permission === 2) {
                        count += 1;
                        if (dealReturnList[0][key]) {
                            fillCount += 1;
                        }
                    }
                }
            } else {
                count += 6;
            }
        }
        

        this.setState({ percent: this.roundToTwo(fillCount / count * 100) });
    }

    render() {
        const { dealMetrics, dealPriorMetrics, dealReturnList, bbg_quarter } = this.props;
        const { quarterList } = this.state;
        const percent = this.state.percent;

        var dealList = this.props.bbgDealList.filter(function (entry) {
            return entry.deal_id === dealMetrics.deal_id
        });

        dealList = dealList.map(item => { return { key: item.for_report_date, value: item.for_report_date, text: item.quarter } });

        return (
            <div style={{ gridTemplateRows: 'auto 1fr auto', display: 'grid', height: '100%', width: '100%' }}>
                <div>
                    <ProgressBar percent={percent} />
                </div>
                <div style={{ overflow: 'auto' }}>
                    <div className="dealForm">
                        {
                            (dealMetrics?.quarter ?? 'Underwriting') === 'Underwriting'
                                ? 
                                <div className="pt10 mb20 lrDisp">
                                    <label className="fsBig p5 w200">Quarter</label>
                                    <div className="w200">
                                        <Dropdown
                                            placeholder="Select Quarter"
                                            name="quarter"
                                            fluid
                                            search
                                            selection
                                            onChange={this.handleDropDownChangeMetrics}
                                            options={quarterList}
                                            value={dealMetrics?.quarter ?? 'Underwriting'}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="pt10 mb20" style={{display: "flex", justifyContent: "space-between"}}>
                                    <label className="fsBig p5">Quarter</label>
                                    <div className="w200">
                                        <Dropdown
                                            placeholder="Select Quarter"
                                            name="quarter"
                                            fluid
                                            search
                                            selection
                                            onChange={this.handleDropDownChangeMetrics}
                                            options={quarterList}
                                            value={dealMetrics?.quarter ?? 'Underwriting'}
                                        />
                                    </div>
                                    <button className="blueB" onClick={this.props.handleClickPriorMetrics}>
                                        {"Load from Prior Quarter"}
                                    </button>
                                </div>

                        }

                        {
                            dealList.length > 0
                                ?
                                <div className="pt10 mb20" style={{display: "flex", justifyContent: "space-between"}}>
                                    <label className="fsBig p5">Bloomberg FY Quarter</label>
                                    <div className="w200">
                                        <Dropdown
                                            placeholder="Select BBG Quarter"
                                            name="bbg_quarter"
                                            fluid
                                            search
                                            selection
                                            onChange={this.handleDropDownChangeMetrics}
                                            options={dealList}
                                            value={bbg_quarter}
                                        />
                                    </div>
                                    <button className="blueB" onClick={this.props.handleClickBbg}>
                                        {"Load from Bloomberg"}
                                    </button>
                                </div>
                                :
                                null
                        }

                        <DealMetrics
                            dealMetrics={dealMetrics}
                            dealPriorMetrics = {dealPriorMetrics}
                            handleChangeMetrics={this.handleChangeMetrics}
                            handleDropDownChangeMetrics={this.handleDropDownChangeMetrics}
                            handleChangeDate={this.handleChangeDate}
                            handleChangeCurrency={this.handleChangeCurrency}
                            fieldList={this.props.fieldList}
                            settingList={this.props.settingList}
                            torPositionVal={this.props.torPositionVal}
                        />

                        {(dealMetrics?.quarter ?? 'Underwriting') === 'Underwriting' 
                            ?
                            <div className="mb30">
                                <DealReturns
                                    dealReturnList={dealReturnList}
                                    fieldList={this.props.fieldList}
                                    id={0}
                                    handleChangeReturns={this.handleChangeReturns}
                                    scenarioName="Tor Base Case"
                                />

                                <DealReturns
                                    dealReturnList={dealReturnList}
                                    fieldList={this.props.fieldList}
                                    id={1}
                                    handleChangeReturns={this.handleChangeReturns}
                                    scenarioName="Tor Downside Case"
                                />

                                <DealReturns
                                    dealReturnList={dealReturnList}
                                    fieldList={this.props.fieldList}
                                    id={2}
                                    handleChangeReturns={this.handleChangeReturns}
                                    scenarioName="Management Base Case"
                                />
                            </div>
                            : null
                        }
                        

                        <FormDisplay
                            formType="commentDisp"
                            heading="Quarterly Performance Update:  Please provide a brief summary of any developments during the quarter"
                            fieldName="deal_metrics_notes"
                            dataField={dealMetrics?.deal_metrics_notes}
                            handleChange={this.handleChangeMetrics}
                            type="notes"
                            fieldList={this.props.fieldList}
                        />
                    </div>
                </div>
                <div>
                    <div style={{ paddingBottom: 15, height: '10px' }}>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(5)}><FontAwesomeIcon icon={faArrowCircleRight} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.props.setTabIndex(3)} title="Back"><FontAwesomeIcon icon={faArrowCircleLeft} /></div>
                        <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.saveDB()} title="Save"><FontAwesomeIcon icon={faSave} /></div>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={() => this.props.approveDB()}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} tooltip="true" title="Submit for Approval" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreditMetrics;