import { Utils } from './../../helper/utils';
import { userBALService } from '../user.bal';
import Moment from 'moment';
const {config} = require('../../config');

const True = true;
const False = false;

const getColumnList = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Public Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, width: 100, hide : true, sort: 'asc'},
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Strategy', 'col': 'Strat', 'agg': 'uniq', 'ag-grid': true, width: 100},
            {'display': 'Investment Code', 'col': 'Investment Code', 'agg': 'uniq', 'ag-grid': true, width: 100},

            {'display': 'Levered Unhedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Levered Unhedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Levered Unhedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Som'], 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Levered Hedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Levered Hedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Levered Hedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Som'], 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Unhedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Unhedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Unhedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl unhedged', 'Capital Asset Prior'], 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Hedged.MTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Hedged.QTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'Unlevered Hedged.YTD', 'agg': 'portfolioReturn', 'args': ['pnl total', 'Capital Asset Prior'], 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            
            {'display': 'MTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'MTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'MTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'MTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},

            {'display': 'QTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'QTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'QTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'QTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},

            {'display': 'YTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Alloc Duration', 'col': 'pnl allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'YTD PNL.Alloc Tail', 'col': 'pnl allocation tail hedge', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'YTD PNL.Alloc Other', 'col': 'pnl allocation other', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 150},
            {'display': 'YTD PNL.Total', 'col': 'pnl total', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},

            {'display': 'MTD CTR.Funding', 'col': 'ctr financing', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Price', 'col': 'ctr price', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.FX', 'col': 'ctr fx', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Int', 'col': 'ctr interest', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Other', 'col': 'ctr other', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Alloc Duration', 'col': 'ctr allocation duration hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'MTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Alloc Tail', 'col': 'ctr allocation hedge', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'MTD CTR.Alloc Other', 'col': 'ctr allocation other', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'MTD CTR.Total', 'col': 'ctr total', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            
            {'display': 'QTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Alloc Duration', 'col': 'ctr allocation duration hedge', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'QTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Alloc Tail', 'col': 'ctr allocation hedge', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'QTD CTR.Alloc Other', 'col': 'ctr allocation other', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'QTD CTR.Total', 'col': 'ctr total', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            
            {'display': 'YTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Alloc Duration', 'col': 'ctr allocation duration hedge', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'YTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Alloc Tail', 'col': 'ctr allocation hedge', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'YTD CTR.Alloc Other', 'col': 'ctr allocation other', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 150},
            {'display': 'YTD CTR.Total', 'col': 'ctr total', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},

            {'display': 'MTD ROI.Asset', 'col': 'Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 120},
            {'display': 'MTD ROI.Capital (Unadj) Avg', 'col': 'Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'MTD ROI.Capital', 'col': 'Capital Total Average', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 120},
            {'display': 'MTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'MTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'MTD ROI.Net ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'MTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'MTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'MTD ROI.Net ROI Total', 'numcols': ['pnl total'], 'denomcols': ['Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},

            {'display': 'QTD ROI.Asset', 'col': 'QTD Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'QTD ROI.Capital (Unadj) Avg', 'col': 'QTD Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'QTD ROI.Capital', 'col': 'QTD Capital Total Average', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'QTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['QTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'QTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['QTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'QTD ROI.Net ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['QTD Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'QTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['QTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'QTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['QTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'QTD ROI.Net ROI Total', 'numcols': ['pnl total'], 'denomcols': ['QTD Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},

            {'display': 'YTD ROI.Asset', 'col': 'YTD Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'YTD ROI.Capital (Unadj) Avg', 'col': 'YTD Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'YTD ROI.Capital', 'col': 'YTD Capital Total Average', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'YTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['YTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'YTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['YTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'YTD ROI.Net ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['YTD Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'YTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['YTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'YTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['YTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'YTD ROI.Net ROI Total', 'numcols': ['pnl total'], 'denomcols': ['YTD Capital Total Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160}
        ]};
}

const getColumnListToggle = async (params) => {
    return  {
        'SnPRatingMap': 
                {'AAA': 1, 'AA+': 2, 'AA': 3, 'AA-': 4, 'A+': 5, 'A': 6, 'A-': 7, 'BBB+': 8, 'BBB': 9, 'BBB-': 10, 'BB+': 11, 'BB': 12, 'BB-': 13, 'B+': 14, 'B': 15, 'B-': 16, 'CCC+': 17, 'CCC': 18, 'CCC-': 19, 'CC': 20, 'C': 21, 'D': 22}, 
        'columnSpecs': [
            {'display': 'Public Private', 'col': 'portfolio', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Country', 'col': 'country', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Sector', 'col': 'gics_sector', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Industry Group', 'col': 'gics_industry_group', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Industry', 'col': 'gics_industry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'GICS Sub Industry', 'col': 'gics_subindustry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Sector', 'col': 'bics_sector', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Industry Group', 'col': 'bics_industry_group', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'BICS Industry', 'col': 'bics_industry', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'TACF Sub Strategy', 'col': 'tacf_substrategy', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'TACOF Sub Strategy', 'col': 'tacof_substrategy', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},   
            {'display': 'Market Risk Sensitivity', 'col': 'market_risk', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Asset Type', 'col': 'asset_type', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Credit Ranking', 'col': 'credit_ranking', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},   
            {'display': 'HoldCo vs OpCo', 'col': 'holdco_opco', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Product Type', 'col': 'product type', 'agg': 'uniq', 'ag-grid': True, width: 100, hide : true, sort: 'asc'},
            {'display': 'Deal', 'col': 'Deal', 'agg': 'uniq', 'ag-grid': true, width: 100, sort: 'asc'},
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'format': 'fundFormat', 'ag-grid': true, width: 100, hide : true, sort: 'asc'},
            {'display': 'Strategy', 'col': 'Strat', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true},
            {'display': 'Investment Code', 'col': 'Investment Code', 'agg': 'uniq', 'ag-grid': true, width: 100, hide : true},
            
            {'display': 'MTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'MTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'MTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'MTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'MTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'MTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'MTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            
            {'display': 'MTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Funding', 'col': 'ctr financing', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'MTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'MTD CTR.Price', 'col': 'ctr price', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'MTD CTR.FX', 'col': 'ctr fx', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'MTD CTR.Int', 'col': 'ctr interest', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'MTD CTR.Other', 'col': 'ctr other', 'agg': 'sumif', 'fltr': {'is current month': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'MTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            
            {'display': 'QTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'QTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'QTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'QTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'QTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'QTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'QTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            
            {'display': 'QTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'QTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'QTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'QTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'QTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'QTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'QTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            
            {'display': 'YTD PNL.Unlevered', 'col': 'pnl unlevered', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Funding', 'col': 'pnl financing', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'millionsFormat2D', 'ag-grid': True, width: 100},
            {'display': 'YTD PNL.Deal Hedge', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            {'display': 'YTD PNL.Price', 'col': 'pnl price', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'YTD PNL.FX', 'col': 'pnl fx', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'YTD PNL.Int', 'col': 'pnl interest', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'YTD PNL.Other', 'col': 'pnl other', 'agg': 'sumif', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100, hide : true},
            {'display': 'YTD PNL.Deal', 'col': 'pnl total deal', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 100},
            
            {'display': 'YTD CTR.Unlevered', 'col': 'ctr unlevered', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Funding', 'col': 'ctr financing', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': False}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            {'display': 'YTD CTR.Deal Hedge', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': True}, 'format': 'percentFormat2P-100All', 'ag-grid': true, width: 100},
            {'display': 'YTD CTR.Price', 'col': 'ctr price', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'YTD CTR.FX', 'col': 'ctr fx', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'YTD CTR.Int', 'col': 'ctr interest', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'YTD CTR.Other', 'col': 'ctr other', 'agg': 'geometricProduct', 'fltr': {'is current year': True, 'is deal hedge': false}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100, hide : true},
            {'display': 'YTD CTR.Deal', 'col': 'ctr total deal', 'agg': 'geometricProduct', 'fltr': {'is current year': True}, 'format': 'percentFormat2P-100All', 'ag-grid': True, width: 100},
            
            {'display': 'MTD ROI.Asset', 'col': 'Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'MTD ROI.Capital (Unadj) Avg', 'col': 'Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current month': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'MTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'MTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'MTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'MTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current month': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},

            {'display': 'QTD ROI.Asset', 'col': 'QTD Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'QTD ROI.Capital (Unadj) Avg', 'col': 'QTD Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current quarter': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'QTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['QTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'QTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['QTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'QTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['QTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'QTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['QTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current quarter': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},

            {'display': 'YTD ROI.Asset', 'col': 'YTD Capital Asset Average', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 130},
            {'display': 'YTD ROI.Capital (Unadj) Avg', 'col': 'YTD Capital Net Raw', 'agg': 'sumif', 'fltr': {'is current year': True}, 'format': 'millionsFormat2D', 'ag-grid': true, width: 180},
            {'display': 'YTD ROI.Gross ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['YTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'YTD ROI.Net (Unadj) ROI Deal', 'numcols': ['pnl total deal'], 'denomcols': ['YTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
            {'display': 'YTD ROI.Gross ROI Total', 'numcols': ['pnl total'], 'denomcols': ['YTD Capital Asset Average'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 160},
            {'display': 'YTD ROI.Net (Unadj) ROI Total', 'numcols': ['pnl total'], 'denomcols': ['YTD Capital Net Raw'], 'agg': 'aggDiv', 'fltr': {'is current year': True}, 'format': 'percentFormat2', 'ag-grid': true, width: 200},
        ]};
}

export const portfolioPerformanceGridBALService = {
     getPositions
    ,getColumnList
    ,getColumnListToggle
    ,getRowData
    ,getAutoGroupColumnDef
};

function getIndentClass(params) {
    var indent = 0;
    var node = params.node;
    while (node && node.parent) {
      indent++;
      node = node.parent;
    }
    return ['indent-' + indent];
}

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Group'
        ,field: 'deal'
        ,pinned: 'left'
        ,hide: false
        ,cellRendererParams:  { suppressCount: true }
        ,cellClass: getIndentClass
       };    
}

function getRowData(params) {
    return getPositions(params);
}

function getPositions(params) {
    var {AsOfDate, Frequency} = params;
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    var asdate = AsOfDate ? Moment(AsOfDate).format('YYYYMMDD') : '';
    Frequency = Frequency ? Frequency : '';
    return fetch(`${config.apiUrl}/allposition?asofdate=${asdate}&frequency=${Frequency}`, requestOptions).then(Utils.handleResponse).then(positions => { return positions; });
}