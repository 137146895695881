import React, { Component } from 'react';
import TorGrid from '../torgrid/torgrid';
import ChartDisplay from "./components/chartDisplay.js"
import moment from 'moment';

class StressView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hedgeData: [],
            chartData: []
        }

        this.filterData = this.filterData.bind(this);
        this.filterChartData = this.filterChartData.bind(this);
    }

    render() {
        return this.getTemplate();
    }

    filterData(colName, value) {
        var data = this.props.hedgeData;
        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                hedgeData: arr
            });
        } else {
            this.setState({
                hedgeData: this.props.hedgeData
            });
        }
    }

    filterChartData(colName, value, freq) {
        var data = this.props.chartData;
        var AsOfDate = moment(this.props.AsOfDate).format("YYYYMMDD");

        data = data.filter(function (item) {
            return item['for frequency'] === freq
        });

        data = data.filter(function (item) {
            return item['report date']  <= AsOfDate
        });

        if (value !== 'All') {
            var arr = data.filter(function (item) {
                return item[colName] === value
            });

            this.setState({
                chartData: arr
            });
        } else {
            this.setState({
                chartData: data
            });
        }
    }

    componentDidMount() {
        if (this.props.fund && this.props.hedgeData && this.props.chartData) {
            this.setState({
                hedgeData: [],
                chartData: []
            }, () => {
                this.filterData('Fund', this.props.fund)
                this.filterChartData('fund', this.props.fund, this.props.Frequency)
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fund !== this.props.fund || prevProps.AsOfDate !== this.props.AsOfDate) {
            this.filterData('Fund', this.props.fund);
            this.filterChartData('fund', this.props.fund, this.props.Frequency)
        }
    }

    getTemplate() {
        if (this.props.GroupBy && this.props.gridData.length > 0) {
            return (
                <div style={{ height: '100%', width: '100%', paddingTop: '15px' }}>
                    <div>
                        <TorGrid
                            guikey="stressSummary"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}

                            rowData={this.props.gridData.concat(this.state.hedgeData)}
                            groupColumns={['stress type', 'Risk Type']}
                            groupDefaultExpanded={1}

                            gridIdentityColumn="positions_id"
                            toggleColumn = {this.props.fund === "All"}
                            groupIncludeTotalFooter={true}
                            suppressExpandablePivotGroups={true}
                            className="dashboardgrid"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            domLayout="autoHeight"

                            defaultColDef={this.props.defaultColDef ??
                            {
                                sortable: false
                                , filter: false
                                , floatingFilter: false
                                , enableRowGroup: true
                                , resizable: true
                                , suppressSizeToFit: false
                                , flex: 1
                            }
                            }
                            sideBar='none'
                            gridTitle='Stress Summary'

                        />
                    </div>
                    <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <div style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                            <div style={{ float: 'right', height: '70', paddingLeft: '10px' }} >
                                <TorGrid
                                    guikey="dealStress"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='asset stress pnl'
                                    gridIdentityColumn="positions_id"

                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , minWidth: 20
                                        , flex: 1
                                    }
                                    }

                                    sideBar='none'
                                    gridTitle='Top Deal Stress'
                                    pagination={true}
                                />
                            </div>
                            <div style={{ float: 'right', height: '70', paddingLeft: '25px' }} >
                                <TorGrid
                                    guikey="cDRisk100"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='cswpct_100'
                                    gridIdentityColumn="positions_id"

                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , minWidth: 20
                                        , flex: 1
                                    }
                                    }

                                    sideBar='none'
                                    gridTitle='Top CD +100% Risk'

                                    pagination={true}
                                />
                            </div>
                            <div style={{ float: 'right', height: '70', paddingLeft: '25px' }} >
                                <TorGrid
                                    guikey='cDRisk'
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='cs 100bp'
                                    gridIdentityColumn="positions_id"

                                    domLayout='autoHeight'

                                    suppressExpandablePivotGroups={true}
                                    className='dashboardgrid nototal'
                                    rowGroupPanelShow='none'
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , minWidth: 20
                                        , flex: 1
                                    }
                                    }

                                    sideBar='none'
                                    gridTitle='Top CD Risk +100bp'

                                    pagination={true}
                                />
                            </div>
                            <div style={{ float: 'right', height: '70', paddingLeft: '25px' }} >
                                <TorGrid
                                    guikey="eqRisk"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='eq delta'
                                    gridIdentityColumn="positions_id"

                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , minWidth: 20
                                        , flex: 1
                                    }
                                    }

                                    sideBar='none'
                                    gridTitle='Top Eq Risk -25%'

                                    pagination={true}
                                />
                            </div>
                            <div style={{ float: 'right', height: '70', paddingLeft: '25px' }} >
                                <TorGrid
                                    guikey="iRRisk"
                                    isHideDateInputs={true}
                                    isHideSaveGridLayout={true}

                                    rowData={this.props.gridData}
                                    ignoreBlanks='ir 100bp'
                                    gridIdentityColumn="positions_id"

                                    domLayout="autoHeight"

                                    suppressExpandablePivotGroups={true}
                                    className="dashboardgrid nototal"
                                    rowGroupPanelShow="none"
                                    enableFilter={false}

                                    defaultColDef={this.props.defaultColDef ??
                                    {
                                        sortable: false
                                        , filter: false
                                        , floatingFilter: false
                                        , enableRowGroup: true
                                        , resizable: true
                                        , suppressSizeToFit: false
                                        , minWidth: 20
                                        , flex: 1
                                    }
                                    }

                                    sideBar='none'
                                    gridTitle='Top IR Risk +100bp'

                                    pagination={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingBottom: '50px' }}>
                        <div style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', display: 'grid', height: '100%', width: '100%', position: 'relative' }}>
                            <div style ={{width: '95%'}}>
                                <ChartDisplay
                                    chartTitle="Stress Test"
                                    chartType='line-grouped'
                                    xKey='format report date'
                                    yKeys={['asset stress pnl']}
                                    yName='Stress Test'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style ={{width: '95%'}}>
                                <ChartDisplay
                                    chartTitle="CD Spread +100%"
                                    chartType='line-grouped'
                                    xKey='format report date'
                                    yKeys={['cswpct_100']}
                                    yName='CD Spread +100%'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style ={{width: '95%'}}>
                                <ChartDisplay
                                    chartTitle="CD Spread +100bp"
                                    chartType='line-grouped'
                                    xKey='format report date'
                                    yKeys={['cs 100bp']}
                                    yName='CD Spred +100bp'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style ={{width: '95%'}}>
                                <ChartDisplay
                                    chartTitle="Eq Risk -25%"
                                    chartType='line-grouped'
                                    xKey='format report date'
                                    yKeys={['eq -25']}
                                    yName='Eq Risk -25%'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            <div style ={{width: '95%'}}>
                                <ChartDisplay
                                    chartTitle="IR +100bp"
                                    chartType='line-grouped'
                                    xKey='format report date'
                                    yKeys={['ir 100bp']}
                                    yName='IR +100bp'
                                    dataList={this.state.chartData}
                                    rotationAngle={335}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
export default StressView;