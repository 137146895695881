
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Name', 'col': 'deal', 'agg': 'uniq', 'ag-grid': true, minWidth : 180}, 
            {'display': 'Approver', 'col': 'full_name', 'agg': 'uniq', 'ag-grid': true, minWidth : 160 },
            /*{'display': 'Analyst 3', 'col': 'analyst3', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150},
            {'display': 'Status.Deal Attributes', 'col': 'statusDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 160}, 
            {'display': 'Status.Tranche Attributes', 'col': 'statusTrancheAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 170}, 
            {'display': 'Status Underwriting.Deal Attributes', 'col': 'statusUnderwritingDealAttributes', 'agg': 'uniq', 'ag-grid': true, maxWidth : 150}, 
            {'display': 'Status Underwriting.Deal Risk', 'col': 'statusUnderwritingDealRisk', 'agg': 'uniq', 'ag-grid': true, maxWidth : 120}, 
            {'display': 'Status Underwriting.Deal Metrics', 'col': 'statusUnderwritingDealMetrics', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}, 
            {'display': 'Status Underwriting.Deal Returns', 'col': 'statusUnderwritingDealReturns', 'agg': 'uniq', 'ag-grid': true, maxWidth : 130}
            */
        ]
    };
}

export const approvalReminderBALService = {
    getColumnList,
    getAutoGroupColumnDef 
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: '',
        field: '',
        maxWidth: 80,
        pinned: 'left',
      };    
}
