import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const SideDrawer = (props) => {
    const classes = useStyles();

    const toggleDrawer = () => (event) => {
        props.toggleDrawer();
    };

    const toggleTab = (val) => (event) => {
        toggleDrawer();
        props.toggleTabIndex(val);
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
 
            <List>
                <ListItem disabled>
                    <ListItemText primary={"Performance"} />
                </ListItem>
                <Divider />
                {[  {id: 2, text: 'PNL'}, 
                    {id: 3, text: 'Cashflows'}, 
                    {id: 4, text: 'Projection'},
                    {id: 8, text: 'Trades'},
                    {id: 9, text: 'Mark History'}
                    ].map((text, index) => (
                    <ListItem button key={text.id} onClick = {toggleTab(text.id)}>
                        <ListItemText primary={text.text} style = {props.tabindex === text.id ? {textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px"} : {paddingLeft: "10px"}} />
                    </ListItem>
                ))}
            </List>
            <List>
                <ListItem disabled>
                    <ListItemText primary={"Exposure"} />
                </ListItem>
                <Divider />
                {[  
                    {id: 5, text: 'Overview'}, 
                    {id: 6, text: 'Risk'}, 
                    // {id: 7, text: 'Credit Metrics'} 
                    ].map((text, index) => (
                    <ListItem button key={text.id} onClick = {toggleTab(text.id)}>
                        <ListItemText primary={text.text} style = {props.tabindex === text.id ? {textDecoration: "underline #3895D3", color: "#3895D3", paddingLeft: "10px"} : {paddingLeft: "10px"}} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div style={{ height: "100%", width: '100%'}}>
            <React.Fragment key={'left'}>
                <SwipeableDrawer
                    anchor={'left'}
                    open={props.show}
                    onClose={toggleDrawer()}
                    onOpen={toggleDrawer()}
                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

export default SideDrawer;