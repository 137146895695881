import { userBALService } from '../user.bal';
import { Utils } from '../../helper/utils';
const {config} = require('../../config');

const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {'display': 'Fund', 'col': 'Fund', 'agg': 'uniq', 'ag-grid': true, width : 120, hide: true},
            {'display': 'PB', 'col': 'PB', 'agg': 'uniq', 'ag-grid': true, width : 80},
            {'display': 'Margin', 'col': 'Margin Balance', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120},
            {'display': 'Cash', 'col': 'Cash Balance', 'agg': 'sum', 'format': 'decimalFormat0', 'ag-grid': true, width : 120}
        ]};
}

export const cashMarginBalService = {
     getPositions
    ,getColumnList
    ,getRowData
    ,getAutoGroupColumnDef
};

function getAutoGroupColumnDef(params) {
    return {
         headerName: 'Fund'
        ,field: 'description'
        ,width: 150
        ,pinned: 'left'
        ,resizable: true
        ,cellRendererParams:  { suppressCount: true } 
      };    
}

function getRowData(query) {
    return getPositions(query);
}

function getPositions(query) {
    const requestOptions = { method: 'POST', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(query) };
    return fetch(`${config.apiDDSUrl}/spdata`, requestOptions).then(Utils.handleResponse);
}