import { s3BALService } from '../../bal/s3.bal';
import React, { Component  } from "react";
import Modal from 'react-awesome-modal';

class FileAttachmentEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value : this.props.value,
      modalVisible: false,
      modalType: 'close',
      modalHeading: '',
      modalHandler: '',

      //files : [{fileName : 'aa.txt', fileFullPath : 'c:\\temp\\aa.txt'} , {fileName : 'bb.txt', fileFullPath : 'c:\\temp\\bb.txt'} ]
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setFile = this.setFile.bind(this);
    this.upload = this.upload.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.remove = this.remove.bind(this);
    this.getFiles = this.getFiles.bind(this);
    if(!this.state?.value?.areFilesLoaded) {
      this.getFiles();
    }
  }

  isPopup() {
    return true;
  }

  setFile(e) {
      this.setState({
          file: e.target.files[0]
      });
  }

  closeModal() {
    if (this.state.modalHandler === 'refresh') {
        window.location.reload();
    }
    this.setState({
        modalVisible: false
    });
  }

  remove(fileFullPath, fileName) { 
    s3BALService.deleteFile({fileName : fileFullPath, type: this.props.value.fileType}).then(data => {
        this.updateFiles(data);
    }, error => {
        this.setState({ 
            showMessage: true, 
            errorList: [error], 
            messageHeader : "Error while removing the attached file:",
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: 'Attached file could not be removed, please contact support!'
        });
    });
}

updateFiles (data) {  
  const { value } = this.state;
  let colId = this.props.column.colId;
  var valueNew = {...value, files: data.files, areFilesLoaded: true }
  this.props.node.setDataValue(colId, valueNew);
  this.setState({ value: valueNew  });
}

  getFiles() {
    const { value } = this.state;
    const { path } = value;  
      s3BALService.getFiles({ path: path.replace(/~/g, '/') }).then(data => {
        this.updateFiles(data);
      });
  }

  upload(e) {
    const { file, value } = this.state;
    const { path } = value;
    if(file) {
        s3BALService.uploadFile({file: file, path: path }).then(data => {
                this.setState({
                    showMessage: false, 
                    file: null,
                    errorList: null, 
                    messageHeader : "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'File uploaded successfully!',
                    open: false
                });
                this.updateFiles(data);
            }, error => {
                this.setState({ 
                    showMessage: true, 
                    errorList: [error], 
                    messageHeader : "Error while uploading the data :",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'save',
                    modalHeading: 'File could not be uploaded, please contact support!'
                });
            }
        );
    }
  }
  
  handleKeyPress(event) {
    if (event.key === 'Enter') {
    }
  };

  render() {
    const { modalHeading } = this.state;
    const files = this.state?.value?.files;
    return <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
    <div>
      <div style={{ float: "left", paddingDown: "15px", paddingRight: "7px" }} >
          Select file to upload : <input type="file" onChange={file => this.setFile(file) } /> 
      </div>
      <div style={{ float: "left", paddingDown: "15px", paddingRight: "50px" }} onClick={this.upload}>
          <button key='uploadButton' className="blueB-no-margin">Upload</button> 
      </div> 
    </div> 
    <div style={{ display: 'grid'}}>
            {files && files.length > 0 && <div style={{ borderTop:'none', border: '1px solid black', width:"964px", float: "left", paddingRight: "55px"}}><h2>Uploaded Files:</h2></div>}
            {files &&
            files.map((file, index) => ( 
                <div style={{ height:'43px'}}>
                    <div style={{borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left",  paddingRight: "15px", width:"600px" }}>
                        <div style={{ paddingLeft:'5px', width:"500px", height:'42px' }}>
                            <h3>{file.fileName.substring(file.fileName.indexOf('_') + 1)}</h3>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'5px', paddingTop:'2px', paddingBottom: "2px", height:'100%', borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left", cursor: "pointer" }} onClick={() => this.download(file.fileFullPath, file.fileName)}>
                        <button key='downloadButton' className="blueB-no-margin">Download</button>
                    </div>
                    <div style={{ paddingLeft:'5px', paddingTop:'2px', paddingBottom: "2px", height:'100%', borderRight: '1px solid black', borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left" }} onClick={() => this.remove(file.fileFullPath)}>
                        <button key='removeButton' className="blueB-no-margin">Remove</button>
                    </div> 
                </div> 
            ))}
    </div> 
    <div>
        <section>
            {
                this.state.modalType === 'close'
                    ?
                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                            <h1>{modalHeading}</h1>
                            <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                        </div>
                    </Modal>
                    :
                    this.state.modalType === 'saveChanges'
                    ?
                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                            <h1>{modalHeading}</h1>
                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                        </div>
                    </Modal>
                    :
                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                        <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                            <h1>{modalHeading}</h1>
                            <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                            <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                            <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                        </div>
                    </Modal>
            }
        </section>
    </div>
  </div>
  }
}

export default FileAttachmentEditor;